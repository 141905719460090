import React from 'react'
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function VerifiedIcon({fontSize = '1.9rem', color = 'var(--stock-change-pos)'}) {
  return (
    <FontAwesomeIcon icon={faCheckSquare} style={{fontSize: fontSize, color: color}}/> 
  )
}

export default VerifiedIcon