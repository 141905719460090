import { useState } from "react";
import Modal from "./subcomponents/Modal";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "../styles/Leaderboard.css";
import "../styles/bootstrap.css";
const Analytics = () => {
  const [isOpen, setIsOpen] = useState(true);

  //eslint-disable-next-line
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const analyticstoken = localStorage.getItem("analyticstoken");
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <>
      {!analyticstoken && <Modal isOpen={isOpen} onClose={closeModal} />}
      {analyticstoken && (
        <div>
          <div class="FeedContainer leaderboard-feed-container">
            <div className="d-flex">
              <div className="sidebars-analytics">
                <div>
                  <ul>
                    <li
                      className={
                        location.pathname === "/analytics/" ? "active" : ""
                      }
                      onClick={() => {
                        navigate("/analytics/");
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-grid"
                      >
                        <rect x="3" y="3" width="7" height="7"></rect>
                        <rect x="14" y="3" width="7" height="7"></rect>
                        <rect x="14" y="14" width="7" height="7"></rect>
                        <rect x="3" y="14" width="7" height="7"></rect>
                      </svg>
                      Dashboard
                    </li>
                    <li
                      onClick={() => {
                        navigate("/analytics/list");
                      }}
                      className={
                        location.pathname === "/analytics/list" ? "active" : ""
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-list"
                      >
                        <line x1="8" y1="6" x2="21" y2="6"></line>
                        <line x1="8" y1="12" x2="21" y2="12"></line>
                        <line x1="8" y1="18" x2="21" y2="18"></line>
                        <line x1="3" y1="6" x2="3.01" y2="6"></line>
                        <line x1="3" y1="12" x2="3.01" y2="12"></line>
                        <line x1="3" y1="18" x2="3.01" y2="18"></line>
                      </svg>
                      List
                    </li>
                    <li
                      onClick={() => {
                        localStorage.removeItem("analyticstoken");
                        window.location.reload();
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="feather feather-log-out"
                      >
                        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                        <polyline points="16 17 21 12 16 7"></polyline>
                        <line x1="21" y1="12" x2="9" y2="12"></line>
                      </svg>
                      Logout
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex2container px-5">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Analytics;
