import React, { useEffect, useState, useRef, useContext } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import '../styles/Stock.css' // re-using Stock.css
import { createStockChart } from '../helper/StockChartHelper'
import {
  getUnixTimestamp,
  formatNumber,
  getUnixTimestampRange,
  getESTDate,
  intervalMap,
  filters,
  getThreeMonthsAgo,
  getMarketStatus,
  filterHighMap,
  isOldCompany,
  getLastValidTradingDay,
  timespanToMilliseconds,
  handleBuyAsset,
  handleSellAsset,
  fetchUserPortfolio,
  fetchAllTransactions,
  fetchDailyOpenClose,
  getDailyChange,
  fetchCompanyDetails,
  fetchStockLists,
  calculateReturns,
  getChangePercentageColor,
  handleRemoveFromList,
  handleAddToList,
  getWatchList,
  getTickerNews,
  fetchAllPortfolios,
  // If you have limit-order helpers, import them here:
  handleLimitBuyAsset,
  handleLimitSellAsset,
} from '../helper/MarketsHelper'
import { AuthContext } from '../context/AuthContext'
import config from '../config'
import {
  LoadingSpinner,
  formatCurrency as fc,
  formatModifiedTicker,
  removeCommas as rc,
  customStylesPlus,
  // For comma-formatting:
  formatWithCommas2 as fc2,
  // (If you have a custom DateInput, import it)
  CustomDateInput,
  convertToReadableDate,
} from '../helper/StylesHelper'
import { ThemeContext } from '../context/ThemeContext'
import MarketSearchBar from './MarketSearchBar'
import BackButton from './BackButton'
import useResponsiveWidth from '../hooks/useResponsiveWidth'
import { shareTrade } from '../helper/CreatePostHelper'
import Select from 'react-select'

// --- If your Stock code uses react-datepicker and MUI for the expiration date:
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Select as MuiSelect, MenuItem, FormControl, InputLabel } from '@mui/material'

export default function Crypto() {
  useResponsiveWidth()

  const { ticker } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const { tc, theme } = useContext(ThemeContext)
  const { isAuthenticated, userToken } = useContext(AuthContext)

  const [stockData, setStockData] = useState([])
  const [dailyData, setDailyData] = useState({})
  const [filter, setFilter] = useState('1d')
  const [currentPrice, setCurrentPrice] = useState(null)
  const [changeAmount, setChangeAmount] = useState(null)
  const [changePercent, setChangePercent] = useState(null)
  const [stockName, setStockName] = useState(ticker)
  const [companyDetails, setCompanyDetails] = useState({})
  const [companyDescription, setCompanyDescription] = useState('')
  const [periodHigh, setPeriodHigh] = useState(null)
  const [periodLow, setPeriodLow] = useState(null)
  const [dateIPO, setDateIPO] = useState(null)
  const [mainStockDataFetched, setMainStockDataFetched] = useState(false)
  const [companyDetailsFetched, setCompanyDetailsFetched] = useState(false)

  const [listOptions, setListOptions] = useState([])
  const [selectedLists, setSelectedLists] = useState([])

  const chartRef = useRef(null)
  const chartInstance = useRef(null)
  const [referencePriceForChart, setReferencePriceForChart] = useState(null)
  const [marketStatus, setMarketStatus] = useState(null)
  const [dailyChangeData, setDailyChangeData] = useState(null)
  const [lastValidTradingDay, setLastValidTradingDay] = useState(null)
  const [livePrice, setLivePrice] = useState(null)
  const [liveChangeAmount, setLiveChangeAmount] = useState(null)
  const [liveChangePercent, setLiveChangePercent] = useState(null)

  const [hoveredPrice, setHoveredPrice] = useState(null)
  const [hoveredChangeAmount, setHoveredChangeAmount] = useState(null)
  const [hoveredChangePercent, setHoveredChangePercent] = useState(null)
  const [hoveredDateTime, setHoveredDateTime] = useState(null)
  const [isHovering, setIsHovering] = useState(false)

  const [openBuyModal, setOpenBuyModal] = useState(false)
  const [openSellModal, setOpenSellModal] = useState(false)
  const [quantity, setQuantity] = useState(null)
  const [estimatedCost, setEstimatedCost] = useState(null)

  const [bidAsk, setBidAsk] = useState({ bid: null, ask: null })

  const [userPortfolio, setUserPortfolio] = useState(null)
  const [userTickerPosition, setUserTickerPosition] = useState(null)
  const [allTransactions, setAllTransactions] = useState([])
  const [returnData, setReturnData] = useState(null)
  const [isInWatchlist, setIsInWatchlist] = useState(false)
  const [stockNews, setStockNews] = useState([])
  const [isConfirming, setIsConfirming] = useState(false)
  const [isCongrats, setIsCongrats] = useState(false)
  const [transactionId, setTransactionId] = useState(null)

  const [allPortfolios, setAllPortfolios] = useState([])
  const [selectedPortfolio, setSelectedPortfolio] = useState(null)

  // For fractional input in buy/sell
  const [sharesInput, setSharesInput] = useState('')
  const [totalInput, setTotalInput] = useState('')

  // Add-cash “arrow/box” states
  const [openAddCashBox, setOpenAddCashBox] = useState(false)
  const [addCashAmount, setAddCashAmount] = useState('')
  const [isAddCashConfirming, setIsAddCashConfirming] = useState(false)
  const [isAddCashCongrats, setIsAddCashCongrats] = useState(false)
  const cachedBuyModal = useRef(null)
  const cachedSellModal = useRef(null)

  const wsRef = useRef(null)
  const instantPriceTimeoutRef = useRef(null)

  // --- NEW STATES FOR LIMIT ORDERS (copied from Stock code) ---
  const [orderType, setOrderType] = useState('market') // "market" or "limit"
  const [limitPriceInput, setLimitPriceInput] = useState('')
  const [limitPrice, setLimitPrice] = useState(null)
  const [selectedLimitOrderExpiration, setSelectedLimitOrderExpiration] = useState(null)
  const [limitOrderData, setLimitOrderData] = useState(null)

  // If you want a single function to reset all transaction states:
  const cancelAllTransactionStates = () => {
    setIsConfirming(false)
    setIsCongrats(false)
    setTransactionId(null)
    setOpenAddCashBox(false)
    setIsAddCashConfirming(false)
    setIsAddCashCongrats(false)
    setAddCashAmount('')
    // any other error text or state resets if needed
  }

  // ---------------------
  //  Watchlist & General
  // ---------------------
  useEffect(() => {
    if (isAuthenticated) {
      getWatchList(ticker, userToken, setIsInWatchlist)
    }
  }, [isAuthenticated, userToken, ticker])

  useEffect(() => {
    if (isAuthenticated && userToken) {
      fetchAllPortfolios(
        isAuthenticated,
        userToken,
        setAllPortfolios,
        setSelectedPortfolio
      )
    }
  }, [isAuthenticated, userToken])

  useEffect(() => {
    if (selectedPortfolio && selectedPortfolio._id) {
      fetchUserPortfolio(
        isAuthenticated,
        userToken,
        setUserPortfolio,
        selectedPortfolio._id
      )
      fetchAllTransactions(
        isAuthenticated,
        userToken,
        ticker,
        setAllTransactions,
        selectedPortfolio._id
      )
    }
  }, [selectedPortfolio?._id, userToken, isAuthenticated, ticker])

  useEffect(() => {
    if (userPortfolio && ticker) {
      setUserTickerPosition(
        userPortfolio?.assets.find(
          (stock) => stock?.ticker?.toUpperCase() === ticker?.toUpperCase()
        )
      )
    }
  }, [userPortfolio, ticker])

  useEffect(() => {
    if ((livePrice || currentPrice) && userTickerPosition && allTransactions) {
      let returns = calculateReturns(
        userTickerPosition,
        rc(livePrice || currentPrice),
        allTransactions
      )
      setReturnData(returns)
    }
  }, [livePrice, currentPrice, userTickerPosition, allTransactions])

  // Re-fetch stockLists on mount
  useEffect(() => {
    if (isAuthenticated && userToken) {
      fetchStockLists(
        isAuthenticated,
        userToken,
        setListOptions,
        setSelectedLists,
        ticker
      )
    }
  }, [isAuthenticated, userToken, ticker])

  useEffect(() => {
    if (livePrice || currentPrice) {
      setEstimatedCost(quantity * rc(livePrice || currentPrice))
    }
  }, [livePrice, quantity, currentPrice])

  // -----------
  //  Websocket
  // -----------
  const updateChanges = (newPrice) => {
    if (!referencePriceForChart) return

    setLivePrice(newPrice)
    const change = newPrice - referencePriceForChart
    const changePercentage = (change / referencePriceForChart) * 100

    if (filter === '1d') {
      let priceChange
      if (dailyChangeData?.todaysClosePrice) {
        priceChange =
          dailyChangeData.todaysClosePrice - dailyChangeData?.prevClosePrice
        setLiveChangeAmount(priceChange)
        setLiveChangePercent(dailyChangeData?.todaysPercentChange)
      } else {
        priceChange = newPrice - dailyChangeData?.prevClosePrice
        setLiveChangeAmount(priceChange)
        setLiveChangePercent(changePercentage)
      }
    } else {
      setLiveChangeAmount(change)
      setLiveChangePercent(changePercentage)
    }
  }

  // Periodically ask for the instantPrice
  useEffect(() => {
    const fetchPrice = () => {
      if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
        wsRef.current.send(
          JSON.stringify({ action: 'getInstantPrice', ticker })
        )
      }
    }
    const intervalId = setInterval(fetchPrice, 1000)
    return () => {
      clearInterval(intervalId)
    }
  }, [ticker])

  const sendInterval = (intervalMs) => {
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      wsRef.current.send(
        JSON.stringify({
          action: 'setInterval',
          ticker,
          intervalMs,
        })
      )
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
        const [multiplier, timespan] = intervalMap[filter]
        const intervalMs = timespanToMilliseconds(multiplier, timespan)
        sendInterval(intervalMs)
        clearInterval(interval)
      }
    }, 500)
    return () => clearInterval(interval)
  }, [filter, ticker])

  // -----------
  //  Buy & Sell
  // -----------
  const handleBuyClick = () => {
    if (!isAuthenticated) {
      alert('Please sign in to access this feature')
      return
    }
    setOpenBuyModal(true)
  }

  const handleSellClick = () => {
    if (!isAuthenticated) {
      alert('Please sign in to access this feature')
      return
    }
    setOpenSellModal(true)
  }

  const handleCloseModal = () => {
    setOpenBuyModal(false)
    setOpenSellModal(false)
    setIsConfirming(false)
    setIsCongrats(false)
    setTransactionId(null)
    if (instantPriceTimeoutRef.current) {
      clearTimeout(instantPriceTimeoutRef.current)
    }

    // Reset add-cash if open
    setOpenAddCashBox(false)
    setIsAddCashConfirming(false)
    setIsAddCashCongrats(false)
    setAddCashAmount('')
  }

  // ----------
  //  Market Status
  // ----------
  useEffect(() => {
    getMarketStatus().then((data) => {
      setMarketStatus(data)
    })
  }, [])

  // -----------
  //  Data Fetch
  // -----------
  const fetchStockData = async () => {
    setChangeAmount(null)
    setPeriodHigh(null)
    setPeriodLow(null)
    setMainStockDataFetched(false)
    setLiveChangeAmount(null)
    setLiveChangePercent(null)
    setLivePrice(null)

    try {
      let fromDateUnixMs
      let multiplier, timespan
      let referencePrice
      let toDateUnixMs = getUnixTimestamp()

      if (filter === 'all time') {
        // Earliest possible for crypto
        const earliestAvailableDateStr = '2003-08-01'
        fromDateUnixMs = earliestAvailableDateStr
      } else if (filter === '3m') {
        fromDateUnixMs = getThreeMonthsAgo()
      } else if (filter === '1d') {
        // Past 24h
        fromDateUnixMs = toDateUnixMs - 24 * 60 * 60 * 1000
      } else if (filter === '1w') {
        fromDateUnixMs = toDateUnixMs - 7 * 24 * 60 * 60 * 1000
      } else {
        fromDateUnixMs = getUnixTimestampRange(filter)
      }

      const intervalArray = intervalMap[filter]
      multiplier = intervalArray[0]
      timespan = intervalArray[1]

      const response = await axios.get(
        `${config.backendUrl}/api/markets/stock/datapoints`,
        {
          params: {
            ticker,
            fromDateUnixMs,
            toDateUnixMs,
            multiplier,
            timespan,
          },
        }
      )
      const data = response.data || []
      setStockData(data)

      if (data.length > 0) {
        const latestPrice = rc(
          livePrice || currentPrice || data[data.length - 1].c
        )

        // If you have a referencePrice from daily data, set it here if needed:
        // if (someCondition) referencePrice = dailyChangeData?.prevClosePrice ?? something

        if (referencePrice) {
          const change = latestPrice - referencePrice
          const changePercentage = (change / referencePrice) * 100
          if (filter === '1d') {
            let priceChange =
              (dailyChangeData?.todaysClosePrice
                ? rc(dailyChangeData.todaysClosePrice)
                : latestPrice) - rc(dailyChangeData?.prevClosePrice)
            setChangeAmount(priceChange)
            setChangePercent(dailyChangeData.todaysPercentChange)
          } else {
            setChangeAmount(change)
            setChangePercent(changePercentage)
          }
        } else {
          const startPrice = rc(data[0].c)
          const change = latestPrice - startPrice
          const changePercentage = (change / startPrice) * 100
          setChangeAmount(change)
          setChangePercent(changePercentage)
        }

        const highPrices = data.map((day) => day.h)
        const lowestPrices = data.map((day) => day.l)
        const highestPrice = Math.max(...highPrices)
        const lowestPrice = Math.min(...lowestPrices)
        setPeriodHigh(highestPrice)
        setPeriodLow(lowestPrice)

        setReferencePriceForChart(referencePrice || data[0].c)
      } else {
        setChangeAmount(null)
        setChangePercent(null)
        setPeriodHigh(null)
        setPeriodLow(null)
      }
    } catch (error) {
      console.error('Error fetching stock data:', error)
    }
    setMainStockDataFetched(true)
  }

  // fetch daily price
  useEffect(() => {
    getDailyChange(ticker, setCurrentPrice, setDailyChangeData)
  }, [ticker])

  // last valid day - might or might not be used for crypto
  useEffect(() => {
    if (marketStatus) {
      getLastValidTradingDay(marketStatus)
        .then((tradingDay) => {
          setLastValidTradingDay(tradingDay)
        })
        .catch((error) => {
          console.error('Error fetching the last valid trading day:', error)
        })
    }
  }, [marketStatus])

  const setCompanyStates = (companyInfo) => {
    setDateIPO(companyInfo.listDate)
    setStockName(companyInfo.name || ticker)
    setCompanyDetails({
      marketCap: companyInfo.marketCap,
    })

    if (
      companyInfo.description === 'No description available.' ||
      !companyInfo.description
    ) {
      return
    }
    setCompanyDescription(companyInfo.description || '')
  }

  useEffect(() => {
    fetchCompanyDetails(ticker, setCompanyStates, setCompanyDetailsFetched)
  }, [ticker])

  useEffect(() => {
    if (!dailyChangeData || !filter) {
      return
    }
    fetchStockData()
    // eslint-disable-next-line
  }, [filter, dailyChangeData])

  const buyOrSellAssetCallback = () => {
    setIsCongrats(true)
    if (selectedPortfolio && selectedPortfolio._id) {
      fetchUserPortfolio(
        isAuthenticated,
        userToken,
        setUserPortfolio,
        selectedPortfolio._id
      )
      fetchAllTransactions(
        isAuthenticated,
        userToken,
        ticker,
        setAllTransactions,
        selectedPortfolio._id
      )
    }
  }

  // daily open close
  useEffect(() => {
    if (lastValidTradingDay) {
      fetchDailyOpenClose(lastValidTradingDay, ticker, setDailyData)
    }
  }, [lastValidTradingDay, ticker])

  // -----------
  //  Chart
  // -----------
  useEffect(() => {
    if (!chartRef?.current) return
    const cleanup = createStockChart({
      chartRef,
      chartInstanceRef: chartInstance,
      stockData,
      referencePriceForChart,
      changeAmount,
      setHoveredPrice,
      setHoveredChangeAmount,
      setHoveredChangePercent,
      setHoveredDateTime,
      setIsHovering,
      theme,
    })
    return cleanup
  }, [
    stockData,
    referencePriceForChart,
    changeAmount,
    theme,
    openBuyModal,
    openSellModal,
    openAddCashBox,
    // If you also want to re-render on changes to limit order modals:
    orderType,
  ])

  // -----------
  //  Add Cash  (arrow/box approach)
  // -----------

  const handleAddCashChange = (e) => {
    setIsAddCashConfirming(false)
    setIsAddCashCongrats(false)

    const rawInput = e.target.value
    // format with commas
    const formattedInput = fc2(rawInput)
    setAddCashAmount(formattedInput)
  }

  const renderAddCashBox = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '2rem',
          alignItems: 'center',
        }}
      >
        <div className="stock-purchase-modal">
          <div className="stock-transaction-content">
            <h2 className="StockName bold">Add Cash</h2>
            {renderPortfolioDropdown()}
            <p className="stock-cash-available">
              Current Cash: ${fc(userPortfolio?.userCash)}
            </p>

            {/* Input for Add Cash: now type="text" and handleAddCashChange */}
            <div
              className="stock-input-section"
              style={{ borderBottom: 'none' }}
            >
              <label htmlFor="add-cash" className="StockName bold">
                Amount
              </label>
              <input
                type="text"
                id="add-cash-input"
                className="StockName StockInput bold"
                value={addCashAmount}
                onChange={handleAddCashChange}
                inputMode="decimal"
                placeholder="0.00"
                autoComplete="off"
              />
            </div>
          </div>

          <div className="stock-transaction-buttons">
            <button
              onClick={() => {
                const numericValue = parseFloat(addCashAmount.replace(/,/g, ''))
                if (numericValue > 0) {
                  setIsAddCashConfirming((prev) => !prev)
                  setIsAddCashCongrats(false)
                }
              }}
              style={{ width: '33%' }}
              className={`CreatePostFeedButton ${
                !isAddCashConfirming ? 'greenButton' : ''
              }`}
              disabled={
                !addCashAmount ||
                isNaN(parseFloat(addCashAmount.replace(/,/g, ''))) ||
                parseFloat(addCashAmount.replace(/,/g, '')) <= 0
              }
            >
              {isAddCashConfirming ? 'Edit' : 'Add'}
            </button>

            <button
              className="CreatePostFeedButton"
              onClick={() => {
                setOpenAddCashBox(false)
                setIsAddCashConfirming(false)
                setIsAddCashCongrats(false)
                setAddCashAmount('')
                // Return to whichever modal was open
                if (cachedBuyModal.current) {
                  setOpenBuyModal(true)
                } else if (cachedSellModal.current) {
                  setOpenSellModal(true)
                }
              }}
            >
              Cancel
            </button>
          </div>
        </div>

        {isAddCashConfirming && (
          <div>
            <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '5rem' }} />
          </div>
        )}

        {isAddCashConfirming && renderAddCashConfirmation()}

        {isAddCashCongrats && (
          <div>
            <FontAwesomeIcon
              icon={faArrowRight}
              style={{ fontSize: '5rem', color: 'var(--stock-change-pos)' }}
            />
          </div>
        )}
        {isAddCashCongrats && renderAddCashCongrats()}
      </div>
    )
  }

  const renderAddCashConfirmation = () => {
    const handleConfirm = async () => {
      try {
        if (!selectedPortfolio || !selectedPortfolio._id) {
          alert('No portfolio selected')
          return
        }
        const numericValue = parseFloat(addCashAmount.replace(/,/g, ''))
        const portfolioId = selectedPortfolio._id
        await axios.post(
          `${config.backendUrl}/api/stockActions/addCash`,
          { amount: numericValue, portfolioId },
          {
            headers: { Authorization: `Bearer ${userToken}` },
          }
        )
        fetchUserPortfolio(
          isAuthenticated,
          userToken,
          setUserPortfolio,
          selectedPortfolio._id
        )
        setIsAddCashCongrats(true)
      } catch (error) {
        console.error('Error adding cash:', error)
        alert(
          error.response?.data?.message ||
            'Adding cash failed. Please try again.'
        )
      }
    }

    return (
      <div className="stock-purchase-modal">
        <div className="stock-transaction-content">
          <h2 className="StockName bold">Confirm</h2>
          <div className="StockConfirmWrapper" style={{ border: 'none' }}>
            <span>
              You are adding{' '}
              <span className="bold">
                ${fc(parseFloat(addCashAmount.replace(/,/g, '')))}
              </span>{' '}
              to portfolio:{' '}
              <span className="bold">{selectedPortfolio?.name || '--'}</span>
            </span>
          </div>
        </div>
        <button
          onClick={handleConfirm}
          style={{ width: '33%', alignSelf: 'flex-end', marginTop: '1rem' }}
          className="CreatePostFeedButton greenButton"
        >
          Confirm
        </button>
      </div>
    )
  }

  const renderAddCashCongrats = () => {
    return (
      <div
        className="stock-purchase-modal"
        style={{ borderColor: 'var(--stock-change-pos)' }}
      >
        <div className="stock-transaction-content">
          <h2
            style={{ color: 'var(--stock-change-pos)' }}
            className="StockName bold"
          >
            Congrats!
          </h2>
          <div className="StockConfirmWrapper" style={{ border: 'none' }}>
            <span>You have successfully added cash to your portfolio.</span>
          </div>
        </div>
        <div className="stock-congrats-btns">
          <button
            onClick={() => {
              setOpenAddCashBox(false)
              setIsAddCashConfirming(false)
              setIsAddCashCongrats(false)
              setAddCashAmount('')

              if (cachedBuyModal.current) {
                setOpenBuyModal(true)
              } else if (cachedSellModal.current) {
                setOpenSellModal(true)
              }
            }}
            className="CreatePostFeedButton"
          >
            Done
          </button>
        </div>
      </div>
    )
  }

  // -----------
  //  Buy/Sell modals
  // -----------

  const handleSharesChange = (e) => {
    setIsConfirming(false)
    setIsCongrats(false)

    const rawInput = e.target.value
    // format with commas
    const formattedInput = fc2(rawInput)
    setSharesInput(formattedInput)

    // remove commas for numeric parse
    const parsedShares = parseFloat(formattedInput.replace(/,/g, ''))
    const price = rc(livePrice || currentPrice)

    if (isNaN(parsedShares) || parsedShares < 0) {
      setQuantity(null)
      setEstimatedCost(null)
      setTotalInput('')
      return
    }

    setQuantity(parsedShares)
    const newTotal = price * parsedShares
    setEstimatedCost(newTotal)

    // Format the “Total” with commas as well
    setTotalInput(fc2(newTotal.toFixed(2)))
  }

  const handleTotalChange = (e) => {
    setIsConfirming(false)
    setIsCongrats(false)

    const rawInput = e.target.value
    // format with commas
    const formattedInput = fc2(rawInput)
    setTotalInput(formattedInput)

    // remove commas for numeric parse
    const parsedTotal = parseFloat(formattedInput.replace(/,/g, ''))
    const price = rc(livePrice || currentPrice)

    if (isNaN(parsedTotal) || parsedTotal < 0) {
      setEstimatedCost(null)
      setQuantity(null)
      setSharesInput('')
      return
    }

    setEstimatedCost(parsedTotal)
    const newShares = price ? parsedTotal / price : 0

    setQuantity(newShares)
    setSharesInput(fc2(newShares.toFixed(6)))
  }

  // ------ NEW: handleLimitPriceChange ------
  const handleLimitPriceChange = (e) => {
    setIsConfirming(false)
    setIsCongrats(false)

    const rawInput = e.target.value
    const formattedInput = fc2(rawInput)
    setLimitPriceInput(formattedInput)

    const parsed = parseFloat(rawInput.replace(/,/g, ''))
    if (isNaN(parsed) || parsed < 0) {
      setLimitPrice(null)
      return
    }
    setLimitPrice(parsed)
  }

  // ------ NEW: handleLimitOrderConfirm ------
  const handleLimitOrderConfirm = () => {
    if (isConfirming) {
      setIsCongrats(false)
      const el = document.getElementById('limit-share-input')
      if (el) el.focus()
    }
    setIsConfirming((prev) => !prev)
  }

  // ------ NEW: handleLimitOrderExecute (Buy/Sell) ------
  const handleLimitOrderExecute = async () => {
    const isBuy = openBuyModal ? true : false
    let response;
    try {
      if (isBuy) {
       response =  await handleLimitBuyAsset(
          isAuthenticated,
          userToken,
          quantity,
          ticker,
          selectedPortfolio?._id,
          limitPrice,
          selectedLimitOrderExpiration,
          buyOrSellAssetCallback,
          location.state
        )
      } else {
       response =  await handleLimitSellAsset(
          isAuthenticated,
          userToken,
          quantity,
          ticker,
          selectedPortfolio?._id,
          limitPrice,
          selectedLimitOrderExpiration,
          buyOrSellAssetCallback,
          location.state
        )
      }
      if (response && response.data) {
        setLimitOrderData(response.data.limitOrder);
      }
    } catch (err) {
      console.error('Error executing limit order:', err)
      alert('Error executing limit order. Please try again.')
    }
  }

  // ------ NEW: renderLimitConfirmation ------
  const renderLimitConfirmation = () => {
    const isBuy = openBuyModal
    const totalCost = quantity * (limitPrice || 0)
    const remainingAmount = isBuy
      ? userPortfolio?.userCash - totalCost
      : (userTickerPosition?.quantity || 0) - quantity

    return (
      <div className="stock-purchase-modal">
        <div className="stock-transaction-content">
          <h2 className="StockName bold">Confirm</h2>
          <div className="StockConfirmWrapper">
            <span>
              <span className="bold">{quantity}</span> {formatModifiedTicker(ticker)} shares
            </span>
            <span>
              Limit Price: <span className="bold">${fc(limitPrice)}</span>
            </span>
            <span>
              Expires:{' '}
              <span className="bold">
                {selectedLimitOrderExpiration
                  ?` ${selectedLimitOrderExpiration.toLocaleDateString()} on market close`
                  : '--'}
              </span>
            </span>
          </div>
          <div className="StockConfirmWrapper" style={{ border: 'none' }}>
            <span>
              <span className="bold">${fc(totalCost)}</span> {`${isBuy ? 'maximum total' : 'minimum credit'}`}
            </span>
            <span>
              <span className="bold">
                {isBuy ? (
                  <>
                    ${fc(remainingAmount)}{' '}
                    <span className="medium"> cash remaining </span>
                  </>
                ) : (
                  <>
                    {fc(remainingAmount)}{' '}
                    <span className="medium"> shares remaining </span>
                  </>
                )}
              </span>
            </span>
          </div>
        </div>

        <button
          onClick={handleLimitOrderExecute}
          style={{ width: '33%', alignSelf: 'flex-end', marginTop: '1rem' }}
          className="CreatePostFeedButton greenButton"
        >
          Confirm
        </button>
      </div>
    )
  }

  // ------ NEW: renderLimitCongrats ------
  const renderLimitCongrats = () => {
    return (
      <div
        className="stock-purchase-modal"
        style={{ borderColor: 'var(--stock-change-pos)' }}
      >
        <div className="stock-transaction-content">
          <h2 style={{ color: 'var(--stock-change-pos)' }} className="StockName bold">
            Congrats!
          </h2>
          <div className="StockConfirmWrapper" style={{ border: 'none' }}>
            <p className="PostContentP" style={{ margin: 0 }}>
              Your limit order has been successfully placed. It will execute{' '}
              {openBuyModal
                ? `if the price reaches or goes below $${fc(limitPrice)} by ${convertToReadableDate(limitOrderData?.expiresAt)}. Make sure you have enough cash in your portfolio to cover this order.`
                : `if the price reaches or goes above $${fc(limitPrice)} by ${convertToReadableDate(limitOrderData?.expiresAt)}. Make sure you have enough shares in your portfolio to cover this order.`}
                
            </p>
            <br />
          </div>
        </div>
        <div className="stock-congrats-btns">
          <button
            onClick={() => handleCloseModal()}
            className="CreatePostFeedButton"
          >
            Close
          </button>

        </div>
      </div>
    )
  }

  // --- RENDER SEPARATE: MARKET FLOW vs LIMIT FLOW
  // If user chooses "Market" order, we show the old flow
  const renderMarketOrderFlow = () => {
    const isBuy = openBuyModal
    const availableText = isBuy
      ? `Cash available: $${fc(userPortfolio?.userCash)}`
      : `Shares available: ${fc(userTickerPosition?.quantity) || 0}`

    let buttonLabel = isBuy ? 'Buy' : 'Sell'
    const originalButtonLabel = buttonLabel
    if (isConfirming) buttonLabel = 'Edit'

    const handleConfirmClick = () => {
      if (isConfirming) {
        setIsCongrats(false)
        document.getElementById('share-input')?.focus()
      }
      setIsConfirming((prev) => !prev)
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '2rem',
          alignItems: 'center',
        }}
      >
        <div className="stock-purchase-modal">
          <div className="stock-transaction-content">
            <h2 className="StockName bold">{originalButtonLabel}</h2>
            {renderPortfolioDropdown()}
            <p className="stock-cash-available">{availableText}</p>

            <div className="stock-input-section">
              <label htmlFor="shares" className="StockName bold">
                Shares
              </label>
              <input
                type="text"
                id="share-input"
                className="StockName StockInput bold"
                value={sharesInput}
                onChange={handleSharesChange}
                inputMode="decimal"
                placeholder="0.00"
                autoComplete="off"
              />
            </div>

            <div
              className="stock-input-section"
              style={{ borderBottom: 'none' }}
            >
              <label className="StockName bold">Total</label>
              <input
                type="text"
                className="StockName StockInput bold"
                value={totalInput}
                onChange={handleTotalChange}
                inputMode="decimal"
                placeholder="0.00"
                autoComplete="off"
              />
            </div>
          </div>

          <div className="stock-transaction-buttons">
            {!openAddCashBox ? (
              <button
                className="CreatePostFeedButton"
                onClick={() => {
                  cachedBuyModal.current = openBuyModal
                  cachedSellModal.current = openSellModal
                  setOpenBuyModal(false)
                  setOpenSellModal(false)
                  setIsAddCashConfirming(false)
                  setIsAddCashCongrats(false)
                  setAddCashAmount('')
                  setOpenAddCashBox(true)
                }}
              >
                Add Cash
              </button>
            ) : (
              <button
                className="CreatePostFeedButton"
                onClick={() => {
                  setOpenAddCashBox(false)
                  setIsAddCashConfirming(false)
                  setIsAddCashCongrats(false)
                  setAddCashAmount('')
                }}
              >
                Cancel
              </button>
            )}

            <button
              onClick={handleConfirmClick}
              style={{ width: '33%' }}
              className={`CreatePostFeedButton ${
                !isConfirming && 'greenButton'
              }`}
              disabled={!quantity || quantity <= 0}
            >
              {buttonLabel}
            </button>
          </div>
        </div>

        {isConfirming && (
          <>
            <div>
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{ fontSize: '5rem' }}
              />
            </div>
            {renderConfirmationModal()}
          </>
        )}

        {isCongrats && (
          <>
            <div>
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{ fontSize: '5rem', color: 'var(--stock-change-pos)' }}
              />
            </div>
            {renderCongratsModal()}
          </>
        )}
      </div>
    )
  }

  // The “market” confirm & congrats from the existing code
  const renderConfirmationModal = () => {
    const isBuy = openBuyModal
    const handleConfirm = async () => {
      try {
        let response
        if (isBuy) {
          response = await handleBuyAsset(
            isAuthenticated,
            userToken,
            quantity,
            ticker,
            selectedPortfolio?._id,
            buyOrSellAssetCallback,
            location.state
          )
        } else {
          response = await handleSellAsset(
            isAuthenticated,
            userToken,
            quantity,
            ticker,
            selectedPortfolio?._id,
            buyOrSellAssetCallback,
            location.state
          )
        }
        let transaction = response?.data?.transaction
        if (transaction) {
          setTransactionId(transaction._id)
        }
      } catch (error) {
        console.error('Transaction failed:', error)
        alert(error.response?.data?.message || 'Transaction failed')
        setTransactionId(null)
      }
    }

    const totalCost = quantity * rc(livePrice || currentPrice)
    const isBuyRemaining = userPortfolio?.userCash - totalCost
    const isSellRemaining = (userTickerPosition?.quantity || 0) - quantity
    const remainingAmount = openBuyModal ? isBuyRemaining : isSellRemaining

    return (
      <div className="stock-purchase-modal">
        <div className="stock-transaction-content">
          <h2 className="StockName bold">Confirm</h2>
          <div className="StockConfirmWrapper">
            <span>
              <span className="bold">{quantity}</span>{' '}
              {formatModifiedTicker(ticker)} shares
            </span>
            <span>
              <span className="bold">${fc(rc(livePrice || currentPrice))}</span>{' '}
              share price
            </span>
          </div>
          <div className="StockConfirmWrapper" style={{ border: 'none' }}>
            <span>
              <span className="bold">${fc(totalCost)}</span> total
            </span>
            <span>
              <span className="bold">
                {isBuy ? (
                  <>
                    {' '}
                    ${fc(remainingAmount)}{' '}
                    <span className="medium"> cash remaining </span>
                  </>
                ) : (
                  <>
                    {' '}
                    {fc(remainingAmount)}{' '}
                    <span className="medium"> shares remaining </span>
                  </>
                )}
              </span>
            </span>
          </div>
        </div>

        <button
          onClick={handleConfirm}
          style={{ width: '33%', alignSelf: 'flex-end', marginTop: '1rem' }}
          className={`CreatePostFeedButton ${!isCongrats && 'greenButton'}`}
          disabled={!quantity || parseFloat(quantity) <= 0 || isCongrats}
        >
          Confirm
        </button>
      </div>
    )
  }

  const renderCongratsModal = () => {
    return (
      <div
        className="stock-purchase-modal"
        style={{ borderColor: 'var(--stock-change-pos)' }}
      >
        <div className="stock-transaction-content">
          <h2
            style={{ color: 'var(--stock-change-pos)' }}
            className="StockName bold"
          >
            Congrats!
          </h2>
          <div className="StockConfirmWrapper" style={{ border: 'none' }}>
            <span>Do you want to share this trade to your feed?</span>
          </div>
        </div>
        <div className="stock-congrats-btns">
          <button
            onClick={() => handleCloseModal()}
            className="CreatePostFeedButton"
          >
            No
          </button>
          <button
            onClick={() => {
              shareTrade(transactionId, navigate)
            }}
            className="CreatePostFeedButton greenButton"
          >
            Yes
          </button>
        </div>
      </div>
    )
  }

  // ------ NEW: renderLimitOrderFlow ------
  const renderLimitOrderFlow = () => {
    const isBuy = openBuyModal
    const availableText = isBuy
      ? `Cash available: $${fc(userPortfolio?.userCash)}`
      : `Shares available: ${fc(userTickerPosition?.quantity) || 0}`

    let buttonLabel = isBuy ? 'Buy' : 'Sell'
    const originalButtonLabel = buttonLabel
    if (isConfirming) buttonLabel = 'Edit'

    return (
      <div
        style={{ display: 'flex', flexDirection: 'row', gap: '2rem', alignItems: 'center' }}
      >
        <div className="stock-purchase-modal" style={{height: 'fit-content'}}>
          <div className="stock-transaction-content">
            <h2 className="StockName bold">{originalButtonLabel}</h2>
            {renderPortfolioDropdown()}
            <p className="stock-cash-available">{availableText}</p>

            {/* Shares input */}
            <div className="stock-input-section">
              <label htmlFor="shares" className="StockName bold">
                Shares
              </label>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <input
                  type="text"
                  id="limit-share-input"
                  className="StockName StockInput bold"
                  value={sharesInput}
                  onChange={handleSharesChange}
                  inputMode="decimal"
                  placeholder="0.00"
                  autoComplete="off"
                />
              </div>
            </div>

            {/* Limit price input */}
            <div className="stock-input-section">
              <label htmlFor="limit-price" className="StockName bold" style={{ whiteSpace: 'nowrap' }}>
                Limit Price
              </label>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <input
                  type="text"
                  id="limit-price-input"
                  className="StockName StockInput bold"
                  value={limitPriceInput}
                  onChange={handleLimitPriceChange}
                  inputMode="decimal"
                  placeholder="0.00"
                  autoComplete="off"
                />
              </div>
            </div>

            {/* Expiration date */}
            <div className="stock-input-section" style={{ borderBottom: 'none' }}>
              <label htmlFor="limit-price" className="StockName bold" style={{ whiteSpace: 'nowrap' }}>
                Expires
              </label>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <DatePicker
                  selected={selectedLimitOrderExpiration}
                  onSelect={(date) => {
                    cancelAllTransactionStates()
                    if (date && date.getTime() === selectedLimitOrderExpiration?.getTime()) {
                      setSelectedLimitOrderExpiration(null)
                    } else {
                      setSelectedLimitOrderExpiration(date)
                    }
                  }}
                  minDate={new Date()}
                  customInput={<CustomDateInput theme={tc()} />}
                  dateFormat="MMMM d, yyyy"
                />
              </div>
            </div>
          </div>

          <div className="stock-transaction-buttons">
            {!openAddCashBox ? (
              <button
                className="CreatePostFeedButton"
                onClick={() => {
                  cachedBuyModal.current = openBuyModal
                  cachedSellModal.current = openSellModal
                  setOpenBuyModal(false)
                  setOpenSellModal(false)

                  setIsAddCashConfirming(false)
                  setIsAddCashCongrats(false)
                  setAddCashAmount('')
                  setOpenAddCashBox(true)
                }}
              >
                Add Cash
              </button>
            ) : (
              <button
                className="CreatePostFeedButton"
                onClick={() => {
                  setOpenAddCashBox(false)
                  setIsAddCashConfirming(false)
                  setIsAddCashCongrats(false)
                  setAddCashAmount('')
                }}
              >
                Cancel
              </button>
            )}

            <button
              onClick={handleLimitOrderConfirm}
              style={{ width: '33%' }}
              className={`CreatePostFeedButton ${!isConfirming && 'greenButton'}`}
              disabled={
                !quantity ||
                quantity <= 0 ||
                !limitPrice ||
                limitPrice <= 0 ||
                !selectedLimitOrderExpiration
              }
            >
              {buttonLabel}
            </button>
          </div>
        </div>

        {isConfirming && (
          <>
            <div>
              <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '5rem' }} />
            </div>
            {renderLimitConfirmation()}
          </>
        )}

        {isCongrats && limitOrderData && (
          <>
            <div>
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{ fontSize: '5rem', color: 'var(--stock-change-pos)' }}
              />
            </div>
            {renderLimitCongrats()}
          </>
        )}
      </div>
    )
  }

  // --- MAIN “Buy or Sell” modal combining Market vs. Limit toggles ---
  const renderBuyOrSellModal = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        {/* The Market/Limit buttons */}
        <div style={{ display: 'flex', gap: '1rem', marginBottom: '1rem' }}>
          <button
            className={`CreatePostFeedButton ${orderType === 'market' ? 'greenButton' : ''}`}
            onClick={() => {
              cancelAllTransactionStates()
              setOrderType('market')
            }}
          >
            Market order
          </button>
          <button
            className={`CreatePostFeedButton ${orderType === 'limit' ? 'greenButton' : ''}`}
            onClick={() => {
              cancelAllTransactionStates()
              setOrderType('limit')
            }}
          >
            Limit order
          </button>
        </div>

        {/* Now choose which flow to show */}
        {orderType === 'market' ? renderMarketOrderFlow() : renderLimitOrderFlow()}
      </div>
    )
  }

  // -----------
  //  Portfolio dropdown
  // -----------
  const renderPortfolioDropdown = () => {
    const portfolioOptions = allPortfolios.map((pf) => ({
      value: pf._id,
      label: pf.name,
    }))
    const selectedPortfolioOption = selectedPortfolio
      ? { value: selectedPortfolio._id, label: selectedPortfolio.name }
      : null

    return (
      isAuthenticated && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
            margin: '1.5rem 0',
          }}
        >
          <Select
            unstyled
            isSearchable={false}
            options={portfolioOptions}
            value={selectedPortfolioOption}
            onChange={(option) => {
              const newPortfolio = allPortfolios.find(
                (pf) => pf._id === option.value
              )
              setSelectedPortfolio(newPortfolio)
            }}
            placeholder={
              <>
                Select portfolio{' '}
                <svg
                  height="18"
                  width="18"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                  focusable="false"
                  className="css-tj5bde-Svg"
                >
                  <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                </svg>
              </>
            }
            styles={customStylesPlus(tc())}
          />
        </div>
      )
    )
  }

  const renderStockNews = () => {
    if (stockNews.length === 0) {
      return <p>No news available</p>
    }
    return stockNews.map((news, index) => (
      <div key={index} className="StockNewsItem">
        <a
          style={{ textDecoration: 'none' }}
          href={news.article_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={news.image_url}
            alt={news.title}
            className="StockNewsImage"
          />
          <h2 className="StockNewsTitle">{news.title}</h2>
        </a>
        <div className="StockNewsMeta">
          <span className="StockNewsAuthor">{news.publisher.name}</span>
          <span className="StockNewsDate">
            {new Date(news.published_utc).toLocaleDateString()}
          </span>
        </div>
      </div>
    ))
  }

  return (
    <div
      id="stock-container-wrapper"
      className="StockContainerWrapper"
      style={{ paddingBottom: '4rem' }}
    >
      <div className="StockContainer">
        <div className="MainStockContainer">
          <div className="StockPriceContent">
            <div className="StockGraph">
              <div className="StockHeader">
                <div className="StockInfo">
                  <BackButton
                    callback={() => {
                      window.history.back()
                    }}
                    fontSize="var(--font-med-l)"
                    padding="3.25rem 0 1.25rem 0"
                  />
                  <h2 className="StockTickerHeader bold">
                    {formatModifiedTicker(ticker)}
                  </h2>
                  <h1 className="StockName bold">{stockName}</h1>
                  <p className="StockPrice bold">
                    {isHovering
                      ? hoveredPrice !== null
                        ? `$${fc(hoveredPrice)}`
                        : '--'
                      : livePrice || currentPrice
                      ? `$${fc(rc(livePrice || currentPrice))}`
                      : '--'}
                  </p>
                  <p
                    className={`StockChange bold ${
                      isHovering
                        ? hoveredChangeAmount >= 0
                          ? 'positive'
                          : 'negative'
                        : (liveChangeAmount || changeAmount) >= 0
                        ? 'positive'
                        : 'negative'
                    }`}
                  >
                    {isHovering ? (
                      <>
                        {hoveredChangeAmount >= 0 ? '+' : ''}
                        {fc(hoveredChangeAmount)} ({fc(hoveredChangePercent)}%)
                      </>
                    ) : (liveChangeAmount || changeAmount) !== null ? (
                      <>
                        {(liveChangeAmount || changeAmount) >= 0 ? '+' : ''}
                        {fc(liveChangeAmount || changeAmount)} (
                        {fc(liveChangePercent || changePercent)}%)
                      </>
                    ) : (
                      <span style={{ color: 'var(--action-grey)' }}>--</span>
                    )}{' '}
                    <span className="StockDuration">
                      {isHovering
                        ? hoveredDateTime
                        : filters[filter] === 'all time'
                        ? 'all time'
                        : filters[filter] === 'live'
                        ? 'live'
                        : `past ${filters[filter]}`}
                    </span>
                  </p>

                  {/* Actions */}
                  {!openBuyModal && !openSellModal && !openAddCashBox && (
                    <div className="StockActions">
                      <button
                        onClick={handleBuyClick}
                        className="CreatePostFeedButton greenButton"
                      >
                        Buy
                      </button>
                      <button
                        onClick={handleSellClick}
                        className="CreatePostFeedButton"
                      >
                        Sell
                      </button>
                      <Select
                        options={listOptions}
                        value={selectedLists}
                        placeholder="Add to list +"
                        isClearable={false}
                        isSearchable={false}
                        unstyled
                        isMulti={true}
                        styles={customStylesPlus(tc())}
                        onMenuOpen={() => {
                          if (!isAuthenticated) {
                            alert('Please sign in to add stocks to lists.')
                            return
                          }
                          fetchStockLists(
                            isAuthenticated,
                            userToken,
                            setListOptions,
                            setSelectedLists,
                            ticker
                          )
                        }}
                        onChange={(selectedOptions) => {
                          // check removed
                          if (selectedOptions.length < selectedLists.length) {
                            const removedList = selectedLists.find(
                              (sel) =>
                                !selectedOptions.some(
                                  (opt) => opt.value === sel.value
                                )
                            )
                            if (removedList) {
                              handleRemoveFromList(
                                isAuthenticated,
                                userToken,
                                ticker,
                                removedList.value,
                                removedList.label,
                                setListOptions,
                                setSelectedLists
                              )
                              setSelectedLists(selectedOptions)
                            }
                          }
                          // check added
                          else if (
                            selectedOptions.length > selectedLists.length
                          ) {
                            const addedList = selectedOptions.find(
                              (opt) =>
                                !selectedLists.some(
                                  (sel) => sel.value === opt.value
                                )
                            )
                            if (addedList) {
                              handleAddToList(
                                isAuthenticated,
                                userToken,
                                ticker,
                                addedList.value,
                                addedList.label,
                                setListOptions,
                                setSelectedLists
                              )
                              setSelectedLists(selectedOptions)
                            }
                          }
                        }}
                      />
                    </div>
                  )}

                  {(openBuyModal || openSellModal || openAddCashBox) && (
                    <div className="StockActions">
                      <button
                        onClick={handleCloseModal}
                        className="CreatePostFeedButton"
                      >
                        Cancel Order
                      </button>
                    </div>
                  )}
                </div>
              </div>

              <div className="StockGraphMain">
                {!mainStockDataFetched ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: 'var(--stock-chart-height)',
                    }}
                  >
                    <LoadingSpinner />
                  </div>
                ) : openBuyModal || openSellModal ? (
                  // ---- RENDER THE “ORDER TYPE” TOGGLE + FLOWS (Market vs Limit)
                  renderBuyOrSellModal()
                ) : openAddCashBox ? (
                  renderAddCashBox()
                ) : (
                  <>
                    <div style={{ height: 'var(--stock-chart-height)' }}>
                      <canvas id="stock-chart" ref={chartRef}></canvas>
                    </div>
                    <div className="StockFilters">
                      {Object.keys(filters).map((key) => (
                        <button
                          key={key}
                          className={`stock-filter-btn ${
                            filter === key ? 'selected' : 'not-selected'
                          }`}
                          onClick={() => setFilter(key)}
                        >
                          {key}
                        </button>
                      ))}
                    </div>
                  </>
                )}

                {allTransactions?.length > 0 && (
                  <div className="StockStatistics">
                    <h2 className="StatsHeader">{`Your Position (${selectedPortfolio?.name})`}</h2>
                    <div className="StatsGrid">
                      <div className="label-value-pair">
                        <span>Shares</span>
                        <strong>{fc(userTickerPosition?.quantity, 6)}</strong>
                      </div>
                      <div className="label-value-pair">
                        <span>Market Value</span>
                        <strong>
                          {fc(
                            (userTickerPosition?.quantity || 0) *
                              rc(livePrice || currentPrice)
                          )}
                        </strong>
                      </div>
                      <div className="label-value-pair">
                        <span>Portfolio Allocation</span>
                        <strong>
                          {fc(
                            userPortfolio?.totalValue
                              ? ((userTickerPosition?.quantity *
                                  userTickerPosition?.avgBuyPrice) /
                                  userPortfolio.totalValue) *
                                  100
                              : 0.0
                          )}
                          %
                        </strong>
                      </div>
                      <div className="label-value-pair">
                        <span>Average Cost</span>
                        <strong>
                          {fc(userTickerPosition?.avgBuyPrice, 6)}
                        </strong>
                      </div>
                      <div className="label-value-pair">
                        <span>Unrealized Return</span>
                        <strong
                          className={
                            returnData?.unrealizedDollar >= 0
                              ? 'StockChange bold positive'
                              : 'StockChange bold negative'
                          }
                        >
                          {returnData ? (
                            <>
                              {returnData.unrealizedDollar >= 0 ? '+' : ''}
                              {fc(returnData.unrealizedDollar)} (
                              {fc(returnData.unrealizedPercentage, 2, true)}%)
                            </>
                          ) : (
                            '--'
                          )}
                        </strong>
                      </div>
                      <div className="label-value-pair">
                        <span>Realized Return</span>
                        <strong
                          className={
                            returnData?.realizedDollar >= 0
                              ? 'StockChange bold positive'
                              : 'StockChange bold negative'
                          }
                        >
                          {returnData ? (
                            <>
                              {returnData.realizedDollar >= 0 ? '+' : ''}
                              {fc(returnData.realizedDollar)} (
                              {fc(returnData.realizedPercentage, 2, true)}%)
                            </>
                          ) : (
                            '--'
                          )}
                        </strong>
                      </div>
                    </div>
                  </div>
                )}

                {companyDescription && (
                  <div className="StockDescription">
                    <h2 className="StockDescriptionHeader">Summary</h2>
                    <p style={{ margin: 0 }}>{companyDescription}</p>
                  </div>
                )}

                <div
                  className="StockStatistics"
                  style={{ paddingBottom: '4rem' }}
                >
                  <h2 className="StatsHeader">Stats</h2>
                  <div className="StatsGrid">
                    <div className="label-value-pair">
                      <span>Open</span>
                      <strong>{fc(dailyData?.open) || '--'}</strong>
                    </div>
                    <div className="label-value-pair">
                      <span>Close</span>
                      <strong>{fc(dailyData?.close) || '--'}</strong>
                    </div>
                    <div className="label-value-pair">
                      <span>High</span>
                      <strong>{fc(dailyData?.high) || '--'}</strong>
                    </div>
                    <div className="label-value-pair">
                      <span>Low</span>
                      <strong>{fc(dailyData?.low) || '--'}</strong>
                    </div>
                    <div className="label-value-pair">
                      <span>Volume</span>
                      <strong>
                        {dailyData?.volume
                          ? formatNumber(dailyData.volume)
                          : '--'}
                      </strong>
                    </div>
                    <div className="label-value-pair">
                      <span style={{ textTransform: 'capitalize' }}>
                        {filterHighMap[filter]} High
                      </span>
                      <strong>{periodHigh ? fc(periodHigh) : '--'}</strong>
                    </div>
                    <div className="label-value-pair">
                      <span style={{ textTransform: 'capitalize' }}>
                        {filterHighMap[filter]} Low
                      </span>
                      <strong>{periodLow ? fc(periodLow) : '--'}</strong>
                    </div>
                  </div>
                </div>

                {stockNews?.length > 0 && (
                  <div className="StockNews">
                    <h2 className="StockNewsHeader">News</h2>
                    <div className="StockNewsList">{renderStockNews()}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
