import React, { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../context/AuthContext'
import { NavLink, useLocation } from 'react-router-dom'
import leaderBoard from '../assets/leaderBoard.svg'
import posts from '../assets/posts.svg'
import trades from '../assets/trades.svg'
import leaderBoardActive from '../assets/leader-assets/leaderBoardActive.svg'
import postsActive from '../assets/postsActive.svg'
import tradesActive from '../assets/tradesActive.svg'
import '../styles/Footer.css'

const Footer = () => {
  const location = useLocation()
  const { visibleSection, handleSwitchSection } = useContext(AuthContext)
  const [activeTab, setActiveTab] = useState(visibleSection)

  // Map paths to tab names
  const pathToTab = {
    '/leaderboards': 'leaders',
  }

  // Determine the active tab using both visibleSection and URL
  useEffect(() => {
    if (pathToTab[location.pathname]) {
      const currentTab = pathToTab[location.pathname]
      setActiveTab(currentTab)
    } else {
      setActiveTab(visibleSection) // Use visibleSection if available
    }
  }, [visibleSection, location.pathname])

  const handleTabSwitch = (tab) => {
    setActiveTab(tab)
    handleSwitchSection(tab)
  }

  return (
    <div className="FooterContainer">
      <NavLink to="/feed" className="FooterItem">
        <button
          className={`FooterItem ${activeTab === 'posts' ? 'active' : ''}`}
          onClick={() => handleTabSwitch('posts')}
        >
          <img
            src={activeTab === 'posts' ? postsActive : posts}
            alt="posts"
            className="FooterIcon"
            style={{ width: '24px', height: '24px' }} // Adjust size if necessary
          />
          <span className="FooterText">Posts</span>
        </button>
      </NavLink>
      <NavLink to="/feed" className="FooterItem">
        <button
          className={`FooterItem ${activeTab === 'trades' ? 'active' : ''}`}
          onClick={() => handleTabSwitch('trades')}
        >
          <img
            src={activeTab === 'trades' ? tradesActive : trades}
            alt="trades"
            className="FooterIcon"
            style={{ width: '24px', height: '24px' }} // Adjust size if necessary
          />
          <span className="FooterText">Trades</span>
        </button>
      </NavLink>
      <NavLink to="/leaderboards" className="FooterItem">
        <button
          className={`FooterItem ${activeTab === 'leaders' ? 'active' : ''}`}
          onClick={() => handleTabSwitch('leaders')}
        >
          <img
            src={activeTab === 'leaders' ? leaderBoardActive : leaderBoard}
            alt="Leaders"
            className="FooterIcon"
            style={{ width: '24px', height: '24px' }} // Adjust size if necessary
          />
          <span className="FooterText">Leaders</span>
        </button>
      </NavLink>
    </div>
  )
}

export default Footer
