import React, { useEffect, useState, useRef, useContext } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import {
  getUnixTimestamp,
  formatNumber,
  getUnixTimestampRange,
  filters,
  getThreeMonthsAgo,
  fetchDailyOpenClose,
  getDailyChange,
  fetchStockLists,
  filterHighMap,
  getLastValidTradingDay,
  portfolioIntervalMap,
  fetchUserPortfolio,
  handleAddToWatchlist,
  handleRemoveFromWatchlist,
  handleRemoveFromList,
  handleAddToList,
  getWatchList,
  getStartOfTradingDay,
  getStartOfTradingWeek,
  getESTDate,
  fetchAllTransactions,
  calculateReturns,
  handleBuyOption,
  handleSellOption,
  handleLimitOptionBuy,       // <--- ADD THESE
  handleLimitOptionSell,      // <--- ADD THESE
  fetchAllPortfolios,
  isOptionExpired,
  getMarketStatus,
} from '../helper/MarketsHelper'
import { AuthContext } from '../context/AuthContext'
import { ThemeContext } from '../context/ThemeContext'
import config from '../config'
import {
  LoadingSpinner,
  formatOptionNameFromTicker,
  removeCommas as rc,
  formatCurrency as fc,
  cleanOptionTicker,
  customStylesPlus,
  // For comma-formatting:
  formatWithCommas2 as fc2,
  // For date input styling:
  CustomDateInput,
  convertToReadableDate,
} from '../helper/StylesHelper'
import { createStockChart } from '../helper/StockChartHelper'
import '../styles/Stock.css' // For convenience, re-using Stock.css
import MarketSearchBar from './MarketSearchBar'
import BackButton from './BackButton'
import { faArrowRight, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import useResponsiveWidth from '../hooks/useResponsiveWidth'
import { shareTrade } from '../helper/CreatePostHelper'
import Select from 'react-select'

// If you want to use react-datepicker for expiration:
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

export default function Option() {
  useResponsiveWidth()

  const { optionTicker } = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  const underlyingTicker = location.state?.underlyingTicker || null
  const { isAuthenticated, userToken } = useContext(AuthContext)
  const { theme, tc } = useContext(ThemeContext)

  const [optionData, setOptionData] = useState([])
  const [dailyChangeData, setDailyChangeData] = useState(null)
  const [dailyData, setDailyData] = useState({})
  const [filter, setFilter] = useState('1d')
  const [currentPrice, setCurrentPrice] = useState(null)
  const [changeAmount, setChangeAmount] = useState(null)
  const [changePercent, setChangePercent] = useState(null)
  const [optionName, setOptionName] = useState(optionTicker)
  const [mainOptionDataFetched, setMainOptionDataFetched] = useState(false)

  const chartRef = useRef(null)
  const chartInstance = useRef(null)
  const [referencePriceForChart, setReferencePriceForChart] = useState(null)
  const [periodHigh, setPeriodHigh] = useState(null)
  const [periodLow, setPeriodLow] = useState(null)
  const [hoveredPrice, setHoveredPrice] = useState(null)
  const [hoveredChangeAmount, setHoveredChangeAmount] = useState(null)
  const [hoveredChangePercent, setHoveredChangePercent] = useState(null)
  const [hoveredDateTime, setHoveredDateTime] = useState(null)
  const [isHovering, setIsHovering] = useState(false)

  const [allTransactions, setAllTransactions] = useState([])
  const [userTickerPosition, setUserTickerPosition] = useState(null)
  const [returnData, setReturnData] = useState(null)
  const [userPortfolio, setUserPortfolio] = useState(null)

  const [listOptions, setListOptions] = useState([])
  const [selectedLists, setSelectedLists] = useState([])

  const [openBuyModal, setOpenBuyModal] = useState(false)
  const [openSellModal, setOpenSellModal] = useState(false)
  const [quantity, setQuantity] = useState(null)
  const [estimatedCost, setEstimatedCost] = useState(null)
  const [isConfirming, setIsConfirming] = useState(false)
  const [isCongrats, setIsCongrats] = useState(false)
  const [stock, setStock] = useState('')
  const [transactionId, setTransactionId] = useState(null)

  const [isInWatchlist, setIsInWatchlist] = useState(false)

  // For handling multiple portfolios
  const [allPortfolios, setAllPortfolios] = useState([])
  const [selectedPortfolio, setSelectedPortfolio] = useState(null)

  // Add Cash "arrow-and-box" approach
  const [openAddCashBox, setOpenAddCashBox] = useState(false)
  const [addCashAmount, setAddCashAmount] = useState('')
  const [isAddCashConfirming, setIsAddCashConfirming] = useState(false)
  const [isAddCashCongrats, setIsAddCashCongrats] = useState(false)

  // For fractional input in buy/sell
  const [sharesInput, setSharesInput] = useState('')
  const [totalInput, setTotalInput] = useState('')

  const cachedBuyModal = useRef(null)
  const cachedSellModal = useRef(null)

  const [marketError, setMarketError] = useState('')

  // ---------- NEW STATES for Limit Orders ----------
  const [orderType, setOrderType] = useState('market') // 'market' or 'limit'
  const [limitPriceInput, setLimitPriceInput] = useState('')
  const [limitPrice, setLimitPrice] = useState(null)
  const [selectedLimitOrderExpiration, setSelectedLimitOrderExpiration] = useState(null)
  const [limitOrderData, setLimitOrderData] = useState(null);
  // -------------------------------------------------

  // -----------
  //  On Mount: check if user wants to buy or sell directly
  // -----------
  useEffect(() => {
    const action = location.state?.action
    if (action === 'buy') {
      setOpenBuyModal(true)
    } else if (action === 'sell') {
      setOpenSellModal(true)
    }
  }, [location.state])

  // -----------
  //  Watchlist
  // -----------
  useEffect(() => {
    if (isAuthenticated) {
      getWatchList(optionTicker, userToken, setIsInWatchlist)
    }
  }, [isAuthenticated, userToken, optionTicker])

  // -----------
  //  Portfolios
  // -----------
  useEffect(() => {
    if (isAuthenticated && userToken) {
      fetchAllPortfolios(
        isAuthenticated,
        userToken,
        setAllPortfolios,
        setSelectedPortfolio
      )
    }
  }, [isAuthenticated, userToken])

  useEffect(() => {
    if (!selectedPortfolio || !selectedPortfolio._id) return
    fetchUserPortfolio(
      isAuthenticated,
      userToken,
      setUserPortfolio,
      selectedPortfolio._id
    )
    fetchAllTransactions(
      isAuthenticated,
      userToken,
      optionTicker,
      setAllTransactions,
      selectedPortfolio._id
    )
  }, [selectedPortfolio?._id, userToken, isAuthenticated, optionTicker])

  useEffect(() => {
    if (userPortfolio && optionTicker) {
      setUserTickerPosition(
        userPortfolio?.assets.find(
          (asset) =>
            asset?.ticker?.toUpperCase() === optionTicker?.toUpperCase()
        )
      )
    }
  }, [userPortfolio, optionTicker])

  // -----------
  //  Returns
  // -----------
  useEffect(() => {
    if (currentPrice && userTickerPosition && allTransactions) {
      // Option cost is typically currentPrice * 100 per contract
      const returns = calculateReturns(
        userTickerPosition,
        rc(currentPrice) * 100,
        allTransactions
      )
      setReturnData(returns)
    }
  }, [currentPrice, userTickerPosition, allTransactions])

  // -----------
  //  Option Name
  // -----------
  useEffect(() => {
    setOptionName(formatOptionNameFromTicker(optionTicker))
  }, [optionTicker])

  // -----------
  //  main fetch
  // -----------
  const fetchOptionData = async () => {
    setChangeAmount(null)
    setPeriodHigh(null)
    setPeriodLow(null)
    setMainOptionDataFetched(false)
    setReferencePriceForChart(null)

    try {
      let fromDateUnixMs
      let multiplier, timespan
      let toDateUnixMs = getUnixTimestamp()

      if (filter === '3m') {
        fromDateUnixMs = getThreeMonthsAgo()
      } else if (filter === '1d') {
        const dateInET = getStartOfTradingDay()
        fromDateUnixMs = dateInET.toMillis()
        toDateUnixMs = fromDateUnixMs + 57600000
      } else if (filter === '1w') {
        const dateInET = getStartOfTradingWeek()
        fromDateUnixMs = dateInET.toMillis()
        toDateUnixMs = fromDateUnixMs + 403200000
      } else if (filter === 'all time') {
        // earliest for your data
        fromDateUnixMs = getESTDate()
      } else {
        fromDateUnixMs = getUnixTimestampRange(filter)
      }

      const intervalArray = portfolioIntervalMap[filter]
      multiplier = intervalArray[0]
      timespan = intervalArray[1]

      const response = await axios.get(
        `${config.backendUrl}/api/markets/option/datapoints`,
        {
          params: {
            optionTicker,
            fromDateUnixMs,
            toDateUnixMs,
            multiplier,
            timespan,
          },
        }
      )
      const data = response.data || []
      setOptionData(data)

      if (data.length > 0) {
        const latestPrice = rc(currentPrice || data[data.length - 1].c)

        if (filter === '1d' && dailyChangeData?.prevClosePrice) {
          const dailyClosePrice = rc(
            dailyChangeData?.todaysClosePrice || latestPrice
          )
          const priceChange =
            dailyClosePrice - rc(dailyChangeData?.prevClosePrice)
          const changePercentage =
            (priceChange / rc(dailyChangeData?.prevClosePrice)) * 100

          setChangeAmount(priceChange)
          setChangePercent(changePercentage)
          setReferencePriceForChart(rc(dailyChangeData?.prevClosePrice))
        } else {
          const startPrice = rc(data[0].c)
          const change = latestPrice - startPrice
          const changePercentage = (change / startPrice) * 100

          setChangeAmount(change)
          setChangePercent(changePercentage)
          setReferencePriceForChart(startPrice)
        }

        const highPrices = data.map((day) => day.h)
        const lowPrices = data.map((day) => day.l)
        const highestPrice = Math.max(...highPrices)
        const lowestPrice = Math.min(...lowPrices)
        setPeriodHigh(highestPrice)
        setPeriodLow(lowestPrice)
      } else {
        setChangeAmount(null)
        setChangePercent(null)
        setPeriodHigh(null)
        setPeriodLow(null)
      }
    } catch (error) {
      console.error('Error fetching option data:', error)
    }

    setMainOptionDataFetched(true)
  }

  // dailyChange
  useEffect(() => {
    getDailyChange(
      optionTicker,
      setCurrentPrice,
      setDailyChangeData,
      underlyingTicker
    )
  }, [optionTicker, underlyingTicker])

  // daily open/close
  useEffect(() => {
    const fetchOptionDailyData = async () => {
      try {
        const lastValidDay = await getLastValidTradingDay()
        fetchDailyOpenClose(lastValidDay, optionTicker, setDailyData)
      } catch (error) {
        console.error('Error fetching daily open/close data:', error)
      }
    }
    fetchOptionDailyData()
  }, [optionTicker])

  // fetch option data once dailyChangeData & filter are set
  useEffect(() => {
    if (!dailyChangeData || !filter) return
    fetchOptionData()
    // eslint-disable-next-line
  }, [filter, dailyChangeData])

  // chart
  useEffect(() => {
    if (!chartRef?.current) return
    const cleanup = createStockChart({
      chartRef,
      chartInstanceRef: chartInstance,
      stockData: optionData,
      referencePriceForChart,
      changeAmount,
      setHoveredPrice,
      setHoveredChangeAmount,
      setHoveredChangePercent,
      setHoveredDateTime,
      setIsHovering,
      theme,
    })
    return cleanup
  }, [optionData, referencePriceForChart, changeAmount, theme, openAddCashBox])

  // stock lists
  useEffect(() => {
    if (isAuthenticated && userToken) {
      fetchStockLists(
        isAuthenticated,
        userToken,
        setListOptions,
        setSelectedLists,
        optionTicker
      )
    }
  }, [userToken, optionTicker, isAuthenticated])

  // -----------
  //  Shared buy/sell callback
  // -----------
  const buyOrSellAssetCallback = () => {
    setIsCongrats(true)
    if (selectedPortfolio && selectedPortfolio._id) {
      fetchUserPortfolio(
        isAuthenticated,
        userToken,
        setUserPortfolio,
        selectedPortfolio._id
      )
      fetchAllTransactions(
        isAuthenticated,
        userToken,
        optionTicker,
        setAllTransactions,
        selectedPortfolio._id
      )
    }
  }

  // -----------
  //  Add Cash box
  // -----------

  // (1) Comma-format approach for the Add Cash input
  const handleAddCashChange = (e) => {
    setIsAddCashConfirming(false)
    setIsAddCashCongrats(false)

    const rawInput = e.target.value
    // Convert to formatted, keep decimal commas etc
    const formattedInput = fc2(rawInput)
    setAddCashAmount(formattedInput)
  }

  const renderAddCashBox = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '2rem',
          alignItems: 'center',
        }}
      >
        {/* The left box: user input */}
        <div className="stock-purchase-modal">
          <div className="stock-transaction-content">
            <h2 className="StockName bold">Add Cash</h2>
            {renderPortfolioDropdown()}
            <p className="stock-cash-available">
              Current Cash: ${fc(userPortfolio?.userCash)}
            </p>

            {/* Input for Add Cash (type="text") and handleAddCashChange */}
            <div
              className="stock-input-section"
              style={{ borderBottom: 'none' }}
            >
              <label htmlFor="add-cash" className="StockName bold">
                Amount
              </label>
              <input
                type="text"
                id="add-cash-input"
                className="StockName StockInput bold"
                value={addCashAmount}
                onChange={handleAddCashChange}
                inputMode="decimal"
                placeholder="0.00"
                autoComplete="off"
              />
            </div>
          </div>

          <div className="stock-transaction-buttons">
            <button
              onClick={() => {
                const numericValue = parseFloat(addCashAmount.replace(/,/g, ''))
                if (numericValue > 0) {
                  setIsAddCashConfirming((prev) => !prev)
                  setIsAddCashCongrats(false)
                }
              }}
              style={{ width: '33%' }}
              className={`CreatePostFeedButton ${
                !isAddCashConfirming ? 'greenButton' : ''
              }`}
              disabled={
                !addCashAmount ||
                isNaN(parseFloat(addCashAmount.replace(/,/g, ''))) ||
                parseFloat(addCashAmount.replace(/,/g, '')) <= 0
              }
            >
              {isAddCashConfirming ? 'Edit' : 'Add'}
            </button>

            <button
              className="CreatePostFeedButton"
              onClick={() => {
                setOpenAddCashBox(false)
                setIsAddCashConfirming(false)
                setIsAddCashCongrats(false)
                setAddCashAmount('')
                // return to whichever modal was open
                if (cachedBuyModal.current) {
                  setOpenBuyModal(true)
                } else if (cachedSellModal.current) {
                  setOpenSellModal(true)
                }
              }}
            >
              Cancel
            </button>
          </div>
        </div>

        {isAddCashConfirming && (
          <div>
            <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '5rem' }} />
          </div>
        )}

        {isAddCashConfirming && renderAddCashConfirmation()}

        {isAddCashCongrats && (
          <div>
            <FontAwesomeIcon
              icon={faArrowRight}
              style={{ fontSize: '5rem', color: 'var(--stock-change-pos)' }}
            />
          </div>
        )}
        {isAddCashCongrats && renderAddCashCongrats()}
      </div>
    )
  }

  const renderAddCashConfirmation = () => {
    const handleConfirm = async () => {
      try {
        if (!selectedPortfolio || !selectedPortfolio._id) {
          alert('No portfolio selected')
          return
        }
        const numericValue = parseFloat(addCashAmount.replace(/,/g, ''))
        const portfolioId = selectedPortfolio._id

        await axios.post(
          `${config.backendUrl}/api/stockActions/addCash`,
          { amount: numericValue, portfolioId },
          {
            headers: { Authorization: `Bearer ${userToken}` },
          }
        )
        fetchUserPortfolio(
          isAuthenticated,
          userToken,
          setUserPortfolio,
          selectedPortfolio._id
        )
        setIsAddCashCongrats(true)
      } catch (error) {
        console.error('Error adding cash:', error)
        alert(
          error.response?.data?.message ||
            'Adding cash failed. Please try again.'
        )
      }
    }

    return (
      <div className="stock-purchase-modal">
        <div className="stock-transaction-content">
          <h2 className="StockName bold">Confirm</h2>
          <div className="StockConfirmWrapper" style={{ border: 'none' }}>
            <span>
              You are adding{' '}
              <span className="bold">
                ${fc(parseFloat(addCashAmount.replace(/,/g, '')))}
              </span>{' '}
              to portfolio:{' '}
              <span className="bold">{selectedPortfolio?.name || '--'}</span>
            </span>
          </div>
        </div>
        <button
          onClick={handleConfirm}
          style={{ width: '33%', alignSelf: 'flex-end', marginTop: '1rem' }}
          className="CreatePostFeedButton greenButton"
        >
          Confirm
        </button>
      </div>
    )
  }

  const renderAddCashCongrats = () => {
    return (
      <div
        className="stock-purchase-modal"
        style={{ borderColor: 'var(--stock-change-pos)' }}
      >
        <div className="stock-transaction-content">
          <h2
            style={{ color: 'var(--stock-change-pos)' }}
            className="StockName bold"
          >
            Congrats!
          </h2>
          <div className="StockConfirmWrapper" style={{ border: 'none' }}>
            <span>You have successfully added cash to your portfolio.</span>
          </div>
        </div>
        <div className="stock-congrats-btns">
          <button
            onClick={() => {
              setOpenAddCashBox(false)
              setIsAddCashConfirming(false)
              setIsAddCashCongrats(false)
              setAddCashAmount('')

              if (cachedBuyModal.current) {
                setOpenBuyModal(true)
              } else if (cachedSellModal.current) {
                setOpenSellModal(true)
              }
            }}
            className="CreatePostFeedButton"
          >
            Done
          </button>
        </div>
      </div>
    )
  }

  // -----------
  //  Input changes (MARKET) - “Contracts” and “Total” 
  // -----------
  const handleSharesChange = (e) => {
    setIsConfirming(false)
    setIsCongrats(false)

    const rawInput = e.target.value
    // Format input with commas
    const formattedInput = fc2(rawInput)
    setSharesInput(formattedInput)

    // Remove commas to parse
    const parsedShares = parseFloat(formattedInput.replace(/,/g, ''))
    const price = rc(currentPrice)

    if (isNaN(parsedShares) || parsedShares < 0) {
      setQuantity(null)
      setEstimatedCost(null)
      setTotalInput('')
      return
    }

    setQuantity(parsedShares)
    // each contract costs `price * 100`
    const newTotal = price > 0 ? parsedShares * price * 100 : 0
    setEstimatedCost(newTotal)
    setTotalInput(fc2(newTotal.toFixed(2)))
  }

  const handleTotalChange = (e) => {
    setIsConfirming(false)
    setIsCongrats(false)

    const rawInput = e.target.value
    // Format input with commas
    const formattedInput = fc2(rawInput)
    setTotalInput(formattedInput)

    // remove commas
    const parsedTotal = parseFloat(formattedInput.replace(/,/g, ''))
    const price = rc(currentPrice)

    if (isNaN(parsedTotal) || parsedTotal < 0) {
      setEstimatedCost(null)
      setQuantity(null)
      setSharesInput('')
      return
    }

    setEstimatedCost(parsedTotal)
    // each contract is `price * 100`
    const newShares = price > 0 ? parsedTotal / (price * 100) : 0
    setQuantity(newShares)
    // Format the “Contracts” input
    setSharesInput(fc2(newShares.toFixed(2)))
  }

  // -----------
  //  Input changes (LIMIT) - “Limit Price”
  // -----------
  const handleLimitPriceChange = (e) => {
    setIsConfirming(false)
    setIsCongrats(false)

    const rawInput = e.target.value
    const formattedInput = fc2(rawInput)
    setLimitPriceInput(formattedInput)

    const parsed = parseFloat(rawInput.replace(/,/g, ''))
    if (isNaN(parsed) || parsed < 0) {
      setLimitPrice(null)
      return
    }
    setLimitPrice(parsed)
  }

  // -----------
  //  Market vs. Limit Toggling
  // -----------
  const handleBuyClickOption = () => {
    if (!isAuthenticated) {
      alert('Please sign in to access this feature')
      return
    }
    setOpenBuyModal(true)
    setOrderType('market') // default to 'market' if you like
  }

  const handleSellClickOption = () => {
    if (!isAuthenticated) {
      alert('Please sign in to access this feature')
      return
    }
    setOpenSellModal(true)
    setOrderType('market') // default to 'market' if you like
  }

  const handleCloseModal = () => {
    setOpenBuyModal(false)
    setOpenSellModal(false)
    setIsConfirming(false)
    setIsCongrats(false)
    setTransactionId(null)
    setMarketError('')
    setOrderType('market')

    // Reset limit order states
    setLimitPriceInput('')
    setLimitPrice(null)
    setSelectedLimitOrderExpiration(null)

    // Reset addCash if open
    setOpenAddCashBox(false)
    setIsAddCashConfirming(false)
    setIsAddCashCongrats(false)
    setAddCashAmount('')
  }

  // -----------
  //  Market Flow
  // -----------
  const renderMarketOrderFlow = () => {
    const isBuy = openBuyModal
    const availableText = isBuy
      ? `Cash available: $${fc(userPortfolio?.userCash)}`
      : `Contracts available: ${fc(userTickerPosition?.quantity) || 0}`

    let buttonLabel = isBuy ? 'Buy' : 'Sell'
    const originalLabel = buttonLabel
    if (isConfirming) buttonLabel = 'Edit'

    const handleConfirmClick = async () => {
      const mStatus = await getMarketStatus()
      const state = mStatus.isPreMarket
        ? 'Pre-market'
        : mStatus.isAfterHours
        ? 'After-hours'
        : ''
      if (mStatus.status === 'closed') {
        setMarketError(
          `The market is currently closed${state && ` (${state})`}. Please try again later.`
        )
        return
      }
      if (isConfirming) {
        setIsCongrats(false)
        document.getElementById('share-input')?.focus()
      }
      setIsConfirming((prev) => !prev)
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '2rem',
          alignItems: 'center',
        }}
      >
        <div className="stock-purchase-modal">
          <div className="stock-transaction-content">
            <h2 className="StockName bold">{originalLabel}</h2>
            {renderPortfolioDropdown()}
            <p className="stock-cash-available">{availableText}</p>

            {/* "Contracts" Input -> type="text" + handleSharesChange */}
            <div className="stock-input-section">
              <label htmlFor="shares" className="StockName bold">
                Contracts
              </label>
              <input
                type="text"
                id="share-input"
                className="StockName StockInput bold"
                value={sharesInput}
                onChange={handleSharesChange}
                inputMode="decimal"
                placeholder="0.00"
                autoComplete="off"
              />
            </div>

            {/* "Total" Input -> type="text" + handleTotalChange */}
            <div className="stock-input-section" style={{ borderBottom: 'none' }}>
              <label className="StockName bold">Total</label>
              <input
                type="text"
                className="StockName StockInput bold"
                value={totalInput}
                onChange={handleTotalChange}
                inputMode="decimal"
                placeholder="0.00"
                autoComplete="off"
              />
            </div>
          </div>

          <div className="stock-transaction-buttons">
            {!openAddCashBox ? (
              <button
                className="CreatePostFeedButton"
                onClick={() => {
                  // hide buy/sell
                  cachedBuyModal.current = openBuyModal
                  cachedSellModal.current = openSellModal
                  setOpenBuyModal(false)
                  setOpenSellModal(false)

                  // reset
                  setIsAddCashConfirming(false)
                  setIsAddCashCongrats(false)
                  setAddCashAmount('')
                  setOpenAddCashBox(true)
                }}
              >
                Add Cash
              </button>
            ) : (
              <button
                className="CreatePostFeedButton"
                onClick={() => {
                  setOpenAddCashBox(false)
                  setIsAddCashConfirming(false)
                  setIsAddCashCongrats(false)
                  setAddCashAmount('')
                }}
              >
                Cancel
              </button>
            )}

            <button
              onClick={handleConfirmClick}
              style={{ width: '33%' }}
              className={`CreatePostFeedButton ${!isConfirming && 'greenButton'}`}
              disabled={!quantity || quantity <= 0}
            >
              {buttonLabel}
            </button>
          </div>
        </div>

        {isConfirming && (
          <>
            <div>
              <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '5rem' }} />
            </div>
            {renderConfirmationModal()}
          </>
        )}

        {isCongrats && (
          <>
            <div>
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{ fontSize: '5rem', color: 'var(--stock-change-pos)' }}
              />
            </div>
            {renderCongratsModal()}
          </>
        )}
      </div>
    )
  }

  const renderConfirmationModal = () => {
    const isBuy = openBuyModal
    const handleConfirm = async () => {
      try {
        if (!selectedPortfolio) {
          alert('Please select a portfolio first.')
          return
        }

        let response
        if (isBuy) {
          response = await handleBuyOption(
            isAuthenticated,
            userToken,
            quantity,
            optionTicker,
            buyOrSellAssetCallback,
            selectedPortfolio._id
          )
        } else {
          response = await handleSellOption(
            isAuthenticated,
            userToken,
            quantity,
            optionTicker,
            buyOrSellAssetCallback,
            selectedPortfolio._id
          )
        }
        let transaction = response?.data?.transaction
        if (transaction) {
          setTransactionId(transaction._id)
        }
      } catch (error) {
        console.error('Transaction failed:', error)
        setTransactionId(null)
        alert(error.response?.data?.message || 'Transaction failed')
      }
    }

    const totalCost = quantity * rc(currentPrice) * 100
    const isBuyRemaining = userPortfolio?.userCash - totalCost
    const isSellRemaining = (userTickerPosition?.quantity || 0) - quantity
    const remainingAmount = openBuyModal ? isBuyRemaining : isSellRemaining

    return (
      <div className="stock-purchase-modal stock-p-modal-large">
        <div className="stock-transaction-content">
          <h2 className="StockName bold">Confirm</h2>
          <div className="StockConfirmWrapper">
            <span>
              <span className="bold">{quantity}</span>{' '}
              {formatOptionNameFromTicker(optionTicker)} contracts
            </span>
            <span>
              <span className="bold">${fc(rc(currentPrice))} </span> option
              price x <span className="bold">{quantity}</span> contracts x{' '}
              <span className="bold">100</span>
            </span>
          </div>
          <div className="StockConfirmWrapper" style={{ border: 'none' }}>
            <span>
              <span className="bold">${fc(totalCost)}</span> total
            </span>
            <span>
              <span className="bold">
                {openBuyModal ? (
                  <>
                    {' '}
                    ${fc(isBuyRemaining)}{' '}
                    <span className="medium"> cash remaining </span>
                  </>
                ) : (
                  <>
                    {' '}
                    {fc(isSellRemaining)}{' '}
                    <span className="medium"> contracts remaining </span>
                  </>
                )}
              </span>
            </span>
          </div>
        </div>

        <button
          onClick={handleConfirm}
          style={{ width: '33%', alignSelf: 'flex-end', marginTop: '1rem' }}
          className="CreatePostFeedButton greenButton"
          disabled={!quantity || parseFloat(quantity) <= 0 || isCongrats}
        >
          Confirm
        </button>
      </div>
    )
  }

  const renderCongratsModal = () => {
    return (
      <div
        className="stock-purchase-modal"
        style={{ borderColor: 'var(--stock-change-pos)' }}
      >
        <div className="stock-transaction-content">
          <h2
            style={{ color: 'var(--stock-change-pos)' }}
            className="StockName bold"
          >
            Congrats!
          </h2>
          <div className="StockConfirmWrapper" style={{ border: 'none' }}>
            <span>Do you want to share this trade to your feed?</span>
          </div>
        </div>
        <div className="stock-congrats-btns">
          <button onClick={handleCloseModal} className="CreatePostFeedButton">
            No
          </button>

          <button
            onClick={() => {
              shareTrade(transactionId, navigate)
            }}
            className="CreatePostFeedButton greenButton"
          >
            Yes
          </button>
        </div>
      </div>
    )
  }

  // -----------
  //  LIMIT FLOW
  // -----------
  const renderLimitOrderFlow = () => {
    const isBuy = openBuyModal
    const availableText = isBuy
      ? `Cash available: $${fc(userPortfolio?.userCash)}`
      : `Contracts available: ${fc(userTickerPosition?.quantity) || 0}`

    let buttonLabel = isBuy ? 'Buy' : 'Sell'
    const originalLabel = buttonLabel
    if (isConfirming) buttonLabel = 'Edit'

    const handleLimitOrderConfirm = async () => {
      // e.g. check if market is open for placing limit orders if your business logic requires
      // otherwise, you can allow placing limit orders while closed
      // We'll skip that check here, or do a simpler approach:
      if (isConfirming) {
        setIsCongrats(false)
      }
      setIsConfirming((prev) => !prev)
    }

    return (
      <div
        style={{ display: 'flex', flexDirection: 'row', gap: '2rem', alignItems: 'center' }}
      >
        <div className="stock-purchase-modal" style={{height: 'fit-content' }}> 
          <div className="stock-transaction-content">
            <h2 className="StockName bold">{originalLabel}</h2>
            {renderPortfolioDropdown()}
            <p className="stock-cash-available">{availableText}</p>

            {/* Contracts input */}
            <div className="stock-input-section">
              <label htmlFor="shares" className="StockName bold">
                Contracts
              </label>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <input
                  type="text"
                  id="limit-contracts-input"
                  className="StockName StockInput bold"
                  value={sharesInput}
                  onChange={handleSharesChange}
                  inputMode="decimal"
                  placeholder="0.00"
                  autoComplete="off"
                />
              </div>
            </div>

            {/* Limit price input */}
            <div className="stock-input-section">
              <label htmlFor="limit-price" className="StockName bold" style={{ whiteSpace: 'nowrap' }}>
                Limit Price
              </label>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <input
                  type="text"
                  id="limit-price-input"
                  className="StockName StockInput bold"
                  value={limitPriceInput}
                  onChange={handleLimitPriceChange}
                  inputMode="decimal"
                  placeholder="0.00"
                  autoComplete="off"
                />
              </div>
            </div>

            {/* Expiration date */}
            <div className="stock-input-section" style={{ borderBottom: 'none' }}>
              <label htmlFor="limit-expiration" className="StockName bold" style={{ whiteSpace: 'nowrap' }}>
                Expires
              </label>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <DatePicker
                 filterDate={(date) => {
                  const day = date.getDay(); // 0 = Sunday, 6 = Saturday
                  return day !== 0 && day !== 6; // Exclude weekends
                }}
                  selected={selectedLimitOrderExpiration}
                  onSelect={(date) => {
                    if (date && date.getTime() === selectedLimitOrderExpiration?.getTime()) {
                      setSelectedLimitOrderExpiration(null)
                    } else {
                      setSelectedLimitOrderExpiration(date)
                    }
                    setIsConfirming(false)
                    setIsCongrats(false)
                  }}
                  minDate={new Date()}
                  customInput={<CustomDateInput theme={tc()} />}
                  dateFormat="MMMM d, yyyy"
                />
              </div>
            </div>
          </div>

          <div className="stock-transaction-buttons">
            {!openAddCashBox ? (
              <button
                className="CreatePostFeedButton"
                onClick={() => {
                  cachedBuyModal.current = openBuyModal
                  cachedSellModal.current = openSellModal
                  setOpenBuyModal(false)
                  setOpenSellModal(false)

                  setIsAddCashConfirming(false)
                  setIsAddCashCongrats(false)
                  setAddCashAmount('')
                  setOpenAddCashBox(true)
                }}
              >
                Add Cash
              </button>
            ) : (
              <button
                className="CreatePostFeedButton"
                onClick={() => {
                  setOpenAddCashBox(false)
                  setIsAddCashConfirming(false)
                  setIsAddCashCongrats(false)
                  setAddCashAmount('')
                }}
              >
                Cancel
              </button>
            )}

            <button
              onClick={handleLimitOrderConfirm}
              style={{ width: '33%' }}
              className={`CreatePostFeedButton ${!isConfirming && 'greenButton'}`}
              disabled={
                !quantity ||
                quantity <= 0 ||
                !limitPrice ||
                limitPrice <= 0 ||
                !selectedLimitOrderExpiration
              }
            >
              {buttonLabel}
            </button>
          </div>
        </div>

        {isConfirming && (
          <>
            <div>
              <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '5rem' }} />
            </div>
            {renderLimitConfirmation()}
          </>
        )}

        {isCongrats && limitOrderData &&  (
          <>
            <div>
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{ fontSize: '5rem', color: 'var(--stock-change-pos)' }}
              />
            </div>
            {renderLimitCongrats()}
          </>
        )}
      </div>
    )
  }

  const renderLimitConfirmation = () => {
    const isBuy = openBuyModal
    const totalPotentialCost = quantity * (limitPrice || 0) * 100
    const remainingAmount = isBuy
      ? userPortfolio?.userCash - totalPotentialCost
      : (userTickerPosition?.quantity || 0) - quantity

    const handleLimitOrderExecute = async () => {
      try {
        if (!selectedPortfolio || !selectedPortfolio._id) {
          alert('Please select a portfolio.')
          return
        }
        let response;
        if (isBuy) {
          response = await handleLimitOptionBuy(
            isAuthenticated,
            userToken,
            quantity,
            optionTicker,
            selectedPortfolio?._id,
            limitPrice,
            selectedLimitOrderExpiration,
            buyOrSellAssetCallback,
            location.state
          )
        } else {
          response = await handleLimitOptionSell(
            isAuthenticated,
            userToken,
            quantity,
            optionTicker,
            selectedPortfolio?._id,
            limitPrice,
            selectedLimitOrderExpiration,
            buyOrSellAssetCallback,
            location.state
          )
        }
        if (response && response.data) {
          setLimitOrderData(response.data.limitOrder);
        }
      } catch (err) {
        console.error('Error executing limit order:', err)
        alert(
          err.response?.data?.message ||
            'Error executing limit order. Please try again.'
        )
      }
    }

    return (
      <div className="stock-purchase-modal">
        <div className="stock-transaction-content">
          <h2 className="StockName bold">Confirm</h2>
          <div className="StockConfirmWrapper">
            <span>
              <span className="bold">{quantity}</span> contracts
            </span>
            <span>
              Limit Price:{' '}
              <span className="bold">
                ${fc(limitPrice)} (per contract)
              </span>
            </span>
            <span>
              Expires:{' '}
              <span className="bold">
                {selectedLimitOrderExpiration
                  ? selectedLimitOrderExpiration.toLocaleDateString()
                  : '--'}
                  {' '}
                  on market close
              </span>
            </span>
          </div>
          <div className="StockConfirmWrapper" style={{ border: 'none' }}>
            <span>
              <span className="bold">${fc(totalPotentialCost)}</span>{' '}
              {`${isBuy ? 'maximum total' : 'minimum credit'}`}
            </span>
            <span>
              <span className="bold">
                {isBuy ? (
                  <>
                    ${fc(remainingAmount)} <span className="medium"> cash remaining</span>
                  </>
                ) : (
                  <>
                    {fc(remainingAmount)} <span className="medium"> contracts remaining</span>
                  </>
                )}
              </span>
            </span>
          </div>
        </div>

        <button
          onClick={handleLimitOrderExecute}
          style={{ width: '33%', alignSelf: 'flex-end', marginTop: '1rem' }}
          className="CreatePostFeedButton greenButton"
        >
          Confirm
        </button>
      </div>
    )
  }

  const renderLimitCongrats = () => {
    const isBuy = openBuyModal
    return (
      <div
        className="stock-purchase-modal"
        style={{ borderColor: 'var(--stock-change-pos)' }}
      >
        <div className="stock-transaction-content">
          <h2 style={{ color: 'var(--stock-change-pos)' }} className="StockName bold">
            Congrats!
          </h2>
          <div className="StockConfirmWrapper" style={{ border: 'none' }}>
            <p className="PostContentP" style={{ margin: 0 }}>
              Your limit order has been successfully placed. It will execute{' '}
              {isBuy
                ? `if the option price reaches or goes below $${fc(
                    limitPrice
                  )} by ${convertToReadableDate(limitOrderData?.expiresAt)}. Make sure you have enough cash in your portfolio to cover this order.`
                : `if the option price reaches or goes above $${fc(
                    limitPrice
                  )} by ${convertToReadableDate(limitOrderData?.expiresAt)}. Make sure you have enough contracts in your portfolio to cover this order.`}

            
            </p>
            <br />
          </div>
        </div>
        <div className="stock-congrats-btns">
          <button
            onClick={() => handleCloseModal()}
            className="CreatePostFeedButton"
          >
            Close
          </button>
          
        </div>
      </div>
    )
  }

  // -----------
  //  Combined Buy/Sell modal that toggles Market vs Limit
  // -----------
  const renderBuyOrSellModal = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        {/* The Market/Limit buttons */}
        <div style={{ display: 'flex', gap: '1rem', marginBottom: '1rem' }}>
          <button
            className={`CreatePostFeedButton ${orderType === 'market' ? 'greenButton' : ''}`}
            onClick={() => {
              setIsConfirming(false)
              setIsCongrats(false)
              setMarketError('')
              setOrderType('market')
            }}
          >
            Market order
          </button>
          <button
            className={`CreatePostFeedButton ${orderType === 'limit' ? 'greenButton' : ''}`}
            onClick={() => {
              setIsConfirming(false)
              setIsCongrats(false)
              setMarketError('')
              setOrderType('limit')
            }}
          >
            Limit order
          </button>
        </div>

        {orderType === 'market' ? renderMarketOrderFlow() : renderLimitOrderFlow()}

        <div style={{ marginTop: '1rem' }} className="stock-market-error">
          {marketError || '\u00A0'}
        </div>
      </div>
    )
  }

  const renderPortfolioDropdown = () => {
    const portfolioOptions = allPortfolios.map((pf) => ({
      value: pf._id,
      label: pf.name,
    }))
    const selectedPortfolioOption = selectedPortfolio
      ? { value: selectedPortfolio._id, label: selectedPortfolio.name }
      : null

    return (
      isAuthenticated && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
            margin: '1.5rem 0',
          }}
        >
          <Select
            unstyled
            isSearchable={false}
            options={portfolioOptions}
            value={selectedPortfolioOption}
            onChange={(option) => {
              const newPortfolio = allPortfolios.find(
                (pf) => pf._id === option.value
              )
              setSelectedPortfolio(newPortfolio)
            }}
            placeholder={
              <>
                Select portfolio{' '}
                <svg
                  height="18"
                  width="18"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                  focusable="false"
                  className="css-tj5bde-Svg"
                >
                  <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                </svg>
              </>
            }
            styles={customStylesPlus(tc())}
          />
        </div>
      )
    )
  }

  return (
    <div
      className="StockContainerWrapper"
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      <div className="StockContainer">
     
        <div className="MainStockContainer">
          <div className="StockPriceContent">
            <div className="StockGraph">
              <div className="StockHeader">
                <div className="StockInfo">
                  <BackButton
                    callback={() => {
                      window.history.back()
                    }}
                    fontSize="var(--font-med-l)"
                    padding="3.25rem 0 1.25rem 0"
                  />
                  <h2 className="StockTickerHeader bold">
                    {cleanOptionTicker(optionTicker)}
                  </h2>
                  <h1 className="StockName bold">
                    {optionName}{' '}
                    {isOptionExpired(optionTicker) && (
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => {
                          e.preventDefault()
                          alert(
                            'This option has expired. Data may not be available.'
                          )
                        }}
                      >
                        <FontAwesomeIcon icon={faInfoCircle} />
                      </span>
                    )}
                  </h1>

                  <p className="StockPrice bold">
                    {isHovering
                      ? hoveredPrice !== null
                        ? `$${fc(hoveredPrice)}`
                        : '--'
                      : currentPrice
                      ? `$${fc(rc(currentPrice))}`
                      : '--'}
                  </p>

                  <p
                    className={`StockChange bold ${
                      (isHovering ? hoveredChangeAmount : changeAmount) >= 0
                        ? 'positive'
                        : 'negative'
                    }`}
                  >
                    {isHovering ? (
                      <>
                        {hoveredChangeAmount >= 0 ? '+' : ''}
                        {fc(hoveredChangeAmount)} ({fc(hoveredChangePercent)}%)
                      </>
                    ) : changeAmount !== null ? (
                      <>
                        {changeAmount >= 0 ? '+' : ''}
                        {fc(changeAmount)} ({fc(changePercent)}%)
                      </>
                    ) : (
                      <span style={{ color: 'var(--action-grey)' }}>--</span>
                    )}
                    <span className="StockDuration">
                      {' '}
                      {isHovering
                        ? hoveredDateTime
                        : filters[filter] === 'all time'
                        ? 'all time'
                        : filters[filter] === 'live'
                        ? 'live'
                        : `past ${filters[filter]}`}
                    </span>
                  </p>

                  {/* Buttons row */}
                  {!openBuyModal && !openSellModal && !openAddCashBox ? (
                    <div className="StockActions">
                      <button
                        onClick={handleBuyClickOption}
                        className="CreatePostFeedButton greenButton"
                      >
                        Buy
                      </button>
                      <button
                        className="CreatePostFeedButton"
                        onClick={handleSellClickOption}
                      >
                        Sell
                      </button>
                      <Select
                        options={listOptions}
                        value={selectedLists}
                        placeholder="Add to list +"
                        isClearable={false}
                        isSearchable={false}
                        unstyled
                        isMulti={true}
                        styles={customStylesPlus(tc())}
                        onMenuOpen={() => {
                          if (!isAuthenticated) {
                            alert('Please sign in to add stocks to lists.')
                            return
                          }
                          fetchStockLists(
                            isAuthenticated,
                            userToken,
                            setListOptions,
                            setSelectedLists,
                            optionTicker
                          )
                        }}
                        onChange={(selectedOptions) => {
                          // handle removal
                          if (selectedOptions.length < selectedLists.length) {
                            const removedList = selectedLists.find(
                              (sel) =>
                                !selectedOptions.some(
                                  (opt) => opt.value === sel.value
                                )
                            )
                            if (removedList) {
                              handleRemoveFromList(
                                isAuthenticated,
                                userToken,
                                optionTicker,
                                removedList.value,
                                removedList.label,
                                setListOptions,
                                setSelectedLists
                              )
                              setSelectedLists(selectedOptions)
                            }
                          }
                          // handle add
                          else if (
                            selectedOptions.length > selectedLists.length
                          ) {
                            const addedList = selectedOptions.find(
                              (opt) =>
                                !selectedLists.some(
                                  (sel) => sel.value === opt.value
                                )
                            )
                            if (addedList) {
                              handleAddToList(
                                isAuthenticated,
                                userToken,
                                optionTicker,
                                addedList.value,
                                addedList.label,
                                setListOptions,
                                setSelectedLists
                              )
                              setSelectedLists(selectedOptions)
                            }
                          }
                        }}
                      />
                    </div>
                  ) : (
                    <div className="StockActions">
                      <button
                        onClick={handleCloseModal}
                        className="CreatePostFeedButton"
                      >
                        Cancel Order
                      </button>
                    </div>
                  )}
                </div>
              </div>

              <div className="StockGraphMain">
                {/* Chart or Buy/Sell or Add Cash UI */}
                {!mainOptionDataFetched ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: 'var(--stock-chart-height)',
                    }}
                  >
                    <LoadingSpinner />
                  </div>
                ) : openBuyModal || openSellModal ? (
                  // RENDER the combined Market/Limit flows
                  renderBuyOrSellModal()
                ) : openAddCashBox ? (
                  renderAddCashBox()
                ) : (
                  <>
                    <div style={{ height: 'var(--stock-chart-height)' }}>
                      <canvas ref={chartRef}></canvas>
                    </div>
                    <div className="StockFilters">
                      {Object.keys(filters).map((key) => (
                        <button
                          key={key}
                          className={`stock-filter-btn ${
                            filter === key ? 'selected' : 'not-selected'
                          }`}
                          onClick={() => setFilter(key)}
                        >
                          {key}
                        </button>
                      ))}
                    </div>
                  </>
                )}

                {allTransactions?.length > 0 && (
                  <div className="StockStatistics">
                    <h2 className="StatsHeader">{`Your Position (${selectedPortfolio?.name})`}</h2>
                    <div className="StatsGrid">
                      <div className="label-value-pair">
                        <span>Contracts</span>
                        <strong>{fc(userTickerPosition?.quantity)}</strong>
                      </div>
                      <div className="label-value-pair">
                        <span>Market Value</span>
                        <strong>
                          {fc(
                            (userTickerPosition?.quantity || 0) *
                              rc(currentPrice) *
                              100
                          )}
                        </strong>
                      </div>
                      <div className="label-value-pair">
                        <span>Portfolio Allocation</span>
                        <strong>
                          {fc(
                            userPortfolio?.totalValue &&
                              userPortfolio?.totalValue !== 0
                              ? ((userTickerPosition?.quantity *
                                  userTickerPosition?.avgBuyPrice) /
                                  userPortfolio.totalValue) *
                                  100
                              : 0.0
                          )}
                          %
                        </strong>
                      </div>
                      <div className="label-value-pair">
                        <span>Average Cost</span>
                        <strong>
                          {fc(userTickerPosition?.avgBuyPrice)} per contract
                        </strong>
                      </div>
                      <div className="label-value-pair">
                        <span>Unrealized Return</span>
                        <strong
                          className={
                            returnData?.unrealizedDollar >= 0
                              ? 'StockChange bold positive'
                              : 'StockChange bold negative'
                          }
                        >
                          {returnData ? (
                            <>
                              {returnData.unrealizedDollar >= 0 ? '+' : ''}
                              {fc(returnData.unrealizedDollar)} (
                              {fc(returnData.unrealizedPercentage, 2, true)}%)
                            </>
                          ) : (
                            '--'
                          )}
                        </strong>
                      </div>
                      <div className="label-value-pair">
                        <span>Realized Return</span>
                        <strong
                          className={
                            returnData?.realizedDollar >= 0
                              ? 'StockChange bold positive'
                              : 'StockChange bold negative'
                          }
                        >
                          {returnData ? (
                            <>
                              {returnData.realizedDollar >= 0 ? '+' : ''}
                              {fc(returnData.realizedDollar)} (
                              {fc(returnData.realizedPercentage, 2, true)}%)
                            </>
                          ) : (
                            '--'
                          )}
                        </strong>
                      </div>
                    </div>
                  </div>
                )}

                <div
                  className="StockStatistics"
                  style={{ paddingBottom: '4rem' }}
                >
                  <h2 className="StatsHeader">Stats</h2>
                  <div className="StatsGrid">
                    <div className="label-value-pair">
                      <span>Open</span>
                      <strong>{fc(dailyData?.open) || '--'}</strong>
                    </div>
                    <div className="label-value-pair">
                      <span>Close</span>
                      <strong>{fc(dailyData?.close) || '--'}</strong>
                    </div>
                    <div className="label-value-pair">
                      <span>High</span>
                      <strong>{fc(dailyData?.high) || '--'}</strong>
                    </div>
                    <div className="label-value-pair">
                      <span>Low</span>
                      <strong>{fc(dailyData?.low) || '--'}</strong>
                    </div>
                    <div className="label-value-pair">
                      <span>Pre-Market</span>
                      <strong>{fc(dailyData?.preMarket) || '--'}</strong>
                    </div>
                    <div className="label-value-pair">
                      <span>After-Hours</span>
                      <strong>{fc(dailyData?.afterHours) || '--'}</strong>
                    </div>
                    <div className="label-value-pair">
                      <span>Volume</span>
                      <strong>
                        {dailyData?.volume
                          ? formatNumber(dailyData.volume)
                          : '--'}
                      </strong>
                    </div>
                    <div className="label-value-pair">
                      <span style={{ textTransform: 'capitalize' }}>
                        {filterHighMap[filter]} High
                      </span>
                      <strong>{periodHigh ? fc(periodHigh) : '--'}</strong>
                    </div>
                    <div className="label-value-pair">
                      <span style={{ textTransform: 'capitalize' }}>
                        {filterHighMap[filter]} Low
                      </span>
                      <strong>{periodLow ? fc(periodLow) : '--'}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
