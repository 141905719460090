import { useEffect, useState } from "react";
import config from "../config";
import axios from "axios";
import { formatCurrency as fc } from "../helper/StylesHelper";
import Marquee from "react-fast-marquee";
import "../styles/MobileView.css";

const MobileView = () => {
  const [indicesData, setIndicesData] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isModalOpen, setisModalOpen] = useState(false);

  useEffect(() => {
    const fetchIndicesData = async () => {
      try {
        const response = await axios.get(
          `${config.backendUrl}/api/markets/indices`
        );
        setIndicesData(response.data);
      } catch (error) {
        console.error("Error fetching indices data:", error);
      }
    };

    fetchIndicesData();
  }, []);

  const handleCreateAccountSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${config.backendUrl}/api/waitlist/add`,
        {
          email,
          name,
        }
      );
      if (response.data.status === 1) {
        setName("");
        setEmail("");
        setisModalOpen(false);
        alert(response.data.message);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      alert(error.response.data);
    }
  };

  return (
    <div className="mo-v-mobile-screen">
      {isModalOpen && (
        <div className="mo-v-modal mo-v-fade mo-v-show">
          <div className="mo-v-modal-dialog mo-v-modal-dialog-centered mo-v-horizontal-padding">
            <div className="mo-v-modal-content mo-v-mob-modal">
              <div className="mo-v-modal-body mo-v-padding-3">
                <form onSubmit={handleCreateAccountSubmit}>
                  <input
                    type="text"
                    className="mo-v-npt-mobile"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                  <input
                    type="text"
                    className="mo-v-npt-mobile"
                    placeholder="Email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <div className="mo-v-button-group">
                    <button className="mo-v-active" style = {{margin:0}} type="submit">
                      Submit
                    </button>
                    <button
                      className="mo-v-button"
                      style={{margin:0}}
                      type="button"
                      onClick={() => setisModalOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Scrolling indices at the top */}
      <div className="mo-v-over-hid">
        <Marquee>
          <ul className="mo-v-ulls">
            {indicesData.concat(indicesData).map((index, idx) => (
              <li key={idx}>
                <span className="bold">{index?.name}</span>&nbsp;
                <span>${fc(index?.close)}</span>&nbsp;
                <span
                  className={
                    index?.percentChange >= 0
                      ? "mo-v-color-green"
                      : "mo-v-color-red"
                  }
                >
                  {index?.isPositive ? "↑" : "↓"}
                  {fc(index?.percentChange, 2, true)}%
                </span>
              </li>
            ))}
          </ul>
        </Marquee>
      </div>

      {/* Centered main content */}
      <div className="mo-v-content">
        <h2 className="mo-v-mobile-heading">
          Vestr is better
          <br />
          on desktop
        </h2>
        <p className="mo-v-mobile-paragraph">
          The Vestr mobile app is scheduled to be
          <br />
          released in March. Sign up for the
          <br />
          waitlist!
        </p>
        <button className="mo-v-active" onClick={() => setisModalOpen(true)}>
          Mobile waitlist
        </button>
        <button className="mo-v-button" onClick={() => window.open('https://vestrdata.com')}>VestrData</button>
        <button className="mo-v-button" onClick={() => window.open('https://www.tiktok.com/@downloadvestr?_t=8sAboPwsXFq&_r=1')}>TikTok</button>
        <button className="mo-v-button" onClick={() => window.open('https://x.com/downloadvestr?s=21')}>Twitter</button>
      </div>
    </div>
  );
};

export default MobileView;