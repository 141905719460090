import React, { useState, useEffect, useContext } from 'react'
import { timeOptions } from '../helper/FeedHelper'
import {
  fetchBestLeaderboards,
  // updated sortOptions with asc property
  sortOptions,
} from '../helper/LeaderboardHelper'
import {
  getImageUrl,
  formatCurrency as fc,
  LoadingSpinner,
  customStylesTrans,
} from '../helper/StylesHelper'
import { getChangePercentageColor } from '../helper/MarketsHelper'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { AuthContext } from '../context/AuthContext'
import { ThemeContext } from '../context/ThemeContext'
import VerifiedIcon from './VerifiedIcon'

export default function CategoryLeaderboardSection({
  categoryName,
  title,
  defaultLimit = 5,
  defaultTime = 'Today', // or timeOptions[0].value
  // Instead of a string, store an entire sort option from sortOptions
  defaultSort = sortOptions[0],
}) {
  const navigate = useNavigate()

  const [selectedTime, setSelectedTime] = useState(defaultTime)
  // Keep the entire object
  const [selectedSortOption, setSelectedSortOption] = useState(defaultSort)
  const [showAll, setShowAll] = useState(false)
  const { userToken } = useContext(AuthContext)
  const { tc } = useContext(ThemeContext)

  // The data object from backend
  const [data, setData] = useState(null)
  // The “expanded list” we accumulate
  const [list, setList] = useState([])
  const [page, setPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)

  // ---- Fetch function ----
  async function doFetchLeaderboards(pageToFetch = 1, isShowAll = false) {
    setIsLoading(true)

    const onData = (res) => {
      setData(res)
      if (pageToFetch === 1) {
        setList(res.topLeaderboards || [])
      } else {
        setList((prev) => [...prev, ...(res.topLeaderboards || [])])
      }
    }

    // Pass both .value and .asc
    await fetchBestLeaderboards(
      selectedTime,
      selectedSortOption.value, // e.g. '%return' or 'netReturn'
      categoryName,
      pageToFetch,
      defaultLimit,
      onData,
      null,
      '', // optional search param
      selectedSortOption.asc, // pass asc
      userToken
    )
    setIsLoading(false)
  }

  // ---- Reset page when time/sort changes ----
  useEffect(() => {
    setPage(1)
    setData(null)
    setList([])
    // eslint-disable-next-line
  }, [selectedTime, selectedSortOption])

  useEffect(() => {
    if (!showAll) {
      // "card" view
      doFetchLeaderboards(1, false)
    } else {
      // table view
      doFetchLeaderboards(page, true)
    }
    // eslint-disable-next-line
  }, [showAll, page, selectedTime, selectedSortOption])

  function handleLoadMore() {
    if (!data) return
    const hasMore = list.length < (data.totalResults || 0)
    if (hasMore) {
      setPage((prev) => prev + 1)
    }
  }

  function renderCard(lb, idx) {
    return (
      <div
        key={lb.leaderboardId}
        className="LeadCard"
        onClick={() => !isMobile && navigate(`/leaderboard/${lb.username}`)}
      >
        <div
          style={{ color: idx === 0 ? '#FFBF19' : 'inherit' }}
          className="LeadCardRank"
        >
          {idx + 1}
        </div>
        <div
          style={{
            boxShadow:
              idx === 0 ? '0 0 0.6rem #030902, 0 0 1rem #FFBF19' : 'none',
          }}
          className="LeadCardImageWrapper LeaderRadius"
        >
          <img
            src={getImageUrl(lb.profileImage)}
            alt={lb.name}
            className="LeadCardImage"
          />
        </div>
        <div className="LeadCardDetails">
          <div className="LeadCardUserDetails">
            <h3 className="LeadCardGain" style={{ color: 'var(--text-color)' }}>
              {lb.name}{' '}
              {lb.isLeaderboardVerified ? (
                <VerifiedIcon
                  color={idx === 0 ? '#FFBF19' : 'var(--stock-change-pos)'}
                  fontSize="1.3rem"
                />
              ) : (
                ''
              )}
            </h3>
            <div
              className="LeadCardGain bold"
              style={{
                color:
                  idx === 0
                    ? '#FFBF19'
                    : getChangePercentageColor(lb.percentageGain, tc()),
              }}
            >
              {selectedSortOption.value === '%return' ? (
                <>
                  <span className="LeadGainArrow">
                    {lb.percentageGain >= 0 ? '↑' : '↓'}
                  </span>
                  {fc(Math.abs(lb.percentageGain), 2, true)}%
                </>
              ) : (
                <>
                  <span className="LeadGainArrow">
                    {lb.netGain >= 0 ? '↑' : '↓'}
                  </span>
                  ${fc(Math.abs(lb.netGain), 2, true)}
                </>
              )}
            </div>
          </div>
          <div className="LeadCardLargestPosition">
            <h3
              className="LeadCardName"
              style={{ color: 'var(--action-grey)' }}
            >
              Members
            </h3>
            <span className="bold" style={{ color: 'var(--text-color)' }}>
              {lb.totalMembers ?? 0}
            </span>
          </div>
        </div>
      </div>
    )
  }

  function renderTableRow(lb, index) {
    return (
      <tr
        key={lb.leaderboardId}
        style={{ cursor: 'pointer' }}
        onClick={() => navigate(`/leaderboard/${lb.username}`)}
      >
        <td className="mv2-table-rank">{index + 1}</td>
        <td>
          <img
            src={getImageUrl(lb.profileImage)}
            alt={lb.name}
            style={{ width: '3rem', borderRadius: '0.5rem' }}
          />
        </td>
        <td>{lb.name}</td>
        <td
          style={{
            color: getChangePercentageColor(lb.dailyPercentageReturn, tc()),
          }}
        >
          {lb.dailyPercentageReturn != null
            ? `${lb.dailyPercentageReturn >= 0 ? '↑' : '↓'}${fc(
                Math.abs(lb.dailyPercentageReturn),
                2,
                true
              )}%`
            : '--'}
        </td>
        <td
          style={{
            color: getChangePercentageColor(lb.weeklyPercentageReturn, tc()),
          }}
        >
          {lb.weeklyPercentageReturn != null
            ? `${lb.weeklyPercentageReturn >= 0 ? '↑' : '↓'}${fc(
                Math.abs(lb.weeklyPercentageReturn),
                2,
                true
              )}%`
            : '--'}
        </td>
        <td
          style={{
            color: getChangePercentageColor(lb.allTimePercentageReturn, tc()),
          }}
        >
          {lb.allTimePercentageReturn != null
            ? `${lb.allTimePercentageReturn >= 0 ? '↑' : '↓'}${fc(
                Math.abs(lb.allTimePercentageReturn),
                2,
                true
              )}%`
            : '--'}
        </td>
        <td>{lb.totalMembers ?? 0}</td>
      </tr>
    )
  }

  return (
    <div className="LeadBestSection">
      <div className="LeadBestSectionHeader">
        <div className="LeadBestTitle">
          <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
            <h2 className="LeadBestTitleH2">{title}</h2>
            <span className="LeadBestTitleSpan">
              Out of {data?.totalResults || '--'} total
            </span>
          </div>
          <span
            className="LeadBestTitleSpan"
            style={{ cursor: 'pointer' }}
            onClick={() => setShowAll((prev) => !prev)}
          >
            {showAll ? 'Show less' : 'Show all'}
          </span>
        </div>
      </div>
      {!isMobile && (
        <>
          {!showAll ? (
            <>
              <div className="LeadBestButtonsWrapper">
                <div className="LeadBestButtons">
                  {timeOptions.map((t) => (
                    <button
                      key={t.value}
                      className={`CreatePostFeedButton ${
                        selectedTime === t.value ? 'greenButton' : ''
                      }`}
                      onClick={() => {
                        setSelectedTime(t.value)
                      }}
                    >
                      {t.label}
                    </button>
                  ))}
                </div>
                <div className="LeadSortWrapper">
                  <Select
                    className="LeadSortSelect"
                    value={sortOptions.find(
                      (o) =>
                        o.value === selectedSortOption.value &&
                        o.asc === selectedSortOption.asc
                    )}
                    onChange={(option) => {
                      setSelectedSortOption(option)
                    }}
                    options={sortOptions}
                    isSearchable={false}
                    placeholder="Sort By"
                    unstyled
                    styles={customStylesTrans(tc())}
                  />
                </div>
              </div>
              <div className="LeadCardContainer">
                {isLoading ? (
                  <LoadingSpinner />
                ) : (
                  data?.topLeaderboards?.map((lb, idx) => renderCard(lb, idx))
                )}
              </div>
            </>
          ) : (
            <div
              style={{
                minHeight: '40rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: '2rem',
              }}
            >
              {isLoading && page === 1 ? (
                <LoadingSpinner />
              ) : (
                <>
                  <div className="leaderboard-table-wrapper">
                    <table className="mv2-table leaderboard-table">
                      <thead className="mv2-thead">
                        <tr className="mv2-thead-tr">
                          <th className="mv2-table-rank"></th>
                          <th>Profile</th>
                          <th>Name</th>
                          <th>1d change</th>
                          <th>7d change</th>
                          <th>All time</th>
                          <th>Members</th>
                        </tr>
                      </thead>
                      <tbody className="mv2-tbody">
                        {list.map((lb, index) => renderTableRow(lb, index))}
                      </tbody>
                    </table>
                  </div>
                  {isLoading && page > 1 && <LoadingSpinner />}
                  {data && list.length < (data.totalResults || 0) && (
                    <button
                      className="CreatePostFeedButton"
                      style={{ marginTop: '2rem' }}
                      onClick={handleLoadMore}
                      disabled={isLoading}
                    >
                      View more
                    </button>
                  )}
                </>
              )}
            </div>
          )}
        </>
      )}
      {isMobile && (
        <>
          {!showAll ? (
            <>
              <div className="LeadBestButtonsWrapper">
                <div className="LeadBestButtons">
                  {timeOptions.map((t) => (
                    <button
                      key={t.value}
                      className={`CreatePostFeedButton ${
                        selectedTime === t.value ? 'greenButton' : ''
                      }`}
                      onClick={() => {
                        setSelectedTime(t.value)
                      }}
                    >
                      {t.label}
                    </button>
                  ))}
                </div>
              </div>
              <div className="LeadSortWrapper">
                <Select
                  className="LeadSortSelect"
                  value={sortOptions.find(
                    (o) =>
                      o.value === selectedSortOption.value &&
                      o.asc === selectedSortOption.asc
                  )}
                  onChange={(option) => {
                    setSelectedSortOption(option)
                  }}
                  options={sortOptions}
                  isSearchable={false}
                  placeholder="Sort By"
                  unstyled
                  styles={customStylesTrans(tc())}
                />
              </div>
              <div className="LeadCardContainer">
                {isLoading ? (
                  <LoadingSpinner />
                ) : (
                  data?.topLeaderboards?.map((lb, idx) => renderCard(lb, idx))
                )}
              </div>
            </>
          ) : (
            <div
              style={{
                minHeight: '40rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: '2rem',
              }}
            >
              {isLoading && page === 1 ? (
                <LoadingSpinner />
              ) : (
                <>
                  <div className="leaderboard-table-wrapper">
                    <table className="mv2-table leaderboard-table">
                      <thead className="mv2-thead">
                        <tr className="mv2-thead-tr">
                          <th className="mv2-table-rank"></th>
                          <th>Profile</th>
                          <th>Name</th>
                          <th>1d change</th>
                          <th>7d change</th>
                          <th>All time</th>
                          <th>Members</th>
                        </tr>
                      </thead>
                      <tbody className="mv2-tbody">
                        {list.map((lb, index) => renderTableRow(lb, index))}
                      </tbody>
                    </table>
                  </div>
                  {isLoading && page > 1 && <LoadingSpinner />}
                  {data && list.length < (data.totalResults || 0) && (
                    <button
                      className="CreatePostFeedButton"
                      style={{ marginTop: '2rem' }}
                      onClick={handleLoadMore}
                      disabled={isLoading}
                    >
                      View more
                    </button>
                  )}
                </>
              )}
            </div>
          )}
        </>
      )}
    </div>
  )
}
