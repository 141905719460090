import React, { useState, useContext, useRef, useEffect } from 'react'
import '../styles/Leaderboards.css'
import { timeOptions } from '../helper/FeedHelper'
import { AuthContext } from '../context/AuthContext'
import useClickOutside from '../hooks/useClickOutside'
import useResponsiveWidth from '../hooks/useResponsiveWidth'
import { ThemeContext } from '../context/ThemeContext'
import {
  // for categories
  fetchBestLeaderboards,
  // for user-based "portfolios"
  fetchBestUsers,
  // sections array
  lbSections,
  // updated sortOptions with asc
  sortOptions,
} from '../helper/LeaderboardHelper'
import {
  getImageUrl,
  formatCurrency as fc,
  customStylesTrans,
  LoadingSpinner,
  formatModifiedTicker,
} from '../helper/StylesHelper'
import { useNavigate } from 'react-router-dom'
import { getChangePercentageColor } from '../helper/MarketsHelper'
import Select from 'react-select'
import axios from 'axios'
import config from '../config'

import blueDice from '../assets/blueDice.jpg'
import goldTrophy from '../assets/goldTrophy.jpg'
import goldPodium from '../assets/goldPodium.jpg'
import greenBook from '../assets/greenBook.jpg'
import orangeBook from '../assets/orangeBook.jpg'
import orangeSchool from '../assets/orangeSchool.jpg'
import purpleBuilding from '../assets/purpleBuilding.jpg'
import greenSchool from '../assets/greenSchool.jpg'

import CreateLeaderboardModal from './CreateLeaderboardModal'
import CategoryLeaderboardSection from './CategoryLeaderboardSection'
import VerifiedIcon from './VerifiedIcon'

const Leaderboards = () => {
  useResponsiveWidth()
  const navigate = useNavigate()

  const { isAuthenticated, userToken } = useContext(AuthContext)
  const { tc } = useContext(ThemeContext)

  // ========== “The Best” (ALL investors) section states ==========
  const [selectedBestTime, setSelectedBestTime] = useState(timeOptions[0].value)
  // Store the entire object from sortOptions (default to first)
  const [selectedSortOption, setSelectedSortOption] = useState(sortOptions[0])
  const [leaderboardData, setLeaderboardData] = useState([])
  const [isLeaderboardDataLoading, setIsLeaderboardDataLoading] =
    useState(false)

  // ========== Show all (ALL investors) states ==========
  const [showAllInvestors, setShowAllInvestors] = useState(false)
  const [allInvestorsData, setAllInvestorsData] = useState(null)
  const [allInvestorsList, setAllInvestorsList] = useState([])
  const [allInvestorsPage, setAllInvestorsPage] = useState(1)
  const [allInvestorsLimit] = useState(5)
  const [isAllInvestorsLoading, setIsAllInvestorsLoading] = useState(false)

  // ========== Right side “joined” states ==========
  const [selectedJoinOption, setSelectedJoinOption] = useState('friends')
  const [selectedTimeJoinOption, setSelectedTimeJoinOption] = useState(
    timeOptions[0].value
  )
  // Also store the entire object for the joined area
  const [selectedSortJoinOption, setSelectedSortJoinOption] = useState(
    sortOptions[0]
  )

  // Leaderboards the user has joined (from /api/leaderboards/joined)
  const [joinedLBs, setJoinedLBs] = useState([])
  const [joinedLBPage, setJoinedLBPage] = useState(1)
  const [joinedLBLimit] = useState(5)
  const [joinedLBTotal, setJoinedLBTotal] = useState(0)
  const [isJoinedLBLoading, setIsJoinedLBLoading] = useState(false)

  // For the “Joined” top-performers area
  const [joinedData, setJoinedData] = useState(null)
  const [joinedList, setJoinedList] = useState([])
  const [joinedPage, setJoinedPage] = useState(1)
  const [joinedLimit] = useState(5)
  const [isJoinedDataLoading, setIsJoinedDataLoading] = useState(false)

  // ========== Manage modal states ==========
  const [showManageModal, setShowManageModal] = useState(false)
  const [managePage, setManagePage] = useState(1)
  const [manageLimit] = useState(5)
  const [manageLeaderboards, setManageLeaderboards] = useState([])
  const [manageTotalResults, setManageTotalResults] = useState(0)
  const [isManageLoading, setIsManageLoading] = useState(false)

  // ========== Create/Edit modal states ==========
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [editParentId, setEditParentId] = useState(null)
  const [editId, setEditId] = useState(null)
  const [editUsername, setEditUsername] = useState(null)

  // ========== Modals references for outside click ==========
  const excludeRef = useRef(null)
  const manageRef = useClickOutside(
    () => {
      setShowManageModal(false)
    },
    showManageModal,
    excludeRef
  )

  // ========== Searching top bar states ==========
  const [topSearch, setTopSearch] = useState('')
  const [topSearchSuggestions, setTopSearchSuggestions] = useState([])
  const debounceTimer = useRef(null)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)

  // ---------- Debounce logic for top search ----------
  const debounce = (func, delay) => {
    let timer
    return (...args) => {
      if (timer) clearTimeout(timer)
      timer = setTimeout(() => func(...args), delay)
    }
  }
  const topSearchDebounce =
    useRef(null).current || debounce(doFetchTopSearch, 500)

  function handleTopSearchChange(e) {
    const val = e.target.value
    setTopSearch(val)
    topSearchDebounce(val)
  }

  function doFetchTopSearch(query) {
    if (!query) {
      setTopSearchSuggestions([])
      return
    }
    axios
      .get(`${config.backendUrl}/api/leaderboards`, {
        params: { q: query, numResults: 5 },
      })
      .then((resp) => {
        const boards = resp.data.leaderboards || []
        setTopSearchSuggestions(boards)
      })
      .catch((err) => console.error('Error searching leaderboards:', err))
  }

  function handleTopSearchSuggestionClick(lb) {
    navigate(`/leaderboard/${lb.username}`)
    setTopSearch('')
    setTopSearchSuggestions([])
  }

  // ========== “The Best” (all investors) ==========
  useEffect(() => {
    async function fetchLeaderboardData() {
      setIsLeaderboardDataLoading(true)
      // we pass selectedSortOption.value, selectedSortOption.asc
      await fetchBestUsers(
        selectedBestTime,
        selectedSortOption.value, // e.g. '%return' or 'netReturn'
        1, // page
        5, // limit
        setLeaderboardData,
        'all',
        '',
        selectedSortOption.asc, // pass asc
        userToken
      )
      setIsLeaderboardDataLoading(false)
    }
    fetchLeaderboardData()
  }, [selectedBestTime, selectedSortOption, userToken])

  // “Show all investors” table
  useEffect(() => {
    async function fetchAllInvestors() {
      if (showAllInvestors) {
        setIsAllInvestorsLoading(true)
        const onData = (res) => {
          setAllInvestorsData(res)
          if (allInvestorsPage === 1) {
            setAllInvestorsList(res.topPortfolios || [])
          } else {
            setAllInvestorsList((prev) => [
              ...prev,
              ...(res.topPortfolios || []),
            ])
          }
        }
        await fetchBestUsers(
          selectedBestTime,
          selectedSortOption.value, // pass value
          allInvestorsPage,
          allInvestorsLimit,
          onData,
          'all',
          '',
          selectedSortOption.asc, // pass asc
          userToken
        )
        setIsAllInvestorsLoading(false)
      } else {
        setAllInvestorsData(null)
        setAllInvestorsList([])
        setAllInvestorsPage(1)
      }
    }
    fetchAllInvestors()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAllInvestors, allInvestorsPage, userToken])

  // ========== “Joined” leaderboards fetch ==========
  useEffect(() => {
    if (!isAuthenticated) return
    async function fetchUserJoinedLeaderboards() {
      try {
        setIsJoinedLBLoading(true)
        const resp = await axios.get(
          `${config.backendUrl}/api/leaderboards/joined`,
          {
            params: {
              page: joinedLBPage,
              limit: joinedLBLimit,
            },
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        )

        if (joinedLBPage === 1) {
          setJoinedLBs(resp.data.leaderboards)
        } else {
          setJoinedLBs((prev) => [...prev, ...resp.data.leaderboards])
        }
        setJoinedLBTotal(resp.data.totalResults)
      } catch (error) {
        console.error('Error fetching user joined leaderboards:', error)
      } finally {
        setIsJoinedLBLoading(false)
      }
    }
    fetchUserJoinedLeaderboards()
  }, [isAuthenticated, userToken, joinedLBPage, joinedLBLimit])

  // ========== Right side “joined” – top portfolios fetch ==========
  useEffect(() => {
    async function fetchJoinedLeaderData() {
      setIsJoinedDataLoading(true)
      const onData = (res) => {
        setJoinedData(res)
        if (joinedPage === 1) {
          setJoinedList(res.topPortfolios || [])
        } else {
          setJoinedList((prev) => [...prev, ...(res.topPortfolios || [])])
        }
      }

      await fetchBestUsers(
        selectedTimeJoinOption,
        selectedSortJoinOption.value, // pass value
        joinedPage,
        joinedLimit,
        onData,
        selectedJoinOption,
        '',
        selectedSortJoinOption.asc, // pass asc
        userToken
      )
      setIsJoinedDataLoading(false)
    }
    fetchJoinedLeaderData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedTimeJoinOption,
    selectedSortJoinOption,
    selectedJoinOption,
    joinedPage,
    userToken,
  ])

  // ========== Manage modal fetch ==========
  useEffect(() => {
    if (!showManageModal) return
    async function fetchManageLeaderboards() {
      try {
        setIsManageLoading(true)
        const resp = await axios.get(
          `${config.backendUrl}/api/leaderboards/manage`,
          {
            params: {
              page: managePage,
              limit: manageLimit,
            },
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        )
        const data = resp.data
        if (managePage === 1) {
          setManageLeaderboards(data.leaderboards)
        } else {
          setManageLeaderboards((prev) => [...prev, ...data.leaderboards])
        }
        setManageTotalResults(data.totalResults)
      } catch (error) {
        console.error('Error fetching manage leaderboards:', error)
      } finally {
        setIsManageLoading(false)
      }
    }
    fetchManageLeaderboards()
  }, [showManageModal, managePage, manageLimit, userToken])

  // ========== CATEGORY SECTIONS (Companies, Random, High School Classes, High Schools) ==========

  // (1) COMPANIES
  const [selectedTimeCompanies, setSelectedTimeCompanies] = useState(
    timeOptions[0].value
  )
  const [selectedSortCompanies, setSelectedSortCompanies] = useState('%return')
  const [showAllCompanies, setShowAllCompanies] = useState(false)
  const [companiesData, setCompaniesData] = useState(null)
  const [companiesList, setCompaniesList] = useState([])
  const [companiesPage, setCompaniesPage] = useState(1)
  const [isCompaniesLoading, setIsCompaniesLoading] = useState(false)

  useEffect(() => {
    async function fetchCompanies() {
      if (showAllCompanies) {
        setIsCompaniesLoading(true)
        const onData = (res) => {
          setCompaniesData(res)
          if (companiesPage === 1) {
            setCompaniesList(res.topLeaderboards || [])
          } else {
            setCompaniesList((prev) => [
              ...prev,
              ...(res.topLeaderboards || []),
            ])
          }
        }
        await fetchBestLeaderboards(
          selectedTimeCompanies,
          selectedSortCompanies,
          'Companies',
          companiesPage,
          5,
          onData,
          null,
          '',
          userToken
        )
        setIsCompaniesLoading(false)
      } else {
        setIsCompaniesLoading(true)
        await fetchBestLeaderboards(
          selectedTimeCompanies,
          selectedSortCompanies,
          'Companies',
          1,
          5,
          (res) => setCompaniesData(res),
          null,
          '',
          userToken
        )
        setCompaniesList([])
        setIsCompaniesLoading(false)
      }
    }
    fetchCompanies()
  }, [
    selectedTimeCompanies,
    selectedSortCompanies,
    showAllCompanies,
    companiesPage,
    userToken,
  ])

  function handleLoadMoreCompanies() {
    if (!companiesData) return
    const hasMore = companiesList.length < (companiesData.totalResults || 0)
    if (hasMore) {
      setCompaniesPage((prev) => prev + 1)
    }
  }

  // (2) RANDOM
  const [selectedTimeRandom, setSelectedTimeRandom] = useState(
    timeOptions[0].value
  )
  const [selectedSortRandom, setSelectedSortRandom] = useState('%return')
  const [showAllRandom, setShowAllRandom] = useState(false)
  const [randomData, setRandomData] = useState(null)
  const [randomList, setRandomList] = useState([])
  const [randomPage, setRandomPage] = useState(1)
  const [isRandomLoading, setIsRandomLoading] = useState(false)

  useEffect(() => {
    async function fetchRandom() {
      if (showAllRandom) {
        setIsRandomLoading(true)
        const onData = (res) => {
          setRandomData(res)
          if (randomPage === 1) {
            setRandomList(res.topLeaderboards || [])
          } else {
            setRandomList((prev) => [...prev, ...(res.topLeaderboards || [])])
          }
        }
        await fetchBestLeaderboards(
          selectedTimeRandom,
          selectedSortRandom,
          'Random',
          randomPage,
          5,
          onData,
          null,
          '',
          userToken
        )
        setIsRandomLoading(false)
      } else {
        setIsRandomLoading(true)
        await fetchBestLeaderboards(
          selectedTimeRandom,
          selectedSortRandom,
          'Random',
          1,
          5,
          (res) => setRandomData(res),
          null,
          '',
          userToken
        )
        setRandomList([])
        setIsRandomLoading(false)
      }
    }
    fetchRandom()
  }, [
    selectedTimeRandom,
    selectedSortRandom,
    showAllRandom,
    randomPage,
    userToken,
  ])

  function handleLoadMoreRandom() {
    if (!randomData) return
    const hasMore = randomList.length < (randomData.totalResults || 0)
    if (hasMore) {
      setRandomPage((prev) => prev + 1)
    }
  }

  // (3) HIGH SCHOOL CLASSES
  const [selectedTimeHSClasses, setSelectedTimeHSClasses] = useState(
    timeOptions[0].value
  )
  const [selectedSortHSClasses, setSelectedSortHSClasses] = useState('%return')
  const [showAllHSClasses, setShowAllHSClasses] = useState(false)
  const [hsClassesData, setHsClassesData] = useState(null)
  const [hsClassesList, setHsClassesList] = useState([])
  const [hsClassesPage, setHsClassesPage] = useState(1)
  const [isHSClassesLoading, setIsHSClassesLoading] = useState(false)

  useEffect(() => {
    async function fetchHSClasses() {
      if (showAllHSClasses) {
        setIsHSClassesLoading(true)
        const onData = (res) => {
          setHsClassesData(res)
          if (hsClassesPage === 1) {
            setHsClassesList(res.topLeaderboards || [])
          } else {
            setHsClassesList((prev) => [
              ...prev,
              ...(res.topLeaderboards || []),
            ])
          }
        }
        await fetchBestLeaderboards(
          selectedTimeHSClasses,
          selectedSortHSClasses,
          'High School Classes',
          hsClassesPage,
          5,
          onData,
          null,
          '',
          userToken
        )
        setIsHSClassesLoading(false)
      } else {
        setIsHSClassesLoading(true)
        await fetchBestLeaderboards(
          selectedTimeHSClasses,
          selectedSortHSClasses,
          'High School Classes',
          1,
          5,
          (res) => setHsClassesData(res),
          null,
          '',
          userToken
        )
        setHsClassesList([])
        setIsHSClassesLoading(false)
      }
    }
    fetchHSClasses()
  }, [
    selectedTimeHSClasses,
    selectedSortHSClasses,
    showAllHSClasses,
    hsClassesPage,
    userToken,
  ])

  function handleLoadMoreHSClasses() {
    if (!hsClassesData) return
    const hasMore = hsClassesList.length < (hsClassesData.totalResults || 0)
    if (hasMore) {
      setHsClassesPage((prev) => prev + 1)
    }
  }

  // (4) HIGH SCHOOLS
  const [selectedTimeHS, setSelectedTimeHS] = useState(timeOptions[0].value)
  const [selectedSortHS, setSelectedSortHS] = useState('%return')
  const [showAllHS, setShowAllHS] = useState(false)
  const [highSchoolData, setHighSchoolData] = useState(null)
  const [highSchoolList, setHighSchoolList] = useState([])
  const [highSchoolPage, setHighSchoolPage] = useState(1)
  const [isHighSchoolLoading, setIsHighSchoolLoading] = useState(false)

  useEffect(() => {
    async function fetchHighSchool() {
      if (showAllHS) {
        setIsHighSchoolLoading(true)
        const onData = (res) => {
          setHighSchoolData(res)
          if (highSchoolPage === 1) {
            setHighSchoolList(res.topLeaderboards || [])
          } else {
            setHighSchoolList((prev) => [
              ...prev,
              ...(res.topLeaderboards || []),
            ])
          }
        }
        await fetchBestLeaderboards(
          selectedTimeHS,
          selectedSortHS,
          'High Schools',
          highSchoolPage,
          5,
          onData,
          null,
          '',
          userToken
        )
        setIsHighSchoolLoading(false)
      } else {
        setIsHighSchoolLoading(true)
        await fetchBestLeaderboards(
          selectedTimeHS,
          selectedSortHS,
          'High Schools',
          1,
          5,
          (res) => setHighSchoolData(res),
          null,
          '',
          userToken
        )
        setHighSchoolList([])
        setIsHighSchoolLoading(false)
      }
    }
    fetchHighSchool()
  }, [selectedTimeHS, selectedSortHS, showAllHS, highSchoolPage, userToken])

  function handleLoadMoreHS() {
    if (!highSchoolData) return
    const hasMore = highSchoolList.length < (highSchoolData.totalResults || 0)
    if (hasMore) {
      setHighSchoolPage((prev) => prev + 1)
    }
  }

  // ========== Handlers ==========
  // The Best: Show all investors “View more”
  function handleLoadMoreAllInvestors() {
    if (!allInvestorsData) return
    const hasMore =
      allInvestorsList.length < (allInvestorsData.totalResults || 0)
    if (hasMore) {
      setAllInvestorsPage((prev) => prev + 1)
    }
  }

  // “Joined” top portfolios “View more”
  function handleLoadMoreJoined() {
    if (!joinedData) return
    const hasMore = joinedList.length < (joinedData.totalResults || 0)
    if (hasMore) {
      setJoinedPage((prev) => prev + 1)
    }
  }
  function handleLoadMoreJoinedLBs() {
    if (!isJoinedLBLoading && joinedLBs.length < joinedLBTotal) {
      setJoinedLBPage((prev) => prev + 1)
    }
  }

  // Manage “View more”
  function handleManageLoadMore() {
    if (manageLeaderboards.length < manageTotalResults) {
      setManagePage((prev) => prev + 1)
    }
  }

  // Create/Edit modal
  function openCreateModal() {
    if (!isAuthenticated) {
      alert('You need to be logged in to create a leaderboard.')
      return
    }
    setIsEditMode(false)
    setEditId(null)
    setShowCreateModal(true)
  }
  function handleEditLeaderboard(lb) {
    if (!isAuthenticated) {
      alert('You need to be logged in to edit a leaderboard.')
      return
    }
    setIsEditMode(true)
    setEditId(lb._id)
    setEditUsername(lb.username)
    setEditParentId(lb.parentLeaderboard)
    setShowManageModal(false)
    setShowCreateModal(true)
  }

  const formatChangeValue = (value) => {
    if (value === null || value === undefined) return '--'
    const sign = value >= 0 ? '↑' : '↓'
    return `${sign}${Math.abs(value).toFixed(2)}%`
  }

  function handleCloseCreateModal() {
    setShowCreateModal(false)
    setIsEditMode(false)
    setEditId(null)
    setEditParentId(null)
    setEditUsername(null)
  }

  // ========== Render “Manage” Modal ==========
  function renderManageModal() {
    if (!showManageModal) return null
    return (
      <div
        className="port-settings-modal"
        ref={manageRef}
        style={{ minWidth: '40vw' }}
      >
        <h2 className="FeedPostHeaderH2">Manage leaderboards</h2>
        <p className="FeedPortfolioSpan">Manage your leaderboards.</p>

        <div className="PortInputContainer" style={{ gap: '0.5rem' }}>
          {manageLeaderboards.map((lb) => (
            <div
              key={lb._id}
              className="PortSection"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                cursor: 'pointer',
              }}
            >
              <div onClick={() => navigate(`/leaderboard/${lb.username}`)}>
                <h3 className="LeadBestTitle" style={{ margin: 0 }}>
                  {lb.name}
                </h3>
                <p className="FeedPortfolioSpan" style={{ margin: 0 }}>
                  {`v/${lb.username}`} | {lb.totalMembers ?? 0} members
                </p>
              </div>
              <button
                className="Feed-toggle-button bold"
                onClick={(e) => {
                  e.stopPropagation()
                  handleEditLeaderboard(lb)
                }}
                style={{
                  backgroundColor: 'var(--background-color-feed-btn)',
                  borderRadius: '5rem',
                  color: 'var(--text-color)',
                }}
              >
                Edit
              </button>
            </div>
          ))}
        </div>

        {isManageLoading && managePage === 1 ? (
          <LoadingSpinner />
        ) : (
          <>
            {isManageLoading && managePage > 1 && <LoadingSpinner />}
            {manageLeaderboards.length < manageTotalResults && (
              <button
                className="Feed-toggle-button bold"
                style={{
                  marginTop: '1rem',
                  backgroundColor: 'var(--background-color)',
                  borderRadius: '5rem',
                }}
                onClick={handleManageLoadMore}
              >
                View more
              </button>
            )}
          </>
        )}

        <div
          className="Feed-toggle-container"
          style={{ gap: '1rem', border: 'none' }}
        >
          <button
            className="Feed-toggle-button bold"
            onClick={() => {
              setShowManageModal(false)
              setManagePage(1)
              setManageLeaderboards([])
            }}
            style={{
              backgroundColor: 'var(--background-color)',
              borderRadius: '5rem',
              color: 'var(--text-color)',
              flex: '0',
            }}
          >
            Close
          </button>
        </div>
      </div>
    )
  }

  // ========== Browse Section (optional) ==========
  const renderBrowseSection = () => {
    const browseItems = [
      { name: 'All Investors', image: goldPodium, linkText: 'investors' },
      { name: 'All Leaderboards', image: goldTrophy, linkText: 'leaderboards' },
      { name: 'Colleges', image: greenSchool, linkText: 'colleges' },
      {
        name: 'College Classes',
        image: greenBook,
        linkText: 'college-classes',
      },
      { name: 'High Schools', image: orangeSchool, linkText: 'high-schools' },
      {
        name: 'High School Classes',
        image: orangeBook,
        linkText: 'high-school-classes',
      },
      { name: 'Companies', image: purpleBuilding, linkText: 'companies' },
      { name: 'Random', image: blueDice, linkText: 'random' },
    ]

    return (
      <div className="LeadBestSection">
        <div className="LeadBestTitle">
          <div
            style={{
              display: 'flex',
              marginBottom: '2rem',
              alignItems: 'center',
            }}
          >
            <h2 className="LeadBestTitleH2">Browse</h2>
          </div>
        </div>
        <div className="LeadBrowseGrid">
          {browseItems.map((item, index) => (
            <div
              key={index}
              className="LeadBrowseCard"
              onClick={() =>
                !isMobile && navigate(`/all-leaderboards/${item.linkText}`)
              }
            >
              <img
                src={item.image}
                alt={item.name}
                className="LeadBrowseCardImage"
              />
              <span className="LeadBrowseCardText bold">{item.name}</span>
            </div>
          ))}
        </div>
      </div>
    )
  }

  // ========== “The Best” Card (user portfolios) ==========
  function renderLeaderboardCard(topPortfolio, index) {
    const { user, percentageGain, netGain, largestPositionTicker } =
      topPortfolio
    console.log('user', user)
    return (
      <div
        key={index}
        className="LeadCard"
        onClick={() => !isMobile && navigate(`/profile/${user.username}`)}
      >
        <div
          style={{ color: index === 0 ? '#FFBF19' : 'inherit' }}
          className="LeadCardRank"
        >
          {index + 1}
        </div>
        <div
          className="LeadCardImageWrapper"
          style={{
            boxShadow:
              index === 0 ? '0 0 0.6rem #030902, 0 0 2rem #FFBF19' : 'none',
          }}
        >
          <img
            src={getImageUrl(user.profilePicture)}
            alt={user.name}
            className="LeadCardImage"
          />
        </div>
        <div className="LeadCardDetails">
          <div className="LeadCardUserDetails">
            <h3
              className="LeadCardGain"
              style={{
                color: index === 0 ? '#FFBF19' : 'var(--text-color)',
              }}
            >
              {user.name}{' '}
              {user.isUserVerified ? (
                <VerifiedIcon
                  color={index === 0 ? '#FFBF19' : 'var(--stock-change-pos)'}
                  fontSize="1.3rem"
                />
              ) : (
                ''
              )}
            </h3>
            <div
              className="LeadCardGain bold"
              style={{
                color:
                  index === 0
                    ? '#FFBF19'
                    : getChangePercentageColor(percentageGain, tc()),
              }}
            >
              {selectedSortOption.value === '%return' ? (
                <>
                  <span className="LeadGainArrow">
                    {percentageGain >= 0 ? '↑' : '↓'}
                  </span>
                  {fc(Math.abs(percentageGain), 2, true)}%
                </>
              ) : (
                <>
                  <span className="LeadGainArrow">
                    {netGain >= 0 ? '↑' : '↓'}
                  </span>
                  ${fc(Math.abs(netGain), 2, true)}
                </>
              )}
            </div>
          </div>
          <div className="LeadCardLargestPosition">
            <h3
              className="LeadCardName"
              style={{ color: 'var(--action-grey)' }}
            >
              Largest Position
            </h3>
            <span
              className="bold"
              style={{ color: index === 0 ? '#FFBF19' : 'var(--text-color)' }}
            >
              {formatModifiedTicker(largestPositionTicker) || '--'}
            </span>
          </div>
        </div>
      </div>
    )
  }

  // ========== Big table rows for user portfolios (“The Best”) ==========
  function renderAllInvestorsRow(item, index) {
    const oneDay = item.dailyPercentageReturn
    const sevenDay = item.weeklyPercentageReturn
    const allTime = item.allTimePercentageReturn
    const trades = (item.totalBuys || 0) + (item.totalSells || 0)
    const copiers = item.totalCopies || '--'
    const bestTrade = item.bestTradeTicker || '--'
    const largestPos = item.largestPositionTicker || '--'
    return (
      <tr
        style={{ cursor: 'pointer' }}
        key={index}
        onClick={() => navigate(`/profile/${item.user.username}`)}
      >
        <td className="mv2-table-rank">{index + 1}</td>
        <td>
          <img
            src={getImageUrl(item.user.profilePicture)}
            alt="user-pfp"
            style={{
              width: '3rem',
              borderRadius: '0.5rem',
            }}
          />
        </td>
        <td>{item.user.name}</td>
        <td style={{ color: getChangePercentageColor(oneDay, tc()) }}>
          {oneDay != null
            ? `${oneDay >= 0 ? '↑' : '↓'}${fc(Math.abs(oneDay), 2, true)}%`
            : '--'}
        </td>
        <td style={{ color: getChangePercentageColor(sevenDay, tc()) }}>
          {sevenDay != null
            ? `${sevenDay >= 0 ? '↑' : '↓'}${fc(Math.abs(sevenDay), 2, true)}%`
            : '--'}
        </td>
        <td style={{ color: getChangePercentageColor(allTime, tc()) }}>
          {allTime != null
            ? `${allTime >= 0 ? '↑' : '↓'}${fc(Math.abs(allTime), 2, true)}%`
            : '--'}
        </td>
        <td>{trades}</td>
        <td>{copiers}</td>
        <td>{formatModifiedTicker(largestPos)}</td>
        <td>{formatModifiedTicker(bestTrade)}</td>
      </tr>
    )
  }

  // ========== Right side card (top portfolios in user’s joined category) ==========
  function renderRightSideUser(topPortfolio, index) {
    const { user, percentageGain, netGain, largestPositionTicker } =
      topPortfolio
    return (
      <div
        className="LeadRightSideUserCard"
        key={index}
        onClick={() => navigate(`/profile/${user.username}`)}
      >
        <div
          style={{
            color: index === 0 ? '#FFBF19' : 'inherit',
          }}
          className="LeadRightSideUserCardRank"
        >
          {index + 1}
        </div>
        <div
          className="LeadRightSideUserImageWrapper"
          style={{
            boxShadow:
              index === 0 ? '0 0 0.6rem #030902, 0 0 2rem #FFBF19' : 'none',
          }}
        >
          <img
            src={getImageUrl(user.profilePicture)}
            alt={user.name}
            className="LeadRightSideUserImg"
          />
        </div>
        <div className="LeadRightSideUserDetails">
          <div
            className="LeadRightSideUserName bold"
            style={{
              color: index === 0 ? '#FFBF19' : '#FFFFFF',
            }}
          >
            <span className="bold">
              {user.name}{' '}
              {user.isUserVerified ? (
                <VerifiedIcon
                  color={index === 0 ? '#FFBF19' : 'var(--stock-change-pos)'}
                  fontSize="1.3rem"
                />
              ) : (
                ''
              )}
            </span>
            <span
              className="LeadRightSideUserGain bold"
              style={{
                color:
                  index === 0
                    ? '#FFBF19'
                    : getChangePercentageColor(percentageGain, tc()),
              }}
            >
              {selectedSortJoinOption.value === '%return' ? (
                <>
                  <span className="LeadGainArrow">
                    {percentageGain >= 0 ? '↑' : '↓'}
                  </span>
                  {fc(Math.abs(percentageGain), 2, true)}%
                </>
              ) : (
                <>
                  <span className="LeadGainArrow">
                    {netGain >= 0 ? '↑' : '↓'}
                  </span>
                  ${fc(Math.abs(netGain), 2, true)}
                </>
              )}
            </span>
          </div>
          <div className="LeadRightSideLargestPosition bold">
            Largest&nbsp;position:{' '}
            <div
              className="LeadRightSideUserGain bold"
              style={{ color: index === 0 ? '#FFBF19' : 'var(--text-color)' }}
            >
              {formatModifiedTicker(largestPositionTicker) || '--'}
            </div>
          </div>
        </div>
      </div>
    )
  }

  // ========== Final Return ==========
  return (
    <div className="LeadContainer">
      {!isMobile && (
        <>
          <CreateLeaderboardModal
            isOpen={showCreateModal}
            onClose={handleCloseCreateModal}
            isEditMode={isEditMode}
            editId={editId}
            userToken={userToken}
            editUsername={editUsername}
            parentLbId={editParentId}
          />

          {showManageModal && renderManageModal()}

          {/* SEARCH BAR */}
          <div ref={excludeRef}>
            <div className="LeadSearchWrapper" style={{ position: 'relative' }}>
              <div className="FeedSearchWrapper" style={{ width: '78%' }}>
                <div
                  className="FeedSearchBarKeywordContainer"
                  style={{ width: '100%' }}
                >
                  <input
                    className="FeedSearchBar bold"
                    type="text"
                    placeholder="Search leaderboards"
                    value={topSearch}
                    onChange={handleTopSearchChange}
                    style={{ width: '100%' }}
                  />
                  {topSearchSuggestions.length > 0 && topSearch && (
                    <ul className="SuggestionsDropdown">
                      {topSearchSuggestions?.map((lb) => (
                        <li
                          key={lb._id}
                          onClick={() => handleTopSearchSuggestionClick(lb)}
                          className="SuggestionItem"
                        >
                          {lb.name} ({lb.username})
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
              <div className="LeadCreateButton" onClick={openCreateModal}>
                Create
              </div>
              <div
                className="LeadCreateButton"
                onClick={() => {
                  setManagePage(1)
                  setManageLeaderboards([])
                  setShowManageModal(true)
                }}
              >
                Manage
              </div>
            </div>
          </div>

          {/* MAIN CONTENT */}
          <div className="LeadMainContent">
            <div
              className={`LeadBackdrop ${
                showManageModal || showCreateModal ? 'active' : ''
              }`}
            />

            {/* LEFT SIDE */}
            <div className="LeadLeftContent">
              {/* (A) THE BEST (ALL INVESTORS) */}
              <div className="LeadBestSection">
                <div className="LeadBestSectionHeader">
                  <div className="LeadBestTitle">
                    <div
                      style={{
                        display: 'flex',
                        gap: '1rem',
                        alignItems: 'center',
                      }}
                    >
                      <h2 className="LeadBestTitleH2">The Best</h2>
                      <span className="LeadBestTitleSpan">
                        Out of {leaderboardData?.totalResults || '--'} investors
                      </span>
                    </div>
                    <span
                      className="LeadBestTitleSpan"
                      style={{ justifySelf: 'flex-end', cursor: 'pointer' }}
                      onClick={() => setShowAllInvestors(!showAllInvestors)}
                    >
                      {showAllInvestors ? 'Show less' : 'Show all'}
                    </span>
                  </div>
                </div>
                {!showAllInvestors ? (
                  <>
                    <div className="LeadBestButtonsWrapper">
                      <div className="LeadBestButtons">
                        {timeOptions.map((time) => (
                          <button
                            key={time.value}
                            className={`CreatePostFeedButton ${
                              selectedBestTime === time.value
                                ? 'greenButton'
                                : ''
                            }`}
                            onClick={() => setSelectedBestTime(time.value)}
                          >
                            {time.label}
                          </button>
                        ))}
                      </div>
                      <div className="LeadSortWrapper">
                        <Select
                          className="LeadSortSelect"
                          // Now we store + read entire object
                          value={selectedSortOption}
                          onChange={(option) => setSelectedSortOption(option)}
                          options={sortOptions}
                          isSearchable={false}
                          placeholder="Sort By"
                          unstyled
                          styles={customStylesTrans(tc())}
                        />
                      </div>
                    </div>
                    <div className="LeadCardContainer">
                      {isLeaderboardDataLoading ? (
                        <LoadingSpinner />
                      ) : (
                        leaderboardData?.topPortfolios?.map((topPort, idx) =>
                          renderLeaderboardCard(topPort, idx)
                        )
                      )}
                    </div>
                  </>
                ) : (
                  <div
                    style={{
                      minHeight: '40rem',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      marginTop: '2rem',
                    }}
                  >
                    {isAllInvestorsLoading && allInvestorsPage === 1 ? (
                      <LoadingSpinner />
                    ) : (
                      <>
                        <div className="leaderboard-table-wrapper">
                          <table className="mv2-table leaderboard-table">
                            <thead className="mv2-thead">
                              <tr className="mv2-thead-tr">
                                <th className="mv2-table-rank"></th>
                                <th>Profile</th>
                                <th>Name</th>
                                <th>1d change</th>
                                <th>7d change</th>
                                <th>All time</th>
                                <th>Trades</th>
                                <th>Copiers</th>
                                <th>Largest position</th>
                                <th>Best Trade</th>
                              </tr>
                            </thead>
                            <tbody className="mv2-tbody">
                              {allInvestorsList.map((item, index) =>
                                renderAllInvestorsRow(item, index)
                              )}
                            </tbody>
                          </table>
                        </div>
                        {isAllInvestorsLoading && allInvestorsPage > 1 && (
                          <LoadingSpinner />
                        )}
                        {allInvestorsData &&
                          allInvestorsList.length <
                            (allInvestorsData.totalResults || 0) && (
                            <button
                              className="CreatePostFeedButton"
                              style={{ marginTop: '2rem' }}
                              onClick={handleLoadMoreAllInvestors}
                            >
                              View more
                            </button>
                          )}
                      </>
                    )}
                  </div>
                )}
              </div>

              {/* BROWSE SECTION */}
              {renderBrowseSection()}

              {lbSections.map((category) => {
                return (
                  <CategoryLeaderboardSection
                    key={category}
                    categoryName={category}
                    title={category}
                  />
                )
              })}
            </div>

            {/* RIGHT SIDE */}
            <div className="LeadRightContent">
              {isAuthenticated ? (
                <>
                  <h2 className="LeadBestTitleH2">Joined</h2>
                  <div className="LeadBestButtons">
                    <button
                      onClick={() => {
                        setJoinedPage(1)
                        setJoinedList([])
                        setSelectedJoinOption('friends')
                      }}
                      className={`CreatePostFeedButton ${
                        selectedJoinOption === 'friends' ? 'greenButton' : ''
                      }`}
                    >
                      Friends
                    </button>
                    <button
                      onClick={() => {
                        setJoinedPage(1)
                        setJoinedList([])
                        setSelectedJoinOption('following')
                      }}
                      className={`CreatePostFeedButton ${
                        selectedJoinOption === 'following' ? 'greenButton' : ''
                      }`}
                    >
                      Following
                    </button>
                    {joinedLBs.length > 0 && (
                      <span className="separator">-</span>
                    )}
                    {joinedLBs.map((lb) => (
                      <button
                        key={lb.leaderboardId}
                        className={`CreatePostFeedButton ${
                          selectedJoinOption === lb.username
                            ? 'greenButton'
                            : ''
                        }`}
                        onClick={() => {
                          setJoinedPage(1)
                          setJoinedList([])
                          setSelectedJoinOption(lb.username)
                        }}
                      >
                        {lb.name}
                      </button>
                    ))}
                    {joinedLBs.length < joinedLBTotal && (
                      <button
                        className="CreatePostFeedButton"
                        onClick={handleLoadMoreJoinedLBs}
                        disabled={isJoinedLBLoading}
                      >
                        {isJoinedLBLoading ? 'Loading...' : 'View more'}
                      </button>
                    )}
                  </div>
                  <div className="LeadFilter">
                    <Select
                      value={timeOptions.find(
                        (o) => o.value === selectedTimeJoinOption
                      )}
                      onChange={(option) => {
                        setJoinedPage(1)
                        setJoinedList([])
                        setSelectedTimeJoinOption(option.value)
                      }}
                      options={timeOptions}
                      isSearchable={false}
                      placeholder="Time"
                      unstyled
                      styles={customStylesTrans(tc())}
                    />
                    <Select
                      // store entire object
                      value={selectedSortJoinOption}
                      onChange={(selectedOption) => {
                        setJoinedPage(1)
                        setJoinedList([])
                        setSelectedSortJoinOption(selectedOption)
                      }}
                      options={sortOptions}
                      isSearchable={false}
                      placeholder="Sort By"
                      unstyled
                      styles={customStylesTrans(tc())}
                    />
                  </div>

                  <div className="LeadRightSideUserList">
                    {isJoinedDataLoading && joinedPage === 1 ? (
                      <LoadingSpinner />
                    ) : (
                      <>
                        {joinedList.length === 0 && !isJoinedDataLoading && (
                          <p>No users found.</p>
                        )}
                        {joinedList.map((topPortfolio, index) =>
                          renderRightSideUser(topPortfolio, index)
                        )}
                        {isJoinedDataLoading && joinedPage > 1 && (
                          <LoadingSpinner />
                        )}
                        {joinedData &&
                          joinedList.length <
                            (joinedData.totalResults || 0) && (
                            <button
                              className="CreatePostFeedButton"
                              style={{ marginTop: '1rem', alignSelf: 'center' }}
                              onClick={handleLoadMoreJoined}
                            >
                              View more
                            </button>
                          )}
                      </>
                    )}
                  </div>
                </>
              ) : (
                <div
                  className="FeedWelcome-container"
                  style={{
                    flexDirection: 'column',
                    gap: '2rem',
                    padding: '0',
                    alignItems: 'flex-start',
                    border: 'none',
                  }}
                >
                  <div
                    className="FeedWelcome-content"
                    style={{ width: '100%' }}
                  >
                    <h1 className="FeedWelcome-title">
                      Join the finance super app 🚀
                    </h1>
                    <p className="FeedWelcome-description">
                      Join leaderboards. Copy trades. Share trades. Buy options,
                      stocks, crypto. Laugh at your friends.{' '}
                      <span className="FeedWelcome-highlight bold">
                        The retail revolution, in one product.
                      </span>
                    </p>
                  </div>
                  <button
                    onClick={() => navigate('/signin')}
                    className="CreatePostFeedButton greenButton moreButtonPadding FeedWelcome-create-account-btn"
                  >
                    Create account
                  </button>
                </div>
              )}
            </div>
          </div>
        </>
      )}
      {/* ========== MOBILE VIEW ========== */}
      {isMobile && (
        <div className="LeadMobileContent">
          <h2 className="LeadBestTitle">Leaderboards</h2>
          {/* BROWSE SECTION */}
          {renderBrowseSection()}
          <div className="LeadBestSection">
            <h2 className="LeadBestTitle" style={{ marginTop: '1.5rem' }}>
              All Investors
            </h2>
            {/* Conditionally show top cards or the big table */}
            {!showAllInvestors ? (
              <>
                <div className="LeadBestButtonsWrapper">
                  <div className="LeadBestButtons">
                    {timeOptions.map((time) => (
                      <button
                        key={time.value}
                        className={`CreatePostFeedButton ${
                          selectedBestTime === time.value ? 'greenButton' : ''
                        }`}
                        onClick={() => setSelectedBestTime(time.value)}
                      >
                        {time.label}
                      </button>
                    ))}
                  </div>
                </div>
                <div className="LeadSortWrapper">
                  <Select
                    value={sortOptions.find(
                      (o) => o.value === selectedSortOption
                    )}
                    onChange={(selectedOption) =>
                      setSelectedSortOption(selectedOption.value)
                    }
                    options={sortOptions}
                    isSearchable={false}
                    placeholder="Sort By"
                    unstyled
                    styles={customStylesTrans(tc())}
                  />
                </div>

                {/* The “best” cards */}
                <div className="LeadCardContainer">
                  {isLeaderboardDataLoading ? (
                    <LoadingSpinner />
                  ) : (
                    leaderboardData?.topPortfolios?.map((topPort, idx) =>
                      renderLeaderboardCard(topPort, idx)
                    )
                  )}
                </div>
              </>
            ) : (
              // The big table of All Investors
              <div
                style={{
                  minHeight: '40rem',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: '2rem',
                }}
              >
                {isAllInvestorsLoading && allInvestorsPage === 1 ? (
                  <LoadingSpinner />
                ) : (
                  <>
                    <div className="leaderboard-table-wrapper">
                      <table className="mv2-table leaderboard-table">
                        <thead className="mv2-thead">
                          <tr className="mv2-thead-tr">
                            <th className="mv2-table-rank"></th>
                            <th>Profile</th>
                            <th>Name</th>
                            <th>1d change</th>
                            <th>7d change</th>
                            <th>All time</th>
                            <th>Trades</th>
                            <th>Copiers</th>
                            <th>Largest position</th>
                            <th>Best Trade</th>
                          </tr>
                        </thead>
                        <tbody className="mv2-tbody">
                          {allInvestorsList.map((item, index) => (
                            <tr
                              style={{ cursor: 'pointer' }}
                              key={index}
                              onClick={() =>
                                navigate(`/profile/${item.user.username}`)
                              }
                            >
                              <td className="mv2-table-rank">{index + 1}</td>
                              <td>
                                <img
                                  src={getImageUrl(item.user.profilePicture)}
                                  alt="user-pfp"
                                  style={{
                                    width: '3rem',
                                    borderRadius: '0.5rem',
                                  }}
                                />
                              </td>
                              <td>{item.user.name}</td>
                              <td
                                style={{
                                  color: getChangePercentageColor(
                                    item.dailyPercentageReturn,
                                    tc()
                                  ),
                                }}
                              >
                                {formatChangeValue(item.dailyPercentageReturn)}
                              </td>
                              <td
                                style={{
                                  color: getChangePercentageColor(
                                    item.weeklyPercentageReturn,
                                    tc()
                                  ),
                                }}
                              >
                                {formatChangeValue(item.weeklyPercentageReturn)}
                              </td>
                              <td
                                style={{
                                  color: getChangePercentageColor(
                                    item.allTimePercentageReturn,
                                    tc()
                                  ),
                                }}
                              >
                                {formatChangeValue(
                                  item.allTimePercentageReturn
                                )}
                              </td>
                              <td>
                                {item.totalBuys + item.totalSells || '--'}
                              </td>
                              <td>{item.totalCopies || '--'}</td>
                              <td>
                                {formatModifiedTicker(
                                  item.largestPositionTicker
                                ) || '--'}
                              </td>
                              <td>
                                {formatModifiedTicker(item.bestTradeTicker) ||
                                  '--'}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    {isAllInvestorsLoading && allInvestorsPage > 1 && (
                      <LoadingSpinner />
                    )}
                    {allInvestorsData &&
                      allInvestorsList.length <
                        (allInvestorsData.totalResults || 0) && (
                        <button
                          className="CreatePostFeedButton"
                          style={{ marginTop: '2rem' }}
                          onClick={handleLoadMoreAllInvestors}
                        >
                          View more
                        </button>
                      )}
                  </>
                )}
              </div>
            )}
          </div>
          {lbSections.map((category) => {
            return (
              <CategoryLeaderboardSection
                key={category}
                categoryName={category}
                title={category}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}

export default Leaderboards
