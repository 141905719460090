import React, { useState } from "react";

const Pagination = ({ totalItems, itemsPerPage, onPageChange }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    onPageChange(page);
  };
  const renderPagination = () => {
    const pagination = [];

    // Show the first page
    if (currentPage > 4) {
      pagination.push(
        <li className="pre" key={1} onClick={() => handlePageChange(1)}>
          1
        </li>
      );
      pagination.push(
        <span style={{ fontSize: 12 }} key="start-dots">
          ...
        </span>
      );
    }

    // Show pages around the current page
    for (
      let i = Math.max(1, currentPage - 2);
      i <= Math.min(totalPages, currentPage + 2);
      i++
    ) {
      pagination.push(
        <li
          className="pre"
          key={i}
          onClick={() => handlePageChange(i)}
          style={{ fontWeight: currentPage === i ? "bold" : "normal" }}
        >
          {i}
        </li>
      );
    }

    // Show the last page
    if (currentPage < totalPages - 3) {
      pagination.push(
        <span style={{ fontSize: 12 }} key="end-dots">
          ...
        </span>
      );
      pagination.push(
        <li
          className="pre"
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
        >
          {totalPages}
        </li>
      );
    }

    return pagination;
  };
  return (
    <div className="paginations">
      <ul style={{ cursor: "pointer" }}>{renderPagination()}</ul>
    </div>
  );
};

export default Pagination;
