import React, { useEffect } from 'react'
import '../styles/Toast.css' // Import the regular CSS file

const Toast = ({ message, type = 'success', onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose()
    }, 3000)

    return () => clearTimeout(timer)
  }, [onClose])

  return (
    <div className={`toastMessage ${type}`}>
      <h3>{message}</h3>
    </div>
  )
}

export default Toast
