import React, { useEffect, useState, useRef, useContext } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import '../styles/Stock.css'
import Select, { components as selectComponents } from 'react-select'
import { createStockChart } from '../helper/StockChartHelper'
import Toast from '../helper/Toast'
import {
  getUnixTimestamp,
  formatNumber,
  getUnixTimestampRange,
  getESTDate,
  intervalMap,
  filters,
  getThreeMonthsAgo,
  getMarketStatus,
  filterHighMap,
  isOldCompany,
  getLastValidTradingDay,
  timespanToMilliseconds,
  handleBuyAsset,
  handleSellAsset,
  fetchUserPortfolio,
  fetchAllTransactions,
  fetchDailyOpenClose,
  getDailyChange,
  fetchCompanyDetails,
  fetchStockLists,
  fetchDividendYield,
  fetchEPSAndPERatio,
  renderAfterMarketChange,
  getStartOfTradingWeek,
  calculateReturns,
  getChangePercentageColor,
  handleAddToWatchlist,
  handleRemoveFromWatchlist,
  handleAddToList,
  handleRemoveFromList,
  getWatchList,
  getTickerNews,
  getStartOfTradingDay,
  calculatePercentageChange,
  fetchAllPortfolios,
  handleLimitBuyAsset,
  handleLimitSellAsset,
} from '../helper/MarketsHelper'
import { AuthContext } from '../context/AuthContext'
import config from '../config'
import {
  LoadingSpinner,
  formatCurrency as fc,
  removeCommas as rc,
  customStylesPlus,
  formatModifiedTicker,
  formatWithCommas2 as fc2,
  parseFloatRemovingCommas as pf,
  CustomDateInput,
  convertToReadableDate,
} from '../helper/StylesHelper'
import { ThemeContext } from '../context/ThemeContext'
import MarketSearchBar from './MarketSearchBar'
import BackButton from './BackButton'
import { DateTime } from 'luxon'
import useResponsiveWidth from '../hooks/useResponsiveWidth'
import { shareTrade } from '../helper/CreatePostHelper'
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker from 'react-datepicker'

// -- Material UI for expiration date dropdown:
import {
  Select as MuiSelect,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material'
import VerifiedIcon from './VerifiedIcon'

export default function Stock() {
  useResponsiveWidth()

  // ------------------- HOOKS & STATE ---------------------
  const { ticker } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { tc, theme } = useContext(ThemeContext)
  const { isAuthenticated, userToken } = useContext(AuthContext)

  const chartRef = useRef(null)
  const chartInstance = useRef(null)
  const wsRef = useRef(null)
  const instantPriceTimeoutRef = useRef(null)
  const cachedBuyModal = useRef(false)
  const cachedSellModal = useRef(false)

  // Basic data
  const [stockData, setStockData] = useState([])
  const [dailyData, setDailyData] = useState({})
  const [currentPrice, setCurrentPrice] = useState(null)

  // Price change states
  const [changeAmount, setChangeAmount] = useState(null)
  const [changePercent, setChangePercent] = useState(null)
  const [livePrice, setLivePrice] = useState(null)
  const [liveChangeAmount, setLiveChangeAmount] = useState(null)
  const [liveChangePercent, setLiveChangePercent] = useState(null)
  const [referencePriceForChart, setReferencePriceForChart] = useState(null)

  // Chart hover states
  const [hoveredPrice, setHoveredPrice] = useState(null)
  const [hoveredChangeAmount, setHoveredChangeAmount] = useState(null)
  const [hoveredChangePercent, setHoveredChangePercent] = useState(null)
  const [hoveredDateTime, setHoveredDateTime] = useState(null)
  const [isHovering, setIsHovering] = useState(false)

  // Filters & main data checks
  const [filter, setFilter] = useState('1d')
  const [dailyChangeData, setDailyChangeData] = useState(null)
  const [mainStockDataFetched, setMainStockDataFetched] = useState(false)

  // Company / stock info
  const [stockName, setStockName] = useState(ticker)
  const [companyDetails, setCompanyDetails] = useState({})
  const [companyDescription, setCompanyDescription] = useState('')
  const [eps, setEps] = useState(null)
  const [peRatio, setPERatio] = useState(null)
  const [dividendYield, setDividendYield] = useState(null)
  const [dateIPO, setDateIPO] = useState(null)
  const [periodHigh, setPeriodHigh] = useState(null)
  const [periodLow, setPeriodLow] = useState(null)

  // Market status
  const [marketStatus, setMarketStatus] = useState(null)
  const [lastValidTradingDay, setLastValidTradingDay] = useState(null)
  const [marketError, setMarketError] = useState('')

  // Buying / selling modals
  const [openBuyModal, setOpenBuyModal] = useState(false)
  const [openSellModal, setOpenSellModal] = useState(false)
  const [isConfirming, setIsConfirming] = useState(false)
  const [isCongrats, setIsCongrats] = useState(false)
  const [transactionId, setTransactionId] = useState(null)
  const [quantity, setQuantity] = useState(null)
  const [estimatedCost, setEstimatedCost] = useState(null)

  // “Add Cash” states
  const [openAddCashBox, setOpenAddCashBox] = useState(false)
  const [addCashAmount, setAddCashAmount] = useState('')
  const [isAddCashConfirming, setIsAddCashConfirming] = useState(false)
  const [isAddCashCongrats, setIsAddCashCongrats] = useState(false)

  // Portfolio, user, watchlist, etc.
  const [allPortfolios, setAllPortfolios] = useState([])
  const [selectedPortfolio, setSelectedPortfolio] = useState(null)
  const [userPortfolio, setUserPortfolio] = useState(null)
  const [userTickerPosition, setUserTickerPosition] = useState(null)
  const [allTransactions, setAllTransactions] = useState([])
  const [returnData, setReturnData] = useState(null)
  const [toast, setToast] = useState({ message: '', type: '' })

  // Lists
  const [listOptions, setListOptions] = useState([])
  const [selectedLists, setSelectedLists] = useState([])

  // Options-trading modal
  const [openTradeOptionsModal, setOpenTradeOptionsModal] = useState(false)
  const [tradeToggle, setTradeToggle] = useState('buy')
  const [optionType, setOptionType] = useState('call')
  const [optionsData, setOptionsData] = useState([])
  const [loadingOptions, setLoadingOptions] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)

  // Option expiration-date states
  const [availableDates, setAvailableDates] = useState([])
  const [expirationDate, setExpirationDate] = useState(null)

  // Instead of a datepicker, we maintain current "month/year" in state
  const [calendarMonth, setCalendarMonth] = useState(new Date().getMonth() + 1)
  const [calendarYear, setCalendarYear] = useState(new Date().getFullYear())

  // News
  const [stockNews, setStockNews] = useState([])

  // B/A
  const [bidAsk, setBidAsk] = useState({ bid: null, ask: null })

  // Numeric inputs for Buy/Sell
  const [sharesInput, setSharesInput] = useState('')
  const [totalInput, setTotalInput] = useState('')

  // Watchlist
  const [isInWatchlist, setIsInWatchlist] = useState(false)

  const [limitPriceInput, setLimitPriceInput] = useState('')
  const [limitPrice, setLimitPrice] = useState(null)
  const [selectedLimitOrderExpiration, setSelectedLimitOrderExpiration] = useState(null);
  const [orderType, setOrderType] = useState('market') // "market" or "limit"

  const [limitOrderData, setLimitOrderData] = useState(null);


  // ------------------- EFFECTS ---------------------

  // 1) Scroll to top on mount
  useEffect(() => {
    document.getElementById('stock-container-wrapper')?.scrollIntoView()
  }, [])

  // 2) Watchlist check
  useEffect(() => {
    if (isAuthenticated) {
      getWatchList(ticker, userToken, setIsInWatchlist)
    }
  }, [isAuthenticated, userToken, ticker])

  // 3) News
  useEffect(() => {
    getTickerNews(ticker).then((data) => {
      setStockNews(data)
    })
  }, [ticker])

  // 4) Market status
  useEffect(() => {
    getMarketStatus().then((data) => {
      setMarketStatus(data)
    })
  }, [])

  // 5) Load last valid trading day
  useEffect(() => {
    if (marketStatus) {
      getLastValidTradingDay(marketStatus)
        .then((tradingDay) => {
          setLastValidTradingDay(tradingDay)
        })
        .catch((error) => {
          console.error('Error fetching the last valid trading day:', error)
        })
    }
  }, [marketStatus])

  // 6) Daily data
  useEffect(() => {
    if (lastValidTradingDay) {
      fetchDailyOpenClose(lastValidTradingDay, ticker, setDailyData)
    }
  }, [lastValidTradingDay, ticker])

  // 7) Grab daily change from prior close
  useEffect(() => {
    getDailyChange(ticker, setCurrentPrice, setDailyChangeData)
  }, [ticker])

  // 8) Load company details
  useEffect(() => {
    fetchCompanyDetails(ticker, setCompanyStates, () => {})
  }, [ticker])

  // Helper for #8
  function setCompanyStates(companyInfo) {
    setDateIPO(companyInfo.listDate)
    setStockName(companyInfo.name || ticker)
    setCompanyDetails({ marketCap: companyInfo.marketCap })
    if (
      companyInfo.description &&
      companyInfo.description !== 'No description available.'
    ) {
      setCompanyDescription(companyInfo.description)
    }
  }

  // 9) Once dailyChangeData is set, we can fetch the main data
  useEffect(() => {
    if (!dailyChangeData || !filter) return
    fetchStockData()
    // eslint-disable-next-line
  }, [dailyChangeData, filter])

  // 10) After main stock data is fetched, get Dividend & PE
  useEffect(() => {
    if (!mainStockDataFetched) return
    fetchDividendYield(ticker, currentPrice, setDividendYield)
    fetchEPSAndPERatio(ticker, setEps, setPERatio)
  }, [ticker, mainStockDataFetched, currentPrice])

  // 11) Load user portfolios if authenticated
  useEffect(() => {
    if (isAuthenticated) {
      fetchAllPortfolios(
        isAuthenticated,
        userToken,
        setAllPortfolios,
        setSelectedPortfolio
      )
    }
  }, [isAuthenticated, userToken])

  // 12) Once we have a selected portfolio, fetch user portfolio
  useEffect(() => {
    if (userToken && selectedPortfolio && selectedPortfolio._id) {
      fetchUserPortfolio(
        isAuthenticated,
        userToken,
        setUserPortfolio,
        selectedPortfolio._id
      )
      fetchAllTransactions(
        isAuthenticated,
        userToken,
        ticker,
        setAllTransactions,
        selectedPortfolio._id
      )
    }
    //eslint-disable-next-line
  }, [userToken, ticker, isAuthenticated, selectedPortfolio])

  // 13) Derive userTickerPosition from portfolio
  useEffect(() => {
    if (userPortfolio && ticker) {
      setUserTickerPosition(
        userPortfolio?.assets.find(
          (stock) => stock?.ticker?.toUpperCase() === ticker?.toUpperCase()
        )
      )
    }
  }, [userPortfolio, ticker])

  // 14) Once we have livePrice & userTickerPosition & allTx, we can compute returns
  useEffect(() => {
    if ((livePrice || currentPrice) && userTickerPosition && allTransactions) {
      let returns = calculateReturns(
        userTickerPosition,
        rc(livePrice || currentPrice),
        allTransactions
      )
      setReturnData(returns)
    }
  }, [livePrice, currentPrice, userTickerPosition, allTransactions])

  // 15) For loading option dates whenever month or year changes
  useEffect(() => {
    loadAvailableDatesOfTheMonth(calendarMonth, calendarYear)

    // eslint-disable-next-line
  }, [calendarMonth, calendarYear, ticker])

  // 16) Default to earliest expiration date
  useEffect(() => {
    if (availableDates.length > 0 && !expirationDate) {
      setExpirationDate(availableDates[0])
    }
  }, [availableDates, expirationDate])

  // 17) Chart creation
  useEffect(() => {
    if (!chartRef.current) return
    const cleanup = createStockChart({
      chartRef,
      chartInstanceRef: chartInstance,
      stockData,
      referencePriceForChart,
      changeAmount,
      setHoveredPrice,
      setHoveredChangeAmount,
      setHoveredChangePercent,
      setHoveredDateTime,
      setIsHovering,
      theme,
    })
    return cleanup
  }, [
    stockData,
    referencePriceForChart,
    changeAmount,
    chartRef,
    theme,
    openBuyModal,
    openSellModal,
    openTradeOptionsModal,
    openAddCashBox,
  ])

  // 18) WebSocket for real-time
  useEffect(() => {
    if (!referencePriceForChart) return

    const ws = new WebSocket(config.socketUrl)
    ws.onopen = () => {
      ws.send(JSON.stringify({ action: 'subscribe', ticker }))
    }
    ws.onmessage = (event) => {
      let message
      try {
        message = JSON.parse(event.data)
      } catch (e) {
        console.error('Error parsing WebSocket message:', e)
        return
      }

      if (message.type === 'instantPrice') {
        // instantPrice
        const data = message.data[0]
        if (data && data.ev === 'T' && data.sym === ticker.toUpperCase()) {
          updateChanges(data.p)
        }

        const dataBidAsk = message.data[1]
        if (
          dataBidAsk &&
          dataBidAsk.ev === 'Q' &&
          dataBidAsk.sym === ticker.toUpperCase()
        ) {
          setBidAsk({ bid: dataBidAsk.bp, ask: dataBidAsk.ap })
        }
      } else {
        // streaming trades, etc.
        const data = message[0]
        if (data && data.ev === 'T' && data.sym === ticker.toUpperCase()) {
          const tradePrice = data.p
          const tradeTime = data.t
          if (!chartInstance.current) return
          if (!tradePrice || !tradeTime) return
          const newPoint = { t: tradeTime, c: parseFloat(tradePrice) }
          // keep chart up to date
          const maxDataPoints = 287
          if (
            chartInstance.current.data.labels.length >= maxDataPoints &&
            filter === 'live'
          ) {
            chartInstance.current.data.labels.shift()
            chartInstance.current.data.datasets[0].data.shift()
          }
          chartInstance.current.data.labels.push(
            new Intl.DateTimeFormat('en-US', {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            }).format(new Date(newPoint.t))
          )
          chartInstance.current.data.datasets[0].data.push(newPoint.c)
          const change = newPoint.c - referencePriceForChart
          const borderColor = getChangePercentageColor(change, tc())
          chartInstance.current.data.datasets[0].borderColor = borderColor
          chartInstance.current.data.datasets[0].pointBackgroundColor =
            borderColor
          chartInstance.current.update({ duration: 100, lazy: true })
        }
      }
    }
    ws.onclose = () => {}
    ws.onerror = (error) => {
      console.error('WebSocket error:', error)
    }
    wsRef.current = ws

    return () => {
      if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
        wsRef.current.send(JSON.stringify({ action: 'unsubscribe', ticker }))
        wsRef.current.close()
      }
    }
  }, [ticker, filter, dailyChangeData, referencePriceForChart, tc])

  // Keep sending intervals
  useEffect(() => {
    const interval = setInterval(() => {
      if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
        const [multiplier, timespan] = intervalMap[filter]
        const intervalMs = timespanToMilliseconds(multiplier, timespan)
        wsRef.current.send(
          JSON.stringify({
            action: 'setInterval',
            ticker,
            intervalMs,
          })
        )
        clearInterval(interval)
      }
    }, 500)
    return () => clearInterval(interval)
  }, [filter, ticker])

  // -------------- MAIN FUNCTIONS ---------------------

  async function fetchStockData() {
    setChangeAmount(null)
    setPeriodHigh(null)
    setPeriodLow(null)
    setMainStockDataFetched(false)
    setLiveChangeAmount(null)
    setLiveChangePercent(null)
    setLivePrice(null)

    try {
      let fromDateUnixMs
      let toDateUnixMs = getUnixTimestamp()
      let referencePrice
      const [multiplier, timespan] = intervalMap[filter]

      if (filter === 'all time') {
        fromDateUnixMs = dateIPO || getESTDate()
        const earliestAvailableDateStr = '2003-08-01'
        if (new Date(fromDateUnixMs) < new Date(earliestAvailableDateStr)) {
          fromDateUnixMs = earliestAvailableDateStr
        }
      } else if (filter === '3m') {
        fromDateUnixMs = getThreeMonthsAgo()
      } else if (filter === '1d') {
        const dateInET = getStartOfTradingDay()
        fromDateUnixMs = dateInET.toMillis()
        toDateUnixMs = fromDateUnixMs + 57600000
        referencePrice = rc(dailyChangeData?.prevClosePrice)
      } else if (filter === '1w') {
        const dateInET = getStartOfTradingWeek()
        fromDateUnixMs = dateInET.toMillis()
        toDateUnixMs = fromDateUnixMs + 403200000
      } else {
        // e.g. 1m or 6m or 1y
        fromDateUnixMs = getUnixTimestampRange(filter)
      }

      if (filter === 'all time' || filter === '3m') {
        toDateUnixMs = getESTDate()
      }

      const response = await axios.get(
        `${config.backendUrl}/api/markets/stock/datapoints`,
        {
          params: {
            ticker,
            fromDateUnixMs,
            toDateUnixMs,
            multiplier,
            timespan,
          },
        }
      )
      const data = response.data || []
      setStockData(data)

      if (data.length > 0) {
        const latestPrice = rc(
          livePrice || currentPrice || data[data.length - 1].c
        )
        if (referencePrice) {
          // For 1d
          const change = latestPrice - referencePrice
          const changePercentage = (change / referencePrice) * 100
          if (filter === '1d') {
            let priceChange =
              (dailyChangeData?.todaysClosePrice
                ? rc(dailyChangeData.todaysClosePrice)
                : latestPrice) - rc(dailyChangeData?.prevClosePrice)
            setChangeAmount(priceChange)
            setChangePercent(dailyChangeData.todaysPercentChange)
          } else {
            setChangeAmount(change)
            setChangePercent(changePercentage)
          }
        } else {
          // e.g. 1y, 6m
          const startPrice = rc(data[0].c)
          const change = latestPrice - startPrice
          const changePercentage = (change / startPrice) * 100
          setChangeAmount(change)
          setChangePercent(changePercentage)
        }

        const highPrices = data.map((day) => day.h)
        const highestPrice = Math.max(...highPrices)
        const lowPrices = data.map((day) => day.l)
        setPeriodHigh(highestPrice)
        setPeriodLow(Math.min(...lowPrices))

        setReferencePriceForChart(referencePrice || data[0].c)
      } else {
        setChangeAmount(null)
        setChangePercent(null)
        setPeriodHigh(null)
        setPeriodLow(null)
      }
    } catch (error) {
      console.error('Error fetching stock data:', error)
    }
    setMainStockDataFetched(true)
  }

  // Update chart data in real-time
  function updateChanges(newPrice) {
    if (!referencePriceForChart) return
    setLivePrice(newPrice)
    const change = newPrice - referencePriceForChart
    const changePercentage = (change / referencePriceForChart) * 100
    if (filter === '1d') {
      if (dailyChangeData?.todaysClosePrice) {
        const priceChange =
          rc(dailyChangeData.todaysClosePrice) -
          rc(dailyChangeData?.prevClosePrice)
        setLiveChangeAmount(priceChange)
        setLiveChangePercent(dailyChangeData.todaysPercentChange)
      } else {
        const priceChange = newPrice - rc(dailyChangeData?.prevClosePrice)
        setLiveChangeAmount(priceChange)
        setLiveChangePercent(changePercentage)
      }
    } else {
      setLiveChangeAmount(change)
      setLiveChangePercent(changePercentage)
    }
  }

  // -------------- BUY / SELL HANDLERS ---------------------
  function handleBuyClick() {
    if (!isAuthenticated) {
      alert('Please sign in to access this feature')
      return
    }
    // just open the modal
    setOpenBuyModal(true)
    cachedBuyModal.current = true
    cachedSellModal.current = false
  }
  function handleSellClick() {
    if (!isAuthenticated) {
      alert('Please sign in to access this feature')
      return
    }
    setOpenSellModal(true)
    cachedSellModal.current = true
    cachedBuyModal.current = false
  }

  async function handleConfirmClick() {
    try {
      // Check if the market is open
      const marketStatusCheck = await getMarketStatus()

      if (
        marketStatusCheck.status === 'closed' &&
        !marketStatusCheck.isAfterHours &&
        !marketStatusCheck.isPreMarket
      ) {
        setMarketError(
          'The market is currently closed. Please try again later.'
        )
        return // Exit early if the market is closed
      }

      if (isConfirming) {
        setIsCongrats(false)
        const el = document.getElementById('share-input')
        if (el) el.focus()
      }

      setIsConfirming((prev) => !prev)
    } catch (error) {
      console.error('Error checking market status:', error)
      setMarketError(
        'An error occurred while checking market status. Please try again later.'
      )
    }
  }

  // “Buy or Sell” confirmation callback
  async function buyOrSellAssetCallback() {
    setIsCongrats(true)
    // re-fetch the user portfolio & transactions
    if (selectedPortfolio && selectedPortfolio._id) {
      await fetchUserPortfolio(
        isAuthenticated,
        userToken,
        setUserPortfolio,
        selectedPortfolio._id
      )
      await fetchAllTransactions(
        isAuthenticated,
        userToken,
        ticker,
        setAllTransactions,
        selectedPortfolio._id
      )
    }
  }
  

  // *** LIMIT ORDER: placeholder confirm
  function handleLimitOrderConfirm() {
    if (isConfirming) {
      setIsCongrats(false);
      const el = document.getElementById('limit-share-input');
      if (el) el.focus();
    }
    // toggle confirm
    setIsConfirming((prev) => !prev);
  }

  async function handleLimitOrderExecute() {
    const isBuy = openBuyModal ? true : false;
    let response;
    try {
      if (isBuy) {
        response = await handleLimitBuyAsset(
          isAuthenticated,
          userToken,
          quantity,
          ticker,
          selectedPortfolio?._id,
          limitPrice,
          selectedLimitOrderExpiration,
          buyOrSellAssetCallback,
          location.state
        );
      } else {
        response = await handleLimitSellAsset(
          isAuthenticated,
          userToken,
          quantity,
          ticker,
          selectedPortfolio?._id,
          limitPrice,
          selectedLimitOrderExpiration,
          buyOrSellAssetCallback,
          location.state
        );
      }

      if (response && response.data) {
        setLimitOrderData(response?.data?.limitOrder);
      }


    } catch (err) {
      console.error('Error executing limit order:', err);
      alert('Error executing limit order. Please try again.');
    }
  }

  // -------------- OPTIONS --------------
  function handleTradeOptionsClick() {
    setOpenTradeOptionsModal(true)
    if (expirationDate && optionType) {
      loadOptionsData(expirationDate, optionType)
    }
  }
  function handleOptionTypeChange(selectedOption) {
    setOptionType(selectedOption)
    if (expirationDate) {
      loadOptionsData(expirationDate, selectedOption)
    }
  }
  function handleTradeOptionTypeChange(selectedOption) {
    setTradeToggle(selectedOption)
  }

  // Next-month button
  function handleNextMonth() {
    let newMonth = calendarMonth + 1
    let newYear = calendarYear
    if (newMonth > 12) {
      newMonth = 1
      newYear += 1
    }
    setCalendarMonth(newMonth)
    setCalendarYear(newYear)
  }

  function handlePrevMonth() {
    const currentDate = new Date()
    const currentMonth = currentDate.getMonth() + 1
    const currentYear = currentDate.getFullYear()
  
    let newMonth = calendarMonth - 1
    let newYear = calendarYear
  
    if (newMonth < 1) {
      newMonth = 12
      newYear -= 1
      newMonth = 12
      newYear -= 1
    }

    // Check if the new month/year is before the current month/year
    if (newYear < currentYear || (newYear === currentYear && newMonth < currentMonth)) {
      return // Prevent the change
    }
  
    setCalendarMonth(newMonth)
    setCalendarYear(newYear)
  }

  // Load the “available” expiration dates for a given month/year
  async function loadAvailableDatesOfTheMonth(month, year) {
    try {
      const response = await axios.get(
        `${config.backendUrl}/api/markets/stock/monthly-options/${ticker}`,
        { params: { month, year } }
      )
      const allOptionsData = response.data.availableOptionsByDate || []
      const newDates = allOptionsData
        .map((option) => {
          const dateTimeInET = DateTime.fromISO(
            `${option.expirationDate}T16:00:00`,
            { zone: 'America/New_York' }
          )
          if (!dateTimeInET.isValid) return null
          return dateTimeInET.toJSDate()
        })
        .filter((date) => date !== null)

      // remove duplicates
      const uniqueDates = Array.from(
        new Set(newDates.map((d) => d.toDateString()))
      ).map((dateString) => new Date(dateString))
      setAvailableDates(uniqueDates)
      if (uniqueDates.length) {
        // Always pick the first date in the array
        setExpirationDate(uniqueDates[0])
        setExpirationDate(uniqueDates[0])
        // Make sure we call loadOptionsData right away
        loadOptionsData(uniqueDates[0], optionType)
        loadOptionsData(uniqueDates[0], optionType)
      } else {
        setAvailableDates([])
        setAvailableDates([])
      }
    } catch (err) {
      console.error(err)
      setAvailableDates([])
    }
  }

  // For the “expiration date” MUI Select
  function handleExpirationDateChange(newDate) {
    console.log('New date selected:', newDate)
    setExpirationDate(newDate)
    loadOptionsData(newDate, optionType)
  }

  // Actually load the chain
  async function loadOptionsData(expiration, type) {
    try {
      setLoadingOptions(true)
      const formattedExpiration = DateTime.fromJSDate(expiration, {
        zone: 'America/New_York',
      }).toFormat('yyyy-MM-dd')

      const response = await axios.get(
        `${config.backendUrl}/api/markets/stock/options/${ticker}`,
        { params: { expiration: formattedExpiration, type } }
      )
      setOptionsData(response.data)
    } catch (error) {
      console.error('Error fetching options data:', error)
      setOptionsData([])
    } finally {
      setLoadingOptions(false)
    }
  }

  // e.g. “January 24 (3d)”
  function getExpirationLabel(date) {
    const now = new Date()
    const diffTime = date.getTime() - now.getTime()
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    const options = { month: 'long', day: 'numeric' }
    const dateString = date.toLocaleDateString(undefined, options)
    return `${dateString} (${diffDays}d)`
  }

  // For the “scroll to current price” line
  const [lineWidth, setLineWidth] = useState(0)
  const currentPriceRef = useRef(null)

  useEffect(() => {
    if (optionsData.length > 0 && currentPriceRef.current) {
      currentPriceRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }, [optionsData])

  useEffect(() => {
    if (optionsData.length > 0 && currentPriceRef.current) {
      const currentPriceTd = currentPriceRef.current
      const currentPriceWrapper = currentPriceTd.querySelector(
        '.current-price-wrapper'
      )
      if (currentPriceWrapper) {
        const tdWidth = currentPriceTd.offsetWidth
        const wrapperWidth = currentPriceWrapper.offsetWidth
        const calculatedWidth = (tdWidth - wrapperWidth) / 2
        setLineWidth(calculatedWidth)
      }
    }
  }, [optionsData])

  // -------------- CLOSE MODAL ---------------------
  function handleCloseModal() {
    setOpenBuyModal(false)
    setOpenSellModal(false)
    setOpenTradeOptionsModal(false)
    setIsConfirming(false)
    setIsCongrats(false)
    setTransactionId(null)
    setOpenAddCashBox(false)
    setIsAddCashConfirming(false)
    setIsAddCashCongrats(false)
    setAddCashAmount('')

    // clear any error text
    setMarketError('')
  }

  // -------------- RENDER HELPERS ---------------------

  function renderPortfolioDropdown() {
    if (!isAuthenticated) return null
    const portfolioOptions = allPortfolios.map((pf) => ({
      value: pf._id,
      label: pf.name,
    }))
    const selectedPortfolioOption = selectedPortfolio
      ? { value: selectedPortfolio._id, label: selectedPortfolio.name }
      : null

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
          margin: '1.5rem 0',
        }}
      >
        <Select
          unstyled
          isSearchable={false}
          options={portfolioOptions}
          value={selectedPortfolioOption}
          onChange={(option) => {
            const newPF = allPortfolios.find((pf) => pf._id === option.value)
            setSelectedPortfolio(newPF)
          }}
          placeholder={
            <>
              Select portfolio{' '}
              <svg
                height="18"
                width="18"
                viewBox="0 0 20 20"
                aria-hidden="true"
                focusable="false"
                className="css-tj5bde-Svg"
              >
                <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
              </svg>
            </>
          }
          styles={customStylesPlus(tc())}
        />
      </div>
    )
  }

  const handleSharesChange = (e) => {
    setIsConfirming(false)
    setIsCongrats(false)

    // Get the raw input value
    const rawInput = e.target.value

    // Format the input with commas
    const formattedInput = fc2(rawInput)
    setSharesInput(formattedInput)

    // Remove commas for calculations
    const parsed = parseFloat(rawInput.replace(/,/g, ''))
    const price = rc(livePrice || currentPrice)

    if (isNaN(parsed) || parsed < 0) {
      setQuantity(null)
      setEstimatedCost(null)
      setTotalInput('')
      return
    }

    // Update quantity and estimated cost
    setQuantity(parsed)
    const newTotal = price * parsed
    setEstimatedCost(newTotal)

    // Format the total with commas
    setTotalInput(fc2(newTotal.toFixed(2)))
  }

  const handleTotalChange = (e) => {
    setIsConfirming(false)
    setIsCongrats(false)

    // Get the raw input value
    const rawInput = e.target.value

    // Format the input with commas
    const formattedInput = fc2(rawInput)
    setTotalInput(formattedInput)

    // Remove commas for calculations
    const parsed = parseFloat(rawInput.replace(/,/g, ''))
    const price = rc(livePrice || currentPrice)

    if (isNaN(parsed) || parsed < 0) {
      setEstimatedCost(null)
      setQuantity(null)
      setSharesInput('')
      return
    }

    // Update estimated cost and quantity
    setEstimatedCost(parsed)
    const newShares = parsed / (price || 1)
    setQuantity(newShares)

    // Format the shares with commas
    setSharesInput(fc2(newShares.toFixed(2)))
  }

  function handleAddCashChange(e) {
    setIsAddCashConfirming(false)
    setIsAddCashCongrats(false)

    // raw input
    const rawInput = e.target.value

    // format with commas, preserving one decimal
    const formattedInput = fc2(rawInput)
    setAddCashAmount(formattedInput)
  }

  // ---- RENDERING THE ORDER TYPE SELECTOR AND FLOWS ----

  function renderBuyOrSellModal() {
    // If user has chosen "market" or "limit"
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <div style={{ display: 'flex', gap: '1rem', marginBottom: '1rem' }}>
          <button
            className={`CreatePostFeedButton ${orderType === 'market' ? 'greenButton' : ''}`}
            onClick={() => {cancelAllTransactionStates(); setOrderType('market')}}
          >
            Market order
          </button>
          <button
            className={`CreatePostFeedButton ${orderType === 'limit' ? 'greenButton' : ''}`}
            onClick={() => {cancelAllTransactionStates(); setOrderType('limit')}}
          >
            Limit order
          </button>
        </div>

        {orderType === 'market' ? renderMarketOrderFlow() : renderLimitOrderFlow()}
      </div>
    )
  }

  // --- MARKET ORDER FLOW (existing) ---
  function renderMarketOrderFlow() {
    const isBuy = openBuyModal
    const availableText = isBuy
      ? `Cash available: $${fc(userPortfolio?.userCash)}`
      : `Shares available: ${fc(userTickerPosition?.quantity) || 0}`

    let buttonLabel = isBuy ? 'Buy' : 'Sell'
    const originalButtonLabel = buttonLabel
    if (isConfirming) buttonLabel = 'Edit'

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '2rem',
          alignItems: 'center',
        }}
      >
        <div className="stock-purchase-modal">
          <div className="stock-transaction-content">
            <h2 className="StockName bold">{originalButtonLabel}</h2>
            {renderPortfolioDropdown()}
            <p className="stock-cash-available">{availableText}</p>

            <div className="stock-input-section">
              <label htmlFor="shares" className="StockName bold">
                Shares
              </label>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <input
                  type="text"
                  id="share-input"
                  className="StockName StockInput bold"
                  value={sharesInput}
                  onChange={handleSharesChange}
                  inputMode="decimal"
                  placeholder="0.00"
                  autoComplete="off"
                />
              </div>
            </div>

            <div
              className="stock-input-section"
              style={{ borderBottom: 'none' }}
            >
              <label className="StockName bold">Total</label>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <input
                  type="text"
                  className="StockName StockInput bold"
                  value={totalInput}
                  onChange={handleTotalChange}
                  inputMode="decimal"
                  placeholder="0.00"
                  autoComplete="off"
                />
              </div>
            </div>
          </div>


          <div className="stock-transaction-buttons">
            {!openAddCashBox ? (
              <button
                className="CreatePostFeedButton"
                onClick={() => {
                  // Hide the buy/sell first
                  cachedBuyModal.current = openBuyModal
                  cachedSellModal.current = openSellModal
                  setOpenBuyModal(false)
                  setOpenSellModal(false)

                  // Reset some states
                  setIsAddCashConfirming(false)
                  setIsAddCashCongrats(false)
                  setAddCashAmount('')
                  setOpenAddCashBox(true)
                }}
              >
                Add Cash
              </button>
            ) : (
              <button
                className="CreatePostFeedButton"
                onClick={() => {
                  setOpenAddCashBox(false)
                  setIsAddCashConfirming(false)
                  setIsAddCashCongrats(false)
                  setAddCashAmount('')
                }}
              >
                Cancel
              </button>
            )}

            <button
              onClick={handleConfirmClick}
              style={{ width: '33%' }}
              className={`CreatePostFeedButton ${!isConfirming && 'greenButton'}`}
              disabled={!quantity || quantity <= 0}
            >
              {buttonLabel}
            </button>
          </div>
        </div>

        {isConfirming && (
          <>
            <div>
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{ fontSize: '5rem' }}
              />
            </div>
            {renderConfirmationModal()}
          </>
        )}

        {isCongrats && (
          <>
            <div>
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{ fontSize: '5rem', color: 'var(--stock-change-pos)' }}
              />
            </div>
            {renderCongratsModal()}
          </>
        )}
      </div>
    )
  }

  const cancelAllTransactionStates = () => {
    setIsConfirming(false)
    setIsCongrats(false)
    setTransactionId(null)
    setOpenAddCashBox(false)
    setIsAddCashConfirming(false)
    setIsAddCashCongrats(false)
    setAddCashAmount('')

    // clear any error text
    setMarketError('')
  }



  function renderConfirmationModal() {
    const isBuy = openBuyModal
    const totalCost = quantity * rc(livePrice || currentPrice)
    const remainingAmount = isBuy
      ? userPortfolio?.userCash - totalCost
      : (userTickerPosition?.quantity || 0) - quantity

    async function handleConfirm() {
      try {
        let response
        if (isBuy) {
          response = await handleBuyAsset(
            isAuthenticated,
            userToken,
            quantity,
            ticker,
            selectedPortfolio._id,
            buyOrSellAssetCallback,
            location.state,
            setToast
          )

          
        } else {
          response = await handleSellAsset(
            isAuthenticated,
            userToken,
            quantity,
            ticker,
            selectedPortfolio._id,
            buyOrSellAssetCallback,
            location.state,
            setToast
          )
        }
        const transaction = response?.data?.transaction
        if (transaction) setTransactionId(transaction._id)
      } catch (error) {
        setToast({
          message:
            error.response?.data?.message ||
            'Transaction failed. Please try again.',
          type: 'error',
        })
        setTransactionId(null)
      }
    }

    return (
      <div className="stock-purchase-modal">
        <div className="stock-transaction-content">
          <h2 className="StockName bold">Confirm</h2>
          <div className="StockConfirmWrapper">
            <span>
              <span className="bold">{quantity}</span> {ticker} shares
            </span>
            <span>
              <span className="bold">${fc(rc(livePrice || currentPrice))}</span>{' '}
              share price
            </span>
          </div>
          <div className="StockConfirmWrapper" style={{ border: 'none' }}>
            <span>
              <span className="bold">${fc(totalCost)}</span> total
            </span>
            <span>
              <span className="bold">
                {isBuy ? (
                  <>
                    ${fc(remainingAmount)}{' '}
                    <span className="medium"> cash remaining </span>
                  </>
                ) : (
                  <>
                    {fc(remainingAmount)}{' '}
                    <span className="medium"> shares remaining </span>
                  </>
                )}
              </span>
            </span>
          </div>
        </div>

        <button
          onClick={handleConfirm}
          style={{ width: '33%', alignSelf: 'flex-end', marginTop: '1rem' }}
          className={`CreatePostFeedButton ${!isCongrats && 'greenButton'}`}
          disabled={!quantity || parseFloat(quantity) <= 0 || isCongrats}
        >
          Confirm
        </button>
      </div>
    )
  }

  function renderCongratsModal() {
    return (
      <div
        className="stock-purchase-modal"
        style={{ borderColor: 'var(--stock-change-pos)' }}
      >
        <div className="stock-transaction-content">
          <h2
            style={{ color: 'var(--stock-change-pos)' }}
            className="StockName bold"
          >
            Congrats!
          </h2>
          <div className="StockConfirmWrapper" style={{ border: 'none' }}>
            <span>Do you want to share this trade to your feed?</span>
          </div>
        </div>
        <div className="stock-congrats-btns">
          <button
            onClick={() => handleCloseModal()}
            className="CreatePostFeedButton"
          >
            No
          </button>
          <button
            onClick={() => {
              shareTrade(transactionId, navigate)
            }}
            className="CreatePostFeedButton greenButton"
          >
            Yes
          </button>
        </div>
      </div>
    )
  }

  function handleLimitPriceChange(e) {
    setIsConfirming(false)
    setIsCongrats(false)
  
    // raw input
    const rawInput = e.target.value
  
    // format the input with commas
    const formattedInput = fc2(rawInput) // from your existing 'formatWithCommas2'
    setLimitPriceInput(formattedInput)
  
    // remove commas for calculations
    const parsed = parseFloat(rawInput.replace(/,/g, ''))
    if (isNaN(parsed) || parsed < 0) {
      setLimitPrice(null)
      return
    }
    setLimitPrice(parsed)
  }


  useEffect(()=> {
    console.log('selectedLimitOrderExpiration', selectedLimitOrderExpiration)
  }, [selectedLimitOrderExpiration])
  
  /** 
   * renderLimitOrderFlow now has a "Limit Price" input 
   */
  function renderLimitOrderFlow() {
    const isBuy = openBuyModal
    const availableText = isBuy
      ? `Cash available: $${fc(userPortfolio?.userCash)}`
      : `Shares available: ${fc(userTickerPosition?.quantity) || 0}`
  
    let buttonLabel = isBuy ? 'Buy' : 'Sell'
    const originalButtonLabel = buttonLabel
    if (isConfirming) buttonLabel = 'Edit'
  
    return (
      <div
        style={{ display: 'flex', flexDirection: 'row', gap: '2rem', alignItems: 'center' }}
      >
        <div className="stock-purchase-modal" style={{height: 'auto'}}>
          <div className="stock-transaction-content">
            <h2 className="StockName bold">{originalButtonLabel}</h2>
            {renderPortfolioDropdown()}
            <p className="stock-cash-available">{availableText}</p>
  
            {/* Shares Input */}
            <div className="stock-input-section">
              <label htmlFor="shares" className="StockName bold">
                Shares
              </label>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <input
                  type="text"
                  id="limit-share-input"
                  className="StockName StockInput bold"
                  value={sharesInput}
                  onChange={handleSharesChange}
                  inputMode="decimal"
                  placeholder="0.00"
                  autoComplete="off"
                />
              </div>
            </div>
  
            {/* Limit Price Input */}
            <div className="stock-input-section" >
              <label htmlFor="limit-price" className="StockName bold" style={{whiteSpace: 'nowrap'}}>
                Limit Price
              </label>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <input
                  type="text"
                  id="limit-price-input"
                  className="StockName StockInput bold"
                  value={limitPriceInput}
                  onChange={handleLimitPriceChange}
                  inputMode="decimal"
                  placeholder="0.00"
                  autoComplete="off"
                />
              </div>
            </div>
  
      {/* Limit Price Input */}
      <div className="stock-input-section" style={{ borderBottom: 'none' }}>
              <label htmlFor="limit-price" className="StockName bold" style={{whiteSpace: 'nowrap'}}>
                Expires
              </label>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                 <DatePicker
                       selected={selectedLimitOrderExpiration}
                       onSelect={(date) => {
                        cancelAllTransactionStates();
                         if (date && date.getTime() === selectedLimitOrderExpiration?.getTime()) {
                            
                           setSelectedLimitOrderExpiration(null); 
                         } else {
                          setSelectedLimitOrderExpiration(date); 
                         }
                       }}
                       filterDate={(date) => {
                        const day = date.getDay(); // 0 = Sunday, 6 = Saturday
                        return day !== 0 && day !== 6; // Exclude weekends
                      }}
                       minDate={new Date()}
                       customInput={<CustomDateInput theme={tc()} />}
                       dateFormat="MMMM d, yyyy"
                     />
              </div>
            </div>
  
          </div>
  
          <div className="stock-transaction-buttons">
            {!openAddCashBox ? (
              <button
                className="CreatePostFeedButton"
                onClick={() => {
                  // Hide the buy/sell first
                  cachedBuyModal.current = openBuyModal
                  cachedSellModal.current = openSellModal
                  setOpenBuyModal(false)
                  setOpenSellModal(false)
  
                  // Reset some states
                  setIsAddCashConfirming(false)
                  setIsAddCashCongrats(false)
                  setAddCashAmount('')
                  setOpenAddCashBox(true)
                }}
              >
                Add Cash
              </button>
            ) : (
              <button
                className="CreatePostFeedButton"
                onClick={() => {
                  setOpenAddCashBox(false)
                  setIsAddCashConfirming(false)
                  setIsAddCashCongrats(false)
                  setAddCashAmount('')
                }}
              >
                Cancel
              </button>
            )}
  
            <button
              onClick={handleLimitOrderConfirm}
              style={{ width: '33%' }}
              className={`CreatePostFeedButton ${!isConfirming && 'greenButton'}`}
              // Require quantity > 0 AND limitPrice > 0
              disabled={!quantity || quantity <= 0 || !limitPrice || limitPrice <= 0 || !selectedLimitOrderExpiration}
            >
              {buttonLabel}
            </button>
          </div>
        </div>
  
        {isConfirming && (
          <>
            <div>
              <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '5rem' }} />
            </div>
            {renderLimitConfirmation()}
          </>
        )}
  
        {isCongrats && limitOrderData && (
          <>
            <div>
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{ fontSize: '5rem', color: 'var(--stock-change-pos)' }}
              />
            </div>
            {renderLimitCongrats()}
          </>
        )}
      </div>
    )
  }
  
  /**
   * In renderLimitConfirmation, 
   * we now consider limitPrice in the total cost.
   */
  function renderLimitConfirmation() {
    const isBuy = openBuyModal
    // Instead of livePrice/currentPrice, we use limitPrice
    const totalCost = quantity * (limitPrice || 0)
    const remainingAmount = isBuy
      ? userPortfolio?.userCash - totalCost
      : (userTickerPosition?.quantity || 0) - quantity
  
    return (
      <div className="stock-purchase-modal">
        <div className="stock-transaction-content">
          <h2 className="StockName bold">Confirm</h2>
          <div className="StockConfirmWrapper">
            <span>
              <span className="bold">{quantity}</span> {ticker} shares
            </span>
            <span>
              Limit Price: <span className="bold">${fc(limitPrice)}</span>
            </span>
            <span>
            Expires: <span className="bold">{selectedLimitOrderExpiration?.toLocaleDateString()} on market close</span>
          </span>
          </div>
          <div className="StockConfirmWrapper" style={{ border: 'none' }}>
            <span>
              <span className="bold">${fc(totalCost)}</span> {`${isBuy ? 'maximum total' : 'minimum credit'}`}
            </span>
            <span>
              <span className="bold">
                {isBuy ? (
                  <>
                    ${fc(remainingAmount)} <span className="medium"> cash remaining </span>
                  </>
                ) : (
                  <>
                    {fc(remainingAmount)} <span className="medium"> shares remaining </span>
                  </>
                )}
              </span>
            </span>
          </div>
        </div>
  
        <button
          onClick={handleLimitOrderExecute}
          style={{ width: '33%', alignSelf: 'flex-end', marginTop: '1rem' }}
          className="CreatePostFeedButton greenButton"
        >
          Confirm
        </button>
      </div>
    )
  }
  function renderLimitCongrats() {
    return (
      <div
        className="stock-purchase-modal"
        style={{ borderColor: 'var(--stock-change-pos)' }}
      >
        <div className="stock-transaction-content">
          <h2 style={{ color: 'var(--stock-change-pos)' }} className="StockName bold">
            Congrats!
          </h2>
          <div className="StockConfirmWrapper" style={{ border: 'none' }}>
            <p className='PostContentP' style={{margin: 0}}> 
              Your limit order has been successfully placed. It will execute 
              {openBuyModal
                ? ` if the market price reaches or goes below your specified limit price of $${fc(limitPrice)} by ${convertToReadableDate(limitOrderData?.expiresAt)}. Make sure you have enough cash in your portfolio to cover this order.`
                : ` if the market price reaches or goes above your specified limit price of $${fc(limitPrice)} by ${convertToReadableDate(limitOrderData?.expiresAt)}. Make sure you have enough shares in your portfolio to cover this order.`}
            </p>
            <br />
          </div>
        </div>
        <div className="stock-congrats-btns">
          <button
            onClick={() => handleCloseModal()}
            className="CreatePostFeedButton"
          >
            Close
          </button>
          
        </div>
      </div>
    );
  }

  // -------------- “Add Cash” --------------
  function renderAddCashBox() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '2rem',
          alignItems: 'center',
        }}
      >
        <div className="stock-purchase-modal">
          <div className="stock-transaction-content">
            <h2 className="StockName bold">Add Cash</h2>
            {renderPortfolioDropdown()}
            <p className="stock-cash-available">
              Current Cash: ${fc(userPortfolio?.userCash)}
            </p>
            <div
              className="stock-input-section"
              style={{ borderBottom: 'none' }}
            >
              <label htmlFor="add-cash" className="StockName bold">
                Amount
              </label>
              <input
                type="text"
                id="add-cash-input"
                className="StockName StockInput bold"
                value={addCashAmount}
                onChange={handleAddCashChange}
                inputMode="decimal"
                placeholder="0.00"
              />
            </div>
          </div>

          <div className="stock-transaction-buttons">
            <button
              onClick={() => {
                const numericValue = parseFloat(addCashAmount.replace(/,/g, ''))
                if (numericValue > 0) {
                  setIsAddCashConfirming((prev) => !prev)
                  setIsAddCashCongrats(false)
                }
              }}
              style={{ width: '33%' }}
              className={`CreatePostFeedButton ${
                !isAddCashConfirming ? 'greenButton' : ''
              }`}
              disabled={
                !addCashAmount ||
                isNaN(parseFloat(addCashAmount.replace(/,/g, ''))) ||
                parseFloat(addCashAmount.replace(/,/g, '')) <= 0
              }
            >
              {isAddCashConfirming ? 'Edit' : 'Add'}
            </button>

            <button
              className="CreatePostFeedButton"
              onClick={() => {
                setOpenAddCashBox(false)
                setIsAddCashConfirming(false)
                setIsAddCashCongrats(false)
                setAddCashAmount('')
                // Depending on whether we were in market or limit flow
                if (cachedBuyModal.current) setOpenBuyModal(true)
                if (cachedSellModal.current) setOpenSellModal(true)
              }}
            >
              Cancel
            </button>
          </div>
        </div>

        {isAddCashConfirming && (
          <div>
            <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '5rem' }} />
          </div>
        )}

        {isAddCashConfirming && renderAddCashConfirmation()}

        {isAddCashCongrats && (
          <div>
            <FontAwesomeIcon
              icon={faArrowRight}
              style={{ fontSize: '5rem', color: 'var(--stock-change-pos)' }}
            />
          </div>
        )}

        {isAddCashCongrats && renderAddCashCongrats()}
      </div>
    )
  }

  function renderAddCashConfirmation() {
    async function handleConfirm() {
      try {
        const numericValue = parseFloat(addCashAmount.replace(/,/g, ''))
        if (!selectedPortfolio || !selectedPortfolio._id) {
          alert('No portfolio selected')
          return
        }
        const portfolioId = selectedPortfolio._id
        await axios.post(
          `${config.backendUrl}/api/stockActions/addCash`,
          { amount: numericValue, portfolioId },
          {
            headers: { Authorization: `Bearer ${userToken}` },
          }
        )
        fetchUserPortfolio(
          isAuthenticated,
          userToken,
          setUserPortfolio,
          selectedPortfolio._id
        )
        setIsAddCashCongrats(true)
      } catch (error) {
        console.error('Error adding cash:', error)
        alert(
          error.response?.data?.message ||
            'Adding cash failed. Please try again.'
        )
      }
    }

    return (
      <div className="stock-purchase-modal">
        <div className="stock-transaction-content">
          <h2 className="StockName bold">Confirm</h2>
          <div className="StockConfirmWrapper" style={{ border: 'none' }}>
            <span>
              You are adding{' '}
              <span className="bold">
                ${fc(parseFloat(addCashAmount.replace(/,/g, '')))}
              </span>{' '}
              to portfolio: <span className="bold">{selectedPortfolio?.name}</span>
            </span>
          </div>
        </div>
        <button
          onClick={handleConfirm}
          style={{ width: '33%', alignSelf: 'flex-end', marginTop: '1rem' }}
          className="CreatePostFeedButton greenButton"
        >
          Confirm
        </button>
      </div>
    )
  }

  function renderAddCashCongrats() {
    return (
      <div
        className="stock-purchase-modal"
        style={{ borderColor: 'var(--stock-change-pos)' }}
      >
        <div className="stock-transaction-content">
          <h2
            style={{ color: 'var(--stock-change-pos)' }}
            className="StockName bold"
          >
            Congrats!
          </h2>
          <div className="StockConfirmWrapper" style={{ border: 'none' }}>
            <span>You have successfully added cash to your portfolio.</span>
          </div>
        </div>
        <div className="stock-congrats-btns">
          <button
            onClick={() => {
              setOpenAddCashBox(false)
              setIsAddCashConfirming(false)
              setIsAddCashCongrats(false)
              setAddCashAmount('')
              if (cachedBuyModal.current) setOpenBuyModal(true)
              else if (cachedSellModal.current) setOpenSellModal(true)
            }}
            className="CreatePostFeedButton"
          >
            Done
          </button>
        </div>
      </div>
    )
  }

  // -------------- RENDERING THE OPTIONS MODAL --------------
  function renderOptionsModal() {
    return (
      <>
        <div className="options-filters">
          <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
            <div className="option-type-container">
              <div
                className="se-toggle-container"
                style={{ padding: 0, height: '3.5rem', border: 'var(--border)' }}
              >
                <div
                  className={`se-toggle-option stock-toggle bold ${
                    tradeToggle === 'buy' && 'active'
                  }`}
                  onClick={() => handleTradeOptionTypeChange('buy')}
                >
                  Buy
                </div>
                <div
                  className={`se-toggle-option stock-toggle bold ${
                    tradeToggle === 'sell' && 'active'
                  }`}
                  onClick={() => handleTradeOptionTypeChange('sell')}
                >
                  Sell
                </div>
              </div>
              <div
                className="se-toggle-container"
                style={{ padding: 0, height: '3.5rem', border: 'var(--border)' }}
              >
                <div
                  className={`se-toggle-option stock-toggle bold ${
                    optionType === 'call' && 'active'
                  }`}
                  onClick={() => handleOptionTypeChange('call')}
                >
                  Call
                </div>
                <div
                  className={`se-toggle-option stock-toggle bold ${
                    optionType === 'put' && 'active'
                  }`}
                  onClick={() => handleOptionTypeChange('put')}
                >
                  Put
                </div>
              </div>
            </div>

            <FormControl
              variant="outlined"
              size="small"
              sx={{
                height: '3.5rem',
                minWidth: '20rem',
                '& .MuiOutlinedInput-root': {
                  height: '3.5rem',
                  borderRadius: '2rem',
                  backgroundColor: 'var(--background-color)',
                  fontFamily: "'SF Pro Display Bold', sans-serif",
                  fontSize: 'var(--font-med-s)',
                  color: 'var(--text-color)',
                  '& fieldset': {
                    border: 'none',
                  },
                },
                '& .MuiOutlinedInput-input': {
                  padding: '0.75rem 1.5rem',
                  display: 'flex',
                  alignItems: 'center',
                  height: '3rem',
                },
                '& .MuiSvgIcon-root': {
                  color: 'var(--text-color)',
                },
              }}
            >
              <MuiSelect
                labelId="expiration-date-label"
                id="expiration-date-select"
                value={expirationDate || ''}
                onChange={(e) => handleExpirationDateChange(e.target.value)}
                displayEmpty
                renderValue={(selectedDate) => {
                  if (!selectedDate) {
                    return (
                      <span style={{ color: 'var(--text-color)' }}>
                        Select a date
                      </span>
                    )
                  }
                  return (
                    <span style={{ color: 'var(--text-color)' }}>
                      Expiring {getExpirationLabel(selectedDate)}
                    </span>
                  )
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      bgcolor: 'var(--background-color)',
                      '& .MuiMenuItem-root': {
                        color: 'var(--text-color)',
                        fontSize: 'var(--font-med-s)',
                        '&:hover': {
                          bgcolor: 'var(--hover-color)',
                          color: 'var(--text-color)',
                        },
                        '&.Mui-selected': {
                          bgcolor: 'var(--background-color)',
                          color: 'var(--text-color)',
                        },
                      },
                    },
                  },
                }}
              >
                {availableDates.map((date, index) => (
                  <MenuItem key={index} value={date}>
                    {expirationDate === date && '✓ '}
                    {getExpirationLabel(date)}
                  </MenuItem>
                ))}
              </MuiSelect>
            </FormControl>

            {/* The “next month” button */}
            <button onClick={handlePrevMonth} className="CreatePostFeedButton">
              Previous Month
            </button>

            {/* The “next month” button */}
            <button onClick={handleNextMonth} className="CreatePostFeedButton">
              Next Month
            </button>
          </div>
        </div>

        <div className="options-list">
          {loadingOptions ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 'var(--stock-chart-height)',
              }}
            >
              <LoadingSpinner />
            </div>
          ) : optionsData.length > 0 ? (
            <table className="options-table">
              <thead>
                <tr className="options-table-heading">
                  <th>Strike Price</th>
                  <th>Breakeven</th>
                  <th>To breakeven</th>
                  <th>Bid</th>
                  <th>Open</th>
                  <th>High</th>
                  <th>Low</th>
                  <th>Close</th>
                  <th>24h Change %</th>
                  <th>Ask</th>
                </tr>
              </thead>
              <tbody>
                {optionsData.map((option, index) => (
                  <React.Fragment key={index}>
                    {index > 0 &&
                      currentPrice < option.strikePrice &&
                      currentPrice >= optionsData[index - 1].strikePrice && (
                        <tr className="current-price-row" ref={currentPriceRef}>
                          <td colSpan="10" className="current-price-td">
                            <div
                              className="current-price-wrapper"
                              style={{ '--line-width': `${lineWidth}px` }}
                            >
                              <span className="current-price-text bold">
                                Current share price: ${fc(rc(livePrice || currentPrice))}
                              </span>
                            </div>
                          </td>
                        </tr>
                      )}
                    <tr
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        navigate(`/options/${option.optionTicker}`, {
                          state: {
                            underlyingTicker: ticker,
                            companyName: stockName,
                          },
                        })
                      }
                    >
                      <td className="bold">
                        {option?.strikePrice !== undefined
                          ? fc(option.strikePrice)
                          : '--'}
                      </td>
                      <td>
                        {option.breakEvenPrice !== undefined
                          ? fc(option.breakEvenPrice)
                          : '--'}
                      </td>
                      <td>
                        {option.changeToBreakEven !== undefined
                          ? option.changeToBreakEven > 0
                            ? `+${option.changeToBreakEven} (${
                                option.changeToBreakEven > 0 ? '+' : ''
                              }${fc(
                                calculatePercentageChange(
                                  rc(livePrice || currentPrice),
                                  option.breakEvenPrice
                                )
                              )}%)`
                            : `${option.changeToBreakEven} (${fc(
                                calculatePercentageChange(
                                  rc(livePrice || currentPrice),
                                  option.breakEvenPrice
                                )
                              )}%)`
                          : '--'}
                      </td>
                      <td>
                        {option.bid !== undefined ? fc(option.bid) : '--'}
                      </td>
                      <td>
                        {option.dailyChangeData?.open !== undefined
                          ? fc(option.dailyChangeData.open)
                          : '--'}
                      </td>
                      <td>
                        {option.dailyChangeData?.high !== undefined
                          ? fc(option.dailyChangeData.high)
                          : '--'}
                      </td>
                      <td>
                        {option.dailyChangeData?.low !== undefined
                          ? fc(option.dailyChangeData.low)
                          : '--'}
                      </td>
                      <td>
                        {option.dailyChangeData?.close !== undefined
                          ? fc(option.dailyChangeData.close)
                          : '--'}
                      </td>
                      <td
                        className="bold"
                        style={{
                          color:
                            option.dailyChangeData?.change_percent !== undefined
                              ? getChangePercentageColor(
                                  option.dailyChangeData.change_percent,
                                  tc()
                                )
                              : 'inherit',
                        }}
                      >
                        {option.dailyChangeData?.change_percent !== undefined
                          ? `${
                              option.dailyChangeData.change_percent >= 0
                                ? '+'
                                : ''
                            }${fc(option.dailyChangeData.change_percent)}%`
                          : '--'}
                      </td>
                      <td className="bold">
                        <div
                          className="ask-container bold"
                          style={{
                            color:
                              option.dailyChangeData?.change_percent !==
                              undefined
                                ? getChangePercentageColor(
                                    option.dailyChangeData.change_percent,
                                    tc()
                                  )
                                : 'var(--action-grey)',
                            borderColor:
                              option.dailyChangeData?.change_percent !==
                              undefined
                                ? getChangePercentageColor(
                                    option.dailyChangeData.change_percent,
                                    tc()
                                  )
                                : 'var(--action-grey)',
                          }}
                        >
                          <div className="ask-price bold">
                            $
                            {option.ask !== undefined
                              ? fc(option.ask, 2)
                              : '--'}
                          </div>
                          <div className="option-button-container">
                            {tradeToggle === 'buy' ? (
                              <button
                                className="option-buy-button bold"
                                style={{
                                  color:
                                    option.dailyChangeData?.change_percent !==
                                    undefined
                                      ? getChangePercentageColor(
                                          option.dailyChangeData.change_percent,
                                          tc()
                                        )
                                      : 'inherit',
                                  borderColor:
                                    option.dailyChangeData?.change_percent !==
                                    undefined
                                      ? getChangePercentageColor(
                                          option.dailyChangeData.change_percent,
                                          tc()
                                        )
                                      : 'inherit',
                                }}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  handleTradeBuyClick(option)
                                }}
                              >
                                Buy
                              </button>
                            ) : (
                              <button
                                className="option-sell-button bold"
                                style={{
                                  color:
                                    option.dailyChangeData?.change_percent !==
                                    undefined
                                      ? getChangePercentageColor(
                                          option.dailyChangeData.change_percent,
                                          tc()
                                        )
                                      : 'inherit',
                                  borderColor:
                                    option.dailyChangeData?.change_percent !==
                                    undefined
                                      ? getChangePercentageColor(
                                          option.dailyChangeData.change_percent,
                                          tc()
                                        )
                                      : 'inherit',
                                }}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  handleTradeSellClick(option)
                                }}
                              >
                                Sell
                              </button>
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          ) : (
            <p style={{ fontSize: 'var(--font-med-l)' }}>
              No options available for this date.
            </p>
          )}
        </div>
      </>
    )
  }

  function handleTradeBuyClick(option) {
    if (!isAuthenticated) {
      alert('Please sign in to access this feature')
      return
    }
    navigate(`/options/${option.optionTicker}`, {
      state: { action: 'buy', underlyingTicker: ticker },
    })
  }
  function handleTradeSellClick(option) {
    if (!isAuthenticated) {
      alert('Please sign in to access this feature')
      return
    }
    navigate(`/options/${option.optionTicker}`, {
      state: { action: 'sell', underlyingTicker: ticker },
    })
  }

  // -------------- RENDER NEWS --------------
  function renderStockNews() {
    if (!stockNews?.length) return <p>No news available</p>
    return stockNews.map((news, idx) => (
      <div key={idx} className="StockNewsItem">
        <a
          style={{ textDecoration: 'none' }}
          href={news.article_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={news.image_url}
            alt={news.title}
            className="StockNewsImage"
          />
          <h2 className="StockNewsTitle">{news.title}</h2>
        </a>
        <div className="StockNewsMeta">
          <span className="StockNewsAuthor">{news.publisher.name}</span>
          <span className="StockNewsDate">
            {new Date(news.published_utc).toLocaleDateString()}
          </span>
        </div>
      </div>
    ))
  }

  // -------------- MAIN RENDER --------------
  return (
    <div id="stock-container-wrapper" className="StockContainerWrapper">
      {toast.message && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast({ message: '', type: '' })}
        />
      )}

      <div className="StockContainer">
        <div className="MainStockContainer">
          <div className="StockPriceContent">
            <div className="StockGraph">
              <div className="StockHeader">
                <div className="StockInfo">
                  <BackButton
                    callback={() => window.history.back()}
                    fontSize="var(--font-med-l)"
                    padding="3.25rem 0 1.25rem 0"
                  />
                  <h2 className="StockTickerHeader bold">
                    {formatModifiedTicker(ticker)}
                  </h2>
                  <h1 className="StockName bold">{stockName}</h1>
                  <p className="StockPrice bold">
                    {isHovering
                      ? hoveredPrice !== null
                        ? `$${fc(hoveredPrice)}`
                        : '--'
                      : livePrice || currentPrice
                      ? `$${fc(rc(livePrice || currentPrice))}`
                      : '--'}
                  </p>
                  <p
                    className={`StockChange bold ${
                      isHovering
                        ? hoveredChangeAmount >= 0
                          ? 'positive'
                          : 'negative'
                        : (liveChangeAmount || changeAmount) >= 0
                        ? 'positive'
                        : 'negative'
                    }`}
                  >
                    {isHovering ? (
                      <>
                        {hoveredChangeAmount >= 0 ? '+' : ''}
                        {fc(hoveredChangeAmount)} ({fc(hoveredChangePercent)}%)
                      </>
                    ) : (liveChangeAmount || changeAmount) !== null ? (
                      <>
                        {(liveChangeAmount || changeAmount) >= 0 ? '+' : ''}
                        {fc(liveChangeAmount || changeAmount)} (
                        {fc(liveChangePercent || changePercent)}%)
                      </>
                    ) : (
                      <span style={{ color: 'var(--action-grey)' }}>--</span>
                    )}{' '}
                    <span className="StockDuration">
                      {isHovering ? (
                        hoveredDateTime
                      ) : filters[filter] === 'all time' ? (
                        <>
                          {filters[filter]}{' '}
                          {isOldCompany(dateIPO) && '(since 8/30/2003)'}
                        </>
                      ) : filters[filter] === 'live' ? (
                        'live'
                      ) : (
                        `past ${filters[filter]}`
                      )}
                    </span>
                  </p>

                  {!isHovering &&
                    renderAfterMarketChange(
                      marketStatus,
                      dailyChangeData,
                      filter,
                      livePrice || currentPrice
                    )}

                  {/* No buy/sell or trade? Show action buttons */}
                  {!openBuyModal &&
                    !openSellModal &&
                    !openTradeOptionsModal &&
                    !openAddCashBox && (
                      <div className="StockActions">
                        <button
                          onClick={handleBuyClick}
                          className="CreatePostFeedButton greenButton"
                        >
                          Buy
                        </button>
                        <button
                          onClick={handleSellClick}
                          className="CreatePostFeedButton"
                        >
                          Sell
                        </button>
                        <button
                          onClick={handleTradeOptionsClick}
                          className="CreatePostFeedButton"
                        >
                          Trade Options
                        </button>
                        <Select
                          options={listOptions}
                          value={selectedLists}
                          placeholder="Add to list +"
                          isClearable={false}
                          isSearchable={false}
                          unstyled
                          isMulti={true}
                          styles={customStylesPlus(tc())}
                          onMenuOpen={() => {
                            if (!isAuthenticated) {
                              alert('Please sign in to add stocks to lists.')
                              return
                            }
                            fetchStockLists(
                              isAuthenticated,
                              userToken,
                              setListOptions,
                              setSelectedLists,
                              ticker
                            )
                          }}
                          onChange={(selectedOptions) => {
                            // Check if a list was removed
                            if (selectedOptions.length < selectedLists.length) {
                              const removedList = selectedLists.find(
                                (selected) =>
                                  !selectedOptions.some(
                                    (option) => option.value === selected.value
                                  )
                              )
                              if (removedList) {
                                handleRemoveFromList(
                                  isAuthenticated,
                                  userToken,
                                  ticker,
                                  removedList.value,
                                  removedList.label,
                                  setListOptions,
                                  setSelectedLists
                                )
                                setSelectedLists(selectedOptions)
                              }
                            } else if (
                              selectedOptions.length > selectedLists.length
                            ) {
                              const addedList = selectedOptions.find(
                                (option) =>
                                  !selectedLists.some(
                                    (selected) =>
                                      selected.value === option.value
                                  )
                              )
                              if (addedList) {
                                handleAddToList(
                                  isAuthenticated,
                                  userToken,
                                  ticker,
                                  addedList.value,
                                  addedList.label,
                                  setListOptions,
                                  setSelectedLists
                                )
                                setSelectedLists(selectedOptions)
                              }
                            }
                          }}
                        />
                      </div>
                    )}

                  {/* If we *are* in a special modal, show a Cancel button */}
                  {(openBuyModal ||
                    openSellModal ||
                    openTradeOptionsModal ||
                    openAddCashBox) && (
                    <div className="StockActions">
                      <button
                        onClick={handleCloseModal}
                        className="CreatePostFeedButton"
                      >
                        Cancel Order
                      </button>
                    </div>
                  )}
                </div>
              </div>

              <div className="StockGraphMain">
                {!mainStockDataFetched ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: 'var(--stock-chart-height)',
                    }}
                  >
                    <LoadingSpinner />
                  </div>
                ) : (
                  <>
                    {openBuyModal || openSellModal ? (
                      // The newly augmented flow with market/limit toggle
                      renderBuyOrSellModal()
                    ) : openTradeOptionsModal ? (
                      <div className="options-filters-and-table">
                        {renderOptionsModal()}
                        <div style={{ height: 'var(--stock-chart-height)' }}>
                          <canvas id="stock-chart" ref={chartRef}></canvas>
                        </div>
                      </div>
                    ) : openAddCashBox ? (
                      renderAddCashBox()
                    ) : (
                      <>
                        <div style={{ height: 'var(--stock-chart-height)' }}>
                          <canvas id="stock-chart" ref={chartRef}></canvas>
                        </div>
                        <div className="StockFilters">
                          {Object.keys(filters).map((key) => (
                            <button
                              key={key}
                              className={`stock-filter-btn ${
                                filter === key ? 'selected' : 'not-selected'
                              }`}
                              onClick={() => setFilter(key)}
                            >
                              {key}
                            </button>
                          ))}
                        </div>
                      </>
                    )}
                  </>
                )}

                {(openBuyModal || openSellModal) && (
                  <div
                    style={{ marginTop: '1rem' }}
                    className="stock-market-error"
                  >
                    {marketError || '\u00A0'}
                  </div>
                )}

                {/* Show position stats if user has transactions */}
                {allTransactions?.length > 0 && (
                  <div className="StockStatistics">
                    <h2 className="StatsHeader">{`Your Position (${selectedPortfolio?.name})`}</h2>
                    <div className="StatsGrid">
                      <div className="label-value-pair">
                        <span>Shares</span>
                        <strong>{fc(userTickerPosition?.quantity)}</strong>
                      </div>
                      <div className="label-value-pair">
                        <span>Market Value</span>
                        <strong>
                          {fc(
                            (userTickerPosition?.quantity || 0) *
                              rc(livePrice || currentPrice)
                          )}
                        </strong>
                      </div>
                      <div className="label-value-pair">
                        <span>Portfolio Allocation</span>
                        <strong>
                          {fc(
                            userPortfolio?.totalValue &&
                              userPortfolio?.totalValue !== 0
                              ? (((userTickerPosition?.quantity || 0) *
                                  userTickerPosition?.avgBuyPrice) /
                                  userPortfolio.totalValue) *
                                  100
                              : 0.0
                          )}
                          %
                        </strong>
                      </div>
                      <div className="label-value-pair">
                        <span>Average Cost</span>
                        <strong>{fc(userTickerPosition?.avgBuyPrice)}</strong>
                      </div>
                      <div className="label-value-pair">
                        <span>Unrealized Return</span>
                        <strong
                          className={
                            returnData?.unrealizedDollar >= 0
                              ? 'StockChange bold positive'
                              : 'StockChange bold negative'
                          }
                        >
                          {returnData ? (
                            <>
                              {returnData.unrealizedDollar >= 0 ? '+' : ''}
                              {fc(returnData.unrealizedDollar)} (
                              {fc(returnData.unrealizedPercentage, 2, true)}%)
                            </>
                          ) : (
                            '--'
                          )}
                        </strong>
                      </div>
                      <div className="label-value-pair">
                        <span>Realized Return</span>
                        <strong
                          className={
                            returnData?.realizedDollar >= 0
                              ? 'StockChange bold positive'
                              : 'StockChange bold negative'
                          }
                        >
                          {returnData ? (
                            <>
                              {returnData.realizedDollar >= 0 ? '+' : ''}
                              {fc(returnData.realizedDollar)} (
                              {fc(returnData.realizedPercentage, 2, true)}%)
                            </>
                          ) : (
                            '--'
                          )}
                        </strong>
                      </div>
                    </div>
                  </div>
                )}

                {/* Company summary */}
                {companyDescription && (
                  <div className="StockDescription">
                    <h2 className="StockDescriptionHeader">Summary</h2>
                    <p style={{ margin: 0 }}>{companyDescription}</p>
                  </div>
                )}

                {!openTradeOptionsModal && (
                  <>
                    {/* Stats block */}
                    <div className="StockStatistics">
                      <h2 className="StatsHeader">Stats</h2>
                      <div className="StatsGrid">
                        <div className="label-value-pair">
                          <span>Open</span>
                          <strong>{fc(dailyData?.open) || '--'}</strong>
                        </div>
                        <div className="label-value-pair">
                          <span>Close</span>
                          <strong>{fc(dailyData?.close) || '--'}</strong>
                        </div>
                        <div className="label-value-pair">
                          <span>High</span>
                          <strong>{fc(dailyData?.high) || '--'}</strong>
                        </div>
                        <div className="label-value-pair">
                          <span>Low</span>
                          <strong>{fc(dailyData?.low) || '--'}</strong>
                        </div>
                        <div className="label-value-pair">
                          <span>Pre-Market</span>
                          <strong>{fc(dailyData?.preMarket) || '--'}</strong>
                        </div>
                        <div className="label-value-pair">
                          <span>After-Hours</span>
                          <strong>{fc(dailyData?.afterHours) || '--'}</strong>
                        </div>
                        <div className="label-value-pair">
                          <span>Volume</span>
                          <strong>
                            {dailyData?.volume
                              ? formatNumber(dailyData.volume)
                              : '--'}
                          </strong>
                        </div>
                        <div className="label-value-pair">
                          <span>P/E Ratio</span>
                          <strong>{fc(peRatio) || '--'}</strong>
                        </div>
                        <div className="label-value-pair">
                          <span style={{ textTransform: 'capitalize' }}>
                            {filterHighMap[filter]} High
                          </span>
                          <strong>{periodHigh ? fc(periodHigh) : '--'}</strong>
                        </div>
                        <div className="label-value-pair">
                          <span style={{ textTransform: 'capitalize' }}>
                            {filterHighMap[filter]} Low
                          </span>
                          <strong>{periodLow ? fc(periodLow) : '--'}</strong>
                        </div>
                        <div className="label-value-pair">
                          <span>EPS (TTM)</span>
                          <strong>{fc(eps) || '--'}</strong>
                        </div>
                        <div className="label-value-pair">
                          <span>Dividend Yield %</span>
                          <strong>
                            {dividendYield}
                            {dividendYield !== 'N/A' ? '%' : ''}
                          </strong>
                        </div>
                        <div className="label-value-pair">
                          <span>Market Cap</span>
                          <strong>
                            {companyDetails?.marketCap
                              ? formatNumber(companyDetails.marketCap)
                              : '--'}
                          </strong>
                        </div>
                      </div>
                    </div>

                    {/* News */}
                    {stockNews?.length > 0 && (
                      <div className="StockNews">
                        <h2 className="StockNewsHeader">News</h2>
                        <div className="StockNewsList">{renderStockNews()}</div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
