// CreateLeaderboardModal.jsx
import React, { useState, useRef, useEffect, useContext } from 'react'
import axios from 'axios'
import Select from 'react-select'
import ImageCropperDialog from '../helper/ImageCropperDialog'
import { customStyles, getImageUrl } from '../helper/StylesHelper'
import useClickOutside from '../hooks/useClickOutside'
import config from '../config'
import '../styles/Leaderboards.css'
import { ThemeContext } from '../context/ThemeContext'
import { lbSections } from '../helper/LeaderboardHelper'

/**
 * Props:
 * - isOpen (bool): whether the modal is open
 * - onClose (fn): callback to close
 * - isEditMode (bool): are we editing or creating
 * - editId (string|null): if editing, the leaderboard's ID
 * - userToken (string): auth token
 * - parentLbId (string|null): if creating a subgroup, pass the parent's ID
 */
function CreateLeaderboardModal({
  isOpen,
  onClose = () => {},
  isEditMode = false,
  editId = null,
  userToken,
  parentLbId = null,
}) {
  // ========== Local State for the form ==========
  const [leaderboardName, setLeaderboardName] = useState('')
  const [leaderboardUsername, setLeaderboardUsername] = useState('')
  const [leaderboardCategory, setLeaderboardCategory] = useState('Colleges')
  const [leaderboardBio, setLeaderboardBio] = useState('')

  // For the "parentLeaderboard" field we send to the server
  const [parentLeaderboard, setParentLeaderboard] = useState('')

  // For searching parent leaderboards via typing
  const [leaderboardQuery, setLeaderboardQuery] = useState('')
  const [leaderboardSuggestions, setLeaderboardSuggestions] = useState([])

  // For image uploading
  const fileInputRef = useRef(null)
  const [croppedImageUrl, setCroppedImageUrl] = useState(null)
  const [showImageCropper, setShowImageCropper] = useState(false)
  const [imageSrc, setImageSrc] = useState(null)

  const { tc } = useContext(ThemeContext)

  // Modal ref & click-outside detection
  const modalRef = useClickOutside(() => {
    if (isOpen) {
      onClose() // Close the modal if it is open
    }
  }, isOpen)

  // ===========================================================================
  // 1) When the modal opens: if editing => fetch existing. If not => clear + check parentLbId
  // ===========================================================================
  useEffect(() => {
    if (!isOpen) return

    if (isEditMode && editId) {
      // We're editing an existing LB
      fetchLeaderboardToEdit()
    } else {
      // We're creating a new LB
      clearForm()
    }

    if (parentLbId) {
      fetchSingleParentLb(parentLbId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, isEditMode, editId, parentLbId])

  useEffect(() => {
    if (!leaderboardQuery) {
      setLeaderboardSuggestions([])
      return
    }
    const timer = setTimeout(() => {
      fetchParentSuggestions(leaderboardQuery)
    }, 500)
    return () => clearTimeout(timer)
  }, [leaderboardQuery])

  // ===========================================================================
  // 3) Functions
  // ===========================================================================

  // Fetch the leaderboard we are editing
  async function fetchLeaderboardToEdit() {
    try {
      const resp = await axios.get(
        `${config.backendUrl}/api/leaderboards/${editId}`,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      const data = resp.data
      setLeaderboardName(data.name || '')
      setLeaderboardUsername(data.username || '')
      setLeaderboardCategory(data.category || 'Colleges')
      setLeaderboardBio(data.bio || '')
      setParentLeaderboard(data.parentLeaderboard || '')

      if (data.profileImage) {
        setCroppedImageUrl(getImageUrl(data.profileImage))
      } else {
        setCroppedImageUrl(null)
      }
    } catch (err) {
      console.error('Error fetching leaderboard for edit:', err)
      alert(
        err.response?.data?.error || 'Failed to fetch leaderboard for editing.'
      )
      onClose()
    }
  }

  // Fetch the "parent" LB if we only have its ID and want to prepopulate
  async function fetchSingleParentLb(lbId) {
    try {
      const resp = await axios.get(
        `${config.backendUrl}/api/leaderboards/${lbId}`,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      const parentData = resp.data
      if (!parentData || !parentData._id) return
      // We have a valid parent LB:
      setParentLeaderboard(parentData._id)

      setLeaderboardQuery(parentData.username)
    } catch (err) {
      console.error('Error fetching parent LB by ID:', err)
      // if it fails, no big deal, just won't be prepopulated
    }
  }

  // If user typed in the parent-lb <Select>, do a general search
  async function fetchParentSuggestions(query) {
    try {
      const resp = await axios.get(`${config.backendUrl}/api/leaderboards`, {
        params: { q: query, numResults: 5 },
      })
      const options = resp.data.leaderboards.map((lb) => ({
        value: lb._id,
        label: `${lb.name} (${lb.category})`,
      }))
      setLeaderboardSuggestions(options)
    } catch (err) {
      console.error('Error fetching parent suggestions:', err)
    }
  }

  // Clear form fields for creating new
  function clearForm() {
    setLeaderboardName('')
    setLeaderboardUsername('')
    setLeaderboardCategory('Colleges')
    setLeaderboardBio('')
    setParentLeaderboard('')
    setCroppedImageUrl(null)
    setImageSrc(null)
  }

  // Image uploading / cropping
  function handleImageUploadClick() {
    fileInputRef.current?.click()
  }

  function handleImageFileChange(e) {
    const file = e.target.files && e.target.files[0]
    if (!file) return

    const reader = new FileReader()
    reader.onload = () => {
      setImageSrc(reader.result)
      setShowImageCropper(true)
    }
    reader.readAsDataURL(file)
  }

  // Create or update the leaderboard
  async function handleCreateOrUpdateLeaderboard() {
    try {
      const formData = new FormData()
      formData.append('name', leaderboardName)
      formData.append('username', leaderboardUsername)
      formData.append('category', leaderboardCategory)
      formData.append('bio', leaderboardBio || '')
      formData.append('parentLeaderboard', parentLeaderboard || '')

      if (isEditMode) {
        // If editing and user removed the existing image
        if (!croppedImageUrl) {
          formData.append('removeProfileImage', 'true')
        } else if (fileInputRef.current?.files?.[0]) {
          // If editing and user uploaded a new file
          formData.append('profileImage', fileInputRef.current.files[0])
        }
      } else {
        // If creating new
        if (fileInputRef.current?.files?.[0]) {
          formData.append('profileImage', fileInputRef.current.files[0])
        }
      }

      const url = isEditMode
        ? `${config.backendUrl}/api/leaderboards/${editId}`
        : `${config.backendUrl}/api/leaderboards`
      const method = isEditMode ? 'patch' : 'post'

      await axios[method](url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userToken}`,
        },
      })
      alert(
        isEditMode
          ? 'Leaderboard updated successfully!'
          : 'Leaderboard created successfully!'
      )
      onClose()
    } catch (err) {
      console.error('Error creating/updating leaderboard:', err)
      alert(err.response?.data?.error || 'Failed to create/update leaderboard.')
    }
  }

  // If the modal isn't open, we render nothing
  if (!isOpen) return null

  // ===========================================================================
  // Render
  // ===========================================================================
  return (
    <div className="port-settings-modal" ref={modalRef}>
      <h2 className="FeedPostHeaderH2">
        {isEditMode ? 'Edit Leaderboard' : 'Create leaderboard'}
      </h2>

      <div className="PortInputContainer">
        {/* FIRST ROW: Name & Profile Image */}
        <div className="LeadSectionWrapper">
          {/* 1) Name */}
          <div className="PortSection" style={{ flex: 1 }}>
            <label className="FeedLabel">Name</label>
            <p className="FeedPortfolioSpan">
              This is the name that will appear at the top of the leaderboard.
            </p>
            <input
              type="text"
              style={{ backgroundColor: 'transparent' }}
              className="FeedInputField"
              placeholder="Enter leaderboard name"
              value={leaderboardName}
              onChange={(e) => setLeaderboardName(e.target.value)}
            />
          </div>

     
          <div className="ProfileImageSection">
            <div
              style={{
                flex: 1,
              }}
            >
              <label className="FeedLabel" style={{ paddingBottom: '1rem' }}>
                Profile image
              </label>
              <p className="FeedPortfolioSpan">
                This is the image that will appear at the top of the
                leaderboard.
              </p>
            </div>
            <label className="ProfileImageUploadBox" htmlFor="fileUpload">
              {croppedImageUrl ? (
                <img
                  src={croppedImageUrl}
                  alt="Uploaded"
                  className="UploadedImagePreview"
                />
              ) : (
                <div className="UploadIcon">+</div>
              )}
            </label>
            <input
              id="fileUpload"
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleImageFileChange}
            />
          </div>
        </div>

        {/* SECOND ROW: Parent LB & Username */}
        <div className="LeadSectionWrapper" style={{ flexDirection: 'column' }}>
          {/* 1) Parent Leaderboard */}
          <div className="PortSection" style={{ flex: 1 }}>
            <label className="FeedLabel">Parent leaderboard</label>
            <p className="FeedPortfolioSpan">
              Some leaderboards are subgroups within a parent leaderboard. Leave
              blank if none.
            </p>
            <div style={{ border: 'var(--border)', borderRadius: '5px' }}>
              <Select
                value={
                  parentLeaderboard
                    ? leaderboardSuggestions.find(
                        (opt) => opt.value === parentLeaderboard
                      ) || null
                    : null
                }
                onChange={(selectedOption) => {
                  if (selectedOption?.value === parentLeaderboard) {
                    // If picking the same one => clear out
                    setParentLeaderboard('')
                  } else {
                    setParentLeaderboard(selectedOption?.value || '')
                  }
                }}
                onInputChange={(newVal, action) => {
                  if (action.action === 'input-change') {
                    setLeaderboardQuery(newVal)
                  }
                }}
                options={leaderboardSuggestions}
                placeholder="Search for a leaderboard"
                isSearchable
                // We apply theme styles
                styles={customStyles(tc())}
                noOptionsMessage={() => 'No leaderboards found'}
                unstyled
              />
            </div>
          </div>

          {/* 2) Username */}
          <div className="PortSection" style={{ flex: 1 }}>
            <label className="FeedLabel">Username</label>
            <p className="FeedPortfolioSpan">
              Create a unique username for investors to find you. No spaces
              allowed.
            </p>
            <input
              type="text"
              className="FeedInputField"
              style={{ backgroundColor: 'transparent' }}
              placeholder="Your username"
              value={leaderboardUsername}
              onChange={(e) => setLeaderboardUsername(e.target.value)}
            />
          </div>
        </div>

        {/* CATEGORY */}
        <div className="PortSection">
          <label className="FeedLabel">Category</label>
          <p className="FeedPortfolioSpan">
            Categories are used to sort leaderboards.
          </p>
          <div className="Feed-toggle-container" style={{ width: '100%' }}>
            {lbSections.map(
              (cat) => (
                <button
                  key={cat}
                  className={`Feed-toggle-button bold ${
                    leaderboardCategory === cat ? 'Feed-active' : ''
                  }`}
                  onClick={() => setLeaderboardCategory(cat)}
                >
                  {cat}
                </button>
              )
            )}
          </div>
        </div>

        {/* BIO */}
        <div className="PortSection">
          <label className="FeedLabel">Bio</label>
          <p className="FeedPortfolioSpan">
            Describe what makes this leaderboard unique.
          </p>
          <textarea
            className="FeedInputField"
            style={{ resize: 'none', backgroundColor: 'transparent' }}
            placeholder="Describe your leaderboard..."
            value={leaderboardBio}
            onChange={(e) => setLeaderboardBio(e.target.value)}
          />
        </div>
      </div>

      {/* BOTTOM BUTTONS */}
      <div
        className="Feed-toggle-container"
        style={{ gap: '1rem', border: 'none' }}
      >
        <button
          onClick={handleCreateOrUpdateLeaderboard}
          className="Feed-toggle-button bold Feed-active"
        >
          {isEditMode ? 'Update leaderboard' : 'Create leaderboard'}
        </button>
        <button
          className="Feed-toggle-button bold"
          onClick={onClose}
          style={{
            backgroundColor: 'var(--background-color)',
            borderRadius: '5rem',
            color: 'var(--text-color)',
          }}
        >
          Close
        </button>
      </div>

      {/* IMAGE CROPPER DIALOG */}
      <ImageCropperDialog
        open={showImageCropper}
        onClose={() => setShowImageCropper(false)}
        imageSrc={imageSrc}
        aspect={1}
        cropShape="rect"
        onSave={(blobUrl, blob) => {
          setCroppedImageUrl(blobUrl)
          setShowImageCropper(false)
        }}
        darkMode={false}
      />
    </div>
  )
}

export default CreateLeaderboardModal
