import React, { useState, useContext, useEffect } from 'react'
import '../styles/Post.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faPlus } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import config from '../config'
import { AuthContext } from '../context/AuthContext'
import {
  allowedHTML,
  renderTagLine,
  fetchDailyDataForTaggedAssets,
  renderTaggedAssets,
  formatTimeSince,
  timeSince,
} from '../helper/PostHelper'
import retweet from '../assets/retweet.svg'
import comment from '../assets/comment.svg'
import { fetchUser } from '../helper/ProfileHelper'
import Repost from './post-actions/Repost'
import Upvote from './post-actions/Upvote'
import Downvote from './post-actions/Downvote'
import Dots from './post-actions/Dots'
import Bookmark from './post-actions/Bookmark'
import DOMPurify from 'dompurify'
import { handleComment } from '../helper/CreatePostHelper'
import {
  getImageUrl,
  LoadingSpinner,
  // eslint-disable-next-line
  formatCurrency as fc,
} from '../helper/StylesHelper'
import { ThemeContext } from '../context/ThemeContext'
import TransactionDetails from './TransactionDetails'
import {
  // eslint-disable-next-line
  getChangePercentageColor,
  // eslint-disable-next-line
  handleNavigateToAsset,
} from '../helper/MarketsHelper'
import VerifiedIcon from './VerifiedIcon'
/**
 *
 * @typedef {import('../helper/PostHelper').QuotePostDetailsObject} QuotePostDetailsObject
 * @typedef {import('../helper/ProfileHelper').UserObject} UserObject
 *
 *
 *
 */

/**
 * Post component represents an individual post within a feed.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.postId - The ID of the post.
 * @param {string} props.profilePic - The profile picture base64 of the post's author.
 * @param {string} props.author - The author of the post
 * @param {string} props.authorId - The ID of the post's author.
 * @param {boolean} props.verified - Whether the post's author is verified.
 * @param {string} props.username - The username of the post's author.
 * @param {string} props.content - The content of the post.
 * @param {boolean} props.copyReposts - Array of user IDs who copy reposted.
 * @param {boolean} props.isQuotePost - Whether the post is a quote post.
 * @param {string} props.quotePostId - The ID of the original post being quoted.
 * @param {string} props.transactionId - The ID of the transaction if applicable.
 * @param {string} props.time - The time the post was created.
 * @param {string} props.title - The title of the post.
 * @param {string} props.sentiment - The sentiment of the post.
 * @param {string} props.type - The type of the post.
 * @param {string[]} props.categories - The categories of the post.
 * @param {boolean} props.bookmarked - Whether the post is bookmarked by the current user.
 * @param {Function} props.onBookmarkClick - Function to handle bookmark clicks.
 * @param {string[]} props.images - Array of image url in the post, only 1 for now.
 * @param {string[]} props.taggedAssets - Array of tagged tickers
 * @param {boolean} props.isCopyRepost - Whether the post is a copy repost.
 * @param {string} props.copyReposterId - The ID of the user who copy reposted the post.
 * @param {boolean} props.isDownvoted - Whether the post is downvoted by the current user.
 * @param {boolean} props.isUpvoted - Whether the post is upvoted by the current user.
 * @param {boolean} props.isAdmin - Whether the current user is an admin.
 * @param {string} props.caption - The caption for the post's image.
 * @param {Function} props.refreshFeed - Function to refresh the parent components feed.
 * @param {Function} props.refreshFollowingStatus - Function to refresh the user following status of parent component.
 * @param {boolean} props.isComment - Whether the post is a comment.
 * @param {string} props.parentPostId - The ID of the parent post.
 * @param {boolean} props.isAuthorFollowed - Whether the author is followed by the current user.
 * @param {Number} props.upvoteCountParam - The total number of upvotes.
 * @param {Number} props.downvoteCountParam - The total number of downvotes.
 * @param {Number} props.repostCountParam - The total number of reposts.
 * @param {boolean} props.commentCountParam - The total number of comments.
 * @param {boolean} props.isCopyRepostedParam - Whether the post is copy reposted by the current user.
 *
 * @returns {JSX.Element} The rendered Post component.
 *
 */

const Post = ({
  postId,
  profilePic,
  author,
  verified,
  username,
  content,
  isQuotePost,
  quotePostId,
  time,
  title,
  sentiment,
  type,
  categories,
  bookmarked,
  onBookmarkClick,
  images,
  isCopyRepost,
  copyReposterId,
  isDownvoted,
  isUpvoted,
  isAdmin,
  caption,
  refreshFeed,
  refreshFollowingStatus,
  parentPostId,
  isComment,
  isAuthorFollowed, //prop from parent
  authorId,
  upvoteCountParam,
  downvoteCountParam,
  repostCountParam,
  commentCountParam,
  isCopyRepostedParam,
  taggedAssets,
  transactionId,
  replyPlaceholder = `Respond to ${username}`,
}) => {
  const navigate = useNavigate()
  const { userToken, isAuthenticated, userId } = useContext(AuthContext)
  const { tc } = useContext(ThemeContext)
  const [isUpvotedState, setIsUpvotedState] = useState(isUpvoted)
  const [isDownvotedState, setIsDownvotedState] = useState(isDownvoted)
  const [upvoteCount, setUpvoteCount] = useState(upvoteCountParam)
  const [downvoteCount, setDownvoteCount] = useState(downvoteCountParam)
  const [commentCount, setCommentCount] = useState(commentCountParam)
  const [repostCount, setRepostCount] = useState(repostCountParam)
  const [isCopyReposted, setIsCopyReposted] = useState(isCopyRepostedParam)
  const [isAuthorFollowedState, setIsAuthorFollowedState] =
    useState(isAuthorFollowed)
  const [isBookmarked, setIsBookmarked] = useState(bookmarked)
  const [replyOpen, setReplyOpen] = useState(false)
  const [replyContent, setReplyContent] = useState('')

  /** @type {[QuotePostDetailsObject, Function]} */
  const [quotePostDetails, setQuotePostDetails] = useState(null)

  const [quotePostLoading, setQuotePostLoading] = useState(true)
  const [copyReposterLoading, setCopyReposterLoading] = useState(true)

  /** @type {[UserObject, Function]} */
  const [copyReposter, setCopyReposter] = useState(null)
  const [dailyChangeData, setDailyChangeData] = useState(null)

  const [tagLine, setTagLine] = useState(null)
  const [quotePostTagLine, setQuotePostTagLine] = useState(null)
  const [loading, setLoading] = useState(true)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)

  useEffect(() => {
    setTagLine(renderTagLine(type, categories, sentiment))
  }, [type, categories, sentiment])

  useEffect(() => {
    if (isQuotePost && quotePostDetails) {
      setQuotePostTagLine(
        renderTagLine(
          quotePostDetails.type,
          quotePostDetails.categories,
          quotePostDetails.sentiment
        )
      )
    }
  }, [isQuotePost, quotePostDetails])

  useEffect(() => {
    const fetchTaggedData = async () => {
      if (
        taggedAssets?.length > 0 ||
        (quotePostDetails && quotePostDetails?.taggedAssets?.length > 0)
      ) {
        let allAssets = quotePostDetails
          ? [...taggedAssets, ...quotePostDetails.taggedAssets]
          : [...taggedAssets]
        const dailyData = await fetchDailyDataForTaggedAssets(allAssets)
        setDailyChangeData(dailyData)
      }
    }
    fetchTaggedData()
  }, [taggedAssets, quotePostDetails])

  // Sync local state with props whenever isAuthorFollowed changes
  useEffect(() => {
    setIsAuthorFollowedState(isAuthorFollowed)
  }, [isAuthorFollowed])

  useEffect(() => {
    const fetchQuotePost = async () => {
      try {
        const response = await axios.get(
          `${config.backendUrl}/api/posts/${quotePostId}`,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        )
        setQuotePostDetails(response.data)
      } catch (error) {
        console.error('Error fetching quote post:', error)
      } finally {
        setQuotePostLoading(false)
      }
    }

    const fetchCopyReposter = async () => {
      try {
        const response = await fetchUser(copyReposterId, userToken)
        setCopyReposter(response.data)
      } catch (error) {
        console.error('Error fetching copy reposter:', error)
      } finally {
        setCopyReposterLoading(false)
      }
    }
    const fetchPostData = async () => {
      setLoading(true) // Set loading to true before fetching
      try {
        // Simulate data fetching or add your actual fetch logic here
        await fetchQuotePost()
        await fetchCopyReposter()
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false) // Set loading to false after fetching
      }
    }
    fetchPostData()
    if (isQuotePost) {
      fetchQuotePost()
    } else {
      setQuotePostLoading(false)
    }

    if (isCopyRepost) {
      fetchCopyReposter()
    } else {
      setCopyReposterLoading(false)
    }
  }, [quotePostId, copyReposterId, isQuotePost, isCopyRepost, userToken])

  const handleProfileClick = () => {
    // if (isMobile) return // Disable for mobile screens
    if (!isAuthenticated) {
      alert('Please sign in to view profiles')
      return
    }
    navigate(`/profile/${username}`)
  }

  const handleFollowClick = async () => {
    if (isMobile) return // Disable for mobile screens
    if (!isAuthenticated) {
      alert('Please sign in to follow users')
      return
    }
    try {
      const response = await axios.post(
        `${config.backendUrl}/api/auth/follow`,
        { currentUserId: userId, viewingUserId: authorId },
        { headers: { Authorization: `Bearer ${userToken}` } }
      )

      setIsAuthorFollowedState(true)
      refreshFollowingStatus(authorId)
      alert(response.data.message)
    } catch (error) {
      console.error('Error following user:', error)
      alert('Error following user')
    }
  }

  const getLimitedContent = (text, wordLimit) => {
    const words = text.split(' ')
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(' ') + '...'
      : text
  }

  const toggleReplyContainer = () => {
    setReplyOpen(!replyOpen)
    if (!replyOpen) setReplyContent('') // Clear reply content on close
  }

  const handlePostReply = async (isDraft = false) => {
    if (!isAuthenticated) {
      alert('Please sign in to post comments')
      return
    }
    try {
      const response = await handleComment(
        postId,
        replyContent,
        userToken,
        isDraft
      )
      if (response) {
        setReplyContent('')
        setReplyOpen(false)
        if (!isDraft) {
          setCommentCount((prevCount) => prevCount + 1)
        }
        alert(response.message)
      } else {
        alert('Error posting comment')
      }
    } catch (error) {
      console.error('Error in handlePostReply:', error)
      alert('Error posting comment')
    }
  }

  return (
    <div className="PostContainerWrapper">
      {quotePostLoading || copyReposterLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {isCopyRepost && (
            <span className="ReposterText">
              {copyReposter?.name || 'A user'} reposted{' '}
              <img src={retweet} alt="Repost" className="ActionIcon" />
            </span>
          )}

          <div className="PostContainer">
            <div className="ProfilePicContainer">
              <div style={{ position: 'relative' }}>
                <img
                  src={getImageUrl(profilePic)}
                  alt="Profile"
                  className="ProfilePic"
                  onClick={handleProfileClick}
                />
                {!isAuthorFollowedState && (
                  <div
                    className="PostFollowUserBtn"
                    onClick={handleFollowClick}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </div>
                )}
              </div>
            </div>
            <div className="PostContentContainerWrapper">
              <div className="PostContentContainer">
                <div className="PostHeader" onClick={handleProfileClick}>
                  <span className="PostAuthor bold">
                    {author} {verified && <VerifiedIcon fontSize="1.3rem" />}{' '}
                    <span
                      className="medium"
                      style={{
                        fontSize: 'var(--font-med-l)',
                        color: 'var(--action-grey)',
                      }}
                    >
                      @{username} · {time} {tagLine && '·'} {tagLine}
                    </span>
                  </span>
                </div>
                <div
                  className="PostContent"
                  onClick={!isMobile ? () => navigate(`/post/${postId}`) : null} // Disable on mobile
                >
                  <h2 className="PostContentH2 bold">
                    {title && <>{title}</>}
                  </h2>
                  <div
                    className="PostContentP"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        getLimitedContent(content, 150),
                        allowedHTML
                      ),
                    }}
                    onClick={(e) => {
                      if (e.target.tagName === 'A') {
                        e.stopPropagation() // Prevents outer div's onClick from firing
                      }
                    }}
                  ></div>

                  {content.split(' ').length > 150 && (
                    <p
                      className="read-more bold"
                      style={{ color: 'var(--stock-change-pos)' }}
                    >
                      Read More
                    </p>
                  )}

                  {images &&
                    images.map((image, index) => (
                      <img
                        key={index}
                        src={getImageUrl(image)}
                        alt={`Post ${index}`}
                        className="PostImage"
                      />
                    ))}
                  {caption && <p className="PostContentCaption">{caption}</p>}
                  {isQuotePost && (
                    <div className="cp-QuotePostContainer">
                      {quotePostDetails ? (
                        <>
                          <div className="cp-QuotePostHeader">
                            <img
                              src={getImageUrl(
                                quotePostDetails.author.profilePicture
                              )}
                              alt="Profile"
                              className="cp-QuoteProfilePic"
                            />
                            <div
                              className="cp-QuotePostInfoWrapper"
                              style={{ flexDirection: 'column' }}
                            >
                              <div className="cp-QuotePostInfo">
                                <span className="cp-QuotePostAuthor cp-bold">
                                  <span className="bold">
                                    {quotePostDetails.author.name}
                                  </span>{' '}
                                  {quotePostDetails.author.isUserVerified && (
                                    <VerifiedIcon fontSize="1.3rem" />
                                  )}{' '}
                                  <span
                                    className="medium"
                                    style={{ color: 'var(--action-grey)' }}
                                  >
                                    @{quotePostDetails.author.username} ·{' '}
                                    {formatTimeSince(
                                      timeSince(quotePostDetails.timePosted)
                                    )}
                                    {quotePostTagLine &&
                                      ` · ${quotePostTagLine}`}
                                  </span>
                                </span>
                              </div>
                              <h2 className="cp-QuotePostTitle bold">
                                {quotePostDetails.title && (
                                  <>{quotePostDetails.title}</>
                                )}
                              </h2>
                              <div className="cp-QuotePostContent">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(
                                      getLimitedContent(
                                        quotePostDetails.content,
                                        150
                                      ),
                                      allowedHTML
                                    ),
                                  }}
                                  onClick={(e) => {
                                    if (e.target.tagName === 'A') {
                                      e.stopPropagation() // Prevents outer div's onClick from firing
                                    }
                                  }}
                                ></div>
                              </div>
                              {quotePostDetails.images?.length > 0 &&
                                quotePostDetails.images.map((image, index) => (
                                  <img
                                    key={index}
                                    src={getImageUrl(image)}
                                    alt={`Post ${index}`}
                                    className="PostImage-sm"
                                  />
                                ))}
                              {quotePostDetails.taggedAssets &&
                                renderTaggedAssets(
                                  quotePostDetails.taggedAssets,
                                  dailyChangeData,
                                  navigate,
                                  tc
                                )}
                              {quotePostDetails.transactionId && (
                                <TransactionDetails
                                  transactionId={quotePostDetails.transactionId}
                                />
                              )}
                            </div>
                          </div>
                        </>
                      ) : (
                        <p>Post was deleted or could not be loaded</p>
                      )}
                    </div>
                  )}
                </div>

                {taggedAssets &&
                  renderTaggedAssets(
                    taggedAssets,
                    dailyChangeData,
                    navigate,
                    tc
                  )}
                {transactionId && (
                  <TransactionDetails transactionId={transactionId} />
                )}

                <div
                  className="PostActions"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="LeftActions">
                    <div className="ActionItem">
                      <button
                        className={`ReplyButton ${
                          replyOpen ? 'CloseButton' : ''
                        }`}
                        style={{ gap: '0.5rem' }}
                        onClick={toggleReplyContainer}
                      >
                        {replyOpen ? ' Close' : ' Reply'}

                        {` (${commentCount})`}
                      </button>
                    </div>
                    <Repost
                      postId={postId}
                      profilePic={profilePic}
                      author={author}
                      verified={verified}
                      username={username}
                      content={content}
                      title={title}
                      time={time}
                      sentiment={sentiment}
                      type={type}
                      categories={categories}
                      images={images}
                      repostCount={repostCount}
                      setRepostCount={setRepostCount}
                      isCopyReposted={isCopyReposted}
                      setIsCopyReposted={setIsCopyReposted}
                      userToken={userToken}
                      taggedAssets={taggedAssets}
                      transactionId={transactionId}
                    />
                    <div className="UpvoteDownvoteContainer">
                      <Upvote
                        isAdmin={isAdmin}
                        isUpvotedState={isUpvotedState}
                        setIsUpvotedState={setIsUpvotedState}
                        setIsDownvotedState={setIsDownvotedState}
                        setUpvoteCount={setUpvoteCount}
                        setDownvoteCount={setDownvoteCount}
                        postId={postId}
                      />
                      <span>{upvoteCount - downvoteCount}</span>
                      <Downvote
                        isAdmin={isAdmin}
                        isDownvotedState={isDownvotedState}
                        setIsDownvotedState={setIsDownvotedState}
                        setIsUpvotedState={setIsUpvotedState}
                        setUpvoteCount={setUpvoteCount}
                        setDownvoteCount={setDownvoteCount}
                        postId={postId}
                        userToken={userToken}
                      />
                    </div>
                  </div>
                  <div className="RightActions">
                    <Bookmark
                      postId={postId}
                      isBookmarked={isBookmarked}
                      onBookmarkClick={onBookmarkClick}
                      setIsBookmarked={setIsBookmarked}
                    />
                    <Dots
                      postId={postId}
                      username={username}
                      userToken={userToken}
                      isAdmin={isAdmin}
                      title={title}
                      content={content}
                      categories={categories}
                      sentiment={sentiment}
                      type={type}
                      caption={caption}
                      images={images}
                      isQuotePost={isQuotePost}
                      quotePostId={quotePostId}
                      refreshFeed={refreshFeed}
                      isComment={isComment}
                      parentPostId={parentPostId}
                      taggedAssets={taggedAssets}
                      transactionId={transactionId}
                    />
                  </div>
                </div>
              </div>
              {replyOpen && (
                <div className="ReplyContainer">
                  <textarea
                    className="ReplyInput"
                    placeholder={replyPlaceholder}
                    value={replyContent}
                    onChange={(e) => {
                      setReplyContent(e.target.value)
                      // Auto-resize textarea
                      e.target.style.height = 'auto'
                      e.target.style.height = `${e.target.scrollHeight}px`
                    }}
                  />
                  <div
                    style={{
                      display: 'flex',
                      gap: '1rem',
                      alignItems: 'center',
                      alignSelf: 'flex-end',
                    }}
                  >
                    <button
                      className="ReplyActionButton"
                      onClick={() => navigate(`/post/${postId}`)}
                    >
                      View replies
                    </button>

                    <button
                      className="ReplyActionButton"
                      onClick={() => handlePostReply(true)}
                    >
                      Save to drafts
                    </button>
                    <button
                      className="ReplyActionButton"
                      style={{
                        color: 'var(--stock-change-pos)',
                        backgroundColor: 'transparent',
                        border: '0.05rem solid var(--stock-change-pos)',
                      }}
                      onClick={() => handlePostReply(false)}
                    >
                      Post
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Post

// <div className="FeedInputWrapper">
//   <textarea
//     className="FeedInputBar"
//     placeholder="Write something to the Vestr community!"
//     value={quickPostContent}
//     onChange={(e) => {
//       setQuickPostContent(e.target.value)
//       // Auto-resize textarea
//       e.target.style.height = 'auto'
//       e.target.style.height = `${e.target.scrollHeight}px`
//     }}
//   />

//   <div
//     style={{
//       display: 'flex',
//       gap: '1rem',
//       alignItems: 'center',
//       alignSelf: 'flex-end',
//     }}
//   >
//     <button
//       className="CreatePostFeedButton"
//       onClick={() => {
//         if (!isAuthenticated) {
//           alert('Please sign in to create a post')
//           return
//         }
//         navigate('/createpost', {
//           state: {
//             quickPostContent: quickPostContent,
//             selectedCategoryQuick: selectedCategoryQuick,
//             selectedPostTypeQuick: selectedPostTypeQuick,
//             fromFeed: true,
//           },
//         })
//       }}
//     >
//       Full View
//     </button>

//     <button
//       className="CreatePostFeedButton"
//       onClick={async () => {
//         if (!isAuthenticated) {
//           alert('Please sign in to create a post')
//           return
//         }
//         if (!quickPostContent) {
//           alert('Content cannot be empty')
//           return
//         }
//         const response = await handleQuickPostSaveToDrafts(
//           quickPostContent,
//           userToken
//         )
//         if (response.status === 201) {
//           alert('Post saved to drafts')
//           setQuickPostContent('')
//         }
//       }}
//     >
//       Save to drafts
//     </button>

//     <button
//       className="CreatePostFeedButton"
//       style={{
//         color: 'var(--stock-change-pos)',
//         backgroundColor: 'transparent',
//         border: '0.05rem solid var(--stock-change-pos)',
//       }}
//       onClick={async () => {
//         if (!isAuthenticated) {
//           alert('Please sign in to create a post')
//           return
//         }
//         if (!quickPostContent) {
//           alert('Content cannot be empty')
//           return
//         }
//         const response = await handleQuickPostSubmit(
//           quickPostContent,
//           selectedCategoryQuick,
//           selectedPostTypeQuick,
//           userToken
//         )
//         if (response.status === 201) {
//           alert('Post submitted successfully')
//           setQuickPostContent('')
//           setSelectedCategoriesQuick([])
//           setSelectedCategoryQuick([])
//           setSelectedPostTypesQuick([])
//           setSelectedPostTypeQuick('')
//         } else {
//           alert('Error submitting post')
//         }
//       }}
//     >
//       Post
//     </button>
//   </div>
// </div>
