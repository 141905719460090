import React, { useState, useEffect, useContext, useCallback } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { AuthContext } from '../context/AuthContext'
import { ThemeContext } from '../context/ThemeContext'
import BackButton from './BackButton'
import '../styles/Leaderboard.css'
import '../styles/AllLeaderboards.css'
import {
  LoadingSpinner,
  customStylesTrans,
} from '../helper/StylesHelper'
import { timeOptions } from '../helper/FeedHelper'

// UPDATED sortOptions
import { sortOptions } from '../helper/LeaderboardHelper'

import {
  fetchBestLeaderboards,
  fetchBestUsers,
  renderLeaderboardCardLeaderboard,
  renderLeaderboardCardUser,
} from '../helper/LeaderboardHelper'
import useResponsiveWidth from '../hooks/useResponsiveWidth'


export default function AllLeaderboards() {
  useResponsiveWidth()
  // Map the URL param to a friendly label
  const typeToNameMap = {
    investors: 'Investors',
    leaderboards: 'All Leaderboards',
    'high-schools': 'High Schools',
    'high-school-classes': 'High School Classes',
    companies: 'Companies',
    random: 'Random',
    colleges: 'Colleges',
    'college-classes': 'College Classes',
  }

  const typeToDescriptionMap = {
    investors: 'Explore the profiles and strategies of top investors from around the world. Discover their achievements, portfolios, and rankings.',
    leaderboards: 'Browse a comprehensive list of leaderboards showcasing top-ranked participants in various categories and competitions.',
    'high-schools': 'Browse the achievements of top-performing high schools globally. Discover schools excelling in academics, sports, and extracurriculars.',
    'high-school-classes': 'Explore individual high school classes with exceptional performances in academics, projects, and competitions.',
    companies: 'Discover leading companies driving innovation, growth, and excellence in their industries. Learn about their rankings and milestones.',
    random: 'Dive into a unique and diverse mix of participants from various categories, offering surprises and fresh perspectives.',
    colleges: 'Discover the top-performing colleges globally, known for their academic excellence, research contributions, and vibrant student communities.',
    'college-classes': 'Explore standout college classes excelling in academics, research, or special projects that make them exceptional.',
  };

  const { type } = useParams()
  const navigate = useNavigate()
  const { userToken } = useContext(AuthContext)
  const { tc } = useContext(ThemeContext)

  const [leaderboardData, setLeaderboardData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [leaderboardPage, setLeaderboardPage] = useState(1)

  // Instead of just a string, store the entire object 
  const [selectedTime, setSelectedTime] = useState(timeOptions[0].value)
  const [selectedSortOption, setSelectedSortOption] = useState(sortOptions[0])

  const [searchQuery, setSearchQuery] = useState('')
  const [debouncedQuery, setDebouncedQuery] = useState('')

  const debounceTime = 500
  const debounce = (func, delay) => {
    let debounceTimer
    return (...args) => {
      clearTimeout(debounceTimer)
      debounceTimer = setTimeout(() => func(...args), delay)
    }
  }

  const debouncedSearch = useCallback(
    debounce((query) => {
      setDebouncedQuery(query)
      setLeaderboardPage(1)
    }, debounceTime),
    []
  )

  useEffect(() => {
    debouncedSearch(searchQuery)
  }, [searchQuery, debouncedSearch])

  function handleLoadMore() {
    if (!leaderboardData) return
    // If type=investors => topPortfolios, else topLeaderboards
    const arrToUse = type === 'investors'
      ? leaderboardData.topPortfolios
      : leaderboardData.topLeaderboards

    const hasMore = arrToUse.length < (leaderboardData.totalResults || 0)
    if (hasMore) {
      setLeaderboardPage((prev) => prev + 1)
    }
  }

  async function fetchData() {
    setLoading(true)
    try {
      let responseData = null

      if (type === 'investors') {
        // fetchBestUsers
        await fetchBestUsers(
          selectedTime,
          selectedSortOption.value,      // pass .value
          leaderboardPage,
          21,  // limit
          (data) => {
            responseData = data
          },
          'all',
          debouncedQuery,
          selectedSortOption.asc,        // pass asc
          userToken
        )
      } else {
        // fetchBestLeaderboards
        await fetchBestLeaderboards(
          selectedTime,
          selectedSortOption.value,      // pass .value
          typeToNameMap[type] || 'all',
          leaderboardPage,
          21,
          (data) => {
            responseData = data
          },
          null,
          debouncedQuery,
          selectedSortOption.asc,        // pass asc
          userToken
        )
      }

      if (leaderboardPage === 1) {
        setLeaderboardData(responseData)
      } else {
        setLeaderboardData((prev) => {
          if (!prev) return responseData

          if (type === 'investors') {
            return {
              ...responseData,
              topPortfolios: [
                ...(prev.topPortfolios || []),
                ...(responseData.topPortfolios || []),
              ],
            }
          } else {
            return {
              ...responseData,
              topLeaderboards: [
                ...(prev.topLeaderboards || []),
                ...(responseData.topLeaderboards || []),
              ],
            }
          }
        })
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line
  }, [type, selectedTime, selectedSortOption, leaderboardPage, userToken, debouncedQuery])

  return (
    <div className="al-container">
      <BackButton padding="2rem 0" />

      {/* HEADER */}
      <div className="al-header">
        <h2 className="al-header-h2">{typeToNameMap[type]}</h2>
        <p className="al-header-p">{typeToDescriptionMap[type]}</p>
        <div style={{ display: 'flex', alignItems: 'center', gap: '3rem' }}>
          <input
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setLeaderboardPage(1)
                setLeaderboardData(null)
              }
            }}
            type="text"
            placeholder="Keyword Search"
            className="AddCashInput"
          />

          <Select
            value={timeOptions.find((o) => o.value === selectedTime)}
            onChange={(option) => {
              setLeaderboardPage(1)
              setLeaderboardData(null)
              setSelectedTime(option.value)
            }}
            options={timeOptions}
            isSearchable={false}
            placeholder="Time"
            unstyled
            styles={customStylesTrans(tc())}
          />

          <Select
            // entire object
            value={selectedSortOption}
            onChange={(option) => {
              setLeaderboardPage(1)
              setLeaderboardData(null)
              setSelectedSortOption(option)
            }}
            options={sortOptions}
            isSearchable={false}
            placeholder="Sort By"
            unstyled
            styles={customStylesTrans(tc())}
          />
        </div>
      </div>

      {loading && leaderboardPage === 1 ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="al-main-container">
            {type === 'investors'
              ? leaderboardData?.topPortfolios?.map((portfolio, index) =>
                  renderLeaderboardCardUser(
                    portfolio,
                    index,
                    selectedSortOption.value, 
                    navigate,
                    tc
                  )
                )
              : leaderboardData?.topLeaderboards?.map((lb, index) =>
                  renderLeaderboardCardLeaderboard(
                    lb,
                    index,
                    selectedSortOption.value, 
                    navigate,
                    tc
                  )
                )}
          </div>

          {!loading && leaderboardData && (
            <>
              {/* If no results are found */}
              {type === 'investors' &&
                (leaderboardData.topPortfolios?.length || 0) === 0 && (
                  <div style={{ textAlign: 'center', width: 'fit-content' }}>
                    No results found
                  </div>
                )}
              {type !== 'investors' &&
                (leaderboardData.topLeaderboards?.length || 0) === 0 && (
                  <div style={{ textAlign: 'center', width: 'fit-content' }}>
                    No results found
                  </div>
                )}

              {/* If loading next pages, show spinner */}
              {loading && leaderboardPage > 1 && (
                <div style={{ textAlign: 'center', margin: '1rem 0' }}>
                  <LoadingSpinner />
                </div>
              )}

              {/* View more */}
              {type === 'investors' && leaderboardData?.topPortfolios && (
                leaderboardData.topPortfolios.length < (leaderboardData.totalResults || 0) && (
                  <button
                    className="CreatePostFeedButton"
                    style={{ width: 'fit-content', margin: '1rem auto' }}
                    onClick={handleLoadMore}
                  >
                    View More
                  </button>
                )
              )}
              {type !== 'investors' && leaderboardData?.topLeaderboards && (
                leaderboardData.topLeaderboards.length < (leaderboardData.totalResults || 0) && (
                  <button
                    className="CreatePostFeedButton"
                    style={{ width: 'fit-content', margin: '1rem auto' }}
                    onClick={handleLoadMore}
                  >
                    View More
                  </button>
                )
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}