import axios from "axios";
import DataTable from "./subcomponents/DataTable";
import { useEffect, useState } from "react";
import config from "../config";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import Pagination from "./Pagination";

const AnalyticTable = () => {
  const analyticstoken = localStorage.getItem("analyticstoken");
  const [selectedDate, setSelectedDate] = useState(null);
  const [data, setdata] = useState([]);
  const [filter_date, setfilter_date] = useState("");
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, settotalItems] = useState(0);
  useEffect(() => {
    getData();
    //eslint-disable-next-line
  }, []);

  const getData = async () => {
    setdata([]);
    try {
      const response = await axios.post(
        `${config.backendUrl}/api/analytics/list`,
        {
          token: analyticstoken,
          page: currentPage,
          size: itemsPerPage,
          filter_date: filter_date,
        }
      );
      console.log(response.data);
      if (response.data.status === 1) {
        setdata(response.data.data.list);
        settotalItems(response.data.data.total_items);
      } else {
        setdata([]);
      }
    } catch (error) {
      setdata([]);
      alert(error.response.data);
    }
  };
  const changeDate = (date) => {
    setSelectedDate(date);
    const demodate = dayjs(date).format("YYYY-MM-DD");
    setfilter_date(demodate);
  };
  useEffect(() => {
    if (filter_date) {
      getData();
    }
  }, [filter_date]);
  function fancyTimeFormat(duration) {
    // Hours, minutes and seconds
    const hrs = ~~(duration / 3600);
    const mins = ~~((duration % 3600) / 60);
    const secs = ~~duration % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    let ret = "";

    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;

    return ret;
  }
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    getData();
  }, [currentPage]);
  return (
    <div className="container analytics-list">
      <div className="row">
        <div className="col-12 pt-5">
          <h1 className="heading-analytics">Analytics Records</h1>
        </div>
        <div className="col-12 pt-4">
          <label className="filterdate">
            Filter with date
            <DatePicker
              selected={selectedDate}
              onChange={(date) => changeDate(date)}
              dateFormat="MM/dd/yyyy"
              showYearDropdown
              showMonthDropdown
              dropdownMode="select"
              placeholderText="Filter with date"
            />
          </label>
        </div>
        <div className="col-12 pt-4">
          <div className="analytics-table-card">
            <table className="analytics-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Page</th>
                  <th>Duration</th>
                  <th>IP Address</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {data.length == 0 && (
                  <tr>
                    <td colSpan={5}>No records found.</td>
                  </tr>
                )}
                {data.map((item, key) => (
                  <tr key={key}>
                    <td>{item.user}</td>
                    <td>{item.page}</td>
                    <td>{fancyTimeFormat(item.duration)}</td>
                    <td>
                      {" "}
                      {item.ip_address && item.ip_address}{" "}
                      {!item.ip_address && "N/A"}
                    </td>
                    <td>
                      {" "}
                      {dayjs(item.createdAt).format("MM-DD-YYYY HH:mm:ss")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-12 pt-5">
          <Pagination
            totalItems={totalItems}
            itemsPerPage={itemsPerPage}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default AnalyticTable;
