import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import "../styles/Portfolio.css";
import { AuthContext } from '../context/AuthContext';
import {
  fetchAllTransactionsGeneral,
  fetchUserPortfolio,
  fetchUserCashBalance,
  getChangeColor,
  getChangePercentageColor,
  filters,
  getUnixTimestampRange,
  getUnixTimestamp,
  calculateReturns,
  fetchCompanyDetails,
  portfolioIntervalMap,
  isOption,
  handleNavigateToAsset,
  isCrypto,
  getTypeOfQuantity,
  getOneYearAgo,
  getThreeMonthsAgo,
  getESTDate,
} from '../helper/MarketsHelper';
import { LoadingSpinner, formatCurrency as fc, formatModifiedTicker } from '../helper/StylesHelper';
import config from '../config';
import { createPortfolioChart } from '../helper/PortfolioChartHelper';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { ThemeContext } from '../context/ThemeContext';
import useResponsiveWidth from '../hooks/useResponsiveWidth';
import { shareTrade } from '../helper/CreatePostHelper';
import BackButton from './BackButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { timeOptions, sortOptionsTrades, tradeTypeOptions, buySellOptions } from '../helper/FeedHelper';
import { customStylesPlusTrans, customStylesTrans } from '../helper/StylesHelper';
import Select from 'react-select';
import useClickOutside from '../hooks/useClickOutside';

export default function Portfolio({ inProfile = false }) {
  useResponsiveWidth();
  const [transactions, setTransactions] = useState([]);
  const [cashBalance, setCashBalance] = useState(null);
  const [assetsValue, setAssetsValue] = useState(null);

  const [allPortfolios, setAllPortfolios] = useState([]);
  const [isCreatingPortfolio, setIsCreatingPortfolio] = useState(false);
  const [newPortfolioName, setNewPortfolioName] = useState('');

  const [selectedPortfolio, setSelectedPortfolio] = useState(null);
  const [transactionsLoading, setTransactionsLoading] = useState(true);
  const [filter, setFilter] = useState('1d');
  const [livePriceMap, setLivePriceMap] = useState(null);
  const [prevClosePriceMap, setPrevClosePriceMap] = useState({});
  const [quantityMap, setQuantityMap] = useState(null);
  const wsRef = useRef(null);
  const navigate = useNavigate();

  const [referenceValue, setReferenceValue] = useState(null);

  const { userToken, isAuthenticated, username: currentUsername } = useContext(AuthContext);
  const { tc, theme } = useContext(ThemeContext);
  const { username } = useParams();
  const location = useLocation();

  const [portfolioData, setPortfolioData] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  const [hoveredPrice, setHoveredPrice] = useState(null);
  const [hoveredChangeAmount, setHoveredChangeAmount] = useState(null);
  const [hoveredChangePercent, setHoveredChangePercent] = useState(null);
  const [hoveredDateTime, setHoveredDateTime] = useState(null);
  const [isHovering, setIsHovering] = useState(false);
  const [tickerCompanyMap, setTickerCompanyMap] = useState({});
  const [balanceLoading, setBalanceLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(true);
  const [showAddCash, setShowAddCash] = useState(false);
  const [cashToAdd, setCashToAdd] = useState('');

  const [portfolioStats, setPortfolioStats] = useState({});
  const [portfolioChangeAmount, setPortfolioChangeAmount] = useState(null);
  const [portfolioChangePercentage, setPortfolioChangePercentage] = useState(null);

  // Filtering trades in the "Transaction history"
  const [selectedTimeTrade, setSelectedTimeTrade] = useState('All time');
  const [selectedSortTrade, setSelectedSortTrade] = useState('Best trades');
  const [selectedTradeType, setSelectedTradeType] = useState(null);
  const [selectedBuySell, setSelectedBuySell] = useState('Buy+Sell');
  const [searchQuery, setSearchQuery] = useState('');

  // Sorting states for holdings table
  const [sortColumn, setSortColumn] = useState(null); 
  const [sortDirection, setSortDirection] = useState('asc'); 

  // Toggle columns in profile view
  const [showSecondColumnSet, setShowSecondColumnSet] = useState(false);

  // Portfolio settings modal states
  const [showPortfolioSettingsModal, setShowPortfolioSettingsModal] = useState(false);
  const [portfolioName, setPortfolioName] = useState('');
  const [mainPortfolioId, setMainPortfolioId] = useState(null);
  const [confirmText, setConfirmText] = useState('');
  const [deleteConfirmText, setDeleteConfirmText] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [portfolioPrivacy, setPortfolioPrivacy] = useState(null);

  // ============= NEW: Tab for "Order history" vs "Transaction history" =============
  const [activeTab, setActiveTab] = useState('transactions'); // 'transactions' or 'orders'
const [orderTimePeriod, setOrderTimePeriod] = useState('All time');

  // ============= NEW: Limit orders data & filters/pagination =============
  const [limitOrders, setLimitOrders] = useState([]);
  const [orderLoading, setOrderLoading] = useState(true);
  const [orderFilters, setOrderFilters] = useState({
    ticker: '',
    assetType: '',
    type: '',
    status: '',
  });
  const [orderPage, setOrderPage] = useState(1);
  const [orderLimit] = useState(9);
  const [orderHasMore, setOrderHasMore] = useState(false);

  // ======================================================================

  // Fetch all user portfolios
  const fetchAllPortfolios = async () => {
    try {
      const response = await axios.get(`${config.backendUrl}/api/portfolios`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        params: { username },
      });

      setAllPortfolios(response.data);

      if (response.data && response.data.length > 0) {
        const mainPortfolio = response.data.find((pf) => pf.isMainPortfolio);
        setMainPortfolioId(mainPortfolio?._id);
        if (!selectedPortfolio) {
          setSelectedPortfolio(mainPortfolio || response.data[0]);
        }
      }
    } catch (error) {
      console.error('Error fetching portfolios:', error);
    }
  };

  useEffect(() => {
    fetchAllPortfolios();
    // eslint-disable-next-line
  }, [username, userToken, isAuthenticated]);

  // Create a new portfolio
  const createNewPortfolio = async () => {
    try {
      const response = await axios.post(
        `${config.backendUrl}/api/portfolios`,
        { name: newPortfolioName },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      setAllPortfolios((prevPortfolios) => [...prevPortfolios, response.data]);
    } catch (error) {
      alert(
        error.response?.data?.message ||
          'An error occurred while creating the portfolio.'
      );
      console.error('Error creating portfolio:', error);
    }
  };

  // Once a specific portfolio is selected, fetch its transactions, balance, etc.
  useEffect(() => {
    if (!selectedPortfolio || !selectedPortfolio._id) return;
    fetchUserPortfolio(isAuthenticated, userToken, setSelectedPortfolio, selectedPortfolio._id);
    fetchAllTransactionsGeneral(isAuthenticated, userToken, setTransactions, selectedPortfolio._id)
      .then(() => setTransactionsLoading(false));
    fetchUserCashBalance(isAuthenticated, userToken, setCashBalance, selectedPortfolio._id);
    // eslint-disable-next-line
  }, [selectedPortfolio?._id, userToken, isAuthenticated]);

  // Fetch company details for each ticker
  useEffect(() => {
    if (!selectedPortfolio || !selectedPortfolio.assets) return;
    const tickerArray = selectedPortfolio.assets.map((stock) => stock.ticker);

    const fetchAllCompanyDetails = async () => {
      try {
        const companyDetailsPromises = tickerArray.map((ticker) =>
          fetchCompanyDetails(ticker)
        );
        const allCompanyDetails = await Promise.all(companyDetailsPromises);
        const tickerCompanyDetailsMap = allCompanyDetails.reduce((acc, info, idx) => {
          const t = tickerArray[idx];
          acc[t] = info;
          return acc;
        }, {});
        setTickerCompanyMap(tickerCompanyDetailsMap);
      } catch (error) {
        console.error('Error fetching company details for tickers:', error);
      }
    };
    fetchAllCompanyDetails();
  }, [selectedPortfolio]);

  // Calculate portfolio stats from transactions & live prices
  useEffect(() => {
    if (
      !selectedPortfolio ||
      !selectedPortfolio.assets ||
      !livePriceMap ||
      !tickerCompanyMap ||
      !transactions
    ) {
      return;
    }

    const buyMap = {};
    const sellMap = {};
    const buyVolumeMap = {};
    const sellVolumeMap = {};

    transactions.forEach((transaction) => {
      const { ticker, type, assetType } = transaction;
      const factor = assetType === 'option' ? 100 : 1;
      if (type === 'buy') {
        buyMap[ticker] = (buyMap[ticker] || 0) + 1;
        buyVolumeMap[ticker] =
          (buyVolumeMap[ticker] || 0) +
          transaction.quantity * transaction.price * factor;
      }
      if (type === 'sell') {
        sellMap[ticker] = (sellMap[ticker] || 0) + 1;
        sellVolumeMap[ticker] =
          (sellVolumeMap[ticker] || 0) +
          transaction.quantity * transaction.price * factor;
      }
    });

    const statsTemp = {};
    selectedPortfolio.assets.forEach((stock) => {
      const t = stock.ticker.toUpperCase();
      const livePrice = livePriceMap[t];
      const txForTicker = transactions.filter((tx) => tx.ticker === t);
      const { unrealizedDollar, unrealizedPercentage, realizedDollar, realizedPercentage } =
        calculateReturns(stock, livePrice, txForTicker);

      statsTemp[t] = {
        totalInvested: stock.quantity * stock.avgBuyPrice,
        marketValue: livePrice * stock.quantity,
        unrealizedDollar,
        unrealizedPercentage,
        realizedDollar,
        realizedPercentage,
        buyCount: buyMap[t] || 0,
        sellCount: sellMap[t] || 0,
        buyVolumeMap: buyVolumeMap[t] || 0,
        sellVolumeMap: sellVolumeMap[t] || 0,
      };
    });

    // Summaries
    let totalInvested = 0,
      marketValue = 0,
      totalRealizedDollar = 0,
      totalUnrealizedDollar = 0;

    Object.keys(statsTemp).forEach((t) => {
      totalInvested += statsTemp[t].totalInvested;
      marketValue += statsTemp[t].marketValue;
      totalRealizedDollar += statsTemp[t].realizedDollar;
      totalUnrealizedDollar += statsTemp[t].unrealizedDollar;
    });

    const initialCash = 100000;
    const cashDeposited = selectedPortfolio?.cashDeposited || 0;
    const totalCash = initialCash + cashDeposited;

    const totalUnrealizedPercentage = totalCash
      ? (totalUnrealizedDollar / totalCash) * 100
      : 0;
    const totalRealizedPercentage = totalCash
      ? (totalRealizedDollar / totalCash) * 100
      : 0;

    let totalBuys = 0,
      totalSells = 0,
      totalBuyVolume = 0,
      totalSellVolume = 0;

    Object.keys(statsTemp).forEach((t) => {
      totalBuys += statsTemp[t].buyCount;
      totalSells += statsTemp[t].sellCount;
      totalBuyVolume += statsTemp[t].buyVolumeMap;
      totalSellVolume += statsTemp[t].sellVolumeMap;
    });

    setPortfolioStats({
      totalInvested,
      marketValue,
      totalUnrealizedPercentage,
      totalUnrealizedDollar,
      totalRealizedDollar,
      totalRealizedPercentage,
      totalBuys,
      totalSells,
      totalBuyVolume,
      totalSellVolume,
      ...statsTemp,
    });
  }, [selectedPortfolio, livePriceMap, transactions, tickerCompanyMap]);

  // Once stats & company details are in, we can show the table
  useEffect(() => {
    if (portfolioStats && tickerCompanyMap) {
      setTableLoading(false);
    }
  }, [portfolioStats, tickerCompanyMap]);

  // Add cash
  const handleConfirmAddCash = async () => {
    try {
      const amount = parseFloat(cashToAdd);
      if (isNaN(amount) || amount <= 0) {
        alert('Please enter a valid amount greater than 0.');
        return;
      }

      const response = await axios.post(
        `${config.backendUrl}/api/stockActions/addCash`,
        { amount, portfolioId: selectedPortfolio._id },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      setCashBalance(response.data.cash);
      setShowAddCash(false);
      setCashToAdd('');

      alert(response.data.message);
      // Refresh
      fetchAllTransactionsGeneral(isAuthenticated, userToken, setTransactions, selectedPortfolio._id);
      fetchUserPortfolio(isAuthenticated, userToken, setSelectedPortfolio, selectedPortfolio._id);
    } catch (error) {
      console.error('Error adding cash:', error);
      alert(error.response?.data?.message || 'An error occurred while adding cash.');
    }
  };

  const handleCancelAddCash = () => {
    setShowAddCash(false);
    setCashToAdd('');
  };

  // Fetch chart data
  const fetchPortfolioDataPoints = async () => {
    if (!selectedPortfolio || !selectedPortfolio._id) return;
    setDataFetched(false);
    try {
      let fromDateUnixMs;
      let toDateUnixMs = getUnixTimestamp();
      fromDateUnixMs = getUnixTimestampRange(filter);

      if (filter === '1y') {
        toDateUnixMs = getESTDate();
        fromDateUnixMs = getOneYearAgo();
      }
      if (filter === 'all time') {
        fromDateUnixMs = '2024-08-01';
        toDateUnixMs = getESTDate();
      }

      const intervalArray = portfolioIntervalMap[filter];
      const multiplier = intervalArray[0];
      const timespan = intervalArray[1];

      const response = await axios.get(
        `${config.backendUrl}/api/markets/portfolio/datapoints/${username}/${selectedPortfolio._id}`,
        {
          params: {
            multiplier,
            timespan,
            fromDateUnixMs,
            toDateUnixMs,
            filter,
          },
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      setReferenceValue(response.data[0]?.portfolioValue);
      setPortfolioData(response.data);
    } catch (error) {
      setPortfolioData([]);
      setPortfolioChangeAmount('--');
      setPortfolioChangePercentage('--');
      console.error('Error fetching portfolio data points:', error);
    }
    setDataFetched(true);
  };

  useEffect(() => {
    if (userToken && selectedPortfolio && selectedPortfolio._id) {
      fetchPortfolioDataPoints();
    }
    // eslint-disable-next-line
  }, [userToken, filter, cashBalance, selectedPortfolio?._id, isAuthenticated]);

  useEffect(() => {
    if (!chartRef.current || !dataFetched || !portfolioData.length) return;
    const referenceValueForChart = portfolioData[0]?.portfolioValue;
    setReferenceValue(referenceValueForChart);
    const cleanup = createPortfolioChart({
      chartRef,
      chartInstanceRef: chartInstance,
      portfolioData,
      referenceValueForChart,
      setHoveredPrice,
      setHoveredChangeAmount,
      setHoveredChangePercent,
      setHoveredDateTime,
      setIsHovering,
      theme,
    });
    return cleanup;
  }, [portfolioData, dataFetched, filter, theme, cashBalance]);

  // WebSocket for live price streaming
  useEffect(() => {
    if (!selectedPortfolio || !selectedPortfolio.assets) return;
    const ws = new WebSocket(config.socketUrl);

    ws.onopen = () => {
      selectedPortfolio.assets.forEach((stock) => {
        const ticker = stock.ticker.toUpperCase();
        ws.send(JSON.stringify({ action: 'subscribe', ticker }));
      });
    };

    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.type === 'instantPrice') {
        message.data.forEach((d) => {
          if (d.ev === 'T') {
            const ticker = d.sym;
            let livePrice = parseFloat(d.p);
            if (isOption(ticker)) {
              livePrice *= 100;
            }
            setLivePriceMap((prev) => ({ ...prev, [ticker]: livePrice }));
          }
        });
      }
    };

    ws.onclose = () => {
      console.log('WebSocket connection closed');
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    wsRef.current = ws;
    return () => {
      if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
        selectedPortfolio.assets.forEach((stock) => {
          const ticker = stock.ticker.toUpperCase();
          wsRef.current.send(JSON.stringify({ action: 'unsubscribe', ticker }));
        });
        wsRef.current.close();
      }
    };
  }, [selectedPortfolio]);

  // Periodic "getInstantPrice" requests
  useEffect(() => {
    if (!selectedPortfolio || !wsRef.current) return;
    const tickers = selectedPortfolio.assets.map((s) => s.ticker.toUpperCase());
    const fetchPrice = () => {
      if (wsRef.current.readyState === WebSocket.OPEN) {
        tickers.forEach((ticker) => {
          wsRef.current.send(JSON.stringify({ action: 'getInstantPrice', ticker }));
        });
      }
    };
    const intervalId = setInterval(fetchPrice, 1000);
    return () => clearInterval(intervalId);
  }, [selectedPortfolio]);

  // Fetch current prices once
  useEffect(() => {
    if (!selectedPortfolio || !selectedPortfolio.assets) return;
    const fetchCurrentPrices = async () => {
      try {
        const pricePromises = selectedPortfolio.assets.map((stock) => {
          const ticker = stock.ticker.toUpperCase();
          return axios
            .get(`${config.backendUrl}/api/markets/dailychange?ticker=${ticker}`)
            .then((res) => {
              let { currentPrice, prevClosePrice } = res.data;
              if (isOption(ticker)) {
                currentPrice *= 100;
                prevClosePrice *= 100;
              }
              return { ticker, currentPrice, prevClosePrice };
            })
            .catch((error) => {
              console.error(`Error fetching price for ${ticker}:`, error);
              return { ticker, currentPrice: '--', prevClosePrice: undefined };
            });
        });

        const prices = await Promise.all(pricePromises);
        const updatedPriceMap = {};
        const updatedPrevCloseMap = {};
        prices.forEach(({ ticker, currentPrice, prevClosePrice }) => {
          updatedPriceMap[ticker] = currentPrice;
          if (prevClosePrice !== undefined) {
            updatedPrevCloseMap[ticker] = prevClosePrice;
          }
        });
        setLivePriceMap((prevMap) => ({ ...prevMap, ...updatedPriceMap }));
        setPrevClosePriceMap((prevMap) => ({ ...prevMap, ...updatedPrevCloseMap }));
      } catch (error) {
        console.error('Error fetching current prices:', error);
      }
    };
    fetchCurrentPrices();
  }, [selectedPortfolio]);

  // Build quantity map
  useEffect(() => {
    if (!selectedPortfolio || !selectedPortfolio.assets) return;
    const qm = selectedPortfolio.assets.reduce((map, s) => {
      map[s.ticker.toUpperCase()] = s.quantity;
      return map;
    }, {});
    setQuantityMap(qm);
  }, [selectedPortfolio]);

  // Compute total assets value
  useEffect(() => {
    if (!livePriceMap || !quantityMap) return;
    const liveValueMapTemp = Object.keys(livePriceMap).reduce((map, t) => {
      const price = parseFloat(livePriceMap[t]);
      const qty = quantityMap[t];
      if (price && qty) {
        map[t] = price * qty;
      }
      return map;
    }, {});
    const total = Object.values(liveValueMapTemp).reduce((sum, v) => sum + v, 0);
    setAssetsValue(total);
  }, [livePriceMap, quantityMap]);

  useEffect(() => {
    if (cashBalance !== null && assetsValue !== null) {
      setBalanceLoading(false);
    }
  }, [cashBalance, assetsValue]);

  // Calculate portfolio's displayed net change
  useEffect(() => {
    if (!dataFetched) {
      setPortfolioChangeAmount('--');
      setPortfolioChangePercentage('--');
    } else if (
      typeof cashBalance === 'number' &&
      typeof assetsValue === 'number' &&
      typeof referenceValue === 'number' &&
      portfolioData.length > 0
    ) {
      const changeAmount = cashBalance + assetsValue - referenceValue;
      const changePercentage = (changeAmount / referenceValue) * 100;
      setPortfolioChangeAmount(changeAmount);
      setPortfolioChangePercentage(changePercentage);
    }
  }, [cashBalance, assetsValue, referenceValue, portfolioData, dataFetched]);

  // -------------------- TRANSACTIONS --------------------

  // Renders each trade card
  const renderTradeCard = (transaction) => {
    if (transactionsLoading || !livePriceMap || !tickerCompanyMap) {
      return <LoadingSpinner key={transaction._id} />;
    }
    const { ticker, type, price, date, quantity, _id } = transaction;
    let displayPrice = price;
    if (isOption(ticker)) {
      displayPrice *= 100; // 1 contract = 100 shares
    }
    const formattedDate = new Date(date).toLocaleString();
    const livePriceVal = parseFloat(livePriceMap[ticker]);
    const liveChangeAmount =
      typeof livePriceVal === 'number' && typeof displayPrice === 'number'
        ? livePriceVal - displayPrice
        : null;
    const liveChangePercentage =
      typeof livePriceVal === 'number' && typeof displayPrice === 'number'
        ? ((livePriceVal - displayPrice) / displayPrice) * 100
        : null;
    const isBuy = type === 'buy';
    const changePercentageToUse = liveChangePercentage || 0;

    return (
      <div
        className="PortTrade"
        key={_id}
        onClick={() => shareTrade(_id, navigate)}
        style={{
          backgroundColor: isBuy
            ? getChangeColor(changePercentageToUse, tc())
            : 'transparent',
          border:
            !isBuy &&
            `0.05rem solid ${getChangePercentageColor(-changePercentageToUse, tc())}`,
          width: inProfile && 'auto',
        }}
      >
        <div className="PortTradeHeader">
          <span className="PortTradeLabel">
            {`${type === 'buy' ? 'BUY' : 'SELL'} - ${formattedDate}`}
          </span>
          <div className="PortTradeCompanyHeader">
            <span className="PortTradeLabel">
              {formatModifiedTicker(ticker)}
            </span>
            <span className="PortTradeValue bold">
              {tickerCompanyMap[ticker]?.name}
            </span>
          </div>
          <div
            className="PortTradeTable"
            style={{
              width: inProfile && '100%',
              gridTemplateColumns:
                inProfile &&
                'fit-content(100%) fit-content(100%) 8rem fit-content(100%) fit-content(100%)',
            }}
          >
            <div className="PortTradeTableRow">
              <span className="PortTradeLabel">Price:</span>
              <span className="PortTradeValue bold">${fc(price)}</span>
              <span></span>
              <span className="PortTradeLabel">
                Since {isBuy ? 'buy' : 'sale'}:
              </span>
              <span
                style={{
                  color: getChangePercentageColor(liveChangeAmount, tc()),
                }}
                className="PortTradeValue bold"
              >
                {liveChangeAmount >= 0 && '+'}
                {fc(liveChangeAmount * quantity)}
              </span>
            </div>
            <div className="PortTradeTableRow">
              <span className="PortTradeLabel">
                {getTypeOfQuantity(ticker)}:{' '}
              </span>
              <span className="PortTradeValue bold">
                {fc(quantity, isCrypto(ticker) ? 4 : 2)}
              </span>
              <span></span>
              <span className="PortTradeLabel">
                Since {isBuy ? 'buy' : 'sale'}:
              </span>
              <span
                style={{
                  color: getChangePercentageColor(liveChangeAmount, tc()),
                }}
                className="PortTradeValue bold"
              >
                {liveChangePercentage >= 0 && '+'}
                {fc(liveChangePercentage)}%
              </span>
            </div>
            <div className="PortTradeTableRow">
              <span className="PortTradeLabel">Total:</span>
              <span className="PortTradeValue bold">
                ${fc(displayPrice * quantity)}
              </span>
              <span></span>
              <span className="PortTradeLabel">Present Value:</span>
              <span className="PortTradeValue bold">
                ${fc(livePriceVal * quantity)}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Filters & sorts transactions
  const filterAndSortTransactions = (allTx) => {
    if (!allTx || allTx.length === 0 || !livePriceMap) return [];
    let filteredTx = allTx;

    // Search by ticker or company name
    if (searchQuery.trim()) {
      const q = searchQuery.toLowerCase();
      filteredTx = filteredTx.filter((tx) => {
        const tickerMatch = tx.ticker.toLowerCase().includes(q);
        const companyMatch = tickerCompanyMap[tx.ticker]?.name
          ?.toLowerCase()
          .includes(q);
        return tickerMatch || companyMatch;
      });
    }

    // Filter by asset type
    if (selectedTradeType) {
      filteredTx = filteredTx.filter((tx) => tx.assetType === selectedTradeType);
    }

    // Filter by buy/sell
    if (selectedBuySell === 'Buy') {
      filteredTx = filteredTx.filter((tx) => tx.type === 'buy');
    } else if (selectedBuySell === 'Sell') {
      filteredTx = filteredTx.filter((tx) => tx.type === 'sell');
    }

    // Filter by time range
    const now = new Date();
    if (selectedTimeTrade !== 'All time') {
      const timeMap = {
        Today: () => new Date().setHours(0, 0, 0, 0),
        '7 days': () => new Date().setDate(now.getDate() - 7),
        '1 month': () => new Date().setMonth(now.getMonth() - 1),
      };
      const fromDate = new Date(timeMap[selectedTimeTrade]());
      filteredTx = filteredTx.filter((tx) => new Date(tx.date) >= fromDate);
    }

    // Sorting
    switch (selectedSortTrade) {
      case 'Best trades':
        return filteredTx.sort((a, b) => {
          const aChg = livePriceMap[a.ticker] - a.price;
          const bChg = livePriceMap[b.ticker] - b.price;
          return bChg - aChg;
        });
      case 'Largest trades':
        return filteredTx.sort((a, b) => b.totalAmount - a.totalAmount);
      case 'Most recent':
        return filteredTx.sort((a, b) => new Date(b.date) - new Date(a.date));
      case 'Most copied':
        return filteredTx.sort(
          (a, b) => (b?.copyCount || 0) - (a?.copyCount || 0)
        );
      default:
        return filteredTx;
    }
  };

  const renderFilteredTransactions = () => {
    const filtered = filterAndSortTransactions(transactions);
    if (!filtered || filtered.length === 0) {
      return <span className="ov-trade-type bold ov-15">No trades</span>;
    }
    return filtered.map((tx) => renderTradeCard(tx));
  };

  // -------------------- HOLDINGS TABLE --------------------

  const calculateOneDayChange = (currentPrice, prevClosePrice) => {
    if (!currentPrice || !prevClosePrice || prevClosePrice === 0) return NaN;
    const change = ((currentPrice - prevClosePrice) / prevClosePrice) * 100;
    return isNaN(change) ? NaN : change;
  };

  const applySorting = (rowsData) => {
    if (!sortColumn) return rowsData;

    const compare = (a, b) => {
      let valA, valB;
      switch (sortColumn) {
        case 'ticker':
          valA = a.ticker;
          valB = b.ticker;
          return valA.localeCompare(valB);
        case 'avgPrice':
          valA = a.avgPrice;
          valB = b.avgPrice;
          return valA - valB;
        case 'currentPrice':
          valA = a.currentPrice;
          valB = b.currentPrice;
          return valA - valB;
        case 'units':
          valA = a.quantity;
          valB = b.quantity;
          return valA - valB;
        case 'marketValue':
          valA = a.marketValue;
          valB = b.marketValue;
          return valA - valB;
        case 'unrealizedReturn':
          valA = a.unrealizedDollar;
          valB = b.unrealizedDollar;
          return valA - valB;
        case 'realizedReturn':
          valA = a.realizedDollar;
          valB = b.realizedDollar;
          return valA - valB;
        case 'oneDayPercent':
          valA = a.oneDayChange;
          valB = b.oneDayChange;
          return valA - valB;
        case 'buysSells':
          valA = a.buyCount + a.sellCount;
          valB = b.buyCount + b.sellCount;
          return valA - valB;
        default:
          return 0;
      }
    };

    const sorted = [...rowsData].sort(compare);
    if (sortDirection === 'desc') sorted.reverse();
    return sorted;
  };

  const handleSort = (column) => {
    if (sortColumn === column) {
      // Toggle among asc, desc, none
      if (sortDirection === 'asc') {
        setSortDirection('desc');
      } else if (sortDirection === 'desc') {
        setSortColumn(null);
        setSortDirection('asc');
      }
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const renderTable = () => {
    if (!transactions || transactions.length === 0) {
      // If no transactions at all, might not have holdings. Hide or show a message if you like.
      return null;
    }
    if (tableLoading || !livePriceMap || !tickerCompanyMap) {
      return <LoadingSpinner />;
    }

    const keysToUse = Object.keys(portfolioStats).filter(
      (t) =>
        ![
          'totalInvested',
          'marketValue',
          'totalUnrealizedPercentage',
          'totalUnrealizedDollar',
          'totalRealizedDollar',
          'totalRealizedPercentage',
          'totalBuys',
          'totalSells',
          'totalBuyVolume',
          'totalSellVolume',
        ].includes(t)
    );

    const rowsData = keysToUse.map((ticker) => {
      const currentPrice = livePriceMap[ticker];
      const pClose = prevClosePriceMap[ticker];
      const qty = quantityMap[ticker];
      const oneDayChange = calculateOneDayChange(currentPrice, pClose);
      const avgPrice =
        selectedPortfolio.assets.find((s) => s.ticker === ticker)?.avgBuyPrice || 0;
      return {
        ticker,
        name: tickerCompanyMap[ticker]?.name || 'N/A',
        avgPrice,
        currentPrice,
        quantity: qty,
        marketValue: portfolioStats[ticker]?.marketValue || 0,
        unrealizedDollar: portfolioStats[ticker]?.unrealizedDollar || 0,
        unrealizedPercentage: portfolioStats[ticker]?.unrealizedPercentage || 0,
        realizedDollar: portfolioStats[ticker]?.realizedDollar || 0,
        realizedPercentage: portfolioStats[ticker]?.realizedPercentage || 0,
        oneDayChange,
        buyCount: portfolioStats[ticker]?.buyCount || 0,
        sellCount: portfolioStats[ticker]?.sellCount || 0,
      };
    });

    const sortedRows = applySorting(rowsData);
    const renderSortIcon = (column) => {
      if (sortColumn === column) {
        return sortDirection === 'asc' ? '▲' : '▼';
      }
      return '';
    };

    const showSet1 = !inProfile || !showSecondColumnSet;
    const showSet2 = !inProfile || showSecondColumnSet;

    return (
      <div className="port-stats-table">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h2 className="port-right-sidebar-header" style={{ margin: 0 }}>
            Holdings
          </h2>
          {inProfile && (
            <div className="mv2-pagination-arrows">
              <button
                className="mv2-pagination-button"
                onClick={() => setShowSecondColumnSet(false)}
                disabled={!showSecondColumnSet}
              >
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  style={{
                    fontSize: '2rem',
                    color: !showSecondColumnSet ? 'var(--border-color)' : 'var(--text-color)',
                  }}
                />
              </button>
              <button
                className="mv2-pagination-button"
                onClick={() => setShowSecondColumnSet(true)}
                disabled={showSecondColumnSet}
              >
                <FontAwesomeIcon
                  icon={faArrowRight}
                  style={{
                    fontSize: '2rem',
                    color: showSecondColumnSet ? 'var(--border-color)' : 'var(--text-color)',
                  }}
                />
              </button>
            </div>
          )}
        </div>
        <table className="stats-table">
          <thead>
            <tr className="port-stats-row">
              <th onClick={() => handleSort('ticker')}>
                Ticker/<br />
                Name {renderSortIcon('ticker')}
              </th>
              {showSet1 && (
                <>
                  <th onClick={() => handleSort('avgPrice')}>
                    Average Price {renderSortIcon('avgPrice')}
                  </th>
                  <th onClick={() => handleSort('currentPrice')}>
                    Current Price {renderSortIcon('currentPrice')}
                  </th>
                  <th onClick={() => handleSort('units')}>
                    Units held {renderSortIcon('units')}
                  </th>
                  <th onClick={() => handleSort('marketValue')}>
                    Market Value/<br />
                    % portfolio {renderSortIcon('marketValue')}
                  </th>
                </>
              )}
              {showSet2 && (
                <>
                  <th onClick={() => handleSort('unrealizedReturn')}>
                    Unrealized Return {renderSortIcon('unrealizedReturn')}
                  </th>
                  <th onClick={() => handleSort('realizedReturn')}>
                    Realized Return {renderSortIcon('realizedReturn')}
                  </th>
                  <th onClick={() => handleSort('oneDayPercent')}>
                    1d % {renderSortIcon('oneDayPercent')}
                  </th>
                  <th onClick={() => handleSort('buysSells')}>
                    Buys/<br />
                    Sells {renderSortIcon('buysSells')}
                  </th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {sortedRows.map((row) => {
              const ticker = row.ticker;
              const change1d = row.oneDayChange;
              const investPercentage =
                portfolioStats[ticker]?.marketValue && portfolioStats?.marketValue
                  ? fc(
                      (portfolioStats[ticker].marketValue / portfolioStats.marketValue) * 100
                    )
                  : '--';

              return (
                <tr
                  className="port-stats-row"
                  onClick={() => handleNavigateToAsset(navigate, ticker)}
                  key={ticker}
                >
                  <td className="bold">
                    {formatModifiedTicker(ticker)}
                    <br />
                    <span className="bold port-stats-company">{row.name}</span>
                  </td>
                  {showSet1 && (
                    <>
                      <td className="bold">${fc(row.avgPrice) || '--'}</td>
                      <td className="bold">${fc(row.currentPrice) || '--'}</td>
                      <td className="bold">{fc(row.quantity)}</td>
                      <td className="bold">
                        ${fc(row.marketValue) || '--'}
                        <br />
                        <span className="bold port-stats-company">{investPercentage}%</span>
                      </td>
                    </>
                  )}
                  {showSet2 && (
                    <>
                      <td
                        className={
                          row.unrealizedPercentage >= 0
                            ? 'port-positive bold'
                            : 'port-negative bold'
                        }
                      >
                        {row.unrealizedDollar >= 0 ? '+' : ''}
                        {fc(row.unrealizedDollar)}
                        <br />({fc(row.unrealizedPercentage, 2, true)}%)
                      </td>
                      <td
                        className={
                          row.realizedPercentage >= 0
                            ? 'port-positive bold'
                            : 'port-negative bold'
                        }
                      >
                        {row.realizedDollar >= 0 ? '+' : ''}
                        {fc(row.realizedDollar)}
                        <br />({fc(row.realizedPercentage, 2, true)}%)
                      </td>
                      <td
                        className="bold"
                        style={{ color: getChangePercentageColor(change1d, tc()) }}
                      >
                        {!isNaN(change1d) && change1d >= 0 && '+'}
                        {isNaN(change1d) ? '--' : `${fc(change1d, 2)}%`}
                      </td>
                      <td className="bold">
                        {row.buyCount !== undefined ? fc(row.buyCount, 0) : '--'} /{' '}
                        {row.sellCount !== undefined ? fc(row.sellCount, 0) : '--'}
                      </td>
                    </>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  // -------------------- PORTFOLIO STATS (SIDEBAR) --------------------

  const renderAccountStats = () => {
    const gridTemplateColumns = inProfile ? '1fr 1fr' : '1fr 1fr 1fr 1fr';
    const cashToInvestmentRatio = portfolioStats?.marketValue
      ? (cashBalance / portfolioStats.marketValue).toFixed(2)
      : '--';

    return (
      <div className="account-stats-container">
        <h2 className="port-right-sidebar-header" style={{ margin: '1rem 0' }}>
          Account Stats
        </h2>
        <div className="account-stats-grid" style={{ gridTemplateColumns }}>
          <div className="account-stat-row">
            <span>Cash</span>
            <span className="bold">
              {cashBalance !== null ? `$${fc(cashBalance)}` : '--'}
            </span>
          </div>
          <div className="account-stat-row">
            <span>Market Value</span>
            <span className="bold">
              {portfolioStats?.marketValue ? `$${fc(portfolioStats.marketValue)}` : '--'}
            </span>
          </div>
          <div className="account-stat-row">
            <span>Total Invested</span>
            <span className="bold">
              {portfolioStats?.totalInvested ? `$${fc(portfolioStats.totalInvested)}` : '--'}
            </span>
          </div>
          <div className="account-stat-row">
            <span>Cash-to-Investment Ratio</span>
            <span className="bold">{cashToInvestmentRatio}</span>
          </div>
          <div className="account-stat-row">
            <span>Unrealized Returns</span>
            <span
              style={{
                color:
                  portfolioStats?.totalUnrealizedPercentage >= 0
                    ? 'var(--stock-change-pos)'
                    : 'var(--stock-change-neg)',
              }}
              className="bold"
            >
              {portfolioStats?.totalUnrealizedDollar !== undefined ? (
                <>
                  {portfolioStats?.totalUnrealizedDollar >= 0 ? '+' : ''}
                  {fc(portfolioStats?.totalUnrealizedDollar)} (
                  {fc(portfolioStats?.totalUnrealizedPercentage, 2, true)}%)
                </>
              ) : (
                '--'
              )}
            </span>
          </div>
          <div className="account-stat-row">
            <span>Realized Returns</span>
            <span
              style={{
                color:
                  portfolioStats?.totalRealizedPercentage >= 0
                    ? 'var(--stock-change-pos)'
                    : 'var(--stock-change-neg)',
              }}
              className="bold"
            >
              {portfolioStats?.totalRealizedDollar !== undefined ? (
                <>
                  {portfolioStats?.totalRealizedDollar >= 0 ? '+' : ''}
                  {fc(portfolioStats?.totalRealizedDollar)} (
                  {fc(portfolioStats?.totalRealizedPercentage, 2, true)}%)
                </>
              ) : (
                '--'
              )}
            </span>
          </div>
          <div className="account-stat-row">
            <span>Trades</span>
            <span className="bold">
              {transactions?.length !== undefined ? transactions.length : '--'}
            </span>
          </div>
          <div className="account-stat-row">
            <span>Buys</span>
            <span className="bold">
              {portfolioStats?.totalBuys !== undefined ? fc(portfolioStats.totalBuys, 0) : '--'}
            </span>
          </div>
          <div className="account-stat-row">
            <span>Buy Volume</span>
            <span className="bold">
              {portfolioStats?.totalBuyVolume !== undefined
                ? `$${fc(portfolioStats.totalBuyVolume)}`
                : '--'}
            </span>
          </div>
          <div className="account-stat-row">
            <span>Sells</span>
            <span className="bold">
              {portfolioStats?.totalSells !== undefined ? fc(portfolioStats.totalSells, 0) : '--'}
            </span>
          </div>
          <div className="account-stat-row">
            <span>Sell Volume</span>
            <span className="bold">
              {portfolioStats?.totalSellVolume !== undefined
                ? `$${fc(portfolioStats.totalSellVolume)}`
                : '--'}
            </span>
          </div>
          <div className="account-stat-row">
            <span>Portfolio Created</span>
            <span className="bold">
              {selectedPortfolio?.createdAt
                ? new Date(selectedPortfolio.createdAt).toLocaleDateString()
                : '--'}
            </span>
          </div>
        </div>
      </div>
    );
  };

  // -------------------- PORTFOLIO BREAKDOWN (INDUSTRY) --------------------

  const renderPortfolioBreakdown = () => {
    const keysToUse = Object.keys(portfolioStats).filter(
      (t) =>
        ![
          'totalInvested',
          'marketValue',
          'totalUnrealizedPercentage',
          'totalUnrealizedDollar',
          'totalRealizedDollar',
          'totalRealizedPercentage',
          'totalBuys',
          'totalSells',
          'totalBuyVolume',
          'totalSellVolume',
        ].includes(t)
    );
    const totalValue = portfolioStats.marketValue || 0;
    if (totalValue === 0) return null;

    const industryMap = {};
    keysToUse.forEach((t) => {
      const industry = tickerCompanyMap[t]?.industry || 'Unknown';
      const mv = portfolioStats[t]?.marketValue || 0;
      if (!industryMap[industry]) {
        industryMap[industry] = 0;
      }
      industryMap[industry] += mv;
    });

    const industryEntries = Object.entries(industryMap).sort((a, b) => b[1] - a[1]);
    const predefinedColors = ['#00FF00', '#00D4FF', '#FFE500', '#FF00D4', '#FF4D00', '#0900FF'];
    const usedColors = new Set();

    const segments = industryEntries.map(([industry, value], idx) => {
      const percentage = (value / totalValue) * 100;
      let color = predefinedColors[idx % predefinedColors.length];
      if (usedColors.has(color)) {
        color = `#${Math.floor(Math.random() * 16777215)
          .toString(16)
          .padStart(6, '0')}`;
      }
      usedColors.add(color);
      return { industry, value, percentage, color };
    });

    return (
      <div className="portfolio-breakdown-container">
        <h2 className="port-right-sidebar-header">Portfolio Breakdown</h2>
        <div className="portfolio-breakdown-bar">
          {segments.map((seg, i) => (
            <div
              key={i}
              className="portfolio-breakdown-segment"
              style={{
                width: `${seg.percentage}%`,
                backgroundColor: seg.color,
              }}
            />
          ))}
        </div>
        <div className="portfolio-breakdown-legend">
          {segments.map((seg, i) => (
            <div key={i} className="portfolio-breakdown-legend-item">
              <span
                className="portfolio-breakdown-legend-color"
                style={{ backgroundColor: seg.color }}
              />
              <span className="portfolio-breakdown-legend-text">
                {seg.industry} ({fc(seg.percentage, 2, true)}%)
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  // -------------------- TOP PORTFOLIO HEADER & CHART --------------------

  const renderPortfolio = () => {
    if (!selectedPortfolio || balanceLoading || portfolioChangeAmount === null || portfolioChangePercentage === null) {
      return <LoadingSpinner />;
    }
    const displayChangeAmount = isHovering ? parseFloat(hoveredChangeAmount) : portfolioChangeAmount;
    const displayChangePercentage = isHovering ? parseFloat(hoveredChangePercent) : portfolioChangePercentage;
    const displayValue =
      dataFetched && typeof cashBalance === 'number' && typeof assetsValue === 'number'
        ? `$${fc(cashBalance + assetsValue)}`
        : '--';

    return (
      <div className="port-header-wrapper">
        <div className="port-cards-wrapper">
          {allPortfolios.map((pf, i) => (
            <div
              key={i}
              onClick={() => setSelectedPortfolio(pf)}
              className={`port-card bold ${
                selectedPortfolio?._id === pf?._id ? 'port-card-selected' : ''
              }`}
            >
              <span className="bold">
                {pf.name}{' '}
                {pf.isMainPortfolio && (
                  <span
                    className={`port-card-main-span ${
                      selectedPortfolio?._id === pf?._id
                        ? 'port-card-main-span-selected'
                        : ''
                    }`}
                  >
                    Main
                  </span>
                )}
              </span>
            </div>
          ))}
          {currentUsername === username &&
            (isCreatingPortfolio ? (
              <div className="port-card-edit-wrapper">
                <div className="port-card-edit">
                  <input
                    type="text"
                    value={newPortfolioName}
                    onChange={(e) => setNewPortfolioName(e.target.value)}
                    placeholder="Portfolio Name"
                    className="AddCashInput"
                  />
                  <button
                    className="CreatePostFeedButton"
                    onClick={async () => {
                      if (!newPortfolioName.trim()) {
                        alert('Please enter a portfolio name');
                        return;
                      }
                      await createNewPortfolio(newPortfolioName);
                      setIsCreatingPortfolio(false);
                      setNewPortfolioName('');
                      fetchAllPortfolios();
                    }}
                  >
                    Save
                  </button>
                  <button
                    className="CreatePostFeedButton"
                    onClick={() => {
                      setIsCreatingPortfolio(false);
                      setNewPortfolioName('');
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div
                className="port-card bold"
                onClick={() => setIsCreatingPortfolio(true)}
              >
                New portfolio +
              </div>
            ))}
        </div>
        <span className="port-selected-name bold">{selectedPortfolio?.name}</span>
        <span className="port-value bold">
          {isHovering ? `$${fc(hoveredPrice)}` : displayValue}
        </span>
        <span
          className={`StockChange bold ${
            !isNaN(displayChangeAmount) && displayChangeAmount >= 0
              ? 'port-positive'
              : 'port-negative'
          }`}
          style={{ textAlign: 'left' }}
        >
          {!isNaN(displayChangeAmount) ? (
            <>
              {displayChangeAmount >= 0 ? '+' : ''}
              {fc(displayChangeAmount)} ({fc(displayChangePercentage, 2, true)}%)
            </>
          ) : (
            <span style={{ color: 'var(--action-grey)' }}>--</span>
          )}
          <span className="StockDuration">
            {' '}
            {isHovering
              ? hoveredDateTime
              : filters[filter] === 'live'
              ? ' live'
              : filters[filter] === 'all time'
              ? ' all time'
              : ` past ${filters[filter]}`}
          </span>
        </span>
      </div>
    );
  };

  // -------------------- PORTFOLIO SETTINGS MODAL --------------------

  useEffect(() => {
    if (selectedPortfolio) {
      setPortfolioName(selectedPortfolio.name || '');
      setPortfolioPrivacy(selectedPortfolio?.privacy);
      setConfirmText('');
      setDeleteConfirmText('');
    }
  }, [selectedPortfolio]);

  const handleSaveChanges = async () => {
    setIsSaving(true);
    try {
      const requestBody = {
        portfolioId: selectedPortfolio._id,
        newName: portfolioName !== selectedPortfolio.name ? portfolioName : null,
        confirmText: confirmText,
        deleteText: deleteConfirmText,
        mainPortfolioId: mainPortfolioId,
        privacy: portfolioPrivacy,
      };

      const response = await axios.put(
        `${config.backendUrl}/api/portfolios/edit-portfolio`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (requestBody.deleteText) {
        alert('Portfolio deleted successfully.');
        fetchAllPortfolios();
        setShowPortfolioSettingsModal(false);
      } else {
        alert(response.data.message || 'Portfolio updated successfully.');
        fetchAllPortfolios();
        setShowPortfolioSettingsModal(false);
      }
    } catch (error) {
      console.error('Error saving portfolio changes:', error);
      alert(
        error.response?.data?.message || 'An error occurred while saving changes.'
      );
    }
    setIsSaving(false);
  };

  const settingsRef = useClickOutside(
    () => setShowPortfolioSettingsModal(false),
    showPortfolioSettingsModal
  );

  const renderPortfolioSettingsModal = () => {
    if (!showPortfolioSettingsModal) return null;
    return (
      <div className="port-settings-modal" ref={settingsRef}>
        <h2 className="FeedPostHeaderH2">Edit portfolio</h2>
        <div className="PortInputContainer">
          <div className="PortSectionRow">
            <div className="PortSection" style={{ flex: 1 }}>
              <label className="FeedLabel">Name</label>
              <p className="FeedPortfolioSpan">Something memorable.</p>
              <input
                type="text"
                style={{ backgroundColor: 'transparent' }}
                className="FeedInputField"
                placeholder="Something memorable."
                value={portfolioName}
                onChange={(e) => setPortfolioName(e.target.value)}
              />
            </div>
            <div className="PortSection" style={{ flex: 1 }}>
              <label className="FeedLabel">Privacy</label>
              <p className="FeedPortfolioSpan">
                Private portfolios are only visible to you.
              </p>
              <div className="Feed-toggle-container" style={{ width: '100%' }}>
                {[
                  { label: 'Public', value: 'public' },
                  { label: 'Followers', value: 'followers' },
                  { label: 'Friends', value: 'friends' },
                  { label: 'Private', value: 'private' },
                ].map((item) => (
                  <button
                    key={item.label}
                    className={`Feed-toggle-button bold ${
                      portfolioPrivacy === item.value ? 'Feed-active' : ''
                    }`}
                    onClick={() => setPortfolioPrivacy(item.value)}
                  >
                    {item.label}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="PortSection">
          <h3 className="PortSectionHeader">Main portfolio (used for leaderboards)</h3>
          <p className="FeedPortfolioSpan">
            The main portfolio is used for leaderboard placement and the privacy
            must be set to public. Main portfolios can only be changed once every
            90 days.
          </p>
          <div
            className="Feed-toggle-container"
            style={{ width: '100%', margin: '1rem 0' }}
          >
            {allPortfolios.map((pf) => (
              <button
                key={pf._id}
                className={`Feed-toggle-button bold ${
                  mainPortfolioId === pf._id ? 'Feed-active' : ''
                }`}
                onClick={() => setMainPortfolioId(pf._id)}
              >
                {pf.name}{' '}
                {pf.isMainPortfolio && (
                  <span
                    className={`port-card-main-span ${
                      mainPortfolioId === pf?._id
                        ? 'port-card-main-span-selected'
                        : ''
                    }`}
                  >
                    Main
                  </span>
                )}
              </button>
            ))}
          </div>
          <label className="FeedLabel" style={{ flex: 'none' }}>
            Type 'CONFIRM' then click save changes to change your main portfolio.
          </label>
          <input
            type="text"
            className="FeedInputField"
            style={{ backgroundColor: 'transparent' }}
            placeholder="CONFIRM"
            value={confirmText}
            onChange={(e) => setConfirmText(e.target.value)}
          />
        </div>
        <div className="PortSection">
          <h3 className="PortSectionHeader">Delete portfolio</h3>
          <p className="FeedPortfolioSpan">
            Type 'BYEBYE' then click save changes to permanently delete this portfolio.
          </p>
          <div
            className="PortInputCol"
            style={{ borderBottom: 'none', paddingBottom: '0' }}
          >
            <input
              type="text"
              className="FeedInputField"
              style={{ backgroundColor: 'transparent' }}
              placeholder="BYEBYE"
              value={deleteConfirmText}
              onChange={(e) => setDeleteConfirmText(e.target.value)}
            />
          </div>
        </div>
        <div
          className="Feed-toggle-container"
          style={{ gap: '1rem', border: 'none' }}
        >
          <button
            onClick={handleSaveChanges}
            className="Feed-toggle-button bold Feed-active"
          >
            Save Changes
          </button>
          <button
            className="Feed-toggle-button bold"
            onClick={() => setShowPortfolioSettingsModal(false)}
            style={{
              backgroundColor: 'var(--background-color)',
              borderRadius: '5rem',
              color: 'var(--text-color)',
            }}
          >
            Close
          </button>
        </div>
        <p>
          <span className="FeedPortfolioSpan">
            • Privacy must be set to public for the main portfolio.
          </span>
        </p>
      </div>
    );
  };

  // -------------------- NEW: ORDER HISTORY TAB & CARDS --------------------

  // Whenever we switch to the "orders" tab or change filter/page, fetch limit orders
  useEffect(() => {
    if (activeTab !== 'orders') return;
    if (!selectedPortfolio?._id) return;

    const fetchLimitOrders = async () => {
      setOrderLoading(true);
      try {
        const { ticker, assetType, type, status } = orderFilters;
        const response = await axios.get(`${config.backendUrl}/api/limitOrders`, {
          headers: { Authorization: `Bearer ${userToken}` },
          params: {
            portfolioId: selectedPortfolio._id,
            ticker: ticker || undefined,
            assetType: assetType || undefined,
            type: type || undefined,
            status: status || undefined,
            timePeriod: orderTimePeriod, 
            page: orderPage,
            limit: orderLimit,
          },
        });
        if (orderPage === 1) {
          setLimitOrders(response.data.orders);
        } else {
          setLimitOrders((prev) => [...prev, ...response.data.orders]);
        }
        setOrderHasMore(response.data.hasMore);
      } catch (error) {
        console.error('Error fetching limit orders:', error);
      }
      setOrderLoading(false);
    };

    fetchLimitOrders();
  }, [activeTab, selectedPortfolio, userToken, orderPage, orderFilters, orderLimit]);

  // If user changes filters, reset the page to 1
  const handleOrderFilterChange = (field, value) => {
    setOrderFilters((prev) => ({ ...prev, [field]: value }));
    setOrderPage(1);
  };

  // Toggle open ↔ cancelled
  const handleToggleOrderStatus = async (order) => {
    const newStatus = order.status === 'open' ? 'cancelled' : 'open';
    try {
      await axios.patch(
        `${config.backendUrl}/api/limitOrders/${order._id}`,
        { status: newStatus },
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      );
      // Update local state
      const updated = limitOrders.map((o) =>
        o._id === order._id ? { ...o, status: newStatus } : o
      );
      setLimitOrders(updated);
    } catch (err) {
      console.error(err);
      alert(err.response?.data?.message || 'Error updating order status');
    }
  };

  const renderOrderCard = (order) => {
    const {
      _id,
      ticker,
      assetType,
      type,
      limitPrice,
      quantity,
      status,
      createdAt,
      expiresAt,
      executedAt,
    } = order;
    const isBuy = type === 'buy';
    const createdDate = new Date(createdAt).toLocaleString();
    const expiresDate = new Date(expiresAt).toLocaleString();
    const executedDate = executedAt ? new Date(executedAt).toLocaleString() : 'N/A';
    return (
      <div
        className="PortTrade"
        key={_id}
        style={{
          backgroundColor: isBuy ? 'var(--background-color)' : 'transparent',
          border: '0.05rem solid var(--border-color)',
          width:  'auto',
          height: 'auto',

        }}
      >
        <div className="PortTradeHeader">
          <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'flex-start' }}>
          <span className="PortTradeLabel">
            {`Limit ${type.toUpperCase()} ${formatModifiedTicker(ticker)}`}
          </span>


              {/* If not executed, show Cancel/Reopen button */}
        {status !== 'executed' && currentUsername === username && (
          <div >
            {status === 'open' ? (
              <div
                className='PortCancelOrder'
                style={{border: 'var(--border)'}}
                onClick={() => handleToggleOrderStatus(order)}
              >
                Cancel Order
              </div>
            ) : status === 'cancelled' ? (
              <div
                style={{border: 'var(--border)'}}
                className='PortCancelOrder'
                onClick={() => handleToggleOrderStatus(order)}
              >
                Reopen Order
              </div>
            ) : null}
          </div>
        )}
          </div>
       
          
          {/* Second line: Ticker + (company name if it exists) */}
          <div className="PortTradeCompanyHeader">
            <span className="PortTradeLabel">{formatModifiedTicker(ticker)}</span>
            <span className="PortTradeValue bold">
              {/* Show company name if you have it; otherwise, fallback to assetType */}
              {tickerCompanyMap[ticker]?.name }
            </span>
          </div>
  
          {/* Rows: limit price, quantity, status, created, expires, executed */}
          <div
            className="PortTradeTable"
            style={{
              width: inProfile && '100%',
              gridTemplateColumns:
                inProfile &&
                'fit-content(100%) fit-content(100%) 8rem fit-content(100%) fit-content(100%)',
            }}
          >
            {/* Row for limit price + quantity/contracts */}
            <div className="PortTradeTableRow">
              <span className="PortTradeLabel">Limit Price:</span>
              <span className="PortTradeValue bold">${fc(limitPrice)}</span>
              <span></span> {/* empty cell for spacing */}
              <span className="PortTradeLabel">
                {assetType === 'option' ? 'Contracts:' : 'Shares:'}
              </span>
              <span className="PortTradeValue bold">{fc(quantity)}</span>
            </div>
  
            {/* Row for status + created date */}
            <div className="PortTradeTableRow">
              <span className="PortTradeLabel">Status:</span>
              <span className="PortTradeValue bold">{status}</span>
              <span></span>
              <span className="PortTradeLabel">Created:</span>
              <span className="PortTradeValue bold">{createdDate}</span>
            </div>
  
            {/* Row for expires + executed date */}
            <div className="PortTradeTableRow">
              <span className="PortTradeLabel">Expires:</span>
              <span className="PortTradeValue bold">{expiresDate}</span>
              <span></span>
              <span className="PortTradeLabel">Executed:</span>
              <span className="PortTradeValue bold">{executedDate}</span>
            </div>
          </div>
        </div>
  
      
      </div>
    );
  };

  const renderOrderFilters = () => {
    return (
      <div className="port-trade-history-sort">
        <input
          type="text"
          placeholder="Ticker"
          className="AddCashInput"
          value={orderFilters.ticker}
          onChange={(e) => handleOrderFilterChange('ticker', e.target.value)}
        />
        <Select
          value={
            orderFilters.assetType
              ? { label: orderFilters.assetType, value: orderFilters.assetType }
              : null
          }
          onChange={(opt) =>
            handleOrderFilterChange('assetType', opt ? opt.value : '')
          }
          options={[
            { value: 'stock', label: 'Stock' },
            { value: 'option', label: 'Option' },
            { value: 'crypto', label: 'Crypto' },
          ]}
          isClearable
          isSearchable={false}
          placeholder="Type"
          unstyled
          styles={customStylesTrans(tc())}
        />
         <Select
        value={{ value: orderTimePeriod, label: orderTimePeriod }}
        onChange={(selected) => {
          const val = selected ? selected.value : 'All time';
          setOrderTimePeriod(val);
          setOrderPage(1); // reset to page 1 when changing time filter
        }}
        options={timeOptions}
        placeholder="Time range"
        isSearchable={false}
        unstyled
        styles={customStylesTrans(tc())}
      />
        <Select
          value={
            orderFilters.type
              ? { label: orderFilters.type, value: orderFilters.type }
              : null
          }
          onChange={(opt) => handleOrderFilterChange('type', opt ? opt.value : '')}
          options={[
            { value: 'buy', label: 'Buy' },
            { value: 'sell', label: 'Sell' },
          ]}
          isClearable
          isSearchable={false}
          placeholder="Buy/Sell"
          unstyled
          styles={customStylesTrans(tc())}
        />
        <Select
          value={
            orderFilters.status
              ? { label: orderFilters.status, value: orderFilters.status }
              : null
          }
          onChange={(opt) =>
            handleOrderFilterChange('status', opt ? opt.value : '')
          }
          options={[
            { value: 'open', label: 'Open' },
            { value: 'executed', label: 'Executed' },
            { value: 'cancelled', label: 'Cancelled' },
            { value: 'expired', label: 'Expired' },
          ]}
          isClearable
          isSearchable={false}
          placeholder="Status"
          unstyled
          styles={customStylesTrans(tc())}
        />
      </div>
    );
  };

  const renderOrderHistory = () => {
    if (orderLoading) {
      return <LoadingSpinner />;
    }
    if (limitOrders.length === 0) {
      return <span className="ov-trade-type bold ov-15">No orders</span>;
    }
    return (
      <>
        {limitOrders.map((ord) => renderOrderCard(ord))}
        {orderHasMore && (
          <button
            className="CreatePostFeedButton"
            style={{ marginTop: '1rem' }}
            onClick={() => setOrderPage((p) => p + 1)}
          >
            View More
          </button>
        )}
      </>
    );
  };

  // -------------------- MAIN RENDER --------------------

  return (
    <div className="port-container" style={{ paddingTop: location.state?.fromProfile && '0', padding: inProfile && '0' }}>
      {location.state?.fromProfile && (
        <BackButton padding={'2rem 0'} callback={() => window.history.back()} />
      )}
      <div className="port-main-content">
        {renderPortfolio()}
        {!dataFetched ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'var(--stock-chart-height)',
            }}
          >
            <LoadingSpinner />
          </div>
        ) : (
          <div style={{ height: 'var(--stock-chart-height)' }}>
            <canvas ref={chartRef}></canvas>
          </div>
        )}
        <div
          className="StockFilters"
          style={{ justifyContent: inProfile && 'space-between', gap: inProfile && '0rem' }}
        >
          {Object.keys(filters).map((key) => (
            <button
              key={key}
              className={`stock-filter-btn ${filter === key ? 'selected' : 'not-selected'}`}
              onClick={() => setFilter(key)}
            >
              {key}
            </button>
          ))}
        </div>
        <div className="port-stats-filter-section">
          {balanceLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <div className="port-settings-container">
                <span className="port-settings-label">
                  Cash:{' '}
                  <span className="bold" style={{ color: 'var(--text-color)' }}>
                    ${fc(cashBalance)}
                  </span>
                </span>
                {showAddCash ? (
                  <div className="AddCashContainer">
                    <input
                      type="number"
                      value={cashToAdd}
                      onChange={(e) => setCashToAdd(e.target.value)}
                      placeholder="Enter amount"
                      className="AddCashInput"
                    />
                    <button onClick={handleConfirmAddCash} className="CreatePostFeedButton">
                      Confirm
                    </button>
                    <button onClick={handleCancelAddCash} className="CreatePostFeedButton">
                      Cancel
                    </button>
                  </div>
                ) : currentUsername === username && selectedPortfolio ? (
                  <div>
                    <button
                      onClick={() => setShowAddCash(true)}
                      className="CreatePostFeedButton"
                      style={{ width: 'fit-content' }}
                    >
                      Add Cash
                    </button>
                  </div>
                ) : null}

                {currentUsername === username && (
                  <button
                    onClick={() => setShowPortfolioSettingsModal(true)}
                    className="CreatePostFeedButton"
                    style={{ width: 'fit-content' }}
                  >
                    Settings
                  </button>
                )}
              </div>
              {renderTable()}
            </>
          )}
        </div>
      </div>

      {renderAccountStats()}
      {renderPortfolioBreakdown()}

      <div className="port-right-sidebar">
        {/* =========== Tabs =========== */}
        <div style={{ display: 'flex', gap: '1rem', marginBottom: '1rem' }}>
          <button
            className={`CreatePostFeedButton ${activeTab === 'transactions' ? 'greenButton' : ''}`}
            onClick={() => setActiveTab('transactions')}
          >
            Transaction history
          </button>
          <button
            className={`CreatePostFeedButton ${activeTab === 'orders' ? 'greenButton' : ''}`}
            onClick={() => setActiveTab('orders')}
          >
            Queued orders
          </button>
        </div>

        {/* =========== Transactions Tab =========== */}
        {activeTab === 'transactions' && (
          <>
            <h2 className="port-right-sidebar-header">Transaction history</h2>
            <div className="port-trade-history-sort">
              <input
                type="text"
                placeholder="Keyword search"
                className="AddCashInput"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <Select
                value={sortOptionsTrades.find((opt) => opt.value === selectedSortTrade)}
                onChange={(val) => setSelectedSortTrade(val.value)}
                options={sortOptionsTrades}
                isSearchable={false}
                placeholder="Sort by"
                unstyled
                styles={customStylesTrans(tc())}
              />
              <Select
                key={selectedTradeType}
                value={tradeTypeOptions.find((opt) => opt.value === selectedTradeType)}
                onChange={(val) => {
                  if (val?.value === selectedTradeType) {
                    setSelectedTradeType(null);
                  } else {
                    setSelectedTradeType(val?.value);
                  }
                }}
                options={tradeTypeOptions}
                isSearchable={false}
                placeholder="Type"
                unstyled
                styles={customStylesTrans(tc())}
              />
              <Select
                value={timeOptions.find((opt) => opt.value === selectedTimeTrade)}
                onChange={(val) => setSelectedTimeTrade(val.value)}
                options={timeOptions}
                isSearchable={false}
                placeholder="Time range"
                unstyled
                styles={customStylesTrans(tc())}
              />
              <Select
                value={buySellOptions.find((opt) => opt.value === selectedBuySell)}
                onChange={(val) => setSelectedBuySell(val.value)}
                options={buySellOptions}
                isSearchable={false}
                placeholder="Buy/Sell"
                unstyled
                styles={customStylesTrans(tc())}
              />
            </div>
            <div className="port-trade-history" style={{ flexDirection: inProfile && 'column' }}>
              {transactionsLoading ? (
                <LoadingSpinner />
              ) : transactions?.length === 0 ? (
                <span style={{ textAlign: 'left' }} className="ov-trade-type bold ov-15">
                  No trades
                </span>
              ) : (
                renderFilteredTransactions()
              )}
            </div>
          </>
        )}

        {/* =========== Orders Tab =========== */}
        {activeTab === 'orders' && (
          <>
            <h2 className="port-right-sidebar-header">Queued orders</h2>
            {renderOrderFilters()}
            <div className="port-trade-history" style={{ 
              flexDirection: inProfile && 'column',
              display: !inProfile && 'grid',
              gridTemplateColumns: !inProfile && '1fr 1fr',
              
            }}>
              {renderOrderHistory()}
            </div>
          </>
        )}
      </div>

      {showPortfolioSettingsModal && renderPortfolioSettingsModal()}
    </div>
  );
}