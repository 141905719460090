import React, { useState, useEffect, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import Select from 'react-select'
import { AuthContext } from '../context/AuthContext'
import { ThemeContext } from '../context/ThemeContext'
import BackButton from './BackButton'
import '../styles/Leaderboard.css'
import config from '../config'
import {
  formatCurrency as fc,
  getImageUrl,
  LoadingSpinner,
  customStylesTrans,
  formatModifiedTicker,
} from '../helper/StylesHelper'
import { timeOptions } from '../helper/FeedHelper'

// updated sortOptions
import {
  handleDeleteLeaderboard,
  handleToggleVerificationLeaderboard,
  sortOptions,
} from '../helper/LeaderboardHelper'
import { getChangePercentageColor } from '../helper/MarketsHelper'
import CreateLeaderboardModal from './CreateLeaderboardModal'
import VerifiedIcon from './VerifiedIcon'
import useResponsiveWidth from '../hooks/useResponsiveWidth'

export default function Leaderboard() {
  useResponsiveWidth()
  const { username } = useParams()
  const navigate = useNavigate()
  const { userToken, isAuthenticated, isAdmin } = useContext(AuthContext)
  const { tc } = useContext(ThemeContext)

  // Basic LB details
  const [leaderboardDetails, setLeaderboardDetails] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  // Investors (left side) filters
  const [selectedLeftTime, setSelectedLeftTime] = useState(timeOptions[0].value)
  // Store entire object from sortOptions
  const [selectedLeftSortOption, setSelectedLeftSortOption] = useState(
    sortOptions[0]
  )

  // Subgroups (right side) filters
  const [selectedRightTime, setSelectedRightTime] = useState(
    timeOptions[0].value
  )
  // entire object
  const [selectedRightSortOption, setSelectedRightSortOption] = useState(
    sortOptions[0]
  )

  // Members data & pagination
  const [leaderboardMembers, setLeaderboardMembers] = useState([])
  const [membersLoading, setMembersLoading] = useState(false)
  const [membersPage, setMembersPage] = useState(1)
  const [membersTotal, setMembersTotal] = useState(0)

  // Subgroups data & pagination
  const [subgroupsList, setSubgroupsList] = useState([])
  const [subgroupsLoading, setSubgroupsLoading] = useState(false)
  const [subgroupsPage, setSubgroupsPage] = useState(1)
  const [subgroupsTotal, setSubgroupsTotal] = useState(0)

  // CREATE SUBGROUP MODAL
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [editId, setEditId] = useState(null)

  // ========== Effects ==========

  useEffect(() => {
    const fetchLeaderboardDetails = async () => {
      try {
        setIsLoading(true)
        const response = await axios.get(
          `${config.backendUrl}/api/leaderboards/${username}`,
          {
            headers: { Authorization: `Bearer ${userToken}` },
          }
        )

        console.log('response.data', response.data)
        setLeaderboardDetails(response.data)
      } catch (error) {
        console.error('Error fetching leaderboard details:', error)
      } finally {
        setIsLoading(false)
      }
    }
    fetchLeaderboardDetails()
  }, [username, userToken, navigate])

  // whenever left-time or left-sort changes, reset page
  useEffect(() => {
    setMembersPage(1)
  }, [selectedLeftTime, selectedLeftSortOption])

  // fetch members
  useEffect(() => {
    if (!leaderboardDetails?._id) return

    const fetchLeaderboardMembers = async () => {
      try {
        setMembersLoading(true)
        const res = await axios.get(
          `${config.backendUrl}/api/leaderboards/${leaderboardDetails.username}/members`,
          {
            params: {
              timePeriod: selectedLeftTime,
              sortBy: selectedLeftSortOption.value, // pass .value
              asc: selectedLeftSortOption.asc, // pass asc
              page: membersPage,
              limit: 5,
            },
            headers: { Authorization: `Bearer ${userToken}` },
          }
        )
        const data = res.data

        // store user rank
        setLeaderboardDetails((prev) => {
          if (!prev) return null
          return {
            ...prev,
            userRank: data.userRank,
            userPercentile: data.userPercentile,
          }
        })

        if (membersPage === 1) {
          setLeaderboardMembers(data.members || [])
        } else {
          setLeaderboardMembers((prev) => [...prev, ...(data.members || [])])
        }
        setMembersTotal(data.totalResults || 0)
      } catch (err) {
        console.error('Error fetching leaderboard members:', err)
      } finally {
        setMembersLoading(false)
      }
    }

    fetchLeaderboardMembers()
  }, [
    leaderboardDetails?._id,
    selectedLeftTime,
    selectedLeftSortOption,
    membersPage,
    userToken,
  ])

  // whenever right-time or right-sort changes, reset subgroups page
  useEffect(() => {
    setSubgroupsPage(1)
  }, [selectedRightTime, selectedRightSortOption])

  // fetch subgroups
  useEffect(() => {
    if (!leaderboardDetails?._id) return

    async function fetchSubgroups() {
      try {
        setSubgroupsLoading(true)
        const resp = await axios.get(
          `${config.backendUrl}/api/leaderboards/top-leaderboards`,
          {
            params: {
              timePeriod: selectedRightTime,
              sortBy: selectedRightSortOption.value,
              asc: selectedRightSortOption.asc, // pass asc
              parentLeaderboard: leaderboardDetails._id,
              page: subgroupsPage,
              limit: 5,
            },
          }
        )
        const data = resp.data || {}

        if (subgroupsPage === 1) {
          setSubgroupsList(data.topLeaderboards || [])
        } else {
          setSubgroupsList((prev) => [...prev, ...(data.topLeaderboards || [])])
        }
        setSubgroupsTotal(data.totalResults || 0)
      } catch (error) {
        console.error('Error fetching subgroups:', error)
      } finally {
        setSubgroupsLoading(false)
      }
    }

    fetchSubgroups()
  }, [
    leaderboardDetails?._id,
    selectedRightTime,
    selectedRightSortOption,
    subgroupsPage,
  ])

  // ========== Join / Leave ==========
  const handleJoin = async () => {
    if (!leaderboardDetails) return
    try {
      await axios.post(
        `${config.backendUrl}/api/leaderboards/${leaderboardDetails._id}/join`,
        {},
        { headers: { Authorization: `Bearer ${userToken}` } }
      )
      setLeaderboardDetails((prev) => {
        if (!prev) return null
        return { ...prev, totalMembers: prev.totalMembers + 1, isMember: true }
      })
    } catch (err) {
      console.error('Error joining leaderboard:', err)
      alert(err?.response?.data?.error || 'Error joining leaderboard')
    }
  }

  const handleLeave = async () => {
    if (!leaderboardDetails) return
    try {
      await axios.post(
        `${config.backendUrl}/api/leaderboards/${leaderboardDetails._id}/leave`,
        {},
        { headers: { Authorization: `Bearer ${userToken}` } }
      )
      setLeaderboardDetails((prev) => {
        if (!prev) return null
        return {
          ...prev,
          totalMembers: Math.max(prev.totalMembers - 1, 0),
          isMember: false,
        }
      })
    } catch (err) {
      console.error('Error leaving leaderboard:', err)
      alert(err?.response?.data?.error || 'Error leaving leaderboard')
    }
  }

  // “View More”
  const handleViewMoreMembers = () => {
    setMembersPage((prev) => prev + 1)
  }

  const handleViewMoreSubgroups = () => {
    setSubgroupsPage((prev) => prev + 1)
  }

  // ========== CREATE SUBGROUP ==========
  const handleCreateSubgroup = () => {
    if (!isAuthenticated) {
      alert('Please sign in to create a subgroup.')
      return
    }
    if (!leaderboardDetails) return
    setIsEditMode(false)
    setEditId(null)
    setShowCreateModal(true)
  }

  const handleCloseCreateModal = () => {
    setShowCreateModal(false)
    setIsEditMode(false)
    setEditId(null)
  }

  // ========== Render ==========

  if (isLoading) {
    return (
      <div className="sLeadContainer">
        <BackButton padding="2rem 0" />
        <div className="sLoadingSpinnerWrapper">
          <LoadingSpinner />
        </div>
      </div>
    )
  }

  if (!leaderboardDetails) {
    return null
  }

  const {
    name,
    bio,
    totalMembers,
    profileImage,
    isMember,
    userRank,
    userPercentile,
  } = leaderboardDetails

  const canViewMoreMembers = leaderboardMembers.length < membersTotal
  const canViewMoreSubgroups = subgroupsList.length < subgroupsTotal

  function renderLeaderboardUser(leaderboardUser) {
    const { user, percentageGain, netGain, largestPositionTicker, rank } =
      leaderboardUser

    const backgroundColor =
      rank === 1 ? 'rgba(255, 195, 40, 0.6)' : 'var(--background-color)'
    const textColor =
      rank === 1 ? '#FFC328' : getChangePercentageColor(percentageGain, tc())

    const valueToUse =
      selectedLeftSortOption.value === '%return' ? percentageGain : netGain
    const arrow = valueToUse >= 0 ? '↑' : '↓'

    return (
      <div
        className="LeadCard"
        key={user._id}
        style={{ backgroundColor: 'transparent' }}
        onClick={() => navigate(`/profile/${user.username}`)}
      >
        <div
          style={{ color: rank === 1 ? textColor : undefined }}
          className="LeadCardRank"
        >
          {rank}
        </div>

        <div
          className="LeadCardImageWrapper"
          style={{
            boxShadow:
              rank === 1 ? '0 0 0.6rem #030902, 0 0 2rem #FFBF19' : 'none',
          }}
        >
          <img
            src={getImageUrl(user.profilePicture)}
            alt="profile"
            className="LeadCardImage"
          />
        </div>
        <div className="LeadCardDetails">
          <div className="LeadCardUserDetails">
            <h3
              style={{ color: rank === 1 ? textColor : 'var(--text-color)' }}
              className="LeadCardGain bold"
            >
              {user.name}{' '}
              {user.isUserVerified && (
                <VerifiedIcon
                  fontSize="1.3rem"
                  color={`${
                    rank === 1 ? textColor : 'var(--stock-change-pos)'
                  }`}
                />
              )}
            </h3>

            <div className="LeadCardGain bold" style={{ color: textColor }}>
              {arrow}
              {selectedLeftSortOption.value === 'netReturn' && '$'}
              {fc(Math.abs(valueToUse), 2, true)}
              {selectedLeftSortOption.value === '%return' && '%'}{' '}
              <span style={{ color: 'var(--text-color)' }}>
                {selectedLeftTime?.toLowerCase()}
              </span>
            </div>
            <div className="LeadCardLargestPosition bold">
              <h3 style={{ color: textColor }} className="LeadCardName bold">
                Largest Position:
              </h3>{' '}
              <span
                className="bold"
                style={{ color: rank === 1 ? '#FFBF19' : 'var(--text-color)' }}
              >
                {formatModifiedTicker(largestPositionTicker) || '--'}
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  function renderSubgroupCard(subgroup, idx) {
    const rank = idx + 1
    const backgroundColor =
      rank === 1 ? 'rgba(255, 195, 40, 0.6)' : 'var(--background-color)'
    const valueToUse =
      selectedRightSortOption.value === '%return'
        ? subgroup.percentageGain
        : subgroup.netGain
    const arrow = valueToUse >= 0 ? '↑' : '↓'
    const textColor =
      rank === 1 ? '#FFC328' : getChangePercentageColor(valueToUse, tc())

    return (
      <div
        className="LeadRightSideUserCard"
        key={subgroup.leaderboardId || subgroup._id}
        onClick={() => navigate(`/leaderboard/${subgroup.username}`)}
      >
        <span
          className="LeadRightSideUserCardRank"
          style={{ color: rank === 1 ? textColor : 'var(--text-color)' }}
        >
          {rank}
        </span>

        <div
          className="LeadRightSideUserImageWrapper"
          style={{
            boxShadow:
              rank === 1 ? '0 0 0.6rem #030902, 0 0 2rem #FFBF19' : 'none',
          }}
        >
          <img
            src={getImageUrl(subgroup.profileImage)}
            alt="subgroup"
            className="LeadRightSideUserImg"
          />
        </div>

        <div className="LeadRightSideUserDetails">
          <div
            style={{ color: rank === 1 ? textColor : 'var(--text-color)' }}
            className="LeadRightSideUserName bold"
          >
            <span className="bold">
              {subgroup.name}{' '}
              {subgroup.isLeaderboardVerified ? (
                <VerifiedIcon
                  fontSize="1.3rem"
                  color={`${
                    rank === 1 ? textColor : 'var(--stock-change-pos)'
                  }`}
                />
              ) : (
                ''
              )}
            </span>
          </div>
          <div
            className="LeadRightSideLargestPosition bold"
            style={{ color: textColor }}
          >
            {arrow}
            {selectedRightSortOption.value === 'netReturn' && '$'}
            {fc(Math.abs(valueToUse), 2, true)}
            {selectedRightSortOption.value === '%return' && '%'}{' '}
            <span style={{ color: 'var(--text-color)' }}>
              {selectedRightTime?.toLowerCase()}
            </span>
          </div>
          <span className="LeadRightSideUserGain bold">
            {fc(subgroup.totalMembers, 0)} members
          </span>
        </div>
      </div>
    )
  }

  if (!leaderboardDetails) return null

  return (
    <div className="sLeadContainer">
      <BackButton padding="2rem 0" />
      <div className={`LeadBackdrop ${showCreateModal ? 'active' : ''}`} />
      {leaderboardDetails && (
        <CreateLeaderboardModal
          isOpen={showCreateModal}
          onClose={handleCloseCreateModal}
          isEditMode={isEditMode}
          editId={editId}
          userToken={userToken}
          parentLbId={leaderboardDetails._id}
          parentLbUsername={leaderboardDetails.username}
        />
      )}

      {/* LEADERBOARD HEADER */}
      <div className="sLeaderboardDetailsWrapper">
        <div className="sLeaderboardHeader">
          <div className="sLeaderboardInfo">
            <h1 className="sLeaderboardName">
              {name}{' '}
              {leaderboardDetails?.isLeaderboardVerified ? (
                <VerifiedIcon />
              ) : (
                ''
              )}
            </h1>
            <h2 className="sLeaderboardUsername medium">
              v/{username}
              {leaderboardDetails && (
                <span className="medium">
                  {' - created by '}
                  <span
                    onClick={() =>
                      navigate(`/profile/${leaderboardDetails?.creator}`)
                    }
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    className="bold"
                  >
                    {leaderboardDetails?.creator}
                  </span>
                  {` on ${new Date(
                    leaderboardDetails?.createdAt
                  ).toLocaleDateString()}`}
                </span>
              )}
            </h2>
            <p className="sLeaderboardStats">
              {totalMembers.toLocaleString()} members
            </p>
            <p className="sLeaderboardBio">{bio}</p>
          </div>
          <div className="sLeaderboardProfileImage">
            {profileImage && (
              <img
                src={getImageUrl(profileImage)}
                alt={`${name} profile`}
                className="sProfileImage"
              />
            )}
          </div>
        </div>

        <div className="sLeaderboardActions">
          {isMember ? (
            <button className="CreatePostFeedButton" onClick={handleLeave}>
              Leave
            </button>
          ) : (
            <button
              className="CreatePostFeedButton greenButton"
              onClick={handleJoin}
            >
              Join
            </button>
          )}
          <button
            className="CreatePostFeedButton"
            onClick={handleCreateSubgroup}
            style={{
              backgroundColor: isMember ? '#111510' : 'grey', // Green if isMember is true, otherwise transparent
            }}
            disabled={!isMember}
          >
            Create Subgroup
          </button>
          {isAdmin && (
            <>
              <button
                className="CreatePostFeedButton"
                onClick={() =>
                  handleToggleVerificationLeaderboard(
                    leaderboardDetails?._id,
                    userToken,
                    (resData) => {
                      const leaderboard = resData.leaderboard
                      const updatedLeaderboard = {
                        ...leaderboardDetails,
                        isLeaderboardVerified:
                          leaderboard.isLeaderboardVerified,
                      }
                      setLeaderboardDetails(updatedLeaderboard)
                    }
                  )
                }
              >
                {leaderboardDetails?.isLeaderboardVerified
                  ? 'Unverify'
                  : 'Verify'}
              </button>

              <button
                onClick={() =>
                  handleDeleteLeaderboard(leaderboardDetails?._id, userToken)
                }
                className="CreatePostFeedButton"
              >
                Delete
              </button>
            </>
          )}
        </div>
      </div>

      <div className="sLeaderboardMainContent">
        {/* LEFT CONTENT (Investors) */}
        <div className="sLeaderboardLeftContent">
          <div className="sLeaderboardLeftContentHeader">
            <h2 className="sLeaderboardName" style={{ margin: 0 }}>
              Investors
            </h2>
            <span className="sLeaderboardPosition">
              Your position:
              {leaderboardDetails.userRank
                ? ` ${leaderboardDetails.userRank} (top ${fc(
                    leaderboardDetails.userPercentile,
                    2
                  )}%)`
                : ' N/A'}
            </span>
          </div>

          {/* Left filters */}
          <div className="sLeaderboardSelects">
            <Select
              value={timeOptions.find((o) => o.value === selectedLeftTime)}
              onChange={(option) => {
                setMembersPage(1)
                setLeaderboardMembers([])
                setSelectedLeftTime(option.value)
              }}
              options={timeOptions}
              isSearchable={false}
              placeholder="Time"
              unstyled
              styles={customStylesTrans(tc())}
            />
            <Select
              value={selectedLeftSortOption}
              onChange={(option) => {
                setMembersPage(1)
                setLeaderboardMembers([])
                setSelectedLeftSortOption(option)
              }}
              options={sortOptions}
              isSearchable={false}
              placeholder="Sort By"
              unstyled
              styles={customStylesTrans(tc())}
            />
          </div>

          {/* Investors list */}
          <div className="sLeaderboardUsersWrapper">
            {membersLoading && membersPage === 1 ? (
              <LoadingSpinner />
            ) : (
              <>
                {leaderboardMembers.map((member) =>
                  renderLeaderboardUser(member)
                )}
                {!membersLoading && leaderboardMembers.length === 0 && (
                  <p style={{ marginTop: '1rem' }}>No members found.</p>
                )}
              </>
            )}
            {membersLoading && membersPage > 1 && <LoadingSpinner />}
          </div>

          {!membersLoading && canViewMoreMembers && (
            <button
              className="CreatePostFeedButton"
              style={{ marginTop: '1rem' }}
              onClick={handleViewMoreMembers}
            >
              View More
            </button>
          )}
        </div>

        {/* RIGHT CONTENT (Subgroups) */}
        <div className="sLeaderboardRightContent">
          <h2 className="sLeaderboardName" style={{ margin: 0 }}>
            Subgroups
          </h2>

          <div className="sLeaderboardSelects">
            <Select
              value={timeOptions.find((o) => o.value === selectedRightTime)}
              onChange={(option) => {
                setSubgroupsPage(1)
                setSubgroupsList([])
                setSelectedRightTime(option.value)
              }}
              options={timeOptions}
              isSearchable={false}
              placeholder="Time"
              unstyled
              styles={customStylesTrans(tc())}
            />
            <Select
              value={selectedRightSortOption}
              onChange={(option) => {
                setSubgroupsPage(1)
                setSubgroupsList([])
                setSelectedRightSortOption(option)
              }}
              options={sortOptions}
              isSearchable={false}
              placeholder="Sort By"
              unstyled
              styles={customStylesTrans(tc())}
            />
          </div>

          <div
            style={{
              marginTop: '1rem',
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
            }}
          >
            {subgroupsLoading && subgroupsPage === 1 ? (
              <LoadingSpinner />
            ) : (
              <>
                {subgroupsList.map((sg, i) => renderSubgroupCard(sg, i))}
                {!subgroupsLoading && subgroupsList.length === 0 && (
                  <p>No subgroups found.</p>
                )}
              </>
            )}
            {subgroupsLoading && subgroupsPage > 1 && <LoadingSpinner />}

            {canViewMoreSubgroups && !subgroupsLoading && (
              <button
                className="CreatePostFeedButton"
                style={{ marginTop: '1rem' }}
                onClick={handleViewMoreSubgroups}
              >
                View More
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
