// PostView.jsx
import React, { useEffect, useState, useContext, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowUp, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import config from '../config';
import { AuthContext } from '../context/AuthContext';
import '../styles/PostView.css';
import retweet from '../assets/retweet.svg';
import Post from './Post';
import { 
  formatTimeSince, 
  timeSince, 
  formatDate, 
  allowedHTML,
  renderTagLine, 
  fetchDailyDataForTaggedAssets,
  renderTaggedAssets
} from '../helper/PostHelper';
import { fetchUser } from '../helper/ProfileHelper';
import Upvote from './post-actions/Upvote';
import Downvote from './post-actions/Downvote';
import Repost from './post-actions/Repost';
import Dots from './post-actions/Dots';
import Bookmark from './post-actions/Bookmark';
import DOMPurify from 'dompurify';
import { getImageUrl } from '../helper/StylesHelper';
import { LoadingSpinner } from '../helper/StylesHelper';
import { ThemeContext } from '../context/ThemeContext';
import useResponsiveWidth from '../hooks/useResponsiveWidth';
import TransactionDetails from './TransactionDetails';
import VerifiedIcon from './VerifiedIcon';
/**
 * @typedef {import('../helper/PostHelper').QuotePostDetailsObject} QuotePostDetailsObject
 * @typedef {import('../helper/ProfileHelper').UserObject} UserObject
 * @typedef {import('../helper/PostHelper').PostObject} PostObject
 */

const PostView = () => {
  useResponsiveWidth();
  const navigate = useNavigate();
  const { postId } = useParams();
  const location = useLocation();

  /** @type {[PostObject, Function]} */
  const [post, setPost] = useState(null);

  /** @type {[QuotePostDetailsObject, Function]} */
  const [quotePostDetails, setQuotePostDetails] = useState(null);



  const [loading, setLoading] = useState(true);
  const { userToken, userId, isAuthenticated } = useContext(AuthContext);
  const {tc} = useContext(ThemeContext);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [isUpvoted, setIsUpvoted] = useState(false);
  const [isDownvoted, setIsDownvoted] = useState(false);
  const [upvoteCount, setUpvoteCount] = useState(0);
  const [downvoteCount, setDownvoteCount] = useState(0);
  const [commentsCount, setCommentsCount] = useState(0);
  const [repostCount, setRepostCount] = useState(0);

  /** @type {[UserObject, Function]} */
  const [currentUser, setCurrentUser] = useState(null); 

  const [newComment, setNewComment] = useState('');  

  /** @type {[PostObject[], Function]} */
  const [allComments, setAllComments] = useState([]);

  /** @type {{ copyReposter: UserObject, isCopyRepost: boolean }} */
  const { copyReposter, isCopyRepost } = location.state || {};

  const [selectedTime, setSelectedTime] = useState('All time');
  const [selectedSort, setSelectedSort] = useState('Most recent');

  const [quotePostLoading, setQuotePostLoading] = useState(false);
  const [isCopyReposted, setIsCopyReposted] = useState(false);

  // Pagination for comments
  const [page, setPage] = useState(0);
  const [hasMoreComments, setHasMoreComments] = useState(true);
  const [loadingComments, setLoadingComments] = useState(false);
  const [dailyChangeData, setDailyChangeData] = useState(null);

  const [tagLine, setTagLine] = useState('');
  const [quotePostTagLine, setQuotePostTagLine] = useState('');


  const loadMoreRef = useRef(null);

  useEffect(() => {
    if (!post) {
      return;
    }
    const fetchTaggedData = async () => {
      if (post.taggedAssets?.length > 0 || (quotePostDetails && quotePostDetails?.taggedAssets?.length > 0)) {
        let allAssets = quotePostDetails ? [...post.taggedAssets, ...quotePostDetails.taggedAssets] : [...post.taggedAssets];
        const dailyData = await fetchDailyDataForTaggedAssets(allAssets);
        setDailyChangeData(dailyData);
      }
  }
  fetchTaggedData();
  }, [post, quotePostDetails]);

  useEffect(() => {
    if (quotePostDetails) {
      setQuotePostTagLine(
        renderTagLine(
          quotePostDetails.type,
          quotePostDetails.categories,
          quotePostDetails.sentiment,
        )
      );
    }
  }, [quotePostDetails]);





  useEffect(() => {
    if (!post) {
      return;
    }
    setTagLine(renderTagLine(post.type, post.categories, post.sentiment));
  }, [ post]);



  useEffect(() => {
    const fetchPost = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${config.backendUrl}/api/posts/${postId}`, {
          headers: { Authorization: `Bearer ${userToken}` },
        });
        setPost(response.data);
        setUpvoteCount(response.data.upvoteCount);
        setDownvoteCount(response.data.downvoteCount);
        setRepostCount(response.data.repostCount);
        setIsUpvoted(response.data.isUpvoted);
        setIsDownvoted(response.data.isDownvoted);
        setIsCopyReposted(response.data.isCopyReposted);
        setCommentsCount(response.data.commentCount);

        if (response.data.quotePostId) {
          fetchQuotePost(response.data.quotePostId);
        }

      
        if (isAuthenticated) {
          const bookmarkResponse = await axios.get(`${config.backendUrl}/api/bookmarks/${postId}/isBookmarked`, {
            headers: { Authorization: `Bearer ${userToken}` },
          });
          setIsBookmarked(bookmarkResponse.data.isBookmarked);
        }

        // Fetch initial comments
        fetchComments(true);
      } catch (error) {
        console.error('Error fetching post or comments:', error);
      }
      setLoading(false);
    };

    const fetchQuotePost = async (quotePostId) => {
      setQuotePostLoading(true);
      try {
        const response = await axios.get(`${config.backendUrl}/api/posts/${quotePostId}`, {
          headers: { Authorization: `Bearer ${userToken}` },
        });
        setQuotePostDetails(response.data);
      } catch (error) {
        console.error('Error fetching quote post:', error);
      }
      setQuotePostLoading(false);
    };

    const fetchCurrentUser = async () => {
      try {
        const response = await fetchUser(userId, userToken);
        setCurrentUser(response.data);
      } catch (error) {
        console.error('Error fetching current user:', error);
      }
    };

    setPost(null);
    setQuotePostDetails(null);
    setAllComments([]);
    setPage(0);
    setHasMoreComments(true);

    fetchCurrentUser(); 
    fetchPost(); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postId, userToken, isAuthenticated, userId]);

  // Fetch comments function
  const fetchComments = async (reset = false) => {
    if (reset) {
      setPage(0);
      setHasMoreComments(true);
      setAllComments([]);
    }

    setLoadingComments(true);
    try {
      const response = await axios.get(`${config.backendUrl}/api/posts/${postId}/comments`, {
        headers: { Authorization: `Bearer ${userToken}` },
        params: {
          page: reset ? 0 : page,
          limit: 5,
          selectedTime,
          selectedSort,
        },
      });

      const fetchedComments = response.data;

      // Map through comments to check if they are bookmarked
      const allFetchedComments = await Promise.all(
        fetchedComments.map(async (comment) => {
          if (!isAuthenticated) {
            return { ...comment, isBookmarked: false };
          }
          const commentBookmarkResponse = await axios.get(
            `${config.backendUrl}/api/bookmarks/${comment._id}/isBookmarked`,
            {
              headers: { Authorization: `Bearer ${userToken}` },
            }
          );
          return {
            ...comment,
            isBookmarked: commentBookmarkResponse.data.isBookmarked,
          };
        })
      );

      setAllComments((prevComments) => [...prevComments, ...allFetchedComments]);

      setHasMoreComments(fetchedComments.length === 5);
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
    setLoadingComments(false);
  };

  // Infinite scroll using IntersectionObserver
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMoreComments && !loadingComments) {
          setPage((prevPage) => prevPage + 1);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      }
    );

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
    };
  }, [hasMoreComments, loadingComments]);

  // Fetch comments when page changes
  useEffect(() => {
    if (page !== 0) {
      fetchComments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  // Reset comments when filters change
  useEffect(() => {
    fetchComments(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTime, selectedSort]);

  const handleProfileClick = () => {
    if (!isAuthenticated) {
      alert('Please sign in to view profiles');
      return;
    }
    navigate(`/profile/${post.author.username}`);
  };

  const handleBookmarkClick = async () => {
    if (!isAuthenticated) {
      alert('Please sign in to bookmark posts');
      return;
    }
    try {
      const url = `${config.backendUrl}/api/bookmarks/${postId}`;
      const method = isBookmarked ? 'delete' : 'post';
      await axios({ method, url, headers: { Authorization: `Bearer ${userToken}` } });
      setIsBookmarked(!isBookmarked);
    } catch (error) {
      console.error('Error updating bookmark:', error);
    }
  };

  const handleCommentBookmarkClick = async (commentId, isCurrentlyBookmarked) => {
    if (!isAuthenticated) {
      alert('Please sign in to bookmark comments');
      return;
    }
    try {
      const url = `${config.backendUrl}/api/bookmarks/${commentId}`;
      const method = isCurrentlyBookmarked ? 'delete' : 'post';
      await axios({ method, url, headers: { Authorization: `Bearer ${userToken}` } });

      setAllComments((prevComments) =>
        prevComments.map((comment) =>
          comment._id === commentId
            ? { ...comment, isBookmarked: !isCurrentlyBookmarked }
            : comment
        )
      );
    } catch (error) {
      console.error('Error updating comment bookmark:', error);
    }
  };

  const refreshFollowingStatus = (comment) => {
    comment.isAuthorFollowed = !comment.isAuthorFollowed; 
  };

  const submitComment = async () => {
    if (!isAuthenticated){ 
      alert('Please sign in to comment on posts'); 
      return;
    }

    if (newComment.trim() === '') {
      alert('Please enter a comment');
      return;
    }

    try {
      const response = await axios.post(
        `${config.backendUrl}/api/posts/${postId}/comments`,
        { content: newComment },
        { headers: { Authorization: `Bearer ${userToken}` } }
      );

      const newCommentData = response.data.comment;

      newCommentData.author = {
        _id: currentUser._id,
        username: currentUser.username,
        name: currentUser.name,
        isUserVerified: currentUser.isUserVerified,
        profilePicture: currentUser.profilePicture,
      };

    

      setAllComments((prevComments) => [
        newCommentData,
        ...prevComments,
      ]);

      setCommentsCount((prevCount) => prevCount + 1);

      setNewComment('');
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  const handleCommentKeyPress = (e) => {
    if (e.key === 'Enter') {
      submitComment();
    }
  };

  const handleCommentButtonClick = () => {
    submitComment();
  };

  if (loading) {
    return (
      <div className="pv-PostViewContainer loading">
       <LoadingSpinner />
      </div>
    );
  }

  if (!post) {
    return <div>Error loading post</div>;
  }

  return (
    <div className="pv-PostViewContainer">
      <div className="pv-BackToFeedContainer">
        <button
          onClick={() => {
            if (post?.parentPostId) {
              navigate(`/post/${post.parentPostId}`);
            } else {
              window.history.back();
            }
          }}
          className="pv-BackButton"
        >
          <FontAwesomeIcon icon={faArrowLeft} /> <span style={{ color: 'var(--action-grey' }}>Back</span>
        </button>
      </div>
      {isCopyRepost && (
        <div className="pv-ReposterText">
          {copyReposter.username} reposted{' '}
          <img src={retweet} alt="Repost" className="ActionIcon" />
        </div>
      )}

      <div className="pv-PostAuthor" onClick={handleProfileClick}>
        <img src={getImageUrl(post.author.profilePicture)} alt="Profile" className="pv-ProfilePic" />
        <div className="pv-AuthorDetails">
          <div className="pv-author" >
            {post.author.name}{' '}
            {post.author.isUserVerified && (
              <VerifiedIcon fontSize='1.3rem'/>
            )}
          </div>
          <div className="pv-username">@{post.author.username}</div>
        </div>
      </div>
      <div className="pv-PostTopicLine">
        {tagLine}
      </div>
      <h1 className="pv-PostTitle bold">{post.title}</h1>

      <div className="pv-PostContent">
        <div className="pv-PostViewContentDateWrapper">
          <div
            className="pv-PostContentP"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.content, allowedHTML) }}
          ></div>
          {post.images &&
            post.images.map((image, index) => (
              <img
                key={index}
                src={getImageUrl(image)}
                alt={`Post ${index}`}
                className="pv-PostImage"
              />
            ))}
                   
          {post.caption && <div className="pv-PostCaption">{post.caption}</div>}

          
          

          {quotePostLoading && (
            <div className="cp-QuotePostContainer loading">
              <LoadingSpinner />
            </div>
          )}

          {!quotePostLoading && quotePostDetails && (
            <div
              style={{ cursor: 'pointer' }}
              className="cp-QuotePostContainer"
              onClick={() => navigate(`/post/${quotePostDetails._id}`)}
            >
              <div className="cp-QuotePostHeader">
                <img
                  src={getImageUrl(quotePostDetails.author.profilePicture)}
                  alt="Profile"
                  className="cp-QuoteProfilePic"
                />
                <div className="cp-QuotePostInfoWrapper" style={{ flexDirection: 'column' }}>
                <div className="cp-QuotePostInfo">
                          <span className="cp-QuotePostAuthor cp-bold">
                            <span className="bold">
                              {quotePostDetails.author.name}
                            </span>{' '}
                            {quotePostDetails.author.isUserVerified && (
                              <VerifiedIcon fontSize="1.3rem" />
                            )}
                            {' '}
                            <span className="medium" style={{ color: 'var(--action-grey)' }}>
                              @{quotePostDetails.author.username} ·{' '}
                                {formatTimeSince(timeSince(quotePostDetails.timePosted))}
                              {quotePostTagLine && ` · ${quotePostTagLine}`}
                            </span>
                          </span>
                        </div>
                  <h2 className="cp-QuotePostTitle bold">
                    {quotePostDetails.title && <>{quotePostDetails.title}</>}
                  </h2>
                  <div className="cp-QuotePostContent">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(quotePostDetails.content, allowedHTML),
                      }}
                      onClick={(e) => {
                        if (e.target.tagName === 'A') {
                          e.stopPropagation(); // Prevents outer div's onClick from firing
                        }
                      }}
                    ></div>
                  
                  </div>
                  {quotePostDetails.images &&
                    quotePostDetails.images.map((image, index) => (
                      <img
                        key={index}
                        src={getImageUrl(image)}
                        alt={`Post ${index}`}
                        className="PostImage-sm"
                      />
                    ))}

                  {renderTaggedAssets(quotePostDetails.taggedAssets, dailyChangeData, navigate, tc )}
                    {quotePostDetails.transactionId && <TransactionDetails transactionId={quotePostDetails.transactionId} />}
                 

                    
                </div>
              </div>
            </div>
          )}

        </div>
      </div>
      {renderTaggedAssets(post.taggedAssets, dailyChangeData, navigate, tc)}
      {post?.transactionId && <TransactionDetails transactionId={post.transactionId} />}
      <div className="pv-PostViewDate bold">{formatDate(post.timePosted)}</div>
      <div className="PostActions pv-PostActions" onClick={(e) => e.stopPropagation()}>
        <div className="LeftActions" style={{ width: '20%' }}>
          <div className="pv-VoteWrapper">
            <Upvote
              isAdmin={currentUser?.isAdmin}
              isUpvotedState={isUpvoted}
              setIsUpvotedState={setIsUpvoted}
              setIsDownvotedState={setIsDownvoted}
              setUpvoteCount={setUpvoteCount}
              setDownvoteCount={setDownvoteCount}
              postId={postId}
              upvoteCount={upvoteCount}
            />
          </div>
          <div className="pv-VoteWrapper">
            <Downvote
              isAdmin={currentUser?.isAdmin}
              isDownvotedState={isDownvoted}
              setIsUpvotedState={setIsUpvoted}
              setIsDownvotedState={setIsDownvoted}
              setUpvoteCount={setUpvoteCount}
              setDownvoteCount={setDownvoteCount}
              postId={postId}
              downvoteCount={downvoteCount}
            />
          </div>

          <div className="pv-VoteWrapper">
            <Repost
              postId={postId}
              profilePic={post.author.profilePicture}
              author={post.author.name}
              verified={post.author.isUserVerified}
              username={post.author.username}
              content={post.content}
              title={post.title}
              time={formatTimeSince(timeSince(post.timePosted))}
              sentiment={post.sentiment}
              type={post.type}
              categories={post.categories}
              taggedAssets={post.taggedAssets}
              images={post.images}
              repostCount={repostCount}
              setRepostCount={setRepostCount}
              isCopyReposted={isCopyReposted}
              setIsCopyReposted={setIsCopyReposted}
              downvoteCount={downvoteCount}
              transactionId={post.transactionId}

            />
          </div>
        </div>
        <div className="RightActions">
          <Bookmark
            isBookmarked={isBookmarked}
            setIsBookmarked={setIsBookmarked}
            onBookmarkClick={handleBookmarkClick}
          />

          <Dots
            title={post.title}
            content={post.content}
            categories={post.categories}
            sentiment={post.sentiment}
            type={post.type}
            caption={post.caption}
            images={post.images}
            isQuotePost={post.isQuotePost}
            quotePostId={post.quotePostId}
            postId={postId}
            isAdmin={currentUser?.isAdmin}
            username={post.author.username}
            isComment={false}
            parentPostId={post.parentPostId}
            taggedAssets={post.taggedAssets}
            transactionId={post.transactionId}
            isCopyRepost={isCopyRepost}
            refreshFeed={() => navigate('/feed')}
          />
        </div>
      </div>

      <div className="pv-CommentsSection">
        <div className="pv-CommentsText">Comments ({commentsCount})</div>
        <div className="pv-CommentsInputWrapper">
          <input
            className="pv-CommentsInput"
            type="text"
            placeholder="Add a comment"
            value={newComment}
            onChange={(e) => {
              if (isAuthenticated) setNewComment(e.target.value);
            }}
            onKeyDown={handleCommentKeyPress}
          />
          <button className="pv-CommentsInputSubmit" onClick={handleCommentButtonClick}>
            <FontAwesomeIcon icon={faArrowUp} />
          </button>
        </div>
        <div className="TopBar" style={{ padding: '2rem 0' }}>
          <select
            className="TopBarButton bold"
            value={selectedTime}
            onChange={(e) => setSelectedTime(e.target.value)}
          >
            <option value="All time">All time</option>
            <option value="Today">Today</option>
            <option value="7 days">Last 7 days</option>
            <option value="1 month">Last 1 month</option>
          </select>
          <select
            className="TopBarButton bold"
            value={selectedSort}
            onChange={(e) => setSelectedSort(e.target.value)}
          >
            <option value="Most recent">Most recent</option>
            <option value="Most popular">Most popular</option>
            <option value="Most commented">Most commented</option>
          </select>
        </div>
        <hr style={{ margin: '0' }} />
        <div className="pv-CommentList">
          {allComments.length === 0 && !loadingComments && (
            <div style={{ textAlign: 'center', margin: '1rem 0' }}>No comments found</div>
          )}
          {allComments.map((comment) => (
            <Post
              key={comment._id}
              postId={comment._id}
              profilePic={comment.author.profilePicture}
              author={comment.author.name}
              verified={comment.author.isUserVerified}
              username={comment.author.username}
              time={formatTimeSince(timeSince(comment.timePosted))}
              content={comment.content}
              title={comment.title}
              copyReposts={comment.copyReposts}
              sentiment={comment.sentiment}
              type={comment.type}
              categories={comment.categories}
              bookmarked={comment.isBookmarked}
              isAdmin={currentUser?.isAdmin}
              taggedAssets={comment.taggedAssets}
              onProfileClick={() => {
                if (!isAuthenticated) {
                  alert('Please sign in to view profiles');
                  return;
                }
                navigate(`/profile/${comment.author.username}`);
              }}
              onBookmarkClick={() => handleCommentBookmarkClick(comment._id, comment.isBookmarked)}
              isQuotePost={comment.isQuotePost}
              quotePostId={comment.quotePostId}
              images={comment.images}
              isCopyRepost={false}
              isDownvoted={comment.isDownvoted}
              isUpvoted={comment.isUpvoted}
              caption={comment.caption}
              parentPostId={postId}
              isComment={true}
              refreshFeed={() => {
                setAllComments(allComments.filter((c) => c._id !== comment._id));
                setCommentsCount((prevCount) => prevCount - 1);
              }}
              isAuthorFollowed={comment.isAuthorFollowed || comment.author._id === userId}
              authorId={comment.author._id}
              refreshFollowingStatus={() => refreshFollowingStatus(comment)}
              upvoteCountParam={comment.upvoteCount}
              downvoteCountParam={comment.downvoteCount}
              commentCountParam={comment.commentCount}
              repostCountParam={comment.repostCount}
              isCopyRepostedParam={comment.isCopyReposted}
            />
          ))}
          {loadingComments && (
            <div style={{ textAlign: 'center', margin: '1rem 0' }}>
              <LoadingSpinner />
            </div>
          )}
          {hasMoreComments && !loadingComments && (
              <button
              ref={loadMoreRef}
              style={{ margin: '0 auto', marginTop: '1rem' }}
              className="cp-AddElementButton"
              onClick={() => {
                page.current += 1;
                fetchComments();
              }}
            >
              View more
            </button>
          )}
          {!hasMoreComments && !loadingComments && allComments.length > 0 && (
            <div style={{ textAlign: 'center', margin: '1rem 0' }}>No more comments to show</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PostView;