import axios from "axios";

import { useEffect, useState } from "react";
import config from "../config";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";

const AnalyticDashboard = () => {
  const analyticstoken = localStorage.getItem("analyticstoken");
  const [selectedDate, setSelectedDate] = useState(null);
  const [data, setdata] = useState([]);
  const [filter_date, setfilter_date] = useState("");
  const [total_hits, settotal_hits] = useState("");
  const [total_net_hits, settotal_net_hits] = useState("");
  useEffect(() => {
    getData();
    //eslint-disable-next-line
  }, []);

  const getData = async () => {
    setdata([]);
    try {
      const response = await axios.post(
        `${config.backendUrl}/api/analytics/dashboard`,
        {
          token: analyticstoken,
          filter_date: filter_date,
        }
      );
      if (response.data.status === 1) {
        setdata(response.data.data.records_per_ip);
        settotal_hits(response.data.data.total_hits);
        settotal_net_hits(response.data.data.total_net_hits);
      } else {
        setdata([]);
      }
    } catch (error) {
      setdata([]);
      alert(error.response.data);
    }
  };
  const changeDate = (date) => {
    setSelectedDate(date);
    const demodate = dayjs(date).format("YYYY-MM-DD");
    setfilter_date(demodate);
  };
  useEffect(() => {
    if (filter_date) {
      getData();
    }
  }, [filter_date]);

  return (
    <div className="container analytics-dashboard">
      <div className="row">
        <div className="col-12 pt-5">
          <h1 className="heading-analytics">Dashboard</h1>
        </div>
        <div className="col-12 pt-4">
          <label className="filterdate">
            Filter with date
            <DatePicker
              selected={selectedDate}
              onChange={(date) => changeDate(date)}
              dateFormat="MM/dd/yyyy"
              showYearDropdown
              showMonthDropdown
              dropdownMode="select"
              placeholderText="Filter with date"
            />
          </label>
        </div>
        <div className="col-12 pt-4">
          <div className="card-records">
            <h2>{total_hits}</h2>
            <p>Total hits</p>
          </div>
          <div className="card-records">
            <h2>{total_net_hits}</h2>
            <p>Total net hits</p>
          </div>
        </div>

        <div className="col-12 pt-4">
          <div className="analytics-table-card">
            <table className="analytics-table">
              <thead>
                <tr>
                  <th>Page</th>
                  <th>Count</th>
                </tr>
              </thead>
              <tbody>
                {data.length == 0 && (
                  <tr>
                    <td colSpan={2}>No Data</td>
                  </tr>
                )}
                {data.map((item, index) => (
                  <tr key={index}>
                    <td>{item.page}</td>
                    <td>{item.count}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyticDashboard;
