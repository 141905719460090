import axios from 'axios';
import config from '../config';
import { formatCurrency as fc, formatModifiedTicker, getModifiedTicker } from './StylesHelper';
import { getImageUrl } from './StylesHelper';
import { getChangePercentageColor } from './MarketsHelper';
import VerifiedIcon from '../components/VerifiedIcon';


export const fetchBestUsers = async (
  timePeriod,
  sortBy = '%return',
  page = 1,
  limit = 5,
  setLeaderboardData,
  filter = 'all',
  searchQuery = '',
  asc = false,
  userToken = null,
) => {
  try {
    const response = await axios.get(`${config.backendUrl}/api/portfolios/top-portfolios`, {
      params: {
        timePeriod,
        sortBy,
        page,
        limit,
        filter,
        searchQuery,
        asc,
      },
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });
    setLeaderboardData(response.data);
  } catch (error) {
    console.error('Error fetching leaderboard data:', error);
  }
};


export const fetchBestLeaderboards = async (
  timePeriod = 'Today',
  sortBy = '%return',
  type = 'all',
  page = 1,
  limit = 5,
  setLeaderboardData,
  parentLeaderboard = null,
  searchQuery = '',
  asc = false,
  userToken = null,
) => {
  try {
    const response = await axios.get(`${config.backendUrl}/api/leaderboards/top-leaderboards`, {
      params: {
        timePeriod,
        sortBy,
        page,
        limit,
        parentLeaderboard,
        searchQuery,
        type,
        asc,
      },
      headers: {
        Authorization: userToken ? `Bearer ${userToken}` : '',
      },
    });

    setLeaderboardData(response.data);
  } catch (error) {
    console.error('Error fetching top leaderboards:', error);
  }
};



export const sortOptions = [
  { value: '%return', label: 'Best % Returns', asc: false },
  { value: '%return', label: 'Worst % Returns', asc: true },
  { value: 'netReturn', label: 'Best Net Returns', asc: false },
  { value: 'netReturn', label: 'Worst Net Returns', asc: true },
]

  export function renderLeaderboardCardUser(topPortfolio, index, selectedSortOption, navigate, tc) {
    const { user, percentageGain, netGain, largestPositionTicker } = topPortfolio;
    return (
      <div
        key={index}
        className="LeadCard"
        onClick={() => navigate(`/profile/${user.username}`)}
      >
        <div
          style={{ color: index === 0 ? '#FFBF19' : 'inherit' }}
          className="LeadCardRank"
        >
          {index + 1}
        </div>
        <div
          className="LeadCardImageWrapper"
          style={{
            boxShadow: index === 0 ? '0 0 0.6rem #030902, 0 0 1rem #FFBF19' : 'none'
          }}
        >
          <img
            src={getImageUrl(user.profilePicture)}
            alt={user.name}
            className="LeadCardImage"
          />
        </div>
        <div>
          <h3 className="LeadCardName">{user.name} {user.isUserVerified ? 
            <VerifiedIcon color={ index === 0 ? '#FFBF19' : 'var(--stock-change-pos)' } fontSize='1.3rem'/> : ''}</h3>
          <div
            className="LeadCardGain bold"
            style={{ color:  index === 0 ? '#FFBF19' : getChangePercentageColor(percentageGain, tc()) }}
          >
            {selectedSortOption === '%return' ? (
              <>
                <span className="LeadGainArrow">{percentageGain >= 0 ? '↑' : '↓'}</span>
                {fc(percentageGain, 2, true)}%
              </>
            ) : (
              <>
                <span className="LeadGainArrow">{netGain >= 0 ? '↑' : '↓'}</span>
                ${fc(netGain, 2, true)}
              </>
            )}
          </div>
        </div>
        <div className="LeadCardLargestPosition">
          <h3 className="LeadCardName" style={{ color: 'var(--action-grey)' }}>
            Largest Position
          </h3>
          <span 
           style={{ color:index === 0 ? '#FFBF19' : 'var(--action-grey)'}}
          className="bold">{formatModifiedTicker(largestPositionTicker) || '--'}</span>
        </div>
      </div>
    );
  }


  export function renderLeaderboardCardLeaderboard(topLeaderboard, index, selectedSortOption, navigate, tc) {
    let leaderboard = topLeaderboard;
    let { percentageGain, netGain, totalMembers } = leaderboard;

    return (
      <div
        key={index}
        className="LeadCard"
        onClick={() => navigate(`/leaderboard/${leaderboard.username}`)}
      >
        <div
          style={{ color: index === 0 ? '#FFBF19' : 'inherit' }}
          className="LeadCardRank"
        >
          {index + 1}
        </div>
        <div className="LeadCardImageWrapper LeaderRadius"  
        style={{
            boxShadow: index === 0 ? '0 0 0.6rem #030902, 0 0 1rem #FFBF19' : 'none'
          }}>
          <img
            src={getImageUrl(leaderboard.profileImage)}
            alt={leaderboard.name}
            className="LeadCardImage"
          />
        </div>
        <div>
          <h3 className="LeadCardName">{leaderboard.name} {leaderboard.isLeaderboardVerified ?
           <VerifiedIcon fontSize='1.3rem' color={index === 0 ? '#FFBF19' : 'var(--stock-change-pos)' }/> : ''}</h3>
          <div
            className="LeadCardGain bold"
            style={{ color: index === 0 ? '#FFBF19' : getChangePercentageColor(percentageGain, tc()) }}
          >
            {selectedSortOption === '%return' ? (
              <>
                <span className="LeadGainArrow">{percentageGain >= 0 ? '↑' : '↓'}</span>
                {fc(percentageGain, 2, true)}%
              </>
            ) : (
              <>
                <span className="LeadGainArrow">{netGain >= 0 ? '↑' : '↓'}</span>
                ${fc(netGain, 2, true)}
              </>
            )}
          </div>
        </div>
        <div className="LeadCardLargestPosition">
          <h3 className="LeadCardName" style={{ color: 'var(--action-grey)' }}>
            {fc(totalMembers,0)} members
          </h3>
         
        </div>
      </div>
    );
  }



  export const handleToggleVerificationLeaderboard = async (leaderboardIdToVerify, userToken, callback = null) => {
    if (!window.confirm('Are you sure you want to verify/unverify this leaderboard?')) {
      return;
    }
  
    try {
      const response = await axios.put(
        `${config.backendUrl}/api/leaderboards/verify-leaderboard`,
        { leaderboardIdToVerify: leaderboardIdToVerify },
        { headers: { Authorization: `Bearer ${userToken}` } }
      );
  
      // Optionally call any callback passed in
      if (typeof callback === 'function') {
        callback(response.data);
      }
  
      // For a simple user experience, you might display a message or refresh data:
      alert(response.data.message);
    } catch (error) {
      console.error('Error verifying leaderboard:', error);
      alert('Error verifying leaderboard, please check console.');
    }
  };

  export const handleDeleteLeaderboard = async (
    leaderboardIdToDelete,
    userToken,
    callback = null
  ) => {
    const confirmed = window.confirm(
      'Are you sure you want to delete this leaderboard?\nThis action cannot be undone.'
    );
    if (!confirmed) return;
  
    try {
      const response = await axios.delete(
        `${config.backendUrl}/api/leaderboards/${leaderboardIdToDelete}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
  
      // Call the callback if provided
      if (typeof callback === 'function') {
        callback(response.data);
      }
  
      alert(response.data.message || 'Leaderboard deleted successfully!');
    } catch (error) {
      console.error('Error deleting leaderboard:', error);
      const errorMsg =
        error.response?.data?.message ||
        'Error deleting leaderboard. Please check the console.';
      alert(errorMsg);
    }
  };

  export const lbSections = [
    'Colleges',
    'College Classes',
    'High Schools',
    'High School Classes',
    'Companies',
    'Random',
]