import { useEffect, useRef } from 'react';

/**
 * Hook to detect clicks outside an element.
 * @param {function} onOutsideClick - Function to call when outside click is detected.
 * @param {boolean} active - Whether the listener is active.
 * @param {React.RefObject} excludeRef - Optional ref to exclude from outside detection.
 */
const useClickOutside = (onOutsideClick, active = true, excludeRef = null) => {
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !(excludeRef?.current && excludeRef.current.contains(event.target))
      ) {
        onOutsideClick();
      }
    };

    if (active) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onOutsideClick, active, excludeRef]);

  return ref;
};

export default useClickOutside;