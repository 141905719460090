import React, { useContext, useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import '../styles/SignIn.css';
import config from '../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import useResponsiveWidth from '../hooks/useResponsiveWidth';

// Example default PFPs
import defaultPfpOption1 from '../assets/defaultPfpOption1.png';
import defaultPfpOption2 from '../assets/defaultPfpOption2.png';
import defaultPfpOption3 from '../assets/defaultPfpOption3.png';

const SignIn = () => {
  useResponsiveWidth();

  const { signIn } = useContext(AuthContext);
  const navigate = useNavigate();

  // "view" can be: 'createAccount', 'createPin', 'createPfp', 'signIn', 'enterPin'
  const [view, setView] = useState('createAccount');

  // Basic account creation fields
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');

  // 12 digits = 6 + 6 for verify
  const [otpCode, setOtpCode] = useState(Array(12).fill(''));
  const otpCodeRefs = useRef([]);

  // Terms checkboxes
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [financialDisclaimerAccepted, setFinancialDisclaimerAccepted] = useState(false);

  // After user is created, store them + token
  const [newUserAccount, setNewUserAccount] = useState(null);

  // For the createPfp step:
  const defaultPfps = [
    { src: defaultPfpOption1, filename: 'defaultPfpOption1.png' },
    { src: defaultPfpOption2, filename: 'defaultPfpOption2.png' },
    { src: defaultPfpOption3, filename: 'defaultPfpOption3.png' },
  ];
  const [selectedPfpFilename, setSelectedPfpFilename] = useState(null); 
  const [selectedPfpFile, setSelectedPfpFile] = useState(null); 
  // We'll store a "File" object here once we've fetched the blob

  // ---------- RENDERING THE VIEWS ----------
  const renderView = () => {
    return (
      <div className="si-auth-content">
        {/* Buttons for Sign in vs. Create account */}
        <div className="SignNavItems">
          <button
            className={`SignNavButton bold ${
              view === 'signIn' || view === 'enterPin' ? 'SignNavButtonActive' : ''
            }`}
            onClick={() => setView('signIn')}
          >
            <span className="si-btn-txt bold">Sign in</span>
          </button>
          <button
            className={`SignNavButton bold ${
              view === 'createAccount' || view === 'createPin' || view === 'createPfp'
                ? 'SignNavButtonActive'
                : ''
            }`}
            onClick={() => setView('createAccount')}
          >
            <span className="si-btn-txt bold">Create account</span>
          </button>
        </div>
        {renderViewContent()}
      </div>
    );
  };

  const renderViewContent = () => {
    if (view === 'createAccount') {
      return (
        <div className="si-auth-form-wrapper">
          <form className="si-auth-form" onSubmit={handleCreateAccountSubmit}>
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
            <input
              type="number"
              placeholder="Phone Number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />

            <button
              type="submit"
              className="CreatePostFeedButton greenButton moreButtonPadding si-form-btn"
            >
              Create account
            </button>
          </form>
        </div>
      );
    }

    if (view === 'createPin') {
      return (
        <div className="si-auth-form-wrapper" style={{ marginTop: '3rem' }}>
          <div className="pv-BackToFeedContainer" style={{ marginBottom: '3rem' }}>
            <button
              onClick={() => {
                setView('createAccount');
              }}
              className="pv-BackButton"
              style={{ padding: 0, fontSize: 'var(--font-med-xl)' }}
            >
              <FontAwesomeIcon icon={faArrowLeft} style={{ color: 'var(--action-grey)' }} />{' '}
              <span style={{ color: 'var(--action-grey)' }}>Back</span>
            </button>
          </div>

          <form onSubmit={handleOtpCreate}>
            <span className="pin-header-label">Create 6-Digit Numeric PIN</span>
            <div className="pin-inputs">
              {Array(6)
                .fill('')
                .map((_, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength="1"
                    className="pin-input"
                    readOnly
                    data-row="1"
                    value={otpCode[index] || ''}
                    onClick={() => otpCodeRefs.current[index].focus()}
                    onKeyDown={(e) => handleOtpCodeChange(e, index, 1)}
                    ref={(el) => (otpCodeRefs.current[index] = el)}
                  />
                ))}
            </div>
            <span className="pin-header-label">Verify PIN</span>
            <div className="pin-inputs">
              {Array(6)
                .fill('')
                .map((_, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength="1"
                    className="pin-input"
                    readOnly
                    data-row="2"
                    value={otpCode[index + 6] || ''}
                    onClick={() => otpCodeRefs.current[index + 6].focus()}
                    onKeyDown={(e) => handleOtpCodeChange(e, index + 6, 2)}
                    ref={(el) => (otpCodeRefs.current[index + 6] = el)}
                  />
                ))}
            </div>

            {/* Terms */}
            <div className="terms-container-signup">
              <label className="terms-label">
                <input
                  type="checkbox"
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                  required
                  className="checkbox-input"
                  style={{ margin: 0, width: 'auto' }}
                />
                <span>
                  I have read and agreed to the{' '}
                  <a target="_blank" href="/privacy" className="si-link bold" rel="noreferrer">
                    privacy policy
                  </a>{' '}
                  and
                  <a target="_blank" href="/terms" className="si-link bold" rel="noreferrer">
                    {' '}
                    terms of service
                  </a>
                  .
                </span>
              </label>
            </div>

            {/* Disclaimer */}
            <div className="disclaimer-container-signup">
              <label className="disclaimer-label">
                <input
                  type="checkbox"
                  checked={financialDisclaimerAccepted}
                  onChange={(e) => setFinancialDisclaimerAccepted(e.target.checked)}
                  required
                  className="checkbox-input"
                  style={{ margin: 0, width: 'auto' }}
                />
                <span>
                  I understand that content on Vestr is not financial advice. Vestr is a tool to inform me, not make
                  decisions for me.
                </span>
              </label>
            </div>

            <button
              type="submit"
              className="CreatePostFeedButton greenButton moreButtonPadding si-form-btn"
              style={{ marginTop: '3rem' }}
            >
              Create account
            </button>
          </form>
        </div>
      );
    }

    if (view === 'createPfp') {
      return (
        <div className="si-auth-form-wrapper createPfpContainer" style={{ marginTop: '3rem' }}>
          <div style={{ marginBottom: '1rem', textAlign: 'center' }}>
            <h2 style={{ margin: 0 }}>Pick a profile picture</h2>
            <p style={{ marginTop: '0.5rem', fontSize: 'var(--font-med-xl)' }}>
              You can change your profile picture at any time.
            </p>
          </div>
          <div className="createPfpOptions">
            {defaultPfps.map((pfp, idx) => (
              <img
                key={idx}
                src={pfp.src}
                alt={`default-pfp-${idx + 1}`}
                className={`createPfpOption ${
                  selectedPfpFilename === pfp.filename ? 'selected' : ''
                }`}
                onClick={() => handleSelectPfp(pfp)}
              />
            ))}
          </div>
          <button
            onClick={handlePfpSubmit}
            className="CreatePostFeedButton greenButton moreButtonPadding si-form-btn"
            style={{ marginTop: '2rem' }}
            disabled={!selectedPfpFile} 
          >
            Save profile picture
          </button>
        </div>
      );
    }

    if (view === 'enterPin') {
      return (
        <div className="si-auth-form-wrapper" style={{ marginTop: '3rem' }}>
          <div className="pv-BackToFeedContainer" style={{ marginBottom: '3rem' }}>
            <button
              onClick={() => {
                setView('signIn');
              }}
              className="pv-BackButton"
              style={{ padding: 0, fontSize: 'var(--font-med-xl)' }}
            >
              <FontAwesomeIcon icon={faArrowLeft} style={{ color: 'var(--action-grey)' }} />{' '}
              <span style={{ color: 'var(--action-grey)' }}>Back</span>
            </button>
          </div>

          <form onSubmit={handleOtpSubmit}>
            <span className="pin-header-label">Enter PIN</span>
            <div className="pin-inputs">
              {Array(6)
                .fill('')
                .map((_, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength="1"
                    className="pin-input"
                    readOnly
                    data-row="1"
                    value={otpCode[index] || ''}
                    onClick={() => otpCodeRefs.current[index].focus()}
                    onKeyDown={(e) => handleOtpCodeChange(e, index, 1)}
                    ref={(el) => (otpCodeRefs.current[index] = el)}
                  />
                ))}
            </div>
            <button
              type="submit"
              className="CreatePostFeedButton greenButton moreButtonPadding si-form-btn"
              style={{ marginTop: '3rem' }}
            >
              Sign in
            </button>
          </form>
        </div>
      );
    }

    if (view === 'signIn') {
      return (
        <div className="si-auth-form-wrapper">
          <form className="si-auth-form" onSubmit={handleLoginSubmit}>
            <input
              type="number"
              placeholder="Phone Number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
            <button
              type="submit"
              className="CreatePostFeedButton greenButton moreButtonPadding si-form-btn"
            >
              Sign in
            </button>
          </form>
        </div>
      );
    }

    return null;
  };

  // ---------- HANDLERS ----------
  const handleOtpCodeChange = (e, index, rowIndex) => {
    const newOtpCode = [...otpCode];
    const isLastInRow = rowIndex === 1 ? 5 : 11;
  
    if (/^[0-9]$/.test(e.key)) {
      newOtpCode[index] = e.key;
      setOtpCode(newOtpCode);

      if (
        index < isLastInRow &&
        otpCodeRefs.current[index + 1].dataset.row === `${rowIndex}`
      ) {
        otpCodeRefs.current[index + 1].focus();
      }
    } else if (e.key === 'Backspace') {
      if (newOtpCode[index]) {
        newOtpCode[index] = '';
      } else if (index > 0) {
        otpCodeRefs.current[index - 1].focus();
        newOtpCode[index - 1] = '';
      }
      setOtpCode(newOtpCode);
    } else if (
      e.key === 'ArrowLeft' &&
      index > 0 &&
      otpCodeRefs.current[index - 1].dataset.row === `${rowIndex}`
    ) {
      otpCodeRefs.current[index - 1].focus();
    } else if (
      e.key === 'ArrowRight' &&
      index < isLastInRow &&
      otpCodeRefs.current[index + 1].dataset.row === `${rowIndex}`
    ) {
      otpCodeRefs.current[index + 1].focus();
    }
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${config.backendUrl}/api/auth/signin`, {
        phone,
      });
      if (response.status === 200) {
        setView('enterPin');
      }
    } catch (error) {
      console.error(error);
      alert('Error signing in');
    }
  };

  const handleCreateAccountSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${config.backendUrl}/api/auth/signup`, {
        email,
        username,
        phone,
        name,
      });
      if (response.status === 200) {
        setView('createPin');
      }
    } catch (error) {
      alert(error.response.data);
    }
  };

  const handleOtpCreate = async (e) => {
    e.preventDefault();
    if (!termsAccepted || !financialDisclaimerAccepted) {
      alert('Please accept the terms and disclaimer');
      return;
    }
    const otp = otpCode.join('');
    if (otp.length !== 12 || otp.slice(0, 6) !== otp.slice(6, 12)) {
      alert('Invalid PIN. Make sure the PIN is 6 digits and matches the verification PIN.');
      return;
    }

    try {
      const response = await axios.post(`${config.backendUrl}/api/auth/create-otp`, {
        otp: otp.slice(0, 6),
        email,
        username,
        phone,
        name,
      });
      if (response.status === 200) {
        setNewUserAccount(response.data.user);
        setView('createPfp');
      }
    } catch (error) {
      console.error(error);
      alert('Error creating PIN');
    }
  };

  // handle user entering PIN to sign in
  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${config.backendUrl}/api/auth/verify-otp`, {
        phone,
        otp: otpCode.join(''),
      });
      if (response.status === 200) {
        signIn(response.data.user);
        navigate('/');
      }
    } catch (error) {
      alert(error.response.data);
    }
  };

  // When user clicks one of the 3 PFP images
  const handleSelectPfp = async (pfp) => {
    // We'll store just the filename in 'selectedPfpFilename' 
    // then also fetch the local image as a blob => create a File
    try {
      const resp = await fetch(pfp.src);
      const blob = await resp.blob();
      const file = new File([blob], pfp.filename, { type: blob.type });
      
      setSelectedPfpFilename(pfp.filename);
      setSelectedPfpFile(file);
    } catch (err) {
      console.error('Error loading local image as file:', err);
      alert('Error loading image. Please try again.');
    }
  };

  // Submitting the selected PFP
  const handlePfpSubmit = async () => {
    if (!selectedPfpFile) {
      alert('Please select a profile picture');
      return;
    }

    const token = newUserAccount?.token;
    const userId = newUserAccount?.id;
    if (!token || !userId) {
      alert('An error has occured. Please refresh the page and sign in.');
      return;
    }

    try {
      // We'll do a multipart/form-data with FormData
      const formData = new FormData();
      formData.append('userId', userId);
      formData.append('username', username);
      formData.append('name', name);
      formData.append('bio', '');
      formData.append('social', '');
      // The server expects "profilePicture" for the key
      formData.append('profilePicture', selectedPfpFile);

      // Now do a PUT with axios
      await axios.put(`${config.backendUrl}/api/auth/edit-profile`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });

      // Done => signIn the user object we have or just navigate
      let userWithNewPfp = { ...newUserAccount, profilePicture: `profile-pictures/${selectedPfpFile.name}` };
      signIn(userWithNewPfp);
      navigate('/');
    } catch (err) {
      console.error(err);
      alert('Error updating profile picture');
    }
  };

  return (
    <div className="signin-container">
      <h1 className="si-main-header">Invest with the best.</h1>
      <div className="si-main-content">
        <div className="si-intro-text">
          <h2>The Vestr Vision</h2>
          <p style={{ paddingTop: '3rem' }}>
            We envision a <b>finance super app</b> complete with leaderboards, live trading, sim trading,
            feed, news, rooms, copy trading, subscriptions, professional research, trading AI and more. We
            want to be the <b>home for the retail revolution</b>, where users can find experts and experts
            find their audience.
          </p>
          <p>
            Also check out{' '}
            <a
              className="si-link bold"
              href="https://vestrdata.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              vestrdata.com
            </a>
            !
          </p>
        </div>
        {renderView()}
      </div>
    </div>
  );
};

export default SignIn;