import React, { createContext, useState, useEffect } from 'react'

export const AuthContext = createContext()

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [loading, setLoading] = useState(true)
  const [username, setUsername] = useState(null)
  const [email, setEmail] = useState(null)
  const [userId, setUserId] = useState(null)
  const [userToken, setUserToken] = useState(null)
  const [name, setName] = useState(null)
  const [profilePicture, setProfilePicture] = useState(null)
  const [phoneNum, setPhoneNum] = useState(null)
  const [isPublic, setIsPublic] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const [visibleSection, setVisibleSection] = useState('posts')

  const handleSwitchSection = (section) => {
    setVisibleSection(section)
  }

  useEffect(() => {
    const storedAuthState = localStorage.getItem('isAuthenticated')
    const storedUsername = localStorage.getItem('username')
    const storedEmail = localStorage.getItem('email')
    const storedUserId = localStorage.getItem('userId')
    const storedUserToken = localStorage.getItem('userToken')
    const storedName = localStorage.getItem('name')
    const storedProfilePicture = localStorage.getItem('profilePicture')
    const storedPhoneNum = localStorage.getItem('phoneNum')
    const storedIsPublic = localStorage.getItem('isPublic')
    const storedIsAdmin = localStorage.getItem('isAdmin')

    if (storedPhoneNum) {
      setPhoneNum(storedPhoneNum)
    }

    if (storedIsPublic) {
      setIsPublic(JSON.parse(storedIsPublic))
    }

    if (storedProfilePicture) {
      setProfilePicture(storedProfilePicture)
    }

    if (storedName) {
      setName(storedName)
    }
    if (storedAuthState) {
      setIsAuthenticated(JSON.parse(storedAuthState))
    }
    if (storedUsername) {
      setUsername(storedUsername)
    }
    if (storedEmail) {
      setEmail(storedEmail)
    }
    if (storedUserId) {
      setUserId(storedUserId)
    }

    if (storedUserToken) {
      setUserToken(storedUserToken)
    }

    if (storedIsAdmin) {
      setIsAdmin(JSON.parse(storedIsAdmin))
    }

    setLoading(false)
  }, [])

  const signIn = (data) => {
    console.log(data)
    setIsAuthenticated(true)
    setUsername(data.username)
    setEmail(data.email)
    setUserId(data.id)
    setUserToken(data.token)
    setName(data.name)
    setProfilePicture(data.profilePicture)
    setPhoneNum(data.phoneNum)
    setIsPublic(data.isPublic)
    setIsAdmin(data.isAdmin)

    localStorage.setItem('isAuthenticated', true)
    localStorage.setItem('username', data.username)
    localStorage.setItem('email', data.email)
    localStorage.setItem('userId', data.id)
    localStorage.setItem('userToken', data.token)
    localStorage.setItem('name', data.name)
    localStorage.setItem('profilePicture', data.profilePicture)
    localStorage.setItem('phoneNum', data.phoneNum)
    localStorage.setItem('isPublic', data.isPublic)
    localStorage.setItem('isAdmin', data.isAdmin)
  }

  const signOut = () => {
    setIsAuthenticated(false)
    setUsername(null)
    setEmail(null)
    setUserId(null)
    setUserToken(null)
    setName(null)
    setProfilePicture(null)
    setPhoneNum(null)
    setIsPublic(false)
    setIsAdmin(false)
    localStorage.removeItem('isAuthenticated')
    localStorage.removeItem('username')
    localStorage.removeItem('email')
    localStorage.removeItem('userId')
    localStorage.removeItem('userToken')
    localStorage.removeItem('name')
    localStorage.removeItem('profilePicture')
    localStorage.removeItem('phoneNum')
    localStorage.removeItem('isPublic')
    localStorage.removeItem('isAdmin')
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        signIn,
        signOut,
        loading,
        email,
        username,
        userId,
        userToken,
        setUsername,
        name,
        setName,
        profilePicture,
        setProfilePicture,
        phoneNum,
        isPublic,
        setPhoneNum,
        setIsPublic,
        setEmail,
        handleSwitchSection,
        visibleSection,
        isAdmin,
      }}
    >
      {!loading && children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
