import React, { useState } from 'react';
import Cropper from 'react-easy-crop';
import { Dialog, DialogContent } from '@mui/material';

function getCroppedImg(imageSrc, croppedAreaPixels) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = imageSrc;
    image.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = croppedAreaPixels.width;
      canvas.height = croppedAreaPixels.height;
      const ctx = canvas.getContext('2d');

      ctx.drawImage(
        image,
        croppedAreaPixels.x,
        croppedAreaPixels.y,
        croppedAreaPixels.width,
        croppedAreaPixels.height,
        0,
        0,
        croppedAreaPixels.width,
        croppedAreaPixels.height
      );

      canvas.toBlob(
        (blob) => {
          resolve(blob);
        },
        'image/jpeg',
        0.8
      );
    };
    image.onerror = (error) => reject(error);
  });
}

/**
 * Props:
 * - open: boolean
 * - onClose: function
 * - imageSrc: string (data URL or image URL)
 * - aspect: number (e.g. 1 for square, 6/1 for banner)
 * - cropShape: 'round' | 'rect'
 * - onSave: function(blobUrl: string, blob: Blob)
 *   Called when user clicks 'Save' after cropping.
 */

const ImageCropperDialog = ({
  open,
  onClose,
  imageSrc,
  aspect,
  cropShape,
  onSave,
  darkMode = false,
  ref = null,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const handleCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleSave = async () => {
    try {
      const croppedImageBlob = await getCroppedImg(imageSrc, croppedAreaPixels);
      const croppedImageUrl = URL.createObjectURL(croppedImageBlob);
      onSave(croppedImageUrl, croppedImageBlob);
    } catch (error) {
      console.error('Error cropping image', error);
    }
  };

  return (
    <Dialog
    disablePortal
    ref={ref}
      sx={{
        '& .MuiDialog-paper': {
          padding: '2rem',
          borderRadius: '1rem',
          backgroundColor: darkMode ? '#030902' : '#fff',
        },
      }}
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogContent>
        {imageSrc && (
          <div className="crop-container" style={{ position: 'relative', width: '100%', height: '60vh' }}>
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={aspect}
              cropShape={cropShape}
              showGrid={false}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={handleCropComplete}
            />
          </div>
        )}
        <div className="crop-actions" style={{ marginTop: '1rem' }}>
          <span style={{ display: 'block', marginBottom: '0.5rem' }}>Scale: {zoom}x</span>
          <input
            type="range"
            min={1}
            max={3}
            step={0.1}
            value={zoom}
            onChange={(e) => setZoom(e.target.value)}
            style={{ width: '100%' }}
          />
          <div className="crop-buttons" style={{ display: 'flex', gap: '1rem', marginTop: '1rem' }}>
            <button className="pr-follow-btn bold" onClick={handleSave}>
              Save
            </button>
            <button className="pr-follow-btn bold" onClick={onClose}>
              Cancel
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ImageCropperDialog;