import React from 'react';
import '../styles/TermsOfService.css'; // Import the stylesheet for this component
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import useResponsiveWidth from '../hooks/useResponsiveWidth';
const TermsOfService = () => {
  useResponsiveWidth();
  const location = useLocation();
  const fromSettings = location.state?.fromSettings || false;
  const navigate = useNavigate();
  return (
    <div className="terms-container">
      {/* Borrowing styles from post view */}
      {fromSettings && <div className="pv-BackToFeedContainer">
        <button onClick={() =>{
            navigate('/settings');
        }} className="pv-BackButton">
          <FontAwesomeIcon icon={faArrowLeft} /> <span style = {{color: 'var(--action-grey)'}}>Settings</span>
        </button>
      </div>
      }

      <h1 className="terms-title bold">Terms of Service</h1>

      <section className="terms-section">
        <h2 className="terms-subtitle bold">Introduction</h2>
            Welcome to Vestr. By accessing or using our platform, you agree to comply with these Terms of Service ("Terms").
            Please read them carefully. If you do not agree with these Terms, you should not use our services.
      </section>

      <section className="terms-section">
        <h2 className="terms-subtitle bold">Use of Our Services</h2>
        <ul className="terms-list">
          <li className="terms-text">
            <span className="bold">Eligibility:</span> You must be at least 13 years old to use Vestr. Users under 18 may only engage in simulated trading and other non-monetary activities. To access real financial trading features, you must be 18 years or older or meet the legal age requirements in your jurisdiction.
          </li>
          <li className="terms-text">
            <span className="bold">Account Creation:</span> When creating an account, you agree to provide accurate information and keep your login credentials confidential.
          </li>
          <li className="terms-text">
            <span className="bold">Platform Use:</span> You agree to use Vestr for lawful purposes only. You must not use the platform to engage in illegal or unauthorized activities, including market manipulation or fraudulent trading.
          </li>
          <li className="terms-text">
            <span className="bold">Service Availability:</span> Vestr reserves the right to modify, suspend, or discontinue the platform at any time without notice.
          </li>
        </ul>
      </section>

      <section className="terms-section">
        <h2 className="terms-subtitle bold">User Content</h2>
        <ul className="terms-list">
          <li className="terms-text">
            <span className="bold">Content Ownership:</span> You retain ownership of the content you post (e.g., research, comments), but grant Vestr a license to use, distribute, and display your content on the platform.
          </li>
          <li className="terms-text">
            <span className="bold">Prohibited Content:</span> You may not post content that is defamatory, fraudulent, harmful, abusive, or violates any third-party rights.
          </li>
          <li className="terms-text">
            <span className="bold">Content Moderation:</span> Vestr reserves the right to review, remove, or block any content that violates these Terms or our community guidelines.
          </li>
        </ul>
      </section>

      <section className="terms-section">
        <h2 className="terms-subtitle bold">Financial Services and Trading</h2>
        <ul className="terms-list">
          <li className="terms-text">
            <span className="bold">Simulated Trading:</span> Users under 18 can participate in simulated trading, which mirrors real financial markets but does not involve actual money.
          </li>
          <li className="terms-text">
            <span className="bold">Real Financial Trading:</span> To engage in real asset trading, including stock purchases, sales, or copy trading, you must be at least 18 years old or the legal age for such activities in your jurisdiction.
          </li>
          <li className="terms-text">
            <span className="bold">No Financial Advice:</span> The content on Vestr is for informational purposes only and should not be construed as investment advice. Vestr does not endorse or guarantee the accuracy of any information posted by users or professional investors.
          </li>
          <li className="terms-text">
            <span className="bold">Trading Limits:</span> Vestr may impose trading or account limits as necessary to ensure the platform's security and compliance.
          </li>
        </ul>
      </section>

      <section className="terms-section">
        <h2 className="terms-subtitle bold">Fees and Payments</h2>
        <ul className="terms-list">
          <li className="terms-text">
            <span className="bold">Transaction Fees:</span> Vestr may charge fees for transactions conducted on the platform. All applicable fees will be clearly disclosed prior to the execution of any transaction.
          </li>
          <li className="terms-text">
            <span className="bold">Payment Methods:</span> You authorize Vestr to charge your selected payment method for any applicable fees, including trading commissions or subscription services.
          </li>
        </ul>
      </section>

      <section className="terms-section">
        <h2 className="terms-subtitle bold">Intellectual Property</h2>
        <ul className="terms-list">
          <li className="terms-text">
            <span className="bold">Platform Ownership:</span> Vestr and its licensors retain all rights, title, and interest in the platform, including its software, design, and branding.
          </li>
          <li className="terms-text">
            <span className="bold">User License:</span> Vestr grants you a limited, non-exclusive, and revocable license to use the platform for its intended purposes.
          </li>
        </ul>
      </section>

      <section className="terms-section">
        <h2 className="terms-subtitle bold">Limitation of Liability</h2>
        <ul className="terms-list">
          <li className="terms-text">
            <span className="bold">No Guarantees:</span> Vestr does not guarantee that the platform will be error-free, uninterrupted, or secure. We provide the platform "as is" without any warranties.
          </li>
          <li className="terms-text">
            <span className="bold">Assumption of Risk:</span> You acknowledge that trading and investing carry inherent risks. Vestr is not liable for any financial losses, missed opportunities, or damages arising from your use of the platform.
          </li>
          <li className="terms-text">
            <span className="bold">Indemnification:</span> You agree to indemnify and hold Vestr harmless from any claims, losses, or damages arising out of your violation of these Terms or any applicable laws.
          </li>
        </ul>
      </section>

      <section className="terms-section">
        <h2 className="terms-subtitle bold">Account Termination</h2>
        <ul className="terms-list">
          <li className="terms-text">
            <span className="bold">By User:</span> You may terminate your account at any time by contacting Vestr. Upon termination, you will no longer have access to your portfolio or any platform features.
          </li>
          <li className="terms-text">
            <span className="bold">By Vestr:</span> Vestr reserves the right to suspend or terminate your account for any violation of these Terms, illegal activities, or any actions that disrupt the platform's integrity.
          </li>
        </ul>
      </section>

      <section className="terms-section">
        <h2 className="terms-subtitle bold">Privacy</h2>
            Your use of the platform is also governed by our <a style = {{color: 'var(--stock-change-pos)'}}href="/privacy" className="terms-link bold">Privacy Policy</a>, which outlines how we collect, use, and protect your personal information.
      </section>

      <section className="terms-section">
        <h2 className="terms-subtitle bold">Dispute Resolution</h2>
        <ul className="terms-list">
          <li className="terms-text">
            <span className="bold">Governing Law:</span> These Terms are governed by the laws of Delaware, without regard to its conflict of law principles.
          </li>
          <li className="terms-text">
            <span className="bold">Arbitration:</span> Any disputes arising from or relating to these Terms or your use of the platform will be resolved through binding arbitration, rather than in court.
          </li>
        </ul>
      </section>

      <section className="terms-section">
        <h2 className="terms-subtitle bold">Changes to the Terms</h2>
            Vestr reserves the right to modify these Terms at any time. We will notify users of significant changes, and continued use of the platform after such changes constitutes acceptance of the revised Terms.
      </section>

      <section className="terms-section">
        <h2 className="terms-subtitle bold">Contact Information</h2>
         If you have any questions about these Terms, please contact us at:
        <address className="terms-address">
          Vestr Inc.<br />
          221 N Broad St, Suite 3A<br />
          Middletown, DE 19709<br />
          Email: support@vestr.io
        </address>
      </section>
    </div>
  );
};

export default TermsOfService;
