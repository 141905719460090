import React, { useContext, useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'
import './styles/App.css'
import './styles/NewsV2.css'
import Header from './components/Header'
import Footer from './components/Footer'
import MrVestr from './components/MrVestr'
import Profile from './components/Profile'
import Feed from './components/Feed'
import SignIn from './components/SignIn'
import SignUp from './components/SignUp'
import { AuthContext } from './context/AuthContext'
import { ThemeContext } from './context/ThemeContext'
import CreatePost from './components/CreatePost'
import Settings from './components/Settings'
import PostView from './components/PostView'
import Overview from './components/Overview'
import VestrPlus from './components/VestrPlus'
import Markets from './components/Markets'
import Stock from './components/Stock'
import Option from './components/Option'
import News from './components/News'
import TermsOfService from './components/TermsOfService'
import PrivacyPolicy from './components/PrivacyPolicy'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import PaymentComplete from './components/PaymentComplete'
import Analytics from './components/Analytics'
import Portfolio from './components/Portfolio'
//import NewsV2 from "./components/NewsV2";
import { LoadingSpinner } from './helper/StylesHelper'
import Crypto from './components/Crypto'
import MarketsV2 from './components/MarketsV2'
import ProfileV2 from './components/ProfileV2'
import MobileView from './components/MobileView'
import AnalyticTable from './components/AnalyticTable'
import AnalyticDashboard from './components/AnalyticsDashboard'
import Leaderboards from './components/Leaderboards'
import Leaderboard from './components/Leaderboard'
import AllLeaderboards from './components/AllLeaderboards'

function App() {
  const [isUserLink, setisUserLink] = useState(
    window.location.pathname.includes('analytics')
  )
  const { theme } = useContext(ThemeContext)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768)
    }

    window.addEventListener('resize', handleResize)

    // Cleanup on component unmount
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    document.body.className = theme
    document.documentElement.setAttribute('data-theme', theme)
  }, [theme])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Router>
        {/* {isMobile && (
          <div>
            <MobileView />
          </div>
        )} */}
        {/* {!isMobile && ( */}
        <div>
          {!isUserLink && (
            <div className="App">
              <Header />
              <div className="Content">
                <Routes>
                  <Route
                    path="/payment-complete"
                    element={<PaymentComplete />}
                  />
                  {/* <Route path="/news" element={<NewsV2 />} />*/}
                  <Route path="/" element={<Feed />} />
                  {/* Default route changed from overview*/}
                  <Route path="/vestrplus" element={<VestrPlus />} />
                  <Route path="/feed" element={<Feed />} />
                  <Route path="/post/:postId" element={<PostView />} />
                  <Route path="/terms" element={<TermsOfService />} />
                  <Route path="/privacy" element={<PrivacyPolicy />} />
                  <Route
                    path="/portfolio/:username"
                    element={
                      <ProtectedRoute>
                        <Portfolio />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/profile/:username"
                    element={
                      <ProtectedRoute>
                        <ProfileV2 />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/createpost"
                    element={
                      <ProtectedRoute>
                        <CreatePost />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/mrvestr"
                    element={
                      <ProtectedRoute>
                        <MrVestr />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/signin"
                    element={
                      <AuthRedirect>
                        <SignIn />
                      </AuthRedirect>
                    }
                  />
                  <Route
                    path="/signup"
                    element={
                      <AuthRedirect>
                        <SignUp />
                      </AuthRedirect>
                    }
                  />
                  <Route
                    path="/settings"
                    element={
                      <ProtectedRoute>
                        <Settings />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="/leaderboards" element={<Leaderboards />} />
                  <Route
                    path="/leaderboard/:username"
                    element={
                      <ProtectedRoute>
                        <Leaderboard />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/all-leaderboards/:type"
                    element={
                      <ProtectedRoute>
                        <AllLeaderboards />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="/stocks/:ticker" element={<Stock />} />
                  <Route path="/crypto/:ticker" element={<Crypto />} />
                  <Route path="/options/:optionTicker" element={<Option />} />
                  <Route path="/markets" element={<MarketsV2 />} />
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
              </div>
              <Footer />
            </div>
          )}
          {isUserLink && (
            <div className="App">
              <div className="Content">
                <Routes>
                  <Route path="/analytics" element={<Analytics />}>
                    <Route path="" element={<AnalyticDashboard />} />
                    <Route path="list" element={<AnalyticTable />} />
                  </Route>
                </Routes>
              </div>
            </div>
          )}
        </div>
        {/* )} */}
      </Router>
    </LocalizationProvider>
  )
}

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, loading } = useContext(AuthContext)

  if (loading) {
    return <LoadingSpinner />
  }

  return isAuthenticated ? children : <Navigate to="/signin" />
}

const AuthRedirect = ({ children }) => {
  const { isAuthenticated } = useContext(AuthContext)

  return isAuthenticated ? <Navigate to="/" /> : children
}

export default App
